import React, { useState } from 'react';
import notification from '../../hocs/notification';

import DataTable from 'react-data-table-component';
import mainUrl from '../../../MainURL';
import axios from 'axios';

import Tooltip from '@mui/material/Tooltip';
import { Row, Col, Card, CardHeader, CardBody, Button, Form, Input, Label, Spinner, Modal, ModalBody, ModalHeader } from 'reactstrap';

import { getTodayDate } from '../../hocs/dateServices';
import { FaFilePdf } from 'react-icons/fa';

const imageUrl = 'https://microsave.boxfarming.in/v1/h7jj24643khb3589jn5dc4/';

const GoatProcessPayment = ({ back, ...props }) => {

    const [loading, setLoading] = useState(false);
    const [frmDate, setFrmDate] = useState(getTodayDate());
    const [toDate, setToDate] = useState(getTodayDate());

    const [tableData2, setTableData2] = useState('');
    const [viewModal2, setViewModal2] = useState(false);

    const [bankTransNo, setBankTransNo] = useState('');
    const [chequeNo, setChequeNo] = useState('');
    const [bankTransDate, setBankTransDate] = useState('');
    const [currPayment_id, setCurrPayment_id] = useState('');

    // 
    const payment_list_handler = (e) => {
        e.preventDefault();
        setLoading(true);
        get_pay_list_handler();
    }

    const get_pay_list_handler = () => {
        let postData = [{ "org_id": 100, "frm_date": frmDate, "to_date": toDate }];
        axios({
            method: 'post',
            url: mainUrl + 'Activity/goat_purchase_payment_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {

                if (response.data !== null) {
                    let list = response.data.map((row, index) => {
                        let status_dtl = '';
                        if (row.bank_transaction_id === '0') {
                            status_dtl = <Button size="sm" onClick={() => { change_staus_handler(row.payment_id) }}> Update Payment Details </Button>;
                        } else {
                            let details = <div>
                                <div><b>Transaction Id:</b> {row.bank_transaction_id}</div>
                                <div><b>Cheque No:</b> {row.cheque_no}</div>
                                <div><b>Payment Date:</b> {row.payment_paid_date}</div>
                            </div>;
                            status_dtl = <Tooltip title={details}>
                                <a className='cursor'>See</a>
                            </Tooltip>
                        }
                        return (
                            {
                                sl: index + 1,
                                init_date: row.payment_init_date.split(" ")[0].split('-').reverse().join('-'),
                                pdf: <div><a href={imageUrl + row.payment_init_pdf} target="_blank" ><FaFilePdf color="#ff4136" size={20} /></a></div>,
                                status: status_dtl
                            }
                        )
                    })
                    setTableData2(list);
                }
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            });
    }

    const change_staus_handler = (id) => {
        setViewModal2(true);
        setCurrPayment_id(id)
    }
    const status_submit_handler = (e) => {
        e.preventDefault();
        let postData = [{
            "org_id": 100,
            "payment_id": currPayment_id,
            "bank_transaction_id": bankTransNo,
            "cheque_no": chequeNo,
            "payment_paid_date": bankTransDate,
        }];

        axios({
            method: 'post',
            url: mainUrl + 'Activity/goat_pur_change_pay_status',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                setCurrPayment_id('');
                setBankTransDate('');
                setBankTransNo('');
                setChequeNo('');
                setViewModal2(false);
                let notify = notification({ message: "Status change successfully.", type: 'success' });
                notify();
                get_pay_list_handler();
            })
            .catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            });
    }

    return (
        <>

            <div className='p-3'>
                <Card>
                    <CardBody>
                        <Form onSubmit={payment_list_handler}>
                            <Row>
                                <Col xs={2}>
                                    <Label for="frmDate"> From Date </Label>
                                    <Input id="frmDate" type='date' bsSize="sm" max={toDate} value={frmDate} onChange={(e) => setFrmDate(e.target.value)} />
                                </Col>
                                <Col xs={2}>
                                    <Label for="toDate"> To Date </Label>
                                    <Input id="toDate" type='date' bsSize="sm" max={getTodayDate()} value={toDate} onChange={(e) => setToDate(e.target.value)} />
                                </Col>
                                <Col xs={6}>
                                    <Button className='mt-4' color='primary' size="sm" disabled={loading}> Submit {loading && <Spinner color="secondary ms-1" size="sm" >Load...</Spinner>}</Button>
                                </Col>
                                <Col xs={2} className="text-end">
                                    <Button className='mt-4' size="sm" onClick={back}>Back</Button>
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                </Card>

                <Card>
                    <CardHeader>Goat Purchase Details</CardHeader>
                    <CardBody>
                        <DataTable
                            title=""
                            columns={[
                                {
                                    name: '#',
                                    selector: row => row.sl,
                                    sortable: false,
                                },
                                {
                                    name: 'Bank advice Date',
                                    selector: row => row.init_date,
                                    sortable: false,
                                },
                                {
                                    name: 'Bank advice PDF',
                                    selector: row => row.pdf,
                                    sortable: false,
                                },
                                {
                                    name: 'Status',
                                    selector: row => row.status,
                                    sortable: false,
                                },


                            ]}
                            data={tableData2}
                            striped
                            highlightOnHover
                            progressPending={loading}
                        />

                    </CardBody>
                </Card>
            </div>

            <div className='text-end p-3'>
                <Button size="sm" onClick={back}>Back</Button>
            </div>

            <Modal
                fullscreen={false}
                centered
                scrollable
                size="md"
                isOpen={viewModal2}
                toggle={() => setViewModal2(!viewModal2)}
            >
                <ModalHeader toggle={() => setViewModal2(!viewModal2)}>
                    Change status
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={status_submit_handler}>
                        <Row>
                            <Col xs={12} className="mb-2">
                                <Label for="bankTransNo"> Bank Transaction Number</Label>
                                <Input id="bankTransNo" type='text' bsSize="sm" maxLength={25} value={bankTransNo} onChange={(e) => setBankTransNo(e.target.value)} required />
                            </Col>
                            <Col xs={12} className="mb-2">
                                <Label for="chequeNo"> Cheque Number</Label>
                                <Input id="chequeNo" type='text' bsSize="sm" maxLength={20} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} value={chequeNo} onChange={(e) => setChequeNo(e.target.value)} required />
                            </Col>
                            <Col xs={12} className="mb-2">
                                <Label for="bankTransDate"> Bank Transaction Date</Label>
                                <Input id="bankTransDate" type='date' bsSize="sm" maxLength={25} value={bankTransDate} onChange={(e) => setBankTransDate(e.target.value)} required />
                            </Col>
                            <Col xs={12} className="mb-2">
                                <Button color='primary' size="sm"> Submit </Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </>    

    )
}

export default GoatProcessPayment;