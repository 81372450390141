import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import notification from '../../hocs/notification';
import mainurl from '../../../MainURL';
import axios from 'axios';

import { Col, Row, Card, CardBody, Input, CardHeader, Label, Button, Spinner } from 'reactstrap';

import Select from 'react-select';
import { FaCheck, FaTimes } from 'react-icons/fa';

import FileBase64 from 'react-file-base64';
import GoatItem from './GoatItem';

const GoatPurchaseEntry = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [districtOpt, setDistrictOpt] = useState();
    const [blockOpt, setBlockOpt] = useState();
    const [villageOpt, setVillageOpt] = useState();
    const [panchyatOpt, setPanchyatOpt] = useState();
    const [pashusakhiOpt, setPashusakhiOpt] = useState();
    const [farmerOpt, setFarmerOpt] = useState();
    const [bankOpt, setBankOpt] = useState("");

    const [district, setDistrict] = useState("0");
    const [block, setBlock] = useState("0");
    const [panchyat, setPanchyat] = useState("0");
    const [pashusakhi, setPashusakhi] = useState("");
    const [pashusakhiName, setPashusakhiName] = useState("");
    const [village, setVillage] = useState("");
    const [farmer, setFarmer] = useState("");
    const [bankName, setBankName] = useState("");
    const [accountNo, setAccountNo] = useState("");
    const [ifsc, setIfsc] = useState("");
    const [branchAddress, setBranchAddress] = useState("");
    const [purchaseDate, setPurchaseDate] = useState("");

    const [weight, setWeight] = useState("");
    const [ageName, setAgeName] = useState("");
    const [age, setAge] = useState("0");
    const [genderName, setGenderName] = useState("");
    const [gender, setGender] = useState("0");
    const [price, setPrice] = useState("");

    const [totalPrice, setTotalPrice] = useState(0.00);
    const [totalWeight, setTotalWeight] = useState(0);

    const [receipt_photo, setReceipt_photo] = useState("");
    const [receiptExt, setReceiptExt] = useState("");
    const [receipt_valid, setReceipt_valid] = useState(0);
    const [passbook_photo, setPassbook_photo] = useState("");
    const [passbookExt, setPassbookExt] = useState("");
    const [pass_valid, setPass_valid] = useState(0);
    const [goat_photo, setGoat_photo] = useState("");
    const [goatExt, setGoatExt] = useState("");
    const [goat_valid, setGoat_valid] = useState(0);

    const [goatList, setGoatList] = useState("");


    const [dist_role, setDist_role] = useState(false);
    const [block_role, setBlock_role] = useState(false);
    const [count, setCount] = useState(1);

    useEffect(() => {
        // check Access
        let accData = JSON.parse(sessionStorage.getItem('accessControl'));

        if (accData[2].pages[2].page_view !== "1") {
            navigate("/home");
        }
        //
        getBankList();
        checkRole();
        getDistrictList();
    }, []);

    const checkRole = () => {
        let role = sessionStorage.getItem('role');
        if (role === "101") {
            getDistrictList();
            setCount(2);
        } else if (role === "102") {
            let dist = sessionStorage.getItem('dist');
            setDist_role(true);
            getBlockList({ target: { value: dist } });
            setCount(2);
        } else if (role === "103") {
            let dist = sessionStorage.getItem('dist');
            setDist_role(true);
            setBlock_role(true);
            getBlockList({ target: { value: dist } });

        }
    }

    const getDistrictList = () => {
        setBlock("0");

        let postData = [{ "org_id": 100, "user_id": "0" }];
        axios({
            method: 'post',
            url: mainurl + 'Geo/geo_district_list2',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return <option key={index} value={row.district_short_cd}> {row.district_name} </option>
                })
                setDistrictOpt(list);
            })
            .catch(error => {
                let notify = notification({ message: "District list not Found! Try Again", type: 'error' });
                notify();
            });
    }

    const getBlockList = (e) => {
        setDistrict(e.target.value);
        setBlock("0");
        let postData = [{ "org_id": 100, "user_id": "0", "dist_id": e.target.value }]
        axios({
            method: 'post',
            url: mainurl + 'Geo/geo_block_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return <option key={index} value={row.block_code}> {row.block_long_name} </option>
                })
                setBlockOpt(list);
                if (count === 1) {
                    setCount(2);
                    let block = sessionStorage.getItem('block');
                    setBlock(block);
                }
            })
            .catch(error => {
                let notify = notification({ message: "Block list not Found! Try Again", type: 'error' });
                notify();
            });
    }
    const getPanchayatList = (e) => {
        setBlock(e.target.value);
        setPanchyat("0");
        let postData = [{ "org_id": 100, "user_id": "0", "block_id": e.target.value }]
        axios({
            method: 'post',
            url: mainurl + 'Geo/geo_pan_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return <option key={index} value={row.panchyat_code}> {row.panchyat_name} </option>
                })

                setPanchyatOpt(list);
            })
            .catch(error => {
                let notify = notification({ message: "Panchayat list not Found! Try Again", type: 'error' });
                notify();
            });
    }

    const getPashusakhiList = (e) => {
        setPashusakhi("");
        setPashusakhiName("");
        setPanchyat(e.target.value);

        let postData = [{ "org_id": 101, "user_id": 128, "geo_type_id": "3", "geo_id": e.target.value }]
        axios({
            method: 'post',
            url: mainurl + 'Geo/geo_aps_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    let name = row.aps_name + '[' + row.aps_id + ']';
                    return <option key={index} value={row.aps_id}> {name} </option>
                })

                setPashusakhiOpt(list);
            })
            .catch(error => {
                let notify = notification({ message: "pashusakhi list not Found! Try Again", type: 'error' });
                notify();
            });
    }

    const getVillageList = (e) => {
        setVillage("");
        setVillageOpt("");
        setPashusakhi(e.target.value);

        let postData = [{ "org_id": 100, "user_id": e.target.value }]
        axios({
            method: 'post',
            url: mainurl + 'Geo/geo_village',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return <option key={index} value={row.village_code}> {row.village_name} </option>
                })

                setVillageOpt(list);
            })
            .catch(error => {
                let notify = notification({ message: "village list not Found! Try Again", type: 'error' });
                notify();
            });
    }
    const getFarmerList = (e) => {
        setFarmerOpt("");
        setVillage(e.target.value);

        let postData = [{ "org_id": 100, "user_id": pashusakhi, "ops_type": 1 }]
        axios({
            method: 'post',
            url: mainurl + 'Geo/farmer_list2',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return (
                        { value: row.f_id, label: row.f_first_name, data: row }
                    )
                })

                setFarmerOpt(list);
            })
            .catch(error => {
                let notify = notification({ message: "Farmer list not Found! Try Again", type: 'error' });
                notify();
            });
    }

    const getBankList = () => {
        let postData = [{ "org_id": 100, "user_id": "0" }];
        // Bank list  
        axios({
            method: 'post',
            url: mainurl + 'Master/bank_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return <option key={index} value={row.bank_id}> {row.bank_name} </option>
                })
                setBankOpt(list);

            })
            .catch(error => {
                let notify = notification({ message: "Bank list not Found! Try Again", type: 'error' });
                notify();
            });
    }

    const farmer_handler = (options) => {
        setFarmer(options);

        setBankName(options.data.bank_name);
        setAccountNo(options.data.account_no);
        setIfsc(options.data.ifsc_code);
        setBranchAddress(options.data.branch_name);
    }

    const add_handler = () => {
        let list = {
            "g_gender": gender,
            "genderName": genderName,
            "age_group": age,
            "ageName": ageName,
            "g_weight": weight,
            "g_price": price,
            "g_count": 1
        };

        let prevList = [...goatList];
        prevList.push(list);
        setGoatList(prevList);

        let totPrice = 0;
        let totWeight = 0;
        prevList.map(row => {
            totPrice = totPrice + (Number(row.g_price) * Number(row.g_weight));
            totWeight = totWeight + Number(row.g_weight);
        })
        setTotalPrice(totPrice);
        setTotalWeight(totWeight);

        setGender('0');
        setGenderName('0');
        setAge('0');
        setAgeName('');
        setWeight('');
        setPrice('');

    }

    const item_delete = (id) => {
        let prevList = [...goatList];
        prevList.splice(id, 1);
        setGoatList(prevList);

        let totPrice = 0;
        let totWeight = 0;
        prevList.map(row => {
            totPrice = totPrice + (Number(row.g_price) * Number(row.g_weight));
            totWeight = totWeight + Number(row.g_weight);
        })
        setTotalPrice(totPrice);
        setTotalWeight(totWeight);
    }

    const receipt_image_handler = (files) => {
        console.log(files);
        if (files.size.split(' ')[0] < 500) {

            let imgtype = (files.type).split('/')[1];

            if (imgtype == 'png' || imgtype == 'jpg' || imgtype == 'jpeg' || imgtype == 'bmp') {
                setReceipt_photo(files.base64);
                setReceiptExt(imgtype);
                setReceipt_valid(1);
            } else {
                let notify = notification({ message: "Only image file accepted.", type: 'error' });
                notify();
                setReceipt_valid(2);
            }
        } else {
            let notify = notification({ message: "Image should be less then 500 kb.", type: 'error' });
            notify();
            setReceipt_valid(2);
        }
    }

    const passbook_image_handler = (files) => {
        console.log(files);
        if (files.size.split(' ')[0] < 500) {

            let imgtype = (files.type).split('/')[1];

            if (imgtype == 'png' || imgtype == 'jpg' || imgtype == 'jpeg' || imgtype == 'bmp') {
                setPassbook_photo(files.base64);
                setPassbookExt(imgtype);
                setPass_valid(1);
            } else {
                let notify = notification({ message: "Only image file accepted.", type: 'error' });
                notify();
                setPass_valid(2);
            }
        } else {
            let notify = notification({ message: "Image should be less then 500 kb.", type: 'error' });
            notify();
            setPass_valid(2);
        }
    }
    const goat_image_handler = (files) => {
        console.log(files);
        if (files.size.split(' ')[0] < 500) {

            let imgtype = (files.type).split('/')[1];

            if (imgtype == 'png' || imgtype == 'jpg' || imgtype == 'jpeg' || imgtype == 'bmp') {
                setGoat_photo(files.base64);
                setGoatExt(imgtype);
                setGoat_valid(1);
            } else {
                let notify = notification({ message: "Only image file accepted.", type: 'error' });
                notify();
                setGoat_valid(2);
            }
        } else {
            let notify = notification({ message: "Image should be less then 500 kb.", type: 'error' });
            notify();
            setGoat_valid(2);
        }
    }

    const goat_purchase_handler = () => {
        let flag = true;

        if (village === "0") {
            flag = false;
            let notify = notification({ message: "Please select Village. ", type: 'warn' });
            notify();
        }
        if (panchyat === "0") {
            flag = false;
            let notify = notification({ message: "Please select Panchayat. ", type: 'warn' });
            notify();
        }
        if (block === "0") {
            flag = false;
            let notify = notification({ message: "Please select Block. ", type: 'warn' });
            notify();
        }
        if (district === "0") {
            flag = false;
            let notify = notification({ message: "Please select District. ", type: 'warn' });
            notify();
        }
        if (pashusakhi === "0") {
            flag = false;
            let notify = notification({ message: "Please select Pashusakhi. ", type: 'warn' });
            notify();
        }
        if (farmer === "") {
            flag = false;
            let notify = notification({ message: "Please select shareholder. ", type: 'warn' });
            notify();
        }
        if (bankName === "0") {
            flag = false;
            let notify = notification({ message: "Please select Bank. ", type: 'warn' });
            notify();
        }
        if (accountNo === "") {
            flag = false;
            let notify = notification({ message: "Please enter Account Number. ", type: 'warn' });
            notify();
        }
        if (ifsc === "") {
            flag = false;
            let notify = notification({ message: "Please enter IFSC code. ", type: 'warn' });
            notify();
        }
        if (branchAddress === "") {
            flag = false;
            let notify = notification({ message: "Please enter branch. ", type: 'warn' });
            notify();
        }
        if (purchaseDate === "") {
            flag = false;
            let notify = notification({ message: "Please select Date. ", type: 'warn' });
            notify();
        }
        
        if (goatList.length < 1) {
            flag = false;
            let notify = notification({ message: "Please Add Goat List. ", type: 'warn' });
            notify();
        }

        if (flag) {

            let postData = [{
                "org_id": 100,
                "user_id": pashusakhi,
                "farmer_id": farmer.value,
                "activity_type": "006",
                "activity_date": purchaseDate,
                "activity_charges": totalPrice,
                "goat_count": goatList,
                "total_weight": totalWeight,
                "receipt_img": receipt_photo,
                "bank_passbook_img": passbook_photo,
                "goat_img": goat_photo,
                "account_no": accountNo,
                "ifsc_code": ifsc,
                "bank_name": bankName,
                "branch": branchAddress,
                "sh_type": 0,
                "vill_code": village
            }];

            console.log(postData);
            setLoading(true);
            axios({
                method: 'post',
                url: mainurl + 'Activity/goat_purchase_web',
                data: postData,
                headers: { 'Content-Type': 'application/JSON', }
            })
                .then(function (response) {
                        setLoading(false);
                    if (response.data[0].retcode == 100) {
                        setDistrict('0');
                        setBlock('0');
                        setBlockOpt('');
                        setPanchyat('0');
                        setPanchyatOpt('');
                        setPashusakhi('');
                        setPashusakhiOpt('');
                        setFarmer('');
                        setFarmerOpt('');
                        setVillage('');
                        setVillageOpt('');
                        setPurchaseDate('');
                        setGoatList('');
                        setBankName('0');
                        setAccountNo('');
                        setIfsc('');
                        setBranchAddress('');
                        let notify = notification({ message: "Added sucessfully.", type: 'success' });
                        notify();
                    }
                })
                .catch(error => {
                    setLoading(false);
                    let notify = notification({ message: "Failed! Try Again", type: 'error' });
                    notify();
                });
        }

    }

    return (
        <>
            <Row className="p-3">
                <Col xs={12}>
                    <Card>
                        <CardHeader>Goat Purchase Entry</CardHeader>
                        <CardBody>
                            <Row>
                                <Col xs={2} className="mb-1">
                                    <Label className="me-sm-2" for="district"> District </Label>
                                    <Input id="district" bsSize="sm" type="select" value={district} onChange={(e) => getBlockList(e)} disabled={dist_role}>
                                        <option value="0"> --Select District--</option>
                                        {districtOpt}
                                    </Input>
                                </Col>
                                <Col xs={2} className="mb-1">
                                    <Label className="me-sm-2" for="block"> Block </Label>
                                    <Input id="block" bsSize="sm" type="select" value={block} onChange={getPanchayatList} disabled={block_role}>
                                        <option value="0"> --Select Block--</option>
                                        {blockOpt}
                                    </Input>
                                </Col>
                                <Col xs={2} className="mb-1">
                                    <Label className="me-sm-2" for="panchayat"> Panchayat </Label>
                                    <Input id="panchayat" bsSize="sm" type="select" value={panchyat} onChange={getPashusakhiList}>
                                        <option value="0"> --Select Panchayat--</option>
                                        {panchyatOpt}
                                    </Input>
                                </Col>
                                <Col xs={3} className="mb-1">
                                    <Label className="me-sm-2" for="pashusakhi"> Pashusakhi </Label>
                                    <Input id="pashusakhi" bsSize="sm" type="select" value={pashusakhi} onChange={getVillageList}>
                                        <option value="0"> --Select Pashusakhi--</option>
                                        {pashusakhiOpt}
                                    </Input>
                                </Col>
                                <Col xs={2} className="mb-1">
                                    <Label className="me-sm-2" for="village"> Village </Label>
                                    <Input id="village" bsSize="sm" type="select" value={village} onChange={getFarmerList}>
                                        <option value="0"> --Select Village--</option>
                                        {villageOpt}
                                    </Input>
                                </Col>
                                <Col xs={2} className="mb-1">
                                    <Label className="me-sm-2" for="farmer"> Farmer </Label>
                                    <Select closeMenuOnSelect={true} value={farmer} isMulti={false} placeholder={<div>Select Shareholder</div>}
                                        options={farmerOpt} onChange={farmer_handler} required />
                                </Col>
                                <Col md={2} className="mb-2">
                                    <Label for="bankName"> Bank Name </Label>
                                    <Input id="bankName" bsSize="sm" type="select" maxLength={50} value={bankName} onChange={(e) => setBankName(e.target.value)} >
                                        <option value="0"> --Select Bank--</option>
                                        {bankOpt}
                                    </Input>
                                </Col>
                                <Col md={2} className="mb-2">
                                    <Label for="accountNo"> Account Number </Label>
                                    <Input id="accountNo" type='text' bsSize="sm" maxLength={20} value={accountNo} onChange={(e) => setAccountNo(e.target.value)} />
                                </Col>
                                <Col md={2} className="mb-2">
                                    <Label for="ifsc"> IFS Code </Label>
                                    <Input id="ifsc" type='text' bsSize="sm" maxLength={15} value={ifsc} onChange={(e) => setIfsc(e.target.value)} />
                                </Col>
                                <Col md={2} className="mb-2">
                                    <Label for="branchAddress"> Branch Address </Label>
                                    <Input id="branchAddress" type='text' bsSize="sm" maxLength={50} value={branchAddress} onChange={(e) => setBranchAddress(e.target.value)} />
                                </Col>
                                <Col xs={2}>
                                    <Label className="me-sm-2" for="purchaseDate"> Purchase Date </Label>
                                    <Input id="purchaseDate" bsSize="sm" type="date" value={purchaseDate} onChange={(e) => setPurchaseDate(e.target.value)} />
                                </Col>

                                <Col xs={12} className="mt-3 ">
                                    <hr />
                                </Col>

                                <Col xs={2}>
                                    <Label className="me-sm-2" for="gender"> Gender </Label>
                                    <Input id="gender" bsSize="sm" type="select" value={gender} onChange={(e) => { setGender(e.target.value); setGenderName(e.target.selectedOptions[0].text) }}>
                                        <option value="0"> --Select Gender--</option>
                                        <option value="1"> Khasi </option>
                                        <option value="2"> Male </option>
                                        <option value="3"> Female </option>
                                    </Input>
                                </Col>
                                <Col xs={2}>
                                    <Label className="me-sm-2" for="age"> Age </Label>
                                    <Input id="age" bsSize="sm" type="select" value={age} onChange={(e) => { setAge(e.target.value); setAgeName(e.target.selectedOptions[0].text) }}>
                                        <option value="0"> --Select age--</option>
                                        <option value="1"> 0-3 माह </option>
                                        <option value="2"> 3-6 माह </option>
                                        <option value="3"> 6-9 माह </option>
                                        <option value="4"> 9-12 माह </option>
                                        <option value="5"> 12+ माह </option>
                                    </Input>
                                </Col>
                                <Col xs={1}>
                                    <Label className="me-sm-2" for="weight"> Weight </Label>
                                    <Input id="weight" bsSize="sm" type="text" value={weight} onChange={(e) => setWeight(e.target.value)} />
                                </Col>
                                <Col xs={1}>
                                    <Label className="me-sm-2" for="price"> Price/Unit </Label>
                                    <Input id="price" bsSize="sm" type="text" value={price} onChange={(e) => setPrice(e.target.value)} />
                                </Col>
                                <Col xs={2} className="mb-1">
                                    <Button className="mt-4" color="primary" size="sm" onClick={add_handler}> Add </Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Card className='mt-3'>
                        <CardHeader>Goat List</CardHeader>
                        <CardBody>
                            <Row>
                                <Col xs={1}> <b>SL No</b> </Col>
                                <Col xs={1}> <b>Gender</b> </Col>
                                <Col xs={1}> <b>Age</b> </Col>
                                <Col xs={1}> <b>Weight</b> </Col>
                                <Col xs={1}> <b>Price/unit</b> </Col>
                                <Col xs={1}> <b>Total Price</b> </Col>
                                <Col xs={1}> <b>Action</b> </Col>
                            </Row>
                            <hr />
                            { goatList.length > 0 ? goatList.map((row, index) => {
                                return (
                                    <GoatItem val={row} index={index} delete_item={() => item_delete(index)} />
                                )
                            })
                                :
                                <div>No list Found!</div>

                            }
                            <hr />
                            <Row>
                                <Col xs={1}>  </Col>
                                <Col xs={1}>  </Col>
                                <Col xs={1} className="text-end"> <b>Total Weight =</b></Col>
                                <Col xs={1}> <b>{totalWeight} kg</b></Col>
                                <Col xs={1} className="text-end">  <b>Total Price =</b></Col>
                                <Col xs={1}> <b>&#8377; {Number(totalPrice).toFixed(2)}</b> </Col>
                            </Row>
                            <hr />
                            <Row className='mt-3'>
                                <Col md={3} className="mb-2">
                                    <Label for="receipt"> Receipt Uplaod </Label>
                                    <div className='v_status'>
                                        {receipt_valid === 1 && <FaCheck size={20} color="#00a500" />}
                                        {receipt_valid === 2 && <FaTimes size={20} color="#ff4136" />}
                                    </div>
                                    <FileBase64
                                        multiple={false}
                                        onDone={receipt_image_handler}
                                    />
                                </Col>
                                <Col md={3} className="mb-2">
                                    <Label for="pass"> Bank Passbook Uplaod </Label>
                                    <div className='v_status'>
                                        {pass_valid === 1 && <FaCheck size={20} color="#00a500" />}
                                        {pass_valid === 2 && <FaTimes size={20} color="#ff4136" />}
                                    </div>
                                    <FileBase64
                                        multiple={false}
                                        onDone={passbook_image_handler}
                                    />
                                </Col>
                                <Col md={3} className="mb-2">
                                    <Label for="pass"> Goat Image Uplaod </Label>
                                    <div className='v_status'>
                                        {goat_valid === 1 && <FaCheck size={20} color="#00a500" />}
                                        {goat_valid === 2 && <FaTimes size={20} color="#ff4136" />}
                                    </div>
                                    <FileBase64
                                        multiple={false}
                                        onDone={goat_image_handler}
                                    />
                                </Col>
                                <Col xs="3">
                                    <Button className="mt-4" color="primary" size="" onClick={goat_purchase_handler} disabled={loading}> Submit {loading && <Spinner color="secondary ms-1" size="sm" >Load...</Spinner>} </Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}
export default GoatPurchaseEntry;