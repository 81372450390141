import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import notification from '../../component/hocs/notification';
import mainurl from '../../MainURL';
import axios from 'axios';
import Page from '../../component/utility/Page';
import DataTable from 'react-data-table-component';

import AddBuyer from '../../component/utility/master/AddBuyer';
import EditBuyer from '../../component/utility/master/EditBuyer';
import ViewBuyer from '../../component/utility/master/ViewBuyer';

import {FaEdit} from 'react-icons/fa';
import {MdDeleteForever, MdPersonAdd} from 'react-icons/md';
import {BsEyeFill, } from 'react-icons/bs';
import * as XLSX from 'xlsx/xlsx.mjs';
import {SiMicrosoftexcel} from 'react-icons/si';
import Tooltip from '@mui/material/Tooltip';

import {
    Col, Row, Card, CardHeader, CardBody, Button, 
    Modal, ModalBody, ModalHeader
} from 'reactstrap';

 

const Buyer = () => {
    const navigate = useNavigate();
    const [mode, setMode] = useState(1);
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState("");
    const [editData, setEditData] = useState('');
    const [viewModal, setViewModal] = useState(false);
    const [modalData, setModalData] = useState('');
    const [accData, setAccData] = useState('');

    useEffect(() => {
        // check Access
        let accData = JSON.parse(sessionStorage.getItem('accessControl'));
        setAccData(accData[3].pages[11]);
        if(accData[3].pages[11].page_view !== "1"){
            navigate("/home");
        }
        
        get_buyer_list();
    }, []);
    

    const open_edit_modal = (row) => {
        setEditData(row);
        setMode(3);
    }

    const open_view_modal = (row) => {
        let temp = <ViewBuyer data={row} />;
        setModalData(temp);
        setViewModal(true);
    }

    const get_buyer_list = () => {
        //
        let accData = JSON.parse(sessionStorage.getItem('accessControl'));
        let acc = accData[3].pages[3];
        //
        setLoading(true);
        setTableData('');
        let postData = [{ "org_id": 100, "user_id": "0" }];
        axios({
          method: 'post',
          url: mainurl + 'Master/box_buyer_list',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            
            let list = response.data.map(row => {
                return(
                    {
                        ids: row.buyer_id,
                        name: row.buyer_name_desc,
                        name_h: row.buyer_name_hindi,
                        mobile: row.mobile_no,
                        landline: row.landline_no,
                        pan: row.pan_no,
                        email: row.email_id,
                        action: <div>
                                    <BsEyeFill className='me-3 cursor' color="#0052cc" onClick={()=> open_view_modal(row)} size={20} />
                                    { acc.page_edit === "1" && <FaEdit className='me-3 cursor' color="#047e04" onClick={()=> open_edit_modal(row)} size={20} />}
                                    { acc.page_delete === "1" && <MdDeleteForever color="#f70000" size={20} />}
                                </div>

                    }
                )
            })
            setTableData(list);
            setLoading(false);
           
          })
          .catch(error => {
            setLoading(false);
            setTableData('');
            //let notify = notification({ message: "Buyers list not Found! Try Again", type: 'error' });
            //notify();
          });
    }

    const download_excel_handler = () => {
        if(tableData.length > 0){
          let wb = XLSX.utils.book_new(),
          ws = XLSX.utils.json_to_sheet(tableData);
    
          XLSX.utils.book_append_sheet(wb, ws, "Mysheet1");
    
          XLSX.writeFile(wb, "Buyers_list.xlsx");
        }
      }

    return(
        <>
            <Header />
            <Page title="Buyers" breadcrumbs="Master / Buyers">
                {mode === 1 &&
                
                    <Row className='p-3'>
                        { accData.page_add === "1" && <Col xs={12} className="mb-2">
                            <Card>
                                <CardBody>
                                    <Button color='primary' size="sm" onClick={() => setMode(2)}><MdPersonAdd /> Add New Buyer</Button>
                                </CardBody>
                            </Card>
                        </Col>}
                        <Col xs={12}>
                            <Card>
                                <CardHeader>Buyers List</CardHeader>
                                <div style={{position:'relative', height:'1px'}}>
                                    <Tooltip title="Excel download">
                                        <SiMicrosoftexcel className='excelIcon' size={20} onClick={download_excel_handler} />
                                    </Tooltip> 
                                </div>
                                <CardBody>
                                <DataTable
                                    title=""
                                    columns={[
                                        {
                                        name: 'Id',
                                        selector: row=>row.ids,
                                        sortable: true,
                                        },
                                        {
                                        name: 'Name',
                                        selector: row=>row.name,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Name (Hindi)',
                                        selector: row=>row.name_h,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Mobile Number',
                                        selector: row=>row.mobile,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Landline Number',
                                        selector: row=>row.landline,
                                        sortable: false,
                                        },
                                        {
                                        name: 'PAN Number',
                                        selector: row=>row.pan,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Email',
                                        selector: row=>row.email,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Action',
                                        selector: row=>row.action,
                                        sortable: false,
                                        },
                                    ]}
                                    data={tableData}
                                    striped
                                    highlightOnHover
                                    pagination
                                    progressPending={loading}
                                    />
                                    
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                }

                { mode === 2 &&
                    <Row className='p-3'>
                        <Col xs={12} className="mb-2">
                            <AddBuyer back_handler={()=> {setMode(1);get_buyer_list()}} />
                        </Col>
                    </Row>    
                }
                { mode === 3 &&
                    <Row className='p-3'>
                        <Col xs={12} className="mb-2">
                            <EditBuyer data={editData} back_handler={()=> {setMode(1);get_buyer_list()}} />
                        </Col>
                    </Row>    
                }

            </Page>
            <Modal
                centered
                scrollable
                size="xl"
                isOpen={viewModal}
                toggle={() => setViewModal(!viewModal)}
            >
                <ModalHeader toggle={() => setViewModal(!viewModal)}>
                   View Buyer Details
                </ModalHeader>
                <ModalBody>
                    {modalData}
                </ModalBody>
            </Modal>
            <Footer />
        </>
    )

}

export default Buyer;