import React, { useState, useEffect } from 'react';
import notification from '../../hocs/notification';

import mainurl from '../../../MainURL';
import axios from 'axios';

import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Row,
} from 'reactstrap';

import Chart from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';

let labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const optionstack = {
    scales: {
        xAxes: [{
            stacked: true
        }],
        yAxes: [{
            stacked: true
        }]
    }
  };
  
const data1 = {
    labels: labels,
    datasets: [
        // These two will be in the same stack.
        {
            label: 'araria',
            data: [0],
            backgroundColor: 'rgba(0, 82, 204, 1)',
        },
        {
            label: 'katihar',
            data: [0],
            backgroundColor: 'rgba(251, 161, 15, 1)',
        },
        {
            label: 'purnia',
            data: [0],
            backgroundColor: 'rgba(220, 57, 18, 1)',
        },

    ]
};

const HomeGraph = () => {
    const [gd1, setGd1] = useState(data1);
    const [gd2, setGd2] = useState(data1);
    const [gd3, setGd3] = useState(data1);
    const [gd4, setGd4] = useState(data1);
    const [gd5, setGd5] = useState(data1);
    const [gd6, setGd6] = useState(data1);
    const [gd7, setGd7] = useState(data1);

    useEffect(() => {
        let labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        let postData1 = [{ "org_id": 100, "user_id": 128, "ops_type": 1 }];
        let postData2 = [{ "org_id": 100, "user_id": 128, "ops_type": 2 }];
        let postData3 = [{ "org_id": 100, "user_id": 128, "ops_type": 3 }];
        let postData4 = [{ "org_id": 100, "user_id": 128, "ops_type": 4 }];
        let postData5 = [{ "org_id": 100, "user_id": 128, "ops_type": 101 }];
        let postData6 = [{ "org_id": 100, "user_id": 128, "ops_type": 6 }];
        // gd-1 vaccination
        axios({
            method: 'post',
            url: mainurl + 'Geo/g1',
            data: postData1,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                //console.log(response.data)
                let gd_1 = response.data.map(row => {
                    let color = "";
                    if ((row.district).toLowerCase() === "araria") {
                        color = "#0052cc";
                    } else if ((row.district).toLowerCase() === "katihar") {
                        color = "#fba10f";
                    } else if ((row.district).toLowerCase() === "kishanganj") {
                        color = "#26c287";
                    } else if ((row.district).toLowerCase() === "purnia") {
                        color = "#dc3912";
                    }
                    let innerD = [];
                    let month = row[1][0].month;
                    for (let i = 1; i < month; i++) {
                        innerD.push(0);
                    }
                    row[1].map(d => {
                        innerD.push(parseInt(d.actual_value));
                    });
                    return (
                        {
                            label: row.district,
                            data: innerD,
                            backgroundColor: color,
                        }
                    )
                })

                setGd1({ "labels": labels, "datasets": gd_1 });

            }).catch(error => {
                //let notify = notification({ message: "Data not Found! Try Again", type: 'error' });
                //notify();
            });

        // gd-2
        axios({
            method: 'post',
            url: mainurl + 'Geo/g1',
            data: postData2,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                //console.log(response.data)
                let gd_2 = response.data.map(row => {
                    let color = "";
                    if ((row.district).toLowerCase() === "araria") {
                        color = "#0052cc";
                    } else if ((row.district).toLowerCase() === "katihar") {
                        color = "#fba10f";
                    } else if ((row.district).toLowerCase() === "kishanganj") {
                        color = "#26c287";
                    } else if ((row.district).toLowerCase() === "purnia") {
                        color = "#dc3912";
                    }
                    let innerD = [];
                    let month = row[2][0].month;
                    for (let i = 1; i < month; i++) {
                        innerD.push(0);
                    }
                    row[2].map(d => {
                        innerD.push(parseInt(d.actual_value));
                    });
                    return (
                        {
                            label: row.district,
                            data: innerD,
                            backgroundColor: color,
                        }
                    )
                })

                setGd2({ "labels": labels, "datasets": gd_2 });

            }).catch(error => {
                //let notify = notification({ message: "Data not Found! Try Again", type: 'error' });
                //notify();
            });
        // gd-3
        axios({
            method: 'post',
            url: mainurl + 'Geo/g1',
            data: postData3,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                //console.log(response.data)
                let gd_3 = response.data.map(row => {
                    let color = "";
                    if ((row.district).toLowerCase() === "araria") {
                        color = "#0052cc";
                    } else if ((row.district).toLowerCase() === "katihar") {
                        color = "#fba10f";
                    } else if ((row.district).toLowerCase() === "kishanganj") {
                        color = "#26c287";
                    } else if ((row.district).toLowerCase() === "purnia") {
                        color = "#dc3912";
                    }
                    let innerD = [];
                    let month = row[3][0].month;
                    for (let i = 1; i < month; i++) {
                        innerD.push(0);
                    }
                    row[3].map(d => {
                        innerD.push(parseInt(d.actual_value));
                    });
                    return (
                        {
                            label: row.district,
                            data: innerD,
                            backgroundColor: color,
                        }
                    )
                })

                setGd3({ "labels": labels, "datasets": gd_3 });

            }).catch(error => {
                //let notify = notification({ message: "Data not Found! Try Again", type: 'error' });
                //notify();
            });
        // gd-4
        axios({
            method: 'post',
            url: mainurl + 'Geo/g1',
            data: postData4,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                //console.log(response.data)
                let gd_4 = response.data.map(row => {
                    let color = "";
                    if ((row.district).toLowerCase() === "araria") {
                        color = "#0052cc";
                    } else if ((row.district).toLowerCase() === "katihar") {
                        color = "#fba10f";
                    } else if ((row.district).toLowerCase() === "kishanganj") {
                        color = "#26c287";
                    } else if ((row.district).toLowerCase() === "purnia") {
                        color = "#dc3912";
                    }
                    let innerD = [];
                    let month = row[4][0].month;
                    for (let i = 1; i < month; i++) {
                        innerD.push(0);
                    }
                    row[4].map(d => {
                        innerD.push(parseInt(d.actual_value));
                    });
                    return (
                        {
                            label: row.district,
                            data: innerD,
                            backgroundColor: color,
                        }
                    )
                })

                setGd4({ "labels": labels, "datasets": gd_4 });

            }).catch(error => {
                //let notify = notification({ message: "Data not Found! Try Again", type: 'error' });
                //notify();
            });
        // gd-5
        axios({
            method: 'post',
            url: mainurl + 'Geo/g1',
            data: postData5,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                console.log(response.data)
                let gd_5 = response.data.map(row => {
                    console.log(row[101][0].actual_value)
                    let color = "";
                    if ((row.district).toLowerCase() === "araria") {
                        color = "#0052cc";
                    } else if ((row.district).toLowerCase() === "katihar") {
                        color = "#fba10f";
                    } else if ((row.district).toLowerCase() === "kishanganj") {
                        color = "#26c287";
                    } else if ((row.district).toLowerCase() === "purnia") {
                        color = "#dc3912";
                    }
                    let innerD = [];
                    let month = row[101][0].month;
                    for (let i = 1; i < month; i++) {
                        innerD.push(0);
                    }
                    row[101].map(d => {
                        innerD.push(parseInt(d.actual_value));
                    });
                    return (
                        {
                            label: row.district,
                            data: innerD,
                            backgroundColor: color,
                        }
                    )
                })

                setGd5({ "labels": labels, "datasets": gd_5 });

            }).catch(error => {
                //let notify = notification({ message: "Data not Found! Try Again", type: 'error' });
                //notify();
            });
        // gd-6
        axios({
            method: 'post',
            url: mainurl + 'Geo/g1',
            data: postData6,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                //console.log(response.data)
                let gd_6 = response.data.map(row => {
                    let color = "";
                    if ((row.district).toLowerCase() === "araria") {
                        color = "#0052cc";
                    } else if ((row.district).toLowerCase() === "katihar") {
                        color = "#fba10f";
                    } else if ((row.district).toLowerCase() === "kishanganj") {
                        color = "#26c287";
                    } else if ((row.district).toLowerCase() === "purnia") {
                        color = "#dc3912";
                    }
                    let innerD = [];
                    let month = row[6][0].month;
                    for (let i = 1; i < month; i++) {
                        innerD.push(0);
                    }
                    row[6].map(d => {
                        innerD.push(parseInt(d.actual_value));
                    });
                    return (
                        {
                            label: row.district,
                            data: innerD,
                            backgroundColor: color,
                        }
                    )
                })

                setGd6({ "labels": labels, "datasets": gd_6 });

            }).catch(error => {
                //let notify = notification({ message: "Data not Found! Try Again", type: 'error' });
                //notify();
            });

            sharholder_mob();

    }, []);

    const sharholder_mob = () => {
        let postData = [{ "org_id": 100, "user_id": 128 }];
        axios({
            method: 'post',
            url: mainurl + 'Geo/g1_mob',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                //console.log(response.data)
                let gd_7 = response.data.map(row => {
                    let color = "";
                    if ((row.district).toLowerCase() === "araria") {
                        color = "#0052cc";
                    } else if ((row.district).toLowerCase() === "katihar") {
                        color = "#fba10f";
                    } else if ((row.district).toLowerCase() === "purnia") {
                        color = "#dc3912";
                    }
                    let innerD = [];
                    let month = row['mob'][0].month;
                    for (let i = 1; i < month; i++) {
                        innerD.push(0);
                    }
                    row['mob'].map(d => {
                        innerD.push(parseInt(d.actual_value));
                    });
                    return (
                        {
                            label: row.district,
                            data: innerD,
                            backgroundColor: color,
                        }
                    )
                })

                setGd7({ "labels": labels, "datasets": gd_7 });

            }).catch(error => {
                //let notify = notification({ message: "Data not Found! Try Again", type: 'error' });
                //notify();
            });
    }

return (
    <Row className='mt-2'>

        <Col lg='4' md='4' sm='12' className='mb-1'>
            <Card>
                <CardHeader>
                    Shareholder Mobilation<small className="text-muted text-capitalize"> In Last 1 Year</small>
                </CardHeader>
                <CardBody>
                    <Bar options={optionstack} data={gd7} />
                </CardBody>
            </Card>

        </Col>
        <Col lg='4' md='4' sm='12' className='mb-1'>
            <Card>
                <CardHeader>
                    Goat Census<small className="text-muted text-capitalize"> In Last 1 Year</small>
                </CardHeader>
                <CardBody>
                    <Bar options={optionstack} data={gd4} />
                </CardBody>
            </Card>

        </Col>
        <Col lg='4' md='4' sm='12' className='mb-1'>
            <Card>
                <CardHeader>
                    Goat Purchase<small className="text-muted text-capitalize"> In Last 1 Year</small>
                </CardHeader>
                <CardBody>
                    <Bar options={optionstack} data={gd6} />
                </CardBody>
            </Card>

        </Col>
       <Col lg='4' md='4' sm='12' className='mb-1'>
            <Card>
                <CardHeader>
                    Goat Sale<small className="text-muted text-capitalize"> In Last 1 Year</small>
                </CardHeader>
                <CardBody>
                    <Bar options={optionstack} data={gd5} />
                </CardBody>
            </Card>

        </Col>
        <Col lg='4' md='4' sm='12' className='mb-1'>
            <Card>
                <CardHeader>
                    Vaccination<small className="text-muted text-capitalize"> In Last 1 Year</small>
                </CardHeader>
                <CardBody>
                    <Bar options={optionstack} data={gd1} />
                </CardBody>
            </Card>

        </Col>
        <Col lg='4' md='4' sm='12' className='mb-1'>
            <Card>
                <CardHeader>
                    De-worming<small className="text-muted text-capitalize"> In Last 1 Year</small>
                </CardHeader>
                <CardBody>
                    <Bar options={optionstack} data={gd2} />
                </CardBody>
            </Card>

        </Col>
        <Col lg='4' md='4' sm='12' className='mb-1'>
            <Card>
                <CardHeader>
                    Castration<small className="text-muted text-capitalize"> In Last 1 Year</small>
                </CardHeader>
                <CardBody>
                    <Bar options={optionstack} data={gd3} />
                </CardBody>
            </Card>

        </Col>
        <Col lg='4' md='4' sm='12' className='mb-1'>
            <Card>
                <CardHeader>
                    Demostration<small className="text-muted text-capitalize"> In Last 1 Year</small>
                </CardHeader>
                <CardBody>
                    <Bar options={optionstack} data={data1} />
                </CardBody>
            </Card>

        </Col>
        <Col lg='4' md='4' sm='12' className='mb-1'>
            <Card>
                <CardHeader>
                    Training<small className="text-muted text-capitalize"> In Last 1 Year</small>
                </CardHeader>
                <CardBody>
                    <Bar options={optionstack} data={data1} />
                </CardBody>
            </Card>

        </Col>
        {/*<Col lg='4' md='4' sm='12' className='mb-1'>
            <Card>
                <CardHeader>
                    Meeting<small className="text-muted text-capitalize"> In Last 1 Year</small>
                </CardHeader>
                <CardBody>
                    <Bar options={optionstack} data={data} />
                </CardBody>
            </Card>

        </Col>*/}

    </Row>
)
}

export default HomeGraph;