import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';

import notification from '../../component/hocs/notification';
import mainurl from '../../MainURL';
import axios from 'axios';
import Page from '../../component/utility/Page';
import DataTable from 'react-data-table-component';
import { Col, Row, Card, CardBody, Button, Input, Label, Modal, ModalHeader, ModalBody } from 'reactstrap';

import * as XLSX from 'xlsx/xlsx.mjs';
import {SiMicrosoftexcel} from 'react-icons/si';
import Tooltip from '@mui/material/Tooltip';

import {BsEyeFill, } from 'react-icons/bs';

const GoatCensusReport = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState(false);
  const [districtOpt, setDistrictOpt] = useState();
  const [blockOpt, setBlockOpt] = useState();
  const [panchyatOpt, setPanchyatOpt] = useState();
  const [district, setDistrict] = useState("0");
  const [block, setBlock] = useState("0");
  const [panchyat, setPanchyat] = useState("0");

  const [farmerData, setFarmerData] = useState('');
  const [modalFarmer, setModalFarmer] = useState(false);

  const [dist_role, setDist_role] = useState(false);
  const [block_role, setBlock_role] = useState(false);
  const [count, setCount] = useState(1);

  useEffect(() => {
    // check Access
    let accData = JSON.parse(sessionStorage.getItem('accessControl'));
    
    if(accData[2].pages[0].page_view !== "1"){
    navigate("/home");
    }
    //
    checkRole();
    getDistrictList();
  }, []);

  const checkRole = () => {
    let role = sessionStorage.getItem('role');
    if( role === "101" ){
      getDistrictList();
      setCount(2);
    }else if( role === "102"){
      let dist = sessionStorage.getItem('dist');
      setDist_role(true);
      getBlockList({target:{value: dist}});
      setCount(2);
    }else if( role === "103"){
      let dist = sessionStorage.getItem('dist');
      setDist_role(true);
      setBlock_role(true);
      getBlockList({target:{value: dist}});

    }
  }

  const getDistrictList = () => {
    setBlock("0");
    setPanchyat("0");

    let postData = [{ "org_id": 100, "user_id": "0" }];
    axios({
      method: 'post',
      url: mainurl + 'Geo/geo_district_list2',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        let list = response.data.map((row, index) => {
          return <option key={index} value={row.district_short_cd}> {row.district_name} </option>
        })
        setDistrictOpt(list);
      })
      .catch(error => {
        let notify = notification({ message: "District list not Found! Try Again", type: 'error' });
        notify();
      });
  }

  const getBlockList = (e) => {
    setDistrict(e.target.value);
    setBlock("0");
    setPanchyat("0");
    let postData = [{ "org_id": 100, "user_id": "0", "dist_id": e.target.value }]
    axios({
      method: 'post',
      url: mainurl + 'Geo/geo_block_list',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        let list = response.data.map((row, index) => {
          return <option key={index} value={row.block_code}> {row.block_long_name} </option>
        })
        setBlockOpt(list);
        if(count === 1){
          setCount(2);
          let block = sessionStorage.getItem('block');
          getPanchayatList({target:{value: block}});
        }
      })
      .catch(error => {
        let notify = notification({ message: "Block list not Found! Try Again", type: 'error' });
        notify();
      });
  }

  const getPanchayatList = (e) => {
    setBlock(e.target.value);
    setPanchyat("0");
    let postData = [{ "org_id": 100, "user_id": "0", "block_id": e.target.value }]
    axios({
      method: 'post',
      url: mainurl + 'Geo/geo_pan_list',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        let list = response.data.map((row, index) => {
          return <option key={index} value={row.panchyat_code}> {row.panchyat_name} </option>
        })

        setPanchyatOpt(list);
      })
      .catch(error => {
        let notify = notification({ message: "Panchayat list not Found! Try Again", type: 'error' });
        notify();
      });
  }

  const submit_handler = () => {
    setLoading(true);
    setTableData("");
    let postData = [{ "org_id": 100, "dist_id": district, "block_id": block, "pan_id": panchyat, "pg_id": 0, "age_group": 0 }];

    axios({
      method: 'post',
      url: mainurl + 'Activity/web_census',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {

        // get pg list
        let pg_list = [];
        response.data.map((row, index) => {
          if (pg_list.indexOf(row.pg_id) === -1) {
            pg_list.push(row.pg_id);
          }
        })
        getTableData(pg_list);
        // end 
      })
      .catch(error => {
        //let notify = notification({ message: "Data not Found! Try Again", type: 'error' });
        //notify();
        setLoading(false);
      })

  }

  const getTableData = (pg_list) => {
    let pg = JSON.stringify(pg_list);
    pg = pg.replaceAll('","', ',');
    pg = pg.replaceAll('"', '');
    pg = pg.replaceAll('[', '');
    pg = pg.replaceAll(']', '');

    console.log(pg)
    let postData = [{ "org_id": 100, "pg_list": pg }];
    console.log(JSON.stringify(postData))
    axios({
      method: 'post',
      url: mainurl + 'Activity/web_census2',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        console.log(response.data);
        let male = 0; let female = 0; let khasi = 0;
        let temp = response.data[0].pg_no;
        let temp2 = response.data[0].animal_age_group;
        let count = 0;
        let tableData = [{
          PG: '',
          Age: '',
          Male: '',
          Female: '',
          Khasi: '',
          action: '',
        }]

        response.data.map((row, index) => {
          if (row.pg_no === temp && row.animal_age_group === temp2) {
            if (row.gender_desc === "Male") {
              male = male + Number(row.stock);
            } else if (row.gender_desc === "Female") {
              female = female + Number(row.stock);
            } else if (row.gender_desc === "Khasi") {
              khasi = khasi + Number(row.stock);
            }

            tableData[count].PG = row.pg_name_desc;
            tableData[count].Age = row.age_group_desc;
            tableData[count].Male = male;
            tableData[count].Female = female;
            tableData[count].Khasi = khasi;
            tableData[count].action = <BsEyeFill className='me-3 cursor' color="#0052cc" onClick={()=> showFarmer(row.pg_no)} size={20} />;

          } else {
            temp = row.pg_no;
            temp2 = row.animal_age_group;
            count = count + 1;
            male = 0;
            female = 0;
            khasi = 0;

            if (row.gender_desc === "Male") {
              male = male + Number(row.stock);
            } else if (row.gender_desc === "Female") {
              female = female + Number(row.stock);
            } else if (row.gender_desc === "Khasi") {
              khasi = khasi + Number(row.stock);
            }

            tableData.push({
              PG: row.pg_name_desc,
              Age: row.age_group_desc,
              Male: male,
              Female: female,
              Khasi: khasi,
              action: <BsEyeFill className='me-3 cursor' color="#0052cc" onClick={()=> showFarmer(row.pg_no)} size={20} />,
            })

          }
        });
        setTableData(tableData);
        setLoading(false);
      })
      .catch(error => {
        setTableData("");
        setLoading(false);
        //let notify = notification({ message: "Data not Found! Try Again", type: 'error' });
        //notify();
      })

  }

  const showFarmer = (id) => {
    console.log(id);

    let postData = [{ "org_id": 100, "user_id": "128", "vill_id": id }]

    axios({
      method: 'post',
      url: mainurl + 'Activity/mb_census2',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {

        let male = 0; let female = 0; let khasi = 0;
        let temp = response.data[0].f_id;

        let count = 0;
        let farmerData = [{
          farmer_name: '',
          village_name: '',
          male: '',
          female: '',
          khasi: '',
        }]

        response.data.map((row, index) => {
          if (row.f_id === temp) {
            if (row.gender_desc === "Male") {
              male = male + Number(row['count(b.stock)']);
            } else if (row.gender_desc === "Female") {
              female = female + Number(row['count(b.stock)']);
            } else if (row.gender_desc === "Khasi") {
              khasi = khasi + Number(row['count(b.stock)']);
            }

            farmerData[count].farmer_name = row.f_last_name;
            farmerData[count].village_name = row.village_name_h;
            farmerData[count].male = male;
            farmerData[count].female = female;
            farmerData[count].khasi = khasi;
          } else {
            temp = row.f_id;

            count = count + 1;
            male = 0;
            female = 0;
            khasi = 0;

            if (row.gender_desc === "Male") {
              male = male + Number(row['count(b.stock)']);
            } else if (row.gender_desc === "Female") {
              female = female + Number(row['count(b.stock)']);
            } else if (row.gender_desc === "Khasi") {
              khasi = khasi + Number(row['count(b.stock)']);
            }

            farmerData.push({
              farmer_name: row.f_last_name,
              village_name: row.village_name_h,
              male: male,
              female: female,
              khasi: khasi,
            })

          }
        });

        //console.log(farmerData);
        setFarmerData(farmerData);
        setModalFarmer(true);

      }).catch(error => {
        //let notify = notification({ message: "Data not Found! Try Again", type: 'error' });
        //notify();
      })
  }

  const download_excel_handler = () => {
    if(tableData.length > 0){
      let wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(tableData);

      XLSX.utils.book_append_sheet(wb, ws, "Mysheet1");

      XLSX.writeFile(wb, "GoatCensus.xlsx");
    }
  }

  return (
    <div>
      <Header />
      <Page title="Goat Census Report" breadcrumbs="Report / Goat Census Report">

        <Row className='p-3'>
          <Col lg={12} md={12} sm={12} xs={12} className="mb-1">
            <Card>
              <CardBody>
                <Row>
                  <Col xs={3} className="mb-1">
                    <Label className="me-sm-2" for="district"> District </Label>
                    <Input id="district" bsSize="sm" type="select" value={district} onChange={(e) => getBlockList(e)} disabled={dist_role}>
                      <option value="0"> --Select District--</option>
                      {districtOpt}
                    </Input>
                  </Col>
                  <Col xs={3} className="mb-1">
                    <Label className="me-sm-2" for="block"> Block </Label>
                    <Input id="block" bsSize="sm" type="select" value={block} onChange={(e) => getPanchayatList(e)} disabled={block_role}>
                      <option value="0"> --Select Block--</option>
                      {blockOpt}
                    </Input>
                  </Col>
                  <Col xs={3} className="mb-1">
                    <Label className="me-sm-2" for="panchayat"> Panchayat </Label>
                    <Input id="panchayat" bsSize="sm" type="select" value={panchyat} onChange={(e) => setPanchyat(e.target.value)}>
                      <option value="0"> --Select Panchayat--</option>
                      {panchyatOpt}
                    </Input>
                  </Col>
                  <Col xs={3} className="mb-1">
                    <Button className="mt-4" color="primary" size="sm" onClick={submit_handler}> Submit </Button>
                  </Col>
                </Row>
                <hr />
                <div style={{position:'relative', height:'1px'}}>
                  <Tooltip title="Excel download">
                    <SiMicrosoftexcel className='excelIcon' size={20} onClick={download_excel_handler} />
                  </Tooltip> 
                </div>
                <DataTable
                  title=""
                  columns={[
                    {
                      name: 'PG / VO',
                      selector: row=>row.PG,
                      sortable: true,
                    },
                    {
                      name: 'Age',
                      selector: row=>row.Age,
                      sortable: false,
                    },
                    {
                      name: 'Male',
                      selector: row=>row.Male,
                      sortable: false,
                    },
                    {
                      name: 'Female',
                      selector: row=>row.Female,
                      sortable: false,
                    },
                    {
                      name: 'Khasi',
                      selector: row=>row.Khasi,
                      sortable: false,
                    },
                    {
                      name: 'Action',
                      selector: row=>row.action,
                      sortable: false,
                    },

                  ]}
                  data={tableData}
                  striped
                  highlightOnHover
                  progressPending={loading}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
      <Modal
        size='lg'
        centered
        scrollable
        isOpen={modalFarmer}
        toggle={() => setModalFarmer(!modalFarmer)}
      >
        <ModalHeader toggle={() => setModalFarmer(!modalFarmer)}>
          Farmers Details
        </ModalHeader>
        <ModalBody>
          <DataTable
            title=""
            columns={[
              {
                name: 'Farmer Name',
                selector: row=>row.farmer_name,
                sortable: false,
              },
              {
                name: 'Male',
                selector: row=>row.male,
                sortable: false,
              },
              {
                name: 'Female',
                selector: row=>row.female,
                sortable: false,
              },
              {
                name: 'Khasi',
                selector: row=>row.khasi,
                sortable: false,
              },

            ]}
            data={farmerData}
            striped
            highlightOnHover
          />
        </ModalBody>

      </Modal>
      <Footer />
    </div>
  );

}

export default GoatCensusReport;