import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import notification from '../../component/hocs/notification';
import mainurl from '../../MainURL';
import axios from 'axios';
import Page from '../../component/utility/Page';
import DataTable from 'react-data-table-component';
import * as XLSX from 'xlsx/xlsx.mjs';
import {SiMicrosoftexcel} from 'react-icons/si';
import {FaSearch} from 'react-icons/fa';
import {BsEyeFill} from 'react-icons/bs';
import Tooltip from '@mui/material/Tooltip';
import {
    Col, Row, Card, CardHeader, CardBody, Button, Input, Label, Form, InputGroup, InputGroupText, Spinner, Modal, ModalHeader, ModalBody
} from 'reactstrap';
import { getTodayDate, getMinDate } from '../../component/hocs/dateServices';


const ActivityLog = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState("");
    const [fmDate, setFmDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [accData, setAccData] = useState('');
    const [searchTxt, setSearchTxt] = useState('');
    const [searchData, setSearchData] = useState('');

    const [modalData, setModalData] = useState('');
    const [viewModal, setViewModal] = useState(false);

    useEffect(() => {
        // check Access
        let accData = JSON.parse(sessionStorage.getItem('accessControl'));
        setAccData(accData[3].pages[15]);
        if(accData[3].pages[15].page_view !== "1"){
            navigate("/home");
        }
    }, []);

    const submit_handler = (e) => {
        e.preventDefault();
        setTableData('');
        setSearchData('');
        setLoading(true);

        let postData = [{ "org_id": 100, "user_id": "0", "fm_date": fmDate, "to_date": toDate }];
        axios({
          method: 'post',
          url: mainurl + 'Master/bx_activity_log',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            console.log(response.data);
            let list = response.data.map((row, index) => {
                return(
                   {
                    sln: index + 1,
                    ids: row.user_id,
                    name: row.aps_name + " ( " + row.aps_name_h +" )",
                    district: row.district_name,
                    block: row.block_long_name,
                    panchayat: row.panchyat_name,
                    action: <BsEyeFill className='me-3 cursor' color="#0052cc" onClick={()=> open_view_modal(row.user_id)} size={20} />
                   }
                )
            })
            setTableData(list);
            setSearchData(list);
            setLoading(false);
          })
          .catch(error => {
            setTableData('');
            setSearchData('');
            setLoading(false);
            //let notify = notification({ message: "Activity list not Found! Try Again", type: 'error' });
            //notify();
          });
    }

    const open_view_modal = (id) => {
        let postData = [{ "org_id": 100, "fm_date": fmDate, "to_date": toDate, "aps_id": id }];
        axios({
          method: 'post',
          url: mainurl + 'Master/activity_log_by_aps',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            console.log(response.data);
            let list = response.data.map((row, index) => {
                return(
                   {
                    sln: index + 1,
                    aps_name: row.aps_name + " ( " + row.user_id +" )",
                    farmer_name: row.f_first_name + " ( " + row.f_id +" )",
                    date: row.activity_date ,
                    activity_name: row.key_name,
                   }
                )
            })
            setModalData(list);
            setViewModal(true);
          })
          .catch(error => {
            //let notify = notification({ message: "Activity list not Found! Try Again", type: 'error' });
            //notify();
          });
    }

    const search_handler = (txt) => {
        setLoading(true);
        setSearchTxt(txt)
        if(tableData.length > 0){
            let tempSearchData = tableData.filter (item => {
                const query = txt.toLowerCase();
                return ( 
                    item.name.toLowerCase().indexOf(query) >= 0 ||
                    item.ids.toLowerCase().indexOf(query) >= 0 ||
                    item.district.toLowerCase().indexOf(query) >= 0 ||
                    item.block.toLowerCase().indexOf(query) >= 0 ||
                    item.panchayat.toLowerCase().indexOf(query) >= 0 
                )
            })
            setSearchData(tempSearchData);
            setLoading(false);
        }
    }

    const download_excel_handler = () => {
        if(tableData.length > 0){
          let wb = XLSX.utils.book_new(),
          ws = XLSX.utils.json_to_sheet(tableData);
    
          XLSX.utils.book_append_sheet(wb, ws, "Mysheet1");
    
          XLSX.writeFile(wb, "ActivityList.xlsx");
        }
      }

    return (
        <>
            <Header />
            <Page title="Activity Log" breadcrumbs="Master / Activity Log">
                <Row className="p-3">
                    <Col xs={12}>
                        <Card className='mb-2'>
                            <CardBody>
                                <Form onSubmit={submit_handler}>
                                    <Row>
                                        
                                        <Col md={2}>
                                            <Label for="fmDate"> From Date</Label>
                                            <Input id="fmDate" type='date' value={fmDate} min={getMinDate(2)} max={getTodayDate()} bsSize="sm" onChange={(e)=> setFmDate(e.target.value)} />
                                        </Col>
                                        <Col md={2}>
                                            <Label for="toDate"> To Date</Label>
                                            <Input id="toDate" type='date' value={toDate}min={getMinDate(2)} max={getTodayDate()} bsSize="sm" onChange={(e)=> setToDate(e.target.value)} />
                                        </Col>
                                        <Col md={2}>
                                            <Button className='mt-4' color="primary" size="sm" >Submit</Button>
                                        </Col>
                                    </Row>

                                </Form>
                            </CardBody>
                        </Card>

                        <Card className='mb-2'>
                            <CardHeader>Activity List</CardHeader>
                            <Col sm={{ offset: 6, size: 3 }} style={{position: 'absolute', top: '2px', right: '2px'}}>
                                <InputGroup size="sm">
                                    <InputGroupText> <FaSearch /> </InputGroupText>
                                    <Input type="search" placeholder='Search' value={searchTxt} onChange={(e)=>search_handler(e.target.value)} />
                                </InputGroup>
                            </Col>
                            <div style={{position:'relative', height:'1px'}}>
                                <Tooltip title="Excel download">
                                    <SiMicrosoftexcel className='excelIcon' size={20} onClick={download_excel_handler} />
                                </Tooltip> 
                            </div>
                            <CardBody>
                                <DataTable
                                    title=""
                                    columns={[
                                        {
                                        name: '#',
                                        selector: row=>row.sln,
                                        sortable: true,
                                        width: '120px'
                                        },
                                        {
                                        name: 'Pashusakhi Id',
                                        selector: row=>row.ids,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Pashusakhi Name',
                                        selector: row=>row.name,
                                        sortable: false,
                                        width: '20%'
                                        },
                                        {
                                        name: 'District',
                                        selector: row=>row.district,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Block',
                                        selector: row=>row.block,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Panchayat',
                                        selector: row=>row.panchayat,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Action',
                                        selector: row=>row.action,
                                        sortable: false,
                                        },
                                    ]}
                                    data={searchData}
                                    striped
                                    highlightOnHover
                                    progressPending={loading}
                                    />
                            </CardBody>
                        </Card> 
                    </Col>
                </Row>

            </Page>
            <Modal
                fullscreen={false}
                centered
                scrollable
                size="xl"
                isOpen={viewModal}
                toggle={() => setViewModal(!viewModal)}
            >
                <ModalHeader toggle={() => setViewModal(!viewModal)}>
                   View Activity Details
                </ModalHeader>
                <ModalBody>
                <DataTable
                                    title=""
                                    columns={[
                                        {
                                        name: '#',
                                        selector: row=>row.sln,
                                        sortable: true,
                                        width: '120px'
                                        },
                                        {
                                        name: 'Pashusakhi Name',
                                        selector: row=>row.aps_name,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Shareholder Name',
                                        selector: row=>row.farmer_name,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Date',
                                        selector: row=>row.date,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Activity',
                                        selector: row=>row.activity_name,
                                        sortable: false,
                                        },
                                    ]}
                                    data={modalData}
                                    striped
                                    highlightOnHover
                                    pagination
                                    progressPending={loading}
                                />
                </ModalBody>
            </Modal>
           
            <Footer />
        </>
    )
}

export default ActivityLog;