import React, { useState, useEffect } from 'react';
import notification from '../../hocs/notification';
import axios from 'axios';
import mainurl from '../../../MainURL';
import {
    Col, Row, Button, Input, Label, Form, InputGroup, InputGroupText, Spinner,
} from 'reactstrap';

import { FaHeading } from 'react-icons/fa';

const EditAsset = ({ geo, data, close_handler, ...props }) => {

    const [geoName, setGeoName] = useState("");
    const [geoNameHindi, setGeoNameHindi] = useState("");
    const [hindiNameLoading, setHindiNameLoading] = useState(false);
    const [type, setType] = useState("");
    const [noAsset, setNoAsset] = useState("");
    const [accDate, setAccDate] = useState("");
    const [unitPrice, setUnitPrice] = useState("");
    const [totalPrice, setTotalPrice] = useState("");

    useEffect(() => {
        console.log(data);
        setGeoName((data.name_desc).trim());
        setGeoNameHindi((data.name_desc_h).trim());
        setType(data.type);
        setNoAsset(data.asset_no);
        setAccDate(data.acquisition_date);
        setUnitPrice(data.unit_price);
        setTotalPrice(data.total_price);

    }, [data]);

    const translate_text_handler = (e) => {
        setHindiNameLoading(true);
        if (geoName.length > 1) {
            
            let postData = [{ "t_text" : geoName}];
            axios({
                method: 'post',
                url: mainurl + 'Master/translate_text',
                data: postData,
                headers: { 'Content-Type': 'application/JSON', }
            })
                .then(function (response) {
                    let trans = response.data;
                    setGeoNameHindi(trans);
                    setHindiNameLoading(false);
                })
                .catch(error => {
                    setHindiNameLoading(false);
                    let notify = notification({ message: "Hindi Translation is not working! Try Again", type: 'error' });
                    notify();
                });

        } else {
            setHindiNameLoading(false);
            let notify = notification({ message: "Please enter geographic english name first.", type: 'warn' });
            notify();
        }
    }

    const edit_handler = (e) => {
        e.preventDefault();
        let postData = [
            {
                "org_id": "100",
                "asset_id": data.asset_id,
                "type": type,
                "name_desc": geoName,
                "name_desc_h": geoNameHindi,
                "asset_no": noAsset,
                "unit_price": unitPrice,
                "total_price": totalPrice,
                "acquisition_date": accDate
            }
        ];
        console.log(postData);

        axios({
            method: 'post',
            url: mainurl + 'Master/box_asset_edit',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                if(response.data[0].retcode === "101"){
                    let notify = notification({ message: "Update successful.", type: 'success' });
                    notify();
                    close_handler();
                }
            })
            .catch(error => {
                close_handler();
                let notify = notification({ message: "Update failed! Try Again", type: 'error' });
                notify();
            });
    }

    return (
        <>
            <Form onSubmit={edit_handler}>
                <Row>
                    <Col xs={2} className="mb-1">
                        <Label className="me-sm-2" for="type"> Type </Label>
                        <Input id="type" name="type" type="select" bsSize='sm' value={type} onChange={(e) => setType(e.target.value)}>
                            <option value=""> --Select type--</option>
                            <option value="fixed_asset"> Fixed Asset </option>
                            <option value="renting_asset"> Renting Asset </option>
                        </Input>
                    </Col>
                    <Col md={3}>
                        <Label for="name"> Asset Name</Label>
                        <Input id="name" type='text' bsSize="sm" onKeyPress={(e) => !/^[a-zA-Z\s]*$/.test(e.key) && e.preventDefault()} value={geoName} onChange={(e) => setGeoName(e.target.value)} required />
                    </Col>
                    <Col md={3}>
                        <Label for="hName"> Asset Name Hindi</Label>
                        <InputGroup size="sm">
                            <Input id="hName" type='text' value={geoNameHindi} bsSize="sm" onChange={(e) => setGeoNameHindi(e.target.value)} />
                            <InputGroupText className='bg-primary cursor' onClick={!hindiNameLoading ? translate_text_handler : undefined}>
                                {hindiNameLoading ? <Spinner color="secondary" size="sm">Loading...</Spinner> : <FaHeading color="#fff" />}
                            </InputGroupText>
                        </InputGroup>
                    </Col>
                    <Col md={2}>
                        <Label for="noAsset"> Number of Assets </Label>
                        <Input id="noAsset" type='text' bsSize="sm" value={noAsset} onChange={(e) => setNoAsset(e.target.value)} required />
                    </Col>
                    <Col md={2}>
                        <Label for="accDate"> Acquisition Date </Label>
                        <Input id="accDate" type='date' bsSize="sm" value={accDate} onChange={(e) => setAccDate(e.target.value)} required />
                    </Col>
                    <Col md={2}>
                        <Label for="unitPrice"> Unit Price </Label>
                        <Input id="unitPrice" type='text' bsSize="sm" value={unitPrice} onChange={(e) => setUnitPrice(e.target.value)} required />
                    </Col>
                    <Col md={2}>
                        <Label for="totalPrice"> Total Price </Label>
                        <Input id="totalPrice" type='text' bsSize="sm" value={totalPrice} onChange={(e) => setTotalPrice(e.target.value)} required />
                    </Col>
                    <Col md={3}>
                        <Button className='mt-4' color="primary" size="sm" >Update</Button>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default EditAsset;