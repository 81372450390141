import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import notification from '../../component/hocs/notification';
import mainurl from '../../MainURL';
import axios from 'axios';
import Page from '../../component/utility/Page';
import DataTable from 'react-data-table-component';

import AddInventory from '../../component/utility/master/AddInventory';
import EditInventory from '../../component/utility/master/EditInventory';
import Switch from '@mui/material/Switch';
import {FaEdit} from 'react-icons/fa';

import {
    Col,
    Row,
    Card,
    CardHeader,
    CardBody,
    Button,
    Modal,
    ModalBody,
    ModalHeader
} from 'reactstrap';

const CategoryMaster = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [currSelect, setCurrSelect] = useState(3);
    const [tableData, setTableData] = useState("");
    const [editModel, setEditModel] = useState(false);
    const [modelData, setModelData] = useState('');
    const [accData, setAccData] = useState(null);

    useEffect(() => {
        
        // check Access
        let accData = JSON.parse(sessionStorage.getItem('accessControl'));
        setAccData(accData[3].pages[16]);
        if(accData[3].pages[16].page_view !== "1"){
            navigate("/home");
        }
        //
    }, []);

    useEffect(() => {
        init();
    }, [currSelect]);

    const showTab = (id) => {
        setCurrSelect(id);   
    }

    const init = () => {
        //
        let accData = JSON.parse(sessionStorage.getItem('accessControl'));
        let acc = accData[3].pages[3];
        //
        setLoading(true);
        setTableData('');
        let postData = [{ "org_id": 100, "type": currSelect }];
        axios({
          method: 'post',
          url: mainurl + 'Master/box_input_view',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            console.log(response.data);
            let list = response.data.map(row => {
                return(
                   {
                    id: row.medicine_id,
                    name: row.description,
                    nameHindi: row.description_h,
                    uom: row.uom_name,
                    action: <div>
                            { acc?.page_edit === "1" &&  <FaEdit className='me-3 cursor' color="#047e04" onClick={()=> open_edit_modal(row)} size={20} />}
                            { acc?.page_delete === "1" && <Switch defaultChecked={(row.active_flag === "1") ? true : false} onChange={()=>enable_disable_handler(row)} size="small" />}
                    </div>
                   }
                )
            })
            setTableData(list);
            setLoading(false);
          })
          .catch(error => {
            setTableData('');
            setLoading(false);
            let notify = notification({ message: "UOM list not Found! Try Again", type: 'error' });
            notify();
          });
    }

    const open_edit_modal = (row) => {
        let temp = <EditInventory inventory={currSelect} data={row} close_handler={close_modal_handler} />;

        setModelData(temp);
        setEditModel(true);
    }
     
    const enable_disable_handler = (row) => {
        let flag;
        if(row.active_flag === "1"){
            flag = 0;
        }else{
            flag = 1;
        }
        let postData = [{ "active_flag": flag, "medicine_id": row.medicine_id }];
        axios({
          method: 'post',
          url: mainurl + 'Master/enable_disable_input',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            console.log(response.data);
            let notify = notification({ message: "Successfully submited.", type: 'success' });
                notify();
            init();
            
          })
          .catch(error => {
            let notify = notification({ message: "failed! Try Again", type: 'error' });
            notify();
          });
    }

    const close_modal_handler = () => {
        setEditModel(false);
        init();
    }

    return(
        <>
            <Header />
            <Page title="Category Master" breadcrumbs="Master / Category Master">
            <Row className='p-3'>
                    {false && <Col xs={12} className="mb-2">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md={2}>
                                        <Button color={currSelect === 3 ? 'primary' : 'light'} onClick={()=>showTab(3)} size="sm" block>Goat Census</Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>}
                    <Col xs={12}>
                        { currSelect === 3 && 
                        <>
                            { accData?.page_add === "1" && <AddInventory type="3" inventory="Goat Census Option"/> }

                            <Card className='mb-2'>
                                <CardHeader>Goat Census Option</CardHeader>
                                <CardBody>
                                    <DataTable
                                        title=""
                                        columns={[
                                            {
                                            name: 'Goat Census Option Id',
                                            selector: 'id',
                                            sortable: true,
                                            },
                                            {
                                            name: 'Goat Census Option Name',
                                            selector: 'name',
                                            sortable: false,
                                            },
                                            {
                                            name: 'Goat Census Option Name (Hindi)',
                                            selector: 'nameHindi',
                                            sortable: false,
                                            },
                                            {
                                            name: 'Action',
                                            selector: 'action',
                                            sortable: false,
                                            },
                                        ]}
                                        data={tableData}
                                        striped
                                        highlightOnHover
                                        pagination
                                        paginationPerPage="100"
                                        progressPending={loading}
                                        />
                                </CardBody>
                            </Card>  
                        </>
                        }
                    </Col>    
                </Row>    

            </Page>
            <Modal
                centered
                scrollable
                size="lg"
                isOpen={editModel}
                toggle={() => setEditModel(!editModel)}
            >
                <ModalHeader toggle={() => setEditModel(!editModel)}>
                   Edit 
                </ModalHeader>
                <ModalBody>
                    {modelData}
                </ModalBody>
            </Modal>
            <Footer />
        </>
    )

}

export default CategoryMaster;