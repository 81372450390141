import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import notification from '../../component/hocs/notification';
import mainurl from '../../MainURL';
import axios from 'axios';
import Page from '../../component/utility/Page';
import DataTable from 'react-data-table-component';
import { getCurrentMonthYear } from '../../component/hocs/dateServices';
import { BsEyeFill, BsFillImageFill } from 'react-icons/bs';
import { MdHideImage } from 'react-icons/md';

import * as XLSX from 'xlsx/xlsx.mjs';
import {SiMicrosoftexcel} from 'react-icons/si';
import Tooltip from '@mui/material/Tooltip';
import {
    Col, Row, Card, CardHeader, CardBody, Modal, Input, ModalHeader, ModalBody
} from 'reactstrap';

const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: 'ALL',
  };

const MeetingTraining = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [modalData, setModalData] = useState('');
    const [tableData, setTableData] = useState('');
    const [monthYear, setMonthYear] = useState(getCurrentMonthYear());

    useEffect(() => {
        // check Access
        let accData = JSON.parse(sessionStorage.getItem('accessControl'));
        
        if(accData[1].pages[0].page_view !== "1"){
        navigate("/home");
        }
        //
        initLoad();
    }, [monthYear]);

    const initLoad = () => {
        setTableData('');
        setLoading(true);
        let year = new Date(monthYear).getFullYear();
        let month = new Date(monthYear).getMonth() + 1;
        let dist = sessionStorage.getItem('dist');
        let block = sessionStorage.getItem('block');

        let postData = [{ "org_id": '100', "user_id": "0", "month": month, "year": year, "dist_id": dist, "block_id": block }];

        axios({
            method: 'post',
            url: mainurl + 'Master/mis_meeting_training',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                if(response.data !== null){

                    let list = response.data.map((row, index) => {
                        let subject = "Training";

                        let imgPath = <MdHideImage color="#f70000" size={20} />;
                        if(row.image_id !== null){
                            imgPath = <a href={row.image_id+'.png'} target="_blank"><BsFillImageFill className='cursor' color="#0052cc" size={20} /></a>
                        }
                        if (row.subject_id === "2") {
                            subject = "Meeting";
                        }
                        return (
                            {
                                SLN: index + 1,
                                Date: row.meeting_data.split(" ")[0].split('-').reverse().join('-'),
                                Type: subject,
                                TrainerName: row.trainer_name,
                                Participants: row.participants,
                                Description: row.desc,
                                image: imgPath,
                                action: <div>
                                    <BsEyeFill className='cursor' color="#0052cc" size={20} onClick={() => participants_detail_handler(row.farmer_list)} />
                                </div>
    
                            }
                        )
                    })
                    setTableData(list);
                    setLoading(false);
                }else{
                    setTableData('');
                    setLoading(false);
                    //let notify = notification({ message: "No Data Found! Try Again", type: 'error' });
                    //notify(); 
                }
            })
            .catch(error => {
                setTableData('');
                setLoading(false);
                //let notify = notification({ message: "No Data Found! Try Again", type: 'error' });
                //notify();
            });

    }

    const participants_detail_handler = (dtl) => {
        setModalData('');
        let fdata = JSON.parse(dtl);
        let list = fdata.map((row, index) => {
            return(
                {
                    sl: index+1,
                    ids: row.farmer_id,
                    name: row.farmer_name
                }
            )
        })

        setModalData(list);
        setViewModal(true);

    }

    const download_excel_handler = () => {
        if(tableData.length > 0){
          let wb = XLSX.utils.book_new(),
          ws = XLSX.utils.json_to_sheet(tableData);
    
          XLSX.utils.book_append_sheet(wb, ws, "Mysheet1");
    
          XLSX.writeFile(wb, "Meeting_Training.xlsx");
        }
      }

    return (
        <>
            <Header />
            <Page title="Meeting & Training" breadcrumbs="Shareholder Services / Meeting & Training">
                <div style={{ width: 'auto', position: 'absolute', top: '60px', right: '20px' }}>
                    <Input
                        type="month"
                        bsSize="sm"
                        defaultValue={monthYear}
                        onChange={(e) => setMonthYear(e.target.value)}
                        style={{ width: '165px' }}
                    />
                </div>
                <Row className="p-3">
                    <Col xs={12}>
                        <Card>
                            <CardHeader>Meeting & Training List</CardHeader>
                            <div style={{position:'relative', height:'1px'}}>
                                <Tooltip title="Excel download">
                                    <SiMicrosoftexcel className='excelIcon' size={20} onClick={download_excel_handler} />
                                </Tooltip> 
                            </div>
                            <CardBody>
                                
                                <DataTable
                                    title=""
                                    columns={[
                                        {
                                            name: '#',
                                            selector: row=>row.SLN,
                                            sortable: true,
                                            width: "5%"
                                        },
                                        {
                                            name: 'Date',
                                            selector: row=>row.Date,
                                            sortable: true,
                                        },
                                        {
                                            name: 'Type',
                                            selector: row=>row.Type,
                                            sortable: false,
                                        },
                                        {
                                            name: 'Trainer Name ',
                                            selector: row=>row.TrainerName,
                                            sortable: false,
                                        },
                                        {
                                            name: 'Participants',
                                            selector: row=>row.Participants,
                                            sortable: false,
                                        },
                                        {
                                            name: 'Description',
                                            selector: row=>row.Description,
                                            sortable: false,
                                            width: "25%"
                                        },
                                        {
                                            name: 'Image',
                                            selector: row=>row.image,
                                            sortable: false,
                                        },
                                        {
                                            name: 'Participants List',
                                            selector: row=>row.action,
                                            sortable: false,
                                        },
                                    ]}
                                    data={tableData}
                                    striped
                                    highlightOnHover
                                    pagination
                                    paginationPerPage="100"
                                    paginationComponentOptions={paginationComponentOptions}
                                    progressPending={loading}

                                />
                            </CardBody>
                        </Card>

                    </Col>
                </Row>
            </Page>
            <Modal
                centered
                scrollable
                size="md"
                isOpen={viewModal}
                toggle={() => setViewModal(!viewModal)}
            >
                <ModalHeader toggle={() => setViewModal(!viewModal)}>
                    Participants List
                </ModalHeader>
                <ModalBody>
                    <DataTable
                        title=""
                        columns={[
                            {
                                name: '#',
                                selector: row=>row.sl,
                                sortable: true,
                                width: "15%"
                            },
                            {
                                name: 'Shareholder Id',
                                selector: row=>row.ids,
                                sortable: false,
                            },
                            {
                                name: 'Shareholder Name',
                                selector: row=>row.name,
                                sortable: false,
                                width: "50%"
                            },
                        ]}
                        data={modalData}
                        striped
                        highlightOnHover
                    />
                </ModalBody>
            </Modal>

            <Footer />
        </>
    )
}
export default MeetingTraining;