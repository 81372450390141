import React, { useState, useEffect } from 'react';
import notification from '../../hocs/notification';
import axios from 'axios';
import mainurl from '../../../MainURL';
import {
    Col, Row, Card, CardHeader, CardBody, Button, Input, Label, Form, InputGroup, InputGroupText, Spinner,
} from 'reactstrap';

import { FaHeading } from 'react-icons/fa';


const EditInventory = ({ data, close_handler, ...props}) => {
    const [inventoryName, setInventoryName] = useState(data.description);
    const [inventoryNameH, setInventoryNameH] = useState(data.description_h);
    const [unit, setUnit] = useState(data.uom_id);
    const [hindiNameLoading, setHindiNameLoading] = useState(false);
    const [uomOpt, setUomOpt] = useState('');

    useEffect(() => {
        get_UOM_list();
    }, []);

    const get_UOM_list = () => {
        let postData = [{ "org_id": 100, "user_id": "0" }];
        axios({
          method: 'post',
          url: mainurl + 'Master/box_uom_list',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            console.log(response.data);
            let list = response.data.map(row => {
                return(
                    <option value={row.uom_id}>{row.uom_name}</option>
                )
            })
            setUomOpt(list);
          })
          .catch(error => {
            let notify = notification({ message: "UOM list not Found! Try Again", type: 'error' });
            notify();
          });
    }

    const edit_handler = (e) => {
        e.preventDefault();
        let postData = [{ 
            "desc": inventoryName, 
            "desc_h": inventoryNameH,
            "type": data.type,
            "uom":unit,
            "medicine_id": data.medicine_id 
        }];

        console.log(postData);
        axios({
          method: 'post',
          url: mainurl + 'Master/box_input_edit',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            console.log(response.data);
            let notify = notification({ message: "updated successfully.", type: 'success' });
            notify();
            close_handler();
          })
          .catch(error => {
            let notify = notification({ message: "update failed! Try Again", type: 'error' });
            notify();
          });
    }

    const translate_text_handler = (e) => {
        setHindiNameLoading(true);
        if(inventoryName.length > 1){
            
            let postData = [{ "t_text" : inventoryName}];
            axios({
                method: 'post',
                url: mainurl + 'Master/translate_text',
                data: postData,
                headers: { 'Content-Type': 'application/JSON', }
              })
                .then(function (response) {
                    let trans = response.data;
                    setInventoryNameH(trans);
                    setHindiNameLoading(false);
                })
                .catch(error => {
                    setHindiNameLoading(false);
                    let notify = notification({ message: "Hindi Translation is not working! Try Again", type: 'error' });
                    notify();
                  });

        }else{
            setHindiNameLoading(false);
            let notify = notification({ message: "Please enter english name first.", type: 'warn' });
            notify();
        }
    }
    return (
        <Card>
            <CardHeader>Update Inventory</CardHeader>
            <CardBody>
                <Form onSubmit={edit_handler}> 
                
                    <Row>

                        <Col md={4}>
                            <Label for="name"> Name</Label>
                            <Input id="name" type='text' bsSize="sm" maxLength={50} onKeyPress={(e) => !/^[a-zA-Z\s]*$/.test(e.key) && e.preventDefault()} value={inventoryName} onChange={(e) => setInventoryName(e.target.value)} required />
                        </Col>
                        <Col md={4}>
                            <Label for="hName"> Name Hindi</Label>
                            <InputGroup size="sm">
                                <Input id="hName" type='text' maxLength={50} value={inventoryNameH} bsSize="sm" onChange={(e)=>setInventoryNameH(e.target.value)} />
                                <InputGroupText className='bg-primary cursor' onClick={!hindiNameLoading ? translate_text_handler : undefined}>
                                    {hindiNameLoading ? <Spinner color="secondary" size="sm">Loading...</Spinner> : <FaHeading color="#fff" />}
                                </InputGroupText>
                            </InputGroup>
                        </Col>
                        {
                        (data.type !=="3" && data.type !=="4") && <Col md={2}>
                            <Label for="unit">Unit</Label>
                            <Input id="unit" bsSize="sm" type="select" value={unit} onChange={(e) => setUnit(e.target.value)}>
                                <option value="0"> --Select Unit--</option>
                                {uomOpt}
                            </Input>
                        </Col>
                        }
                        <Col md={2}>
                            <Button className='mt-4' color="primary" size="sm" >Update </Button>
                        </Col>
                    </Row>
                </Form>
            </CardBody>
        </Card>

    )
}

export default EditInventory;