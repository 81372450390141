import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import notification from '../../component/hocs/notification';
import mainurl from '../../MainURL';
import axios from 'axios';
import Page from '../../component/utility/Page';
import DataTable from 'react-data-table-component';
import AddDemographic from '../../component/utility/master/AddDemographic';
import EditDemographic from '../../component/utility/master/EditDemographic';
import { Col, Row, Card, CardHeader, CardBody, Button, Input, Label, Modal, ModalBody,  ModalHeader, InputGroup, InputGroupText
} from 'reactstrap';
import * as XLSX from 'xlsx/xlsx.mjs';
import {SiMicrosoftexcel} from 'react-icons/si';
import Tooltip from '@mui/material/Tooltip';

import {FaEdit, FaSearch, FaSignInAlt} from 'react-icons/fa';
import {MdDeleteForever, } from 'react-icons/md';

const DemographicMaster = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [currSelect, setCurrSelect] = useState(1);

    const [districtOpt, setDistrictOpt] = useState();
    const [blockOpt, setBlockOpt] = useState();
    const [panchyatOpt, setPanchyatOpt] = useState();
    const [district, setDistrict] = useState("0");
    const [block, setBlock] = useState("0");
    const [panchyat, setPanchyat] = useState("0");
    const [village, setVillage] = useState("");
    
    const [districtTable, setDistrictTable] = useState("");
    const [blockTable, setBlockTable] = useState("");
    const [panchyatTable, setPanchyatTable] = useState("");
    const [villageTable, setVillageTable] = useState("");
    const [pgTable, setPgTable] = useState("");
    const [exlpgTable, setExlPgTable] = useState("");
    const [demoCenterTable, setDemoCenterTable] = useState("");
    
    const [editModel, setEditModel] = useState(false);
    const [modelData, setModelData] = useState('');
    const [accData, setAccData] = useState('');

    const [dist_role, setDist_role] = useState(false);
    const [block_role, setBlock_role] = useState(false);

    const [searchTxt, setSearchTxt] = useState('');
    const [searchData, setSearchData] = useState('');


    useEffect(() => {
        // check Access
        let accData = JSON.parse(sessionStorage.getItem('accessControl'));
        setAccData(accData[3].pages[4]);
        if(accData[3].pages[4].page_view !== "1"){
            navigate("/home");
        }
        //
        checkRole();
    }, []);

    const checkRole = () => {
        let role = sessionStorage.getItem('role');
        if (role === "101") {
            getDistrictList();
        } else if (role === "102") {
            let dist = sessionStorage.getItem('dist');
            setDist_role(true);
            getBlockList({ target: { value: dist } });
        } else if (role === "103") {
            let dist = sessionStorage.getItem('dist');
            let block = sessionStorage.getItem('block');
            setDist_role(true);
            setBlock_role(true);
            getBlockList({ target: { value: dist } });
            getPanchayatList({ target: { value: block } });
    
        }
    }
    

    const showTab = (id) => {
        setCurrSelect(id);
        if(id == 1){
            //Call District API
            getDistrictList();
        }else if(id === 5){
            demo_center_list();
        }else if(id === 6){
            //pg_vo_list();
            setDistrict("0");
            setBlock("0");
            setPanchyat("0");

        }
    }

    const reset_handler = () => {
        setDistrictTable('');
        setBlockTable('');
        setPanchyatTable('');
        setVillageTable('');

        showTab(currSelect);
    }

    const pg_vo_list = () => {
        setLoading(true);
        setSearchData('');
        let postData = [{ "org_id": 100, "dist_id": district, "block_id": block, "panchyat_id":panchyat}];
        axios({
          method: 'post',
          url: mainurl + 'Master/box_master_pg_vo_list',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            let data = response.data.map(row => {
                return(
                    {
                        ids: row.pg_id,
                        pgName: row.pg_name_desc,
                        pgNameHindi: row.pg_name_hindi,
                        Location: row.district_name +" / "+ row.block_long_name +" / "+ row.panchyat_name +" / "+ row.village_name,
                        action: <div>
                            { accData.page_edit === "1" && <FaEdit className='me-3 cursor' color="#047e04" onClick={()=> edit_handler(row, "6", "PG/VO")} size={20} />}
                            { accData.page_edit === "1" && <FaSignInAlt className='me-3 cursor' color="#fba10f" onClick={()=> edit_handler(row, "7", "PG/VO")} size={20} />}
                            { accData.page_delete === "1" && <MdDeleteForever color="#f70000" size={20} />}
                        </div>
                    }
                )
            })
            setLoading(false);
            setPgTable(data);
            setSearchData(data);

            let exldata = response.data.map(row => {
                return(
                    {
                        ids: row.pg_id,
                        PG_Name: row.pg_name_desc,
                        PG_Name_Hindi: row.pg_name_hindi,
                        District: row.district_name, 
                        Block: row.block_long_name,
                        Panchayat: row.panchyat_name,
                        Village: row.village_name,
                    }
                )
            })
            setExlPgTable(exldata);
          })
          .catch(error => {
            setLoading(false);
            //let notify = notification({ message: "PG / VO list not Found! Try Again", type: 'error' });
            //notify();
          });
    }
    const search_handler = (txt) => {
        setLoading(true);
        setSearchTxt(txt)
        if(pgTable.length > 0){
            let tempSearchData = pgTable.filter (item => {
                const query = txt.toLowerCase();
                return ( 
                    item.pgName.toLowerCase().indexOf(query) >= 0 ||
                    item.ids.toLowerCase().indexOf(query) >= 0   
                )
            })
            setSearchData(tempSearchData);
            setLoading(false);
        }
    }
    const demo_center_list = () => {
        
        let postData = [{ "org_id": 100}];
        axios({
          method: 'post',
          url: mainurl + 'Master/box_democenter_list',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            let data = response.data.map(row => {
                return(
                    {
                        id: row.demo_center_code,
                        name: row.demo_center_name,
                        nameHindi: row.demo_center_name_hindi,
                        action: <div>
                            { accData.page_edit === "1" && <FaEdit className='me-3 cursor' color="#047e04" onClick={()=> edit_handler(row, "6", "PG/VO")} size={20} />}
                            { accData.page_delete === "1" && <MdDeleteForever color="#f70000" size={20} />}
                        </div>
                    }
                )
            })
            setDemoCenterTable(data);
          })
          .catch(error => {
            //let notify = notification({ message: "Demo Center list not Found! Try Again", type: 'error' });
            //notify();
          });
    }

    const edit_handler = (row, id, name) => {       
        let temp = <ModalBody>
                    <EditDemographic geoId={id} geo={name} data={row} close_handler={close_modal_handler} reset={reset_handler} />
                </ModalBody>

        setModelData(temp);
        setEditModel(true);        

    }

    const close_modal_handler = () => {
        setEditModel(false);
    }

    // district, block, panchayat, village API call
    const getDistrictList = () => {
        //
        let accData = JSON.parse(sessionStorage.getItem('accessControl'));
        let acc = accData[3].pages[3];
        //
        setBlock("0");
        setPanchyat("0");
        setDistrictTable("")
    
        let postData = [{ "org_id": 100, "user_id": "0" }];
        axios({
          method: 'post',
          url: mainurl + 'Geo/geo_district_list2',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            let list = response.data.map((row, index) => {
              return <option key={index} value={row.district_short_cd}> {row.district_name} </option>
            })
            setDistrictOpt(list);
            let data = response.data.map(row => {
                return(
                    {
                        id: row.district_short_cd,
                        distName: row.district_name,
                        distNameHindi: row.district_name_h,
                        action: <div>
                            { acc.page_edit === "1" &&  <FaEdit className='me-3 cursor' color="#047e04" onClick={()=> edit_handler(row, "1", "District")} size={20} />}
                            { acc.page_delete === "1" && <MdDeleteForever color="#f70000" size={20} />}
                        </div>
                    }
                )
            })
            setDistrictTable(data);
          })
          .catch(error => {
            //let notify = notification({ message: "District list not Found! Try Again", type: 'error' });
            //notify();
          });
      }
    
      const getBlockList = (e) => {
        setBlockTable("")
        setDistrict(e.target.value);
        setBlock("0");
        setPanchyat("0");
        let postData = [{ "org_id": 100, "user_id": "0", "dist_id": e.target.value }]
        axios({
          method: 'post',
          url: mainurl + 'Geo/geo_block_list',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            let list = response.data.map((row, index) => {
              return <option key={index} value={row.block_code}> {row.block_long_name} </option>
            })
            setBlockOpt(list);
            let data = response.data.map(row => {
                return(
                    {
                        id: row.block_code,
                        distName: row.district_name,
                        blockName: row.block_long_name,
                        blockNameHindi: row.block_name_h,
                        action: <div>
                            { accData.page_edit === "1" &&  <FaEdit className='me-3 cursor' color="#047e04" onClick={()=> edit_handler(row, "2", "Block")}  size={20} />}
                            { accData.page_delete === "1" && <MdDeleteForever color="#f70000" size={20} />}
                        </div>
                    }
                )
            })
            setBlockTable(data);
          })
          .catch(error => {
            //let notify = notification({ message: "Block list not Found! Try Again", type: 'error' });
            //notify();
          });
      }
    
      const getPanchayatList = (e) => {
        setBlock(e.target.value);
        setPanchyatTable("")
        setPanchyat("0");
        let postData = [{ "org_id": 100, "user_id": "0", "block_id": e.target.value }]
        axios({
          method: 'post',
          url: mainurl + 'Geo/geo_pan_list',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            let list = response.data.map((row, index) => {
              return <option key={index} value={row.panchyat_code}> {row.panchyat_name} </option>
            })
    
            setPanchyatOpt(list);
            let data = response.data.map(row => {
                return(
                    {
                        id: row.panchyat_code,
                        distName: row.district_name,
                        blockName: row.block_long_name,
                        panchyatName: row.panchyat_name,
                        panchyatNameHindi: row.panchyat_name_hindi,
                        action: <div>
                            { accData.page_edit === "1" &&  <FaEdit className='me-3 cursor' color="#047e04" onClick={()=> edit_handler(row, "3", "Panchayat")}  size={20} />}
                            { accData.page_delete === "1" && <MdDeleteForever color="#f70000" size={20} />}
                        </div>
                    }
                )
            })
            setPanchyatTable(data);
          })
          .catch(error => {
            //let notify = notification({ message: "Panchayat list not Found! Try Again", type: 'error' });
            //notify();
          });
      }
      const getVillageList = (e) => {
        setPanchyat(e.target.value);
        setVillageTable("")
        let postData = [{ "org_id": 100, "user_id": "128", "panchayat_id": e.target.value }]
        axios({
          method: 'post',
          url: mainurl + 'Geo/geo_village_list',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            let data = response.data.map(row => {
                return(
                    {
                        id: row.village_code,
                        distName: row.district_name,
                        blockName: row.block_long_name,
                        panchyatName: row.panchyat_name,
                        villageName: row.village_name,
                        villageNameHindi: row.village_name_h,
                        action: <div>
                            { accData.page_edit === "1" && <FaEdit className='me-3 cursor' color="#047e04" onClick={()=> edit_handler(row, "4", "Village")}  size={20} />}
                            { accData.page_delete === "1" && <MdDeleteForever color="#f70000" size={20} />}
                        </div>
                    }
                )
            })
            setVillageTable(data);
          })
          .catch(error => {
            //let notify = notification({ message: "Village list not Found! Try Again", type: 'error' });
            //notify();
          });
      }
      // End

      const download_excel_handler = (id) => {
        let data = '';
        if(id === 1){
            data = districtTable;
        }else if(id === 2){
            data = blockTable;
        }else if(id === 3){
            data = panchyatTable;
        }else if(id === 4){
            data = villageTable;
        }else if(id === 5){
            data = demoCenterTable;
        }else if(id === 6){
            data = exlpgTable;
        }
        if(data.length > 0){
          let wb = XLSX.utils.book_new(),
          ws = XLSX.utils.json_to_sheet(data);
    
          XLSX.utils.book_append_sheet(wb, ws, "Mysheet1");
    
          XLSX.writeFile(wb, "demographic_list.xlsx");
        }
      }

    return(
        <div>
            <Header />
            <Page title="Demographic Master" breadcrumbs="Master / Demographic Master">
                <Row className='p-3'>
                    <Col xs={12} className="mb-2">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md={1}>
                                        <Button color={currSelect === 1 ? 'primary' : 'light'} onClick={()=>showTab(1)} size="sm" block>District</Button>
                                    </Col>
                                    <Col md={1}>
                                        <Button color={currSelect === 2 ? 'primary' : 'light'} onClick={()=>showTab(2)} size="sm" block>Block</Button>
                                    </Col>
                                    <Col md={1}>
                                        <Button color={currSelect === 3 ? 'primary' : 'light'} onClick={()=>showTab(3)} size="sm" block>Panchayat</Button>
                                    </Col>
                                    <Col md={1}>
                                        <Button color={currSelect === 4 ? 'primary' : 'light'} onClick={()=>showTab(4)} size="sm" block>Village</Button>
                                    </Col>
                                    <Col md={2}>
                                        <Button color={currSelect === 5 ? 'primary' : 'light'} onClick={()=>showTab(5)} size="sm" block>Demo Centers</Button>
                                    </Col>
                                    <Col md={1}>
                                        <Button color={currSelect === 6 ? 'primary' : 'light'} onClick={()=>showTab(6)} size="sm" block>PG / VO</Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={12}>
                        { currSelect === 1 && 
                        <>
                           { accData.page_add === "1" &&  <AddDemographic geoId="1" geo="District" reset={reset_handler} />}

                            <Card className='mb-2'>
                                <CardHeader>District Master</CardHeader>
                                <div style={{position:'relative', height:'1px'}}>
                                    <Tooltip title="Excel download">
                                        <SiMicrosoftexcel className='excelIcon' size={20} onClick={()=>download_excel_handler(1)} />
                                    </Tooltip> 
                                </div>
                                <CardBody>
                                    <DataTable
                                        title=""
                                        columns={[
                                            {
                                            name: 'District Id',
                                            selector: 'id',
                                            sortable: true,
                                            },
                                            {
                                            name: 'District Name',
                                            selector: 'distName',
                                            sortable: false,
                                            },
                                            {
                                            name: 'District Name (Hindi)',
                                            selector: 'distNameHindi',
                                            sortable: false,
                                            },
                                            {
                                            name: 'Action',
                                            selector: 'action',
                                            sortable: false,
                                            },
                                        ]}
                                        data={districtTable}
                                        striped
                                        highlightOnHover
                                        progressPending={loading}
                                        />
                                </CardBody>
                            </Card>  
                        </>
                        }
                        { currSelect === 2 && 
                            <>
                                { accData.page_add === "1" && <AddDemographic geoId="2" geo="Block" reset={reset_handler} />}

                                <Card className='mb-2'>
                                    <CardHeader>Block Master</CardHeader>
                                    <div style={{position:'relative', height:'1px'}}>
                                        <Tooltip title="Excel download">
                                            <SiMicrosoftexcel className='excelIcon' size={20} onClick={()=>download_excel_handler(2)} />
                                        </Tooltip> 
                                    </div>
                                    <CardBody>
                                        <Row>
                                            <Col xs={3} className="mb-1">
                                                <Label className="me-sm-2" for="district"> District </Label>
                                                <Input id="district" bsSize="sm" name="district" type="select" value={district} onChange={(e) => getBlockList(e)} disabled={dist_role}>
                                                <option value=""> --Select District--</option>
                                                {districtOpt}
                                                </Input>
                                            </Col>
                                        </Row>  
                                        <hr />  
                                        <DataTable
                                            title=""
                                            columns={[
                                                
                                                {
                                                name: 'District Name',
                                                selector: 'distName',
                                                sortable: false,
                                                },
                                                {
                                                name: 'Block Id',
                                                selector: 'id',
                                                sortable: false,
                                                },
                                                {
                                                name: 'Block Name',
                                                selector: 'blockName',
                                                sortable: false,
                                                },
                                                {
                                                name: 'Block Name (Hindi)',
                                                selector: 'blockNameHindi',
                                                sortable: false,
                                                },
                                                {
                                                    name: 'Action',
                                                    selector: 'action',
                                                    sortable: false,
                                                },
                                                
                                            ]}
                                            data={blockTable}
                                            striped
                                            highlightOnHover
                                            progressPending={loading}
                                        />
                                        
                                    </CardBody>
                                </Card>
                            </>
                        }
                        { currSelect === 3 && 
                            <>
                                { accData.page_add === "1" && <AddDemographic geoId="3" geo="Panchayat" reset={reset_handler} />}

                                <Card className='mb-2'>
                                    <CardHeader>Panchayat Master</CardHeader>
                                    <div style={{position:'relative', height:'1px'}}>
                                        <Tooltip title="Excel download">
                                            <SiMicrosoftexcel className='excelIcon' size={20} onClick={()=>download_excel_handler(3)} />
                                        </Tooltip> 
                                    </div>
                                    <CardBody>
                                    <Row>
                                            <Col xs={3} className="mb-1">
                                                <Label className="me-sm-2" for="district"> District </Label>
                                                <Input id="district" bsSize="sm" name="district" type="select" value={district} onChange={(e) => getBlockList(e)} disabled={dist_role}>
                                                <option value=""> --Select District--</option>
                                                {districtOpt}
                                                </Input>
                                            </Col>
                                            <Col xs={3} className="mb-1">
                                                <Label className="me-sm-2" for="block"> Block </Label>
                                                <Input id="block" bsSize="sm" name="block" type="select" value={block} onChange={(e) => getPanchayatList(e)} disabled={block_role}>
                                                <option value=""> --Select Block--</option>
                                                {blockOpt}
                                                </Input>
                                            </Col>
                                        </Row>  
                                        <hr />  
                                        <DataTable
                                            title=""
                                            columns={[
                                                
                                                {
                                                name: 'District Name',
                                                selector: 'distName',
                                                sortable: false,
                                                },
                                                {
                                                name: 'Block Name',
                                                selector: 'blockName',
                                                sortable: false,
                                                },
                                                {
                                                name: 'Panchayat Id',
                                                selector: 'id',
                                                sortable: false,
                                                },
                                                {
                                                name: 'Panchayat Name',
                                                selector: 'panchyatName',
                                                sortable: false,
                                                },
                                                {
                                                name: 'Panchayat Name (Hindi)',
                                                selector: 'panchyatNameHindi',
                                                sortable: false,
                                                },
                                                {
                                                    name: 'Action',
                                                    selector: 'action',
                                                    sortable: false,
                                                },
                                                
                                            ]}
                                            data={panchyatTable}
                                            striped
                                            highlightOnHover
                                            progressPending={loading}
                                        />
                                        
                                    </CardBody>
                                </Card>
                            </>
                        }
                        { currSelect === 4 && 
                            <>
                                { accData.page_add === "1" && <AddDemographic geoId="4" geo="Village" reset={reset_handler} />}
                            
                                <Card className='mb-2'>
                                    <CardHeader>Village Master</CardHeader>
                                    <div style={{position:'relative', height:'1px'}}>
                                        <Tooltip title="Excel download">
                                            <SiMicrosoftexcel className='excelIcon' size={20} onClick={()=>download_excel_handler(4)} />
                                        </Tooltip> 
                                    </div>
                                    <CardBody>
                                    <Row>
                                        <Col xs={3} className="mb-1">
                                            <Label className="me-sm-2" for="district"> District </Label>
                                            <Input id="district" bsSize="sm" name="district" type="select" value={district} onChange={(e) => getBlockList(e)} disabled={dist_role}>
                                            <option value=""> --Select District--</option>
                                            {districtOpt}
                                            </Input>
                                        </Col>
                                        <Col xs={3} className="mb-1">
                                            <Label className="me-sm-2" for="block"> Block </Label>
                                            <Input id="block" bsSize="sm" name="block" type="select" value={block} onChange={(e) => getPanchayatList(e)} disabled={block_role}>
                                            <option value=""> --Select Block--</option>
                                            {blockOpt}
                                            </Input>
                                        </Col>
                                        <Col xs={3} className="mb-1">
                                            <Label className="me-sm-2" for="village"> Panchayat </Label>
                                            <Input id="panchyat" bsSize="sm" name="panchyat" type="select" value={panchyat} onChange={(e) => getVillageList(e)}>
                                            <option value=""> --Select panchayat--</option>
                                            {panchyatOpt}
                                            </Input>
                                        </Col>
                                        </Row>  
                                        <hr /> 
                                        <DataTable
                                            title=""
                                            columns={[
                                                
                                                {
                                                name: 'District Name',
                                                selector: 'distName',
                                                sortable: false,
                                                },
                                                {
                                                name: 'Block Name',
                                                selector: 'blockName',
                                                sortable: false,
                                                },
                                                {
                                                name: 'Panchayat Name',
                                                selector: 'panchyatName',
                                                sortable: false,
                                                },
                                                {
                                                    name: 'Village Id',
                                                    selector: 'id',
                                                    sortable: false,
                                                },
                                                {
                                                name: 'Village Name',
                                                selector: 'villageName',
                                                sortable: false,
                                                },
                                                {
                                                name: 'Village Name (Hindi)',
                                                selector: 'villageNameHindi',
                                                sortable: false,
                                                },
                                                {
                                                    name: 'Action',
                                                    selector: 'action',
                                                    sortable: false,
                                                },
                                                
                                            ]}
                                            data={villageTable}
                                            striped
                                            highlightOnHover
                                            progressPending={loading}
                                        />
                                    </CardBody>
                                </Card>
                            </>
                        }
                        { currSelect === 5 && 
                            <>
                                { accData.page_add === "1" && <AddDemographic geoId="5" geo="Demo Center" reset={reset_handler} />}
                                
                                <Card>
                                    <CardHeader>Demo Center Master</CardHeader>
                                    <div style={{position:'relative', height:'1px'}}>
                                        <Tooltip title="Excel download">
                                            <SiMicrosoftexcel className='excelIcon' size={20} onClick={()=>download_excel_handler(5)} />
                                        </Tooltip> 
                                    </div>
                                    <CardBody>
                                        <DataTable
                                            title=""
                                            columns={[
                                                {
                                                name: 'Demo Center Id',
                                                selector: 'id',
                                                sortable: true,
                                                },
                                                {
                                                name: 'Demo Center Name',
                                                selector: 'name',
                                                sortable: false,
                                                },
                                                {
                                                name: 'Demo Center Name (Hindi)',
                                                selector: 'nameHindi',
                                                sortable: false,
                                                },
                                                {
                                                name: 'Action',
                                                selector: 'action',
                                                sortable: false,
                                                },
                                            ]}
                                            data={demoCenterTable}
                                            striped
                                            highlightOnHover
                                            progressPending={loading}
                                        />
                                    </CardBody>
                                </Card>
                            </>
                        }
                        { currSelect === 6 && 
                            <>
                                { accData.page_add === "1" && <AddDemographic geoId="6" geo="PG/VO" reset={reset_handler} />}

                                <Card>

                                    <CardHeader>PG / VO Master</CardHeader>
                                    <Col sm={{ offset: 6, size: 3 }} style={{position: 'absolute', top: '2px', right: '2px'}}>
                                        <InputGroup size="sm">
                                            <InputGroupText> <FaSearch /> </InputGroupText>
                                            <Input type="search" placeholder='Search' value={searchTxt} onChange={(e)=>search_handler(e.target.value)} />
                                        </InputGroup>
                                    </Col>
                                    <div style={{position:'relative', height:'1px'}}>
                                        <Tooltip title="Excel download">
                                            <SiMicrosoftexcel className='excelIcon' size={20} onClick={()=>download_excel_handler(6)} />
                                        </Tooltip> 
                                    </div>
                                    <CardBody>
                                    <Row>
                                        <Col xs={3} className="mb-1">
                                            <Label className="me-sm-2" for="district"> District </Label>
                                            <Input id="district" bsSize="sm" name="district" type="select" value={district} onChange={(e) => getBlockList(e)} disabled={dist_role}>
                                            <option value="0"> --All--</option>
                                            {districtOpt}
                                            </Input>
                                        </Col>
                                        <Col xs={3} className="mb-1">
                                            <Label className="me-sm-2" for="block"> Block </Label>
                                            <Input id="block" bsSize="sm" name="block" type="select" value={block} onChange={(e) => getPanchayatList(e)} disabled={block_role}>
                                            <option value="0"> --All--</option>
                                            {blockOpt}
                                            </Input>
                                        </Col>
                                        <Col xs={3} className="mb-1">
                                            <Label className="me-sm-2" for="village"> Panchayat </Label>
                                            <Input id="panchyat" bsSize="sm" name="panchyat" type="select" value={panchyat} onChange={(e) => getVillageList(e)}>
                                            <option value="0"> --All--</option>
                                            {panchyatOpt}
                                            </Input>
                                        </Col>
                                        <Col xs={3} className="mb-1">
                                            <Button className='mt-4' size="sm" color="primary" onClick={pg_vo_list}>Submit</Button>
                                        </Col>
                                        </Row>  
                                        <hr /> 
                                        <DataTable
                                            title=""
                                            columns={[
                                                {
                                                name: 'PG/VO Id',
                                                selector: row=>row.ids,
                                                sortable: true,
                                                },
                                                {
                                                name: 'PG/VO Name',
                                                selector: row=>row.pgName,
                                                sortable: false,
                                                },
                                                {
                                                name: 'PG/VO Name (Hindi)',
                                                selector: row=>row.pgNameHindi,
                                                sortable: false,
                                                },
                                                {
                                                name: 'Location',
                                                selector: row=>row.Location,
                                                sortable: false,
                                                width: "30%"
                                                },
                                                {
                                                name: 'Action',
                                                selector: row=>row.action,
                                                sortable: false,
                                                },
                                            ]}
                                            data={searchData}
                                            striped
                                            highlightOnHover
                                            progressPending={loading}
                                        />
                                    </CardBody>
                                </Card>
                            </>
                        }
                    </Col>
                    <Col>
                    <Button className='mt-4' color="primary" size="sm" onClick={reset_handler}  >Reset </Button>
                    </Col>
                </Row>
            </Page>
            <Modal
                centered
                scrollable
                size="xl"
                isOpen={editModel}
                toggle={() => setEditModel(!editModel)}
            >
                <ModalHeader toggle={() => setEditModel(!editModel)}>
                   Edit
                </ModalHeader>
                {modelData}
            </Modal>
            <Footer />
        </div>
    )
}

export default DemographicMaster;