import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import notification from '../../hocs/notification';
import axios from 'axios';
import mainurl from '../../../MainURL';
import {
  Col, Row, Button, Input, Label, Form, InputGroup, InputGroupText, Spinner, Card, CardHeader, CardBody, CardFooter
} from 'reactstrap';

import { FaHeading } from 'react-icons/fa';

const AddPashusaakhi = ({ back_handler, ...props }) => {
  
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [nameHin, setNameHin] = useState("");
  const [hindiNameLoading, setHindiNameLoading] = useState(false);
  const [mobileNo, setMobileNo] = useState("");
  const [pg, setPg] = useState("");
  const [selectPg, setSelectPg] = useState("");
  const [district, setDistrict] = useState("");
  const [block, setBlock] = useState("");
  const [panchayat, setPanchayat] = useState("");

  const [districtOpt, setDistrictOpt] = useState("");
  const [blockOpt, setBlockOpt] = useState("");
  const [panchayatOpt, setPanchayatOpt] = useState("");
  const [pgOpt, setPgOpt] = useState("");

  useEffect(() => {
    //pg_vo_list();
    getDistrictList();
  }, []);

  

  // district, block, panchayat, PG API call
  const getDistrictList = () => {
    setBlock("");
    setPanchayat("");
    setPg([]);
    setSelectPg([]);

    let postData = [{ "org_id": 100, "user_id": "0" }];
    axios({
      method: 'post',
      url: mainurl + 'Geo/geo_district_list2',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        let list = response.data.map((row, index) => {
          return <option key={index} value={row.district_short_cd}> {row.district_name} </option>
        })
        setDistrictOpt(list);

      })
      .catch(error => {
        let notify = notification({ message: "District list not Found! Try Again", type: 'error' });
        notify();
      });
  }

  const getBlockList = (e) => {
    setDistrict(e.target.value);
    setBlock("");
    setPanchayat("");
    setPg([]);
    setSelectPg([]);
    let postData = [{ "org_id": 100, "user_id": "0", "dist_id": e.target.value }]
    axios({
      method: 'post',
      url: mainurl + 'Geo/geo_block_list',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        let list = response.data.map((row, index) => {
          return <option key={index} value={row.block_code}> {row.block_long_name} </option>
        })
        setBlockOpt(list);

      })
      .catch(error => {
        let notify = notification({ message: "Block list not Found! Try Again", type: 'error' });
        notify();
      });
  }

  const getPanchayatList = (e) => {
    setBlock(e.target.value);
    setPanchayat("");
    setPg([]);
    setSelectPg([]);
    let postData = [{ "org_id": 100, "user_id": "0", "block_id": e.target.value }]
    axios({
      method: 'post',
      url: mainurl + 'Geo/geo_pan_list',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        let list = response.data.map((row, index) => {
          return <option key={index} value={row.panchyat_code}> {row.panchyat_name} </option>
        })

        setPanchayatOpt(list);

      })
      .catch(error => {
        let notify = notification({ message: "Panchayat list not Found! Try Again", type: 'error' });
        notify();
      });
  }

  const pg_vo_list = (e) => {
    setPg([]);
    setSelectPg([]);
    setPgOpt("");
    setPanchayat(e.target.value);

    let postData = [{ "org_id": 100, "panchyat_id": e.target.value }];
    axios({
      method: 'post',
      url: mainurl + 'Master/pg_list_panchyat_wise',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        let list = response.data.map(row => {
          return (
            { value: row.pg_no, label: row.pg_name_desc }
          )
        })
        setPgOpt(list);
      })
      .catch(error => {
        let notify = notification({ message: "PG / VO list not Found! Try Again", type: 'error' });
        notify();
      });
  }
  
  // End


  const translate_text_handler = (e) => {
    setHindiNameLoading(true);
    if (name.length > 1) {
      
      let postData = [{ "t_text" : name}];
      axios({
        method: 'post',
        url: mainurl + 'Master/translate_text',
        data: postData,
        headers: { 'Content-Type': 'application/JSON', }
      })
        .then(function (response) {
          let trans = response.data;
          setNameHin(trans);
          setHindiNameLoading(false);
        })
        .catch(error => {
          setHindiNameLoading(false);
          let notify = notification({ message: "Hindi Translation is not working! Try Again", type: 'error' });
          notify();
        });

    } else {
      setHindiNameLoading(false);
      let notify = notification({ message: "Please enter english name first.", type: 'warn' });
      notify();
    }
  }

  const add_handler = (e) => {
    e.preventDefault();
    setLoading(true);

    let pg_list = JSON.stringify(pg);
    pg_list = pg_list.replaceAll('","', ',');
    pg_list = pg_list.replaceAll('"', '');
    pg_list = pg_list.replaceAll('[', '');
    pg_list = pg_list.replaceAll(']', '');

    let postData = [{
      "org_id": "100",
      "aps_id": code,
      "aps_name": name,
      "aps_name_h": nameHin,
      "mobile_no": mobileNo,
      "pg_id": pg_list,
      "dist_id": district,
      "block_id": block,
      "panchyat_code": panchayat,
    }]

    axios({
      method: 'post',
      url: mainurl + 'Master/box_master_aps_add',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        if (response.data[0].retcode === "101") {
          let notify = notification({ message: "Added successfully.", type: 'success' });
          notify();
          back_handler();
        } else if (response.data[0].retcode === "102") {
          let notify = notification({ message: "Pashusakhi code already exists.", type: 'error' });
          notify();
        }
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        let notify = notification({ message: "Submit Failed! Try Again", type: 'error' });
        notify();
      });
  }

  const handle_PG = (options) => {
    let temp=[];
    options.map(val => {
      temp.push(val.value);
    })
    setPg(temp);
    setSelectPg(options);
  }

  return (
    <Card>
      <CardHeader>Add Pashusakhi</CardHeader>
      <CardBody>
        <Form onSubmit={add_handler}>
          <Row>
            <Col md={2} className="mb-2">
              <Label for="name">District</Label>
              <Input id="district" bsSize="sm" type="select" value={district} onChange={(e) => getBlockList(e)}>
                <option value="0"> --Select District--</option>
                {districtOpt}
              </Input>
            </Col>
            <Col xs={2} className="mb-2">
              <Label for="block"> Block </Label>
              <Input id="block" bsSize="sm" type="select" value={block} onChange={(e) => getPanchayatList(e)}>
                <option value="0"> --Select Block--</option>
                {blockOpt}
              </Input>
            </Col>

            <Col xs={2} className="mb-2">
              <Label for="panchyat"> Panchayat </Label>
              <Input id="panchyat" bsSize="sm" type="select" value={panchayat} onChange={(e) => pg_vo_list(e)} >
                <option value="0"> --Select Panchayat--</option>
                {panchayatOpt}
              </Input>
            </Col>
            <Col md={4} className="mb-2">
              <Label for="pg"> PG Name </Label>
              
              <Select closeMenuOnSelect={false} isMulti value={selectPg} options={pgOpt} onChange={handle_PG} />
            </Col>
            <Col md={2} className="mb-2">
              <Label for="code"> Pashusakhi Code</Label>
              <Input id="code" type='text' bsSize="sm" value={code} onChange={(e) => setCode(e.target.value)} required />
            </Col>
            <Col md={2} className="mb-2">
              <Label for="name"> Pashusakhi Name</Label>
              <Input id="name" type='text' bsSize="sm" onKeyPress={(e) => !/^[a-zA-Z\s]*$/.test(e.key) && e.preventDefault()} value={name} onChange={(e) => setName(e.target.value)} required />
            </Col>
            <Col md={2} className="mb-2">
              <Label for="hName"> Pashusakhi Name Hindi</Label>
              <InputGroup size="sm">
                <Input id="hName" type='text' value={nameHin} bsSize="sm" onChange={(e) => setNameHin(e.target.value)} />
                <InputGroupText className='bg-primary cursor' onClick={!hindiNameLoading ? translate_text_handler : undefined}>
                  {hindiNameLoading ? <Spinner color="secondary" size="sm">Loading...</Spinner> : <FaHeading color="#fff" />}
                </InputGroupText>
              </InputGroup>
            </Col>
            <Col md={2} className="mb-2">
              <Label for="mobileNo"> Mobile Number</Label>
              <Input id="mobileNo" type='text' bsSize="sm" maxLength={10} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} value={mobileNo} onChange={(e) => setMobileNo(e.target.value)} required />
            </Col>
            <hr />
            <Col md={2} className="mb-2">
              <Button color='primary' size="sm" block disabled= {loading} > Submit {loading && <Spinner color="secondary ms-1" size="sm" >Load...</Spinner>}</Button>
            </Col>
          </Row>
        </Form>
      </CardBody>
      <CardFooter className='text-end'>
        <Button color='primary' size="sm" onClick={back_handler}> Back </Button>
      </CardFooter>
    </Card>
  )
}

export default AddPashusaakhi;