import React, { useState, useEffect } from 'react';
import notification from '../../hocs/notification';

import DataTable from 'react-data-table-component';
import mainUrl from '../../../MainURL';
import axios from 'axios';
import * as XLSX from 'xlsx/xlsx.mjs';
import { SiMicrosoftexcel } from 'react-icons/si';

import Tooltip from '@mui/material/Tooltip';
import { Row, Col, Card, CardHeader, CardBody, Input, Spinner, Form, FormGroup, Label, Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

import { FaCheck, FaTimes, FaEye, FaFilePdf } from 'react-icons/fa';

import { getTodayDate } from '../../hocs/dateServices';

const imageUrl = 'https://microsave.boxfarming.in/v1/h7jj24643khb3589jn5dc4/';

const GoatPurchaseDtl = ({ back, ...props }) => {

    const [loading, setLoading] = useState(false);
    const [excelList, setExcelList] = useState('');
    const [frmDate, setFrmDate] = useState(getTodayDate());
    const [toDate, setToDate] = useState(getTodayDate());
    const [tableData, setTableData] = useState('');
    const [viewModal, setViewModal] = useState(false);
    const [viewModal2, setViewModal2] = useState(false);
    const [tr_id, setTr_id] = useState('');
    const [modalData, setModalData] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [remarks, setRemarks] = useState('0');
    const [other, setOther] = useState('');
    const [status, setStatus] = useState('1');

    const [districtOpt, setDistrictOpt] = useState();
    const [blockOpt, setBlockOpt] = useState();
    const [panchyatOpt, setPanchyatOpt] = useState();
    const [district, setDistrict] = useState("0");
    const [block, setBlock] = useState("0");
    const [panchyat, setPanchyat] = useState("0");

    const [dist_role, setDist_role] = useState(false);
    const [block_role, setBlock_role] = useState(false);
    const [count, setCount] = useState(1);

    useEffect(() => {
        checkRole();
        getDistrictList();
    }, []);

    const checkRole = () => {
        let role = sessionStorage.getItem('role');
        if (role === "101") {
            getDistrictList();
            setCount(2);
        } else if (role === "102") {
            let dist = sessionStorage.getItem('dist');
            setDist_role(true);
            getBlockList({ target: { value: dist } });
            setCount(2);
        } else if (role === "103") {
            let dist = sessionStorage.getItem('dist');
            setDist_role(true);
            setBlock_role(true);
            getBlockList({ target: { value: dist } });

        }
    }

    const getDistrictList = () => {
        setBlock("0");
        setPanchyat("0");
        let postData = [{ "org_id": 100, "user_id": "0" }];
        axios({
            method: 'post',
            url: mainUrl + 'Geo/geo_district_list2',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return <option key={index} value={row.district_short_cd}> {row.district_name} </option>
                })
                setDistrictOpt(list);
            })
            .catch(error => {
                let notify = notification({ message: "District list not Found! Try Again", type: 'error' });
                notify();
            });
    }

    const getBlockList = (e) => {
        setDistrict(e.target.value);
        setBlockOpt('');
        setPanchyatOpt('');
        setBlock("0");
        setPanchyat("0");
        let postData = [{ "org_id": 100, "user_id": "0", "dist_id": e.target.value }];
        if (e.target.value !== "0") {
            axios({
                method: 'post',
                url: mainUrl + 'Geo/geo_block_list',
                data: postData,
                headers: { 'Content-Type': 'application/JSON', }
            })
                .then(function (response) {
                    let list = response.data.map((row, index) => {
                        return <option key={index} value={row.block_code}> {row.block_long_name} </option>
                    })
                    setBlockOpt(list);
                    if (count === 1) {
                        setCount(2);
                        let block = sessionStorage.getItem('block');
                        getPanchayatList({ target: { value: block } });
                    }
                })
                .catch(error => {
                    let notify = notification({ message: "Block list not Found! Try Again", type: 'error' });
                    notify();
                });
        }
    }

    const getPanchayatList = (e) => {
        setBlock(e.target.value);
        setPanchyatOpt('');
        setPanchyat("0");
        let postData = [{ "org_id": 100, "user_id": "0", "block_id": e.target.value }]
        if (e.target.value !== "0") {
            axios({
                method: 'post',
                url: mainUrl + 'Geo/geo_pan_list',
                data: postData,
                headers: { 'Content-Type': 'application/JSON', }
            })
                .then(function (response) {
                    let list = response.data.map((row, index) => {
                        return <option key={index} value={row.panchyat_code}> {row.panchyat_name} </option>
                    })

                    setPanchyatOpt(list);
                })
                .catch(error => {
                    let notify = notification({ message: "Panchayat list not Found! Try Again", type: 'error' });
                    notify();
                });
        }
    }
    //

    const purchase_list_handler = (e) => {
        e.preventDefault();
        get_purchase_list();
    }

    const get_purchase_list = () => {
        setLoading(true);
        let postData = [{ "org_id": 100, "frm_date": frmDate, "to_date": toDate, "t_type": 1, "dist_id": district, "block_id": block, "pan_id": panchyat }];
        axios({
            method: 'post',
            url: mainUrl + 'Activity/goat_sale_purchase_view',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                if (response.data !== null) {

                    let list = response.data.map((row, index) => {
                        let bankinfo = <div>
                            <div><b>Account No:</b> {row.account_no}</div>
                            <div><b>IFSC code:</b> {row.ifsc_code}</div>
                            <div><b>Bank:</b> {row.bank_name}</div>
                            <div><b>Branch:</b> {row.branch}</div>
                        </div>;
                        let receipt = 'NA'; let passbook = 'NA'; let goatImg = 'NA';
                        if (row.receipt_img !== '' && row.receipt_img !== null && row.receipt_img !== "0") {
                            receipt = <a href={imageUrl + row.receipt_img} target="_blank" >View</a>;
                        } else {
                            receipt = 'NA';
                        }
                        if (row.bank_passbook_img !== '' && row.bank_passbook_img !== null && row.bank_passbook_img !== "0") {
                            passbook = <a href={imageUrl + row.bank_passbook_img} target="_blank" >View</a>;
                        } else {
                            passbook = 'NA';
                        }
                        if (row.goat_img !== '' && row.goat_img !== null && row.goat_img !== "0") {
                            goatImg = <a href={imageUrl + row.goat_img} target="_blank" >View</a>;
                        } else {
                            goatImg = 'NA';
                        }
                        let validate;
                        if (row.v_flag === '0') {
                            if (sessionStorage.getItem('role') === "103") {
                                validate = <Button size="sm" onClick={() => { setViewModal2(true); setTr_id(row.trans_id) }}> Change </Button>
                            } else {
                                validate = "Validation pending at Block level";
                            }
                        } else if (row.v_flag === '1') {
                            validate = <Tooltip title={row.remark}>
                                <span className='text-success'>Verified <FaCheck size={20} color="#00a500" /></span>
                            </Tooltip>;
                        } else if (row.v_flag === '2') {
                            validate = <Tooltip title={row.remark}>
                                <span className='text-danger'>Rejected <FaTimes size={20} color="#ff4136" /></span>
                            </Tooltip>;
                        } else if (row.v_flag === '3') {
                            validate = <div><a href={imageUrl + row.payment_detail[0].payment_init_pdf} target="_blank" ><FaFilePdf color="#ff4136" size={20} /></a> <span className='text-success'> Payment initiated</span></div>
                        } else if (row.v_flag === '4') {
                            let details = <div>
                                <div><b>Transaction Id:</b> {row.payment_detail[0].bank_transaction_id}</div>
                                <div><b>Cheque No:</b> {row.payment_detail[0].cheque_no}</div>
                                <div><b>Payment Date:</b> {row.payment_detail[0].payment_paid_date}</div>
                            </div>;
                            validate = <Tooltip title={details}>
                                <span className='text-success'> Payment Done</span>
                            </Tooltip>

                        }

                        return {
                            sl: index + 1,
                            user: row.aps_name + ' (' + row.user_id + ')',
                            farmer: row.f_first_name,
                            shareholder: row.shareholder === "0" ? "No" : "Yes",
                            act_date: row.trans_date.split(" ")[0].split('-').reverse().join('-'),
                            qty: <div>{row.trans_qty} <FaEye className='ms-2 cursor' size={20} color="#00a500" onClick={() => detail_view_handler(row.animal_detail)} /></div>,
                            weight: row.trans_weight + ' kg',
                            amt: <div>&#8377; {row.trans_amt}</div>,
                            receipt: receipt,
                            passbook: passbook,
                            goatImg: goatImg,
                            bank_dtl: <Tooltip title={bankinfo}>
                                <a className='cursor'>See</a>
                            </Tooltip>,
                            v_flag: validate

                        }
                    })

                    setTableData(list);
                    setLoading(false);

                    let excellist = response.data.map((row, index) => {
                        let status;
                        if (row.v_flag === '0') {
                            status = "Validation pending at Block level";
                        } else if (row.v_flag === '1') {
                            status = "Verified"
                        } else if (row.v_flag === '2') {
                            status = "Rejected"
                        } else if (row.v_flag === '3') {
                            status = "Payment initiated"
                        } else if (row.v_flag === '4') {
                            status = "Payment Done"
                        }

                        return {
                            sl: index + 1,
                            Pashusakhi: row.aps_name + ' (' + row.user_id + ')',
                            Shareholder_Name: row.f_first_name,
                            shareholder: row.shareholder === "0" ? "No" : "Yes",
                            Purchase_Date: row.trans_date.split(" ")[0].split('-').reverse().join('-'),
                            Quantity: row.trans_qty,
                            weight: row.trans_weight + ' kg',
                            Amount: row.trans_amt,
                            Account_No: row.account_no,
                            IFSC_code: row.ifsc_code,
                            Bank_Name: row.bank_name,
                            Branch: row.branch,
                            Status: status,
                            Remarks: row.remark

                        }
                    })

                    setExcelList(excellist);
                } else {
                    let notify = notification({ message: "No data Found!", type: 'warn' });
                    notify();
                    setLoading(false);
                    setTableData('');
                    setExcelList('');
                }

            })
            .catch(error => {
                let notify = notification({ message: "Goat purchase list not Found! Try Again", type: 'error' });
                notify();
                setLoading(false);
            });
    }

    const detail_view_handler = (data) => {

        let header = <Row>
            <Col><b>Gender</b></Col>
            <Col><b>Age</b></Col>
            <Col><b>Weight</b></Col>
            <Col><b>Rate/kg</b></Col>
        </Row>
        if (data !== null) {
            let m_data = data.map(row => {
                return (
                    <Row>
                        <Col>{row.gender}</Col>
                        <Col>{row.age_group}</Col>
                        <Col>{row.weight} kg</Col>
                        <Col>&#8377; {Number(row.price).toFixed(2)}</Col>
                    </Row>
                )
            });

            m_data.unshift(header);
            setModalData(m_data);
        } else {
            setModalData("No record found!");
        }
        setModalTitle("Animal Details");
        setViewModal(true);
    }

    const change_status = (id) => {

        let postData = [{ "trans_id": id, "status": status, "remark": remarks }];
        
        axios({
            method: 'post',
            url: mainUrl + 'Activity/goat_purchase_status',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                get_purchase_list();
                setTr_id('');
                let notify = notification({ message: "Status change successfully.", type: 'success' });
                notify();
                setRemarks('');
                setViewModal2(false);
            })
            .catch(error => {
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            });
    }

    const download_excel_handler = () => {
        if (excelList.length > 0) {
            let wb = XLSX.utils.book_new(),
                ws = XLSX.utils.json_to_sheet(excelList);

            XLSX.utils.book_append_sheet(wb, ws, "Mysheet1");

            XLSX.writeFile(wb, "Goat_Purchase_List.xlsx");
        }
    }


    return (
        <>
            <div className='p-3'>
                <Card className='mb-3'>
                    <CardBody>
                        <Form onSubmit={purchase_list_handler}>
                            <Row>
                                <Col xs={2}>
                                    <Label for="frmDate"> From Date </Label>
                                    <Input id="frmDate" type='date' max={toDate} bsSize="sm" value={frmDate} onChange={(e) => setFrmDate(e.target.value)} />
                                </Col>
                                <Col xs={2}>
                                    <Label for="toDate"> To Date </Label>
                                    <Input id="toDate" type='date' max={getTodayDate()} bsSize="sm" value={toDate} onChange={(e) => setToDate(e.target.value)} />
                                </Col>

                                <Col xs={2} className="mb-1">
                                    <Label className="me-sm-2" for="district"> District </Label>
                                    <Input id="district" bsSize="sm" type="select" value={district} onChange={(e) => getBlockList(e)} disabled={dist_role}>
                                        <option value="0"> --All District--</option>
                                        {districtOpt}
                                    </Input>
                                </Col>
                                <Col xs={2} className="mb-1">
                                    <Label className="me-sm-2" for="block"> Block </Label>
                                    <Input id="block" bsSize="sm" type="select" value={block} onChange={(e) => getPanchayatList(e)} disabled={block_role}>
                                        <option value="0"> --All Block--</option>
                                        {blockOpt}
                                    </Input>
                                </Col>
                                <Col xs={2} className="mb-1">
                                    <Label className="me-sm-2" for="panchayat"> Panchayat </Label>
                                    <Input id="panchayat" bsSize="sm" type="select" value={panchyat} onChange={(e) => setPanchyat(e.target.value)}>
                                        <option value="0"> --All Panchayat--</option>
                                        {panchyatOpt}
                                    </Input>
                                </Col>
                                <Col xs={1}>
                                    <Button className='mt-4' color='primary' size="sm" disabled={loading}> Submit {loading && <Spinner color="secondary ms-1" size="sm" >Load...</Spinner>}</Button>
                                </Col>
                                <Col xs={1}>
                                <Button className="mt-4" size="sm" onClick={back}>Back</Button>
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader>Goat Purchase Details</CardHeader>
                    <div style={{ position: 'relative', height: '1px' }}>
                        <Tooltip title="Excel download">
                            <SiMicrosoftexcel className='excelIcon' size={20} onClick={download_excel_handler} />
                        </Tooltip>
                    </div>
                    <CardBody>
                        <DataTable
                            title=""
                            columns={[
                                {
                                    name: '#',
                                    selector: row => row.sl,
                                    sortable: true,
                                    width: '60px'
                                },
                                {
                                    name: 'Pashusakhi',
                                    selector: row => row.user,
                                    sortable: false,
                                    width: '9%',
                                    wrap: true
                                },
                                {
                                    name: 'Shareholder Name',
                                    selector: row => row.farmer,
                                    sortable: false,
                                    width: '9%',
                                    wrap: true
                                },
                                {
                                    name: 'Shareholder',
                                    selector: row => row.shareholder,
                                    sortable: false,
                                    wrap: true
                                },
                                {
                                    name: 'Date',
                                    selector: row => row.act_date,
                                    sortable: false,
                                },
                                {
                                    name: 'Goat count',
                                    selector: row => row.qty,
                                    sortable: false,
                                },
                                {
                                    name: 'Weight',
                                    selector: row => row.weight,
                                    sortable: false,
                                },
                                {
                                    name: 'Amount',
                                    selector: row => row.amt,
                                    sortable: false,
                                },
                                {
                                    name: 'Receipt',
                                    selector: row => row.receipt,
                                    sortable: false,
                                },
                                {
                                    name: 'Passbook',
                                    selector: row => row.passbook,
                                    sortable: false,
                                },
                                {
                                    name: 'Goat Img',
                                    selector: row => row.goatImg,
                                    sortable: false,
                                },
                                {
                                    name: 'Bank Details',
                                    selector: row => row.bank_dtl,
                                    sortable: false,
                                },
                                {
                                    name: 'Validate',
                                    selector: row => row.v_flag,
                                    sortable: false,
                                    width: '11%',
                                    wrap: true
                                },

                            ]}
                            data={tableData}
                            striped
                            highlightOnHover
                            pagination
                            progressPending={loading}
                        />



                    </CardBody>
                </Card>
            </div>
            <div className='text-end p-3'>
                <Button className="" size="sm" onClick={back}>Back</Button>
            </div>
            <Modal
                fullscreen={false}
                centered
                scrollable
                size="md"
                isOpen={viewModal}
                toggle={() => setViewModal(!viewModal)}
            >
                <ModalHeader toggle={() => setViewModal(!viewModal)}>
                    {modalTitle}
                </ModalHeader>
                <ModalBody>
                    {modalData}
                </ModalBody>
            </Modal>
            <Modal
                fullscreen={false}
                centered
                scrollable
                size="md"
                isOpen={viewModal2}
                toggle={() => setViewModal2(!viewModal2)}
            >
                <ModalHeader toggle={() => setViewModal2(!viewModal2)}>
                    Change Status
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={12} className="mb-2">
                            <FormGroup check inline >
                                <Input className='cursor' type="radio" id="varify" checked={status === "1" ? true : false} onClick={()=>setStatus("1")} />
                                <Label className='cursor' check for="varify">Verified</Label>
                            </FormGroup>
                            <FormGroup check inline >
                                <Input className='cursor' type="radio" id="rejected" checked={status === "2" ? true : false} onClick={()=>setStatus("2")} />
                                <Label className='cursor' check for="rejected">Rejected</Label>
                            </FormGroup>
                        </Col>
                        <Col md={12} className="mb-2">
                            <Label for="remarks"> Remarks</Label>
                            <Input id="idType" bsSize="sm" type="select" value={remarks} 
                                onChange={(e)=> { if(e.target.value === "1") {
                                    setOther(true);
                                    setRemarks('');
                                } else{
                                        setRemarks(e.target.value);
                                        setOther(false);
                                    } 
                                 }} 
                                required>
                                <option value="0"> --Select Remark--</option>
                                { status === "1" && 
                                    <>
                                        <option value="Checked and Verified."> Checked and Verified. </option>
                                    </>
                                }
                                { status === "2" && 
                                    <>
                                        <option value="Passbook account no. not clear."> Passbook account no. not clear. </option>
                                        <option value="Purchase receipt not clear."> Purchase receipt not clear. </option>
                                        <option value="Purchase receipt is incorrect."> Purchase receipt is incorrect. </option>
                                    </>
                                }
                                <option value="1"> Other </option>
                            </Input>
                        </Col>
                        { other && 
                            <Col md={12} className="mb-2">
                                <Input id="remarks" type='textarea' bsSize="sm" maxLength={150} onKeyPress={(e) => !/^[a-zA-Z\s]*$/.test(e.key) && e.preventDefault()} value={remarks} onChange={(e) => setRemarks(e.target.value)} />
                            </Col>
                        }
                        <Col md={6}>
                            <Button size="sm" color='primary' onClick={() => change_status(tr_id)}> Submit </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </>
    )
}

export default GoatPurchaseDtl;