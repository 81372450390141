import React from 'react';
import { useState, useEffect } from 'react';
const mainCss = {
    width: "30px",
    height: "30px",
    background: "#fba10f",
    borderRadius: "50%",
    display: "flex",
    justifyContent: 'center',
    alignItems: "center",
    position: "absolute",
    top: "-16px",
    right: "8px",
    fontSize: "18px",
    fontWeight: "bold",
    color: "#fff",
}

const Name = ({title}) => { 
    return(
        <div className='text-uppercase' style={mainCss}>
            {Array.from(title)[0]}
        </div>
    )
}
export default Name;