import React from 'react';
import {
  Col,
  Row,
} from 'reactstrap';
import {MdDeleteForever} from 'react-icons/md';

const GoatItem = ({val, index, delete_item, ...restProps }) => {
  return (
    <Row className='mb-1 '>
        <Col xs={1}> {index + 1} </Col>
        <Col xs={1}> {val.genderName} </Col>
        <Col xs={1}> {val.age_group} </Col>
        <Col xs={1}> {val.g_weight} kg</Col>
        <Col xs={1}>&#8377; {Number(val.g_price).toFixed(2)} </Col>
        <Col xs={1}>&#8377; {Number(Number(val.g_weight) * Number(val.g_price)).toFixed(2)} </Col>
        <Col xs={1}> <MdDeleteForever color="#f70000" className='cursor' size={20} onClick={delete_item} /> </Col>
    </Row>
  );
};

export default GoatItem;