import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import notification from '../../component/hocs/notification';
import mainurl from '../../MainURL';
import axios from 'axios';
import Page from '../../component/utility/Page';
import DataTable from 'react-data-table-component';
import EditBank from '../../component/utility/master/EditBank';
import * as XLSX from 'xlsx/xlsx.mjs';
import {SiMicrosoftexcel} from 'react-icons/si';
import Tooltip from '@mui/material/Tooltip';
import {
    Col, Row, Card, CardHeader, CardBody, Button, Input, Label, Form, InputGroup, InputGroupText, Spinner,
    Modal, ModalBody, ModalHeader
} from 'reactstrap';

import { FaHeading} from 'react-icons/fa';
import {FaEdit, FaPlayCircle} from 'react-icons/fa';
import {MdDeleteForever, } from 'react-icons/md';

const Video = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState("");
    const [statusOpt, setStatusOpt] = useState("");
    const [changeStatus, setChangeStatus] = useState("");
    const [currStatus, setCurrStatus] = useState('');
    
    const [playModal, setPlayModal] = useState(false);
    const [statusModal, setStatusModal] = useState(false);

    const [currSrc, setCurrSrc] = useState("");
    const [currVideoId, setCurrVideoId] = useState("");

    const [accData, setAccData] = useState('');

    useEffect(() => {
        // check Access
        let accData = JSON.parse(sessionStorage.getItem('accessControl'));
        setAccData(accData[3].pages[17]);
        if(accData[3].pages[17].page_view !== "1"){
            navigate("/home");
        }
        
    }, []);

    useEffect(() => {
        get_status_opt();
    }, [accData]);

    const get_video_list = () => {
        setLoading(true);

        let postData = [{ "org_id": 100, "user_id": 0}];
        axios({
          method: 'post',
          url: mainurl + 'Master/box_video_list_master',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            let list = response.data.map(row => {
                return(
                    {
                        ids: row.video_id,
                        topic: row.topic_desc +" ("+ row.topic_desc_h + ")",
                        date: row.upload_date.split(" ")[0].split('-').reverse().join('-'),
                        status: row.status_desc,
                        action: <div>
                            <FaPlayCircle className='me-4 cursor' color="#0052cc" size={20} onClick={()=> {setCurrSrc(row.video_link);setPlayModal(true);}}/>
                            { accData.page_edit === "1" && <FaEdit className='cursor' color="#047e04" onClick={()=> open_status_modal(row)} size={20} />}
                        </div>
                    }
                )
            })

            setTableData(list);
            setLoading(false);
          })
          .catch(error => {
            setLoading(false);
            setTableData('');
            //let notify = notification({ message: "Video list not Found! Try Again", type: 'error' });
            //notify();
          });
    }

    const get_status_opt = () => {
        let postData = [{ "org_id": 100, "status_id": 0}];
        axios({
            method: 'post',
            url: mainurl + 'Master/box_status_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
          })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return (
                        <option key={index} value={row.status_id}> {row.status_desc} </option>
                      )
                })
    
                setStatusOpt(list);                                               
            })
            .catch(error => {
                let notify = notification({ message: "Status list not Found! Try Again", type: 'error' });
                notify();
            });
    }

    const open_status_modal = (row) => {
        setCurrVideoId(row.video_id);
        setCurrStatus(row.status_desc);
        setStatusModal(true);
    }

    const change_status_handler = (e) => {
        e.preventDefault();
        let postData = [{ "org_id": 100, "video_id": currVideoId, "status_id": changeStatus}];
        console.log(postData)
        axios({
            method: 'post',
            url: mainurl + 'Master/change_video_status',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
          })
            .then(function (response) {
                setStatusModal(false);
                if(response.data[0].retcode === "101"){
                    let notify = notification({ message: "Status change successfully.", type: 'success' });
                    notify();                                             
                    get_video_list();
                } 
            })
            .catch(error => {
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            });
    }

    const download_excel_handler = () => {
        if(tableData.length > 0){
          let wb = XLSX.utils.book_new(),
          ws = XLSX.utils.json_to_sheet(tableData);
    
          XLSX.utils.book_append_sheet(wb, ws, "Mysheet1");
    
          XLSX.writeFile(wb, "videoList.xlsx");
        }
      }

    return (
        <>
            <Header />
            <Page title="Video Management" breadcrumbs="Master / Video Management">
                <Row className="p-3">
                    <Col xs={12}>
                        <Card className='mb-2'>
                            <CardBody>
                                <Row>
                                    <Col xs={12}>
                                        <Button className="" color="primary" size="sm" onClick={get_video_list}> Get Video List </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className='mb-2'>
                            <CardHeader>Video List</CardHeader>
                            <div style={{position:'relative', height:'1px'}}>
                                <Tooltip title="Excel download">
                                    <SiMicrosoftexcel className='excelIcon' size={20} onClick={download_excel_handler} />
                                </Tooltip> 
                            </div>
                            <CardBody>
                                <DataTable
                                    title=""
                                    columns={[
                                        {
                                        name: '#',
                                        selector: row=>row.ids,
                                        sortable: true,
                                        width: "80px"
                                        },
                                        {
                                        name: 'Topic',
                                        selector: row=>row.topic,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Status',
                                        selector: row=>row.status,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Date',
                                        selector: row=>row.date,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Action',
                                        selector: row=>row.action,
                                        sortable: false,
                                        },
                                    ]}
                                    data={tableData}
                                    striped
                                    highlightOnHover
                                    progressPending={loading}
                                    />
                            </CardBody>
                        </Card> 
                    </Col>
                </Row>

            </Page>
            <Modal
                centered
                scrollable
                size="md"
                isOpen={playModal}
            >
                <ModalHeader toggle={() => setPlayModal(!playModal)}>
                   See Video
                </ModalHeader>
                <ModalBody className='text-center'>
                    <video width="400" height="240" controls>
                        <source src={currSrc} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </ModalBody>
            </Modal>
            <Modal
                centered
                scrollable
                size="md"
                isOpen={statusModal}
            >
                <ModalHeader toggle={() => setStatusModal(!statusModal)}>
                   Change Video Status
                </ModalHeader>
                <ModalBody>
                <Form onSubmit={change_status_handler}>
                    <Row>
                        <Col xs={12} className="mb-3">Current Status: <span className='text-primary'><b>{currStatus}</b></span></Col>
                        <Col xs={6} className="mb-2">
                            <Input id="status" bsSize="sm" type="select" onChange={(e) => setChangeStatus(e.target.value)} required>
                                <option value=""> --Select Status--</option>
                                {statusOpt}
                            </Input>
                        </Col>
                        <Col xs={6} className="mb-2">
                            <Button color="primary" size="sm"> Submit </Button>
                        </Col>
                    </Row>
                </Form>
                </ModalBody>
            </Modal>
            <Footer />
        </>
    )
}

export default Video;