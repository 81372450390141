import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import notification from '../../component/hocs/notification';
import mainurl from '../../MainURL';
import axios from 'axios';
import Page from '../../component/utility/Page';
import DataTable from 'react-data-table-component';
import { BsEyeFill, BsFillImageFill } from 'react-icons/bs';
import { MdHideImage } from 'react-icons/md';

import * as XLSX from 'xlsx/xlsx.mjs';
import {SiMicrosoftexcel} from 'react-icons/si';
import Tooltip from '@mui/material/Tooltip';
import {
    Col, Row, Card, CardHeader, CardBody, Button, Input, Label, Form, InputGroup, InputGroupText, Spinner,
    Modal, ModalBody, ModalHeader
} from 'reactstrap';
import { getTodayDate } from '../../component/hocs/dateServices';


const MeetingTrainingReport = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState("");
    const [viewModal, setViewModal] = useState(false);
    const [modalData, setModalData] = useState('');
    const [frmDate, setFrmDate] = useState(getTodayDate());
    const [toDate, setToDate] = useState(getTodayDate());

    const [accData, setAccData] = useState('');

    useEffect(() => {
        // check Access
        let accData = JSON.parse(sessionStorage.getItem('accessControl'));
        setAccData(accData[3].pages[8]);
        if(accData[3].pages[8].page_view !== "1"){
            navigate("/home");
        }
        
    }, []);

    const submit_handler = (e) => {
        e.preventDefault();
        initLoad();
    }
    const initLoad = () => {
        setTableData('');
        setLoading(true);
        
        let postData = [{ "org_id": '100', "user_id": "0", "frm_date": frmDate, "to_date": toDate }];

        axios({
            method: 'post',
            url: mainurl + 'Master/meeting_training_report',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                if(response.data !== null){

                    let list = response.data.map((row, index) => {
                        let subject = "Training";

                        let imgPath = <MdHideImage color="#f70000" size={20} />;
                        if(row.image_id !== null){
                            imgPath = <a href={row.image_id+'.png'} target="_blank"><BsFillImageFill className='cursor' color="#0052cc" size={20} /></a>
                        }
                        if (row.subject_id === "2") {
                            subject = "Meeting";
                        }
                        return (
                            {
                                SLN: index + 1,
                                Date: row.meeting_data.split(" ")[0].split('-').reverse().join('-'),
                                Type: subject,
                                TrainerName: row.trainer_name,
                                Participants: row.participants,
                                Description: row.desc,
                                image: imgPath,
                                action: <div>
                                    <BsEyeFill className='cursor' color="#0052cc" size={20} onClick={() => participants_detail_handler(row.farmer_list)} />
                                </div>
    
                            }
                        )
                    })
                    setTableData(list);
                    setLoading(false);
                }else{
                    setTableData('');
                    setLoading(false);
                    let notify = notification({ message: "No Data Found! Try Again", type: 'error' });
                    notify(); 
                }
            })
            .catch(error => {
                setTableData('');
                setLoading(false);
                //let notify = notification({ message: "No Data Found! Try Again", type: 'error' });
                //notify();
            });

    }

    const participants_detail_handler = (dtl) => {
        setModalData('');
        let fdata = JSON.parse(dtl);
        let list = fdata.map((row, index) => {
            return(
                {
                    sl: index+1,
                    ids: row.farmer_id,
                    name: row.farmer_name
                }
            )
        })

        setModalData(list);
        setViewModal(true);

    }

    const download_excel_handler = () => {
        if(tableData.length > 0){
          let wb = XLSX.utils.book_new(),
          ws = XLSX.utils.json_to_sheet(tableData);
    
          XLSX.utils.book_append_sheet(wb, ws, "Mysheet1");
    
          XLSX.writeFile(wb, "meeting_training_report.xlsx");
        }
      }

    return (
        <>
            <Header />
            <Page title="Meeting & Training Report" breadcrumbs="Report / Meeting & Training Report">
                <Row className="p-3">
                    <Col xs={12}>
                    <Card className='mb-2'>
                            <CardBody>
                                <Form onSubmit={submit_handler}>
                                    <Row>
                                        <Col md={2}>
                                            <Label for="frmDate"> From Date </Label>
                                            <Input id="frmDate" type='date' bsSize="sm" max={toDate} value={frmDate} onChange={(e) => setFrmDate(e.target.value)} required />
                                        </Col>
                                        <Col md={2}>
                                            <Label for="toDate"> To Date </Label>
                                            <Input id="toDate" type='date' bsSize="sm" max={getTodayDate()} value={toDate} onChange={(e) => setToDate(e.target.value)} required />
                                        </Col>
                                        
                                        <Col md={2}>
                                            <Button className='mt-4' color="primary" size="sm" >Submit</Button>
                                        </Col>
                                    </Row>

                                </Form>
                            </CardBody>
                        </Card>

                        <Card className='mb-2'>
                            <CardHeader>Meeting & Training Report</CardHeader>
                            <div style={{position:'relative', height:'1px'}}>
                                <Tooltip title="Excel download">
                                    <SiMicrosoftexcel className='excelIcon' size={20} onClick={download_excel_handler} />
                                </Tooltip> 
                            </div>
                            <CardBody>
                            <DataTable
                                    title=""
                                    columns={[
                                        {
                                            name: '#',
                                            selector: row=>row.SLN,
                                            sortable: true,
                                            width: "5%"
                                        },
                                        {
                                            name: 'Date',
                                            selector: row=>row.Date,
                                            sortable: true,
                                        },
                                        {
                                            name: 'Type',
                                            selector: row=>row.Type,
                                            sortable: false,
                                        },
                                        {
                                            name: 'Trainer Name ',
                                            selector: row=>row.TrainerName,
                                            sortable: false,
                                        },
                                        {
                                            name: 'Participants',
                                            selector: row=>row.Participants,
                                            sortable: false,
                                        },
                                        {
                                            name: 'Description',
                                            selector: row=>row.Description,
                                            sortable: false,
                                            width: "25%"
                                        },
                                        {
                                            name: 'Image',
                                            selector: row=>row.image,
                                            sortable: false,
                                        },
                                        {
                                            name: 'Participants List',
                                            selector: row=>row.action,
                                            sortable: false,
                                        },
                                    ]}
                                    data={tableData}
                                    striped
                                    highlightOnHover
                                    pagination
                                    paginationPerPage="100"
                                    progressPending={loading}

                                />
                            </CardBody>
                        </Card> 
                    </Col>
                </Row>

            </Page>
            <Modal
                centered
                scrollable
                size="md"
                isOpen={viewModal}
                toggle={() => setViewModal(!viewModal)}
            >
                <ModalHeader toggle={() => setViewModal(!viewModal)}>
                    Participants List
                </ModalHeader>
                <ModalBody>
                    <DataTable
                        title=""
                        columns={[
                            {
                                name: '#',
                                selector: row=>row.sl,
                                sortable: true,
                                width: "15%"
                            },
                            {
                                name: 'Shareholder Id',
                                selector: row=>row.ids,
                                sortable: false,
                            },
                            {
                                name: 'Shareholder Name',
                                selector: row=>row.name,
                                sortable: false,
                                width: "50%"
                            },
                        ]}
                        data={modalData}
                        striped
                        highlightOnHover
                    />
                </ModalBody>
            </Modal>
            
            <Footer />
        </>
    )
}

export default MeetingTrainingReport;