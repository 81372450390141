import React from 'react';
import classNames from 'classnames';

const MapPill = ({text, bgColor, ...restProps }) => {
  const classes = classNames('map-pill', {
    [`bg-${bgColor}`]: bgColor,
  });
  return (
    <div className='d-inline-flex align-items-center me-2'>
      <span className={classes}></span>
      <span style={{fontSize:'14px'}}>{text}</span>
    </div>
  );
};

export default MapPill;