import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import notification from '../../component/hocs/notification';
import mainurl from '../../MainURL';
import axios from 'axios';
import Page from '../../component/utility/Page';
import DataTable from 'react-data-table-component';
import EditAsset from '../../component/utility/master/EditAsset';
import * as XLSX from 'xlsx/xlsx.mjs';
import {SiMicrosoftexcel} from 'react-icons/si';
import Tooltip from '@mui/material/Tooltip';
import {
    Col, Row, Card, CardHeader, CardBody, Button, Input, Label, Form, InputGroup, InputGroupText, Spinner,
    Modal, ModalBody, ModalHeader
} from 'reactstrap';

import { FaHeading} from 'react-icons/fa';
import {FaEdit} from 'react-icons/fa';
import {MdDeleteForever, } from 'react-icons/md';

const AssetManagement = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState("");
    const [editModel, setEditModel] = useState(false);
    const [modelData, setModelData] = useState("");

    
    const [geoName, setGeoName] = useState("");
    const [geoNameHindi, setGeoNameHindi] = useState("");
    const [type, setType] = useState("");
    const [noAsset, setNoAsset] = useState("");
    const [accDate, setAccDate] = useState("");
    const [unitPrice, setUnitPrice] = useState("");
    const [totalPrice, setTotalPrice] = useState("");
    const [hindiNameLoading, setHindiNameLoading] = useState(false);
    const [accData, setAccData] = useState('');

    useEffect(() => {
        // check Access
        let accData = JSON.parse(sessionStorage.getItem('accessControl'));
        setAccData(accData[3].pages[19]);
        if(accData[3].pages[19].page_view !== "1"){
            navigate("/home");
        }
        
        get_asset_list();
    }, []);

    const get_asset_list = () => {
        //
        let accData = JSON.parse(sessionStorage.getItem('accessControl'));
        let acc = accData[3].pages[3];
        //
        let postData = [{ "org_id": 100 }];
        axios({
          method: 'post',
          url: mainurl + 'Master/box_asset_list',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            
            let list = response.data.map(row => {
                return(
                    {
                        ids: row.asset_id,
                        name: row.name_desc,
                        name_hindi:row.name_desc_h,
                        type:row.type,
                        no_asset:row.asset_no,
                        unit_price:row.unit_price,
                        total_price:row.total_price,
                        acquisition_date:row.acquisition_date.split(" ")[0].split('-').reverse().join('-'),
                        action: <div>
                            { acc.page_edit === "1" && <FaEdit className='me-3 cursor' color="#047e04" onClick={()=> open_edit_modal(row)} size={20} />}
                            { acc.page_delete === "1" && <MdDeleteForever color="#f70000" size={20} />}
                        </div>
                    }
                )
            })

            setTableData(list);
          })
          .catch(error => {
            //let notify = notification({ message: "Asset list not Found! Try Again", type: 'error' });
            //notify();
          });
    }

    const translate_text_handler = (e) => {
        setHindiNameLoading(true);
        if(geoName.length > 1){
            
            let postData = [{ "t_text" : geoName}];
            axios({
                method: 'post',
                url: mainurl + 'Master/translate_text',
                data: postData,
                headers: { 'Content-Type': 'application/JSON', }
              })
                .then(function (response) {
                    let trans = response.data;
                    setGeoNameHindi(trans);
                    setHindiNameLoading(false);
                })
                .catch(error => {
                    setHindiNameLoading(false);
                    let notify = notification({ message: "Hindi Translation is not working! Try Again", type: 'error' });
                    notify();
                  });

        }else{
            setHindiNameLoading(false);
            let notify = notification({ message: "Please enter english name first.", type: 'warn' });
            notify();
        }
    }

    const add_asset_handler = (e) => {
        e.preventDefault();
        let postData = [
            {
              "org_id":"100",
              "type":type,
              "name_desc": geoName,
              "name_desc_h": geoNameHindi,
              "asset_no": noAsset,
              "unit_price": unitPrice,
              "total_price": totalPrice,
              "acquisition_date": accDate
            }
        ];
        console.log(postData);

        axios({
            method: 'post',
            url: mainurl + 'Master/box_asset_add',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
          })
            .then(function (response) {
                if(response.data[0].retcode === "101"){
                    let notify = notification({ message: "Added successfully.", type: 'success' });
                    notify();
                    setGeoName("");
                    setGeoNameHindi("");
                    setType("");
                    setNoAsset("");
                    setAccDate("");
                    setUnitPrice("");
                    setTotalPrice("");
                    get_asset_list();
                }
            })
            .catch(error => {
                let notify = notification({ message: "Add failed! Try Again", type: 'error' });
                notify();
              });
    
    }

    const open_edit_modal = (row) => {
        let temp = <EditAsset  geo="Asset" data={row} close_handler={close_modal_handler} />

        setModelData(temp);
        setEditModel(true);
    }

    const close_modal_handler = () => {
        setEditModel(false);
        get_asset_list();
    }

    const download_excel_handler = () => {
        if(tableData.length > 0){
          let wb = XLSX.utils.book_new(),
          ws = XLSX.utils.json_to_sheet(tableData);
    
          XLSX.utils.book_append_sheet(wb, ws, "Mysheet1");
    
          XLSX.writeFile(wb, "BankList.xlsx");
        }
      }

    return (
        <>
            <Header />
            <Page title="Asset Management" breadcrumbs="Master / Asset Management">
                <Row className="p-3">
                    <Col xs={12}>
                    { accData.page_add === "1" && <Card className='mb-2'>
                            <CardHeader>Add New Asset</CardHeader>
                            <CardBody>
                                <Form onSubmit={add_asset_handler}>
                                    <Row>
                                        <Col xs={2} className="mb-1">
                                            <Label className="me-sm-2" for="type"> Type </Label>
                                            <Input id="type" name="type" type="select" bsSize='sm' value={type} onChange={(e) => setType(e.target.value)}>
                                            <option value=""> --Select type--</option>
                                            <option value="fixed_asset"> Fixed Asset </option>
                                            <option value="renting_asset"> Renting Asset </option>
                                            </Input>
                                        </Col>
                                        <Col md={2}>
                                            <Label for="name"> Name</Label>
                                            <Input id="name" type='text' bsSize="sm" maxLength={50} value={geoName} onChange={(e) => setGeoName(e.target.value)} required />
                                        </Col>
                                        <Col md={2}>
                                            <Label for="hName"> Name Hindi</Label>
                                            <InputGroup size="sm">
                                                <Input id="hName" type='text' maxLength={50} value={geoNameHindi} bsSize="sm" onChange={(e)=>setGeoNameHindi(e.target.value)} />
                                                <InputGroupText className='bg-primary cursor' onClick={!hindiNameLoading ? translate_text_handler : undefined}>
                                                    {hindiNameLoading ? <Spinner color="secondary" size="sm">Loading...</Spinner> : <FaHeading color="#fff" />}
                                                </InputGroupText>
                                            </InputGroup>
                                        </Col>
                                        <Col md={2}>
                                            <Label for="noAsset"> Number of Assets </Label>
                                            <Input id="noAsset" type='text' bsSize="sm" maxLength={10} value={noAsset} onChange={(e) => setNoAsset(e.target.value)} required />
                                        </Col>
                                        <Col md={2}>
                                            <Label for="accDate"> Acquisition Date </Label>
                                            <Input id="accDate" type='date' bsSize="sm" value={accDate} onChange={(e) => setAccDate(e.target.value)} required />
                                        </Col>
                                        <Col md={2}>
                                            <Label for="unitPrice"> Unit Price </Label>
                                            <Input id="unitPrice" type='text' bsSize="sm" maxLength={10} value={unitPrice} onChange={(e) => setUnitPrice(e.target.value)} required />
                                        </Col>
                                        <Col md={2}>
                                            <Label for="totalPrice"> Total Price </Label>
                                            <Input id="totalPrice" type='text' bsSize="sm" maxLength={50} value={totalPrice} onChange={(e) => setTotalPrice(e.target.value)} required />
                                        </Col>
                                        <Col md={2}>
                                            <Button className='mt-4' color="primary" size="sm" >Add Asset</Button>
                                        </Col>
                                    </Row>

                                </Form>
                            </CardBody>
                        </Card>}

                        <Card className='mb-2'>
                            <CardHeader>Asset Management</CardHeader>
                            <div style={{position:'relative', height:'1px'}}>
                                <Tooltip title="Excel download">
                                    <SiMicrosoftexcel className='excelIcon' size={20} onClick={download_excel_handler} />
                                </Tooltip> 
                            </div>
                            <CardBody>
                                <DataTable
                                    title=""
                                    columns={[
                                        {
                                        name: 'Asset Id',
                                        selector: row=>row.ids,
                                        sortable: true,
                                        },
                                        {
                                        name: 'Asset Type',
                                        selector: row=>row.type,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Asset Name',
                                        selector: row=>row.name,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Asset Name (Hindi)',
                                        selector: row=>row.name_hindi,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Number of Asset',
                                        selector: row=>row.no_asset,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Unit Price',
                                        selector: row=>row.unit_price,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Total Price',
                                        selector: row=>row.total_price,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Acquisition Date',
                                        selector: row=>row.acquisition_date,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Action',
                                        selector: row=>row.action,
                                        sortable: false,
                                        },
                                    ]}
                                    data={tableData}
                                    striped
                                    highlightOnHover
                                    progressPending={loading}
                                    />
                            </CardBody>
                        </Card> 
                    </Col>
                </Row>

            </Page>
            <Modal
                centered
                scrollable
                size="lg"
                isOpen={editModel}
                toggle={() => setEditModel(!editModel)}
            >
                <ModalHeader toggle={() => setEditModel(!editModel)}>
                   Edit Asset
                </ModalHeader>
                <ModalBody>
                    {modelData}
                </ModalBody>
            </Modal>
            <Footer />
        </>
    )
}

export default AssetManagement;