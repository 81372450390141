import React, {useState, useEffect} from 'react';
import {Row, Col} from 'reactstrap';

const TopBox = ({dist, block, farmer, aps, ...props}) => {
    const [loc, setLoc] = useState('ADMIN');
    useEffect(() => {
        let lData = JSON.parse(sessionStorage.getItem('loginData'));
        
        if(lData.user_role === "102"){
            let name = lData.district_name + " District";
            setLoc(name);
        }else if(lData.user_role === "103"){
            let name = lData.block_long_name + " Block";
            setLoc(name);
        } 
    }, []);

    return (
        <Row className='p-2' style={{position: "relative"}}>
            <Col md={3} >
                <div className='topBox'>
                    <div className='fw-bold'>INTERVENTION DISTRICT</div> 
                    <h3 className='text-primary'>{dist}</h3> 
                </div>
            </Col>
            <Col md={3} >
                <div className='topBox'>
                    <div className='fw-bold'>INTERVENTION BLOCK</div> 
                    <h3 className='text-primary'>{block}</h3> 
                </div>
            </Col>
            <Col md={3} >
                <div className='topBox'>
                    <div className='fw-bold'>TOTAL SHAREHOLDER</div> 
                    <h3 className='text-primary'>{farmer}</h3> 
                </div>
            </Col>
            <Col md={3} >
                <div className='topBox'>
                    <div className='fw-bold'>TOTAL PASHUSAKHI</div> 
                    <h3 className='text-primary'>{aps}</h3> 
                </div>
            </Col>
            <div className='welcomeTxt bg-primary'><h6 className='m-0'>Welcome to {loc}</h6></div>
        </Row>
    )
}

export default TopBox;