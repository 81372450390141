import React, {useState, useEffect} from 'react';
import notification from '../../hocs/notification';
import axios from 'axios';
import mainurl from '../../../MainURL';
import Select from 'react-select';
import {
    Col, Row, Card, CardHeader, CardBody, Button, Input, Label, Form, InputGroup, InputGroupText, Spinner,
} from 'reactstrap';

import { FaHeading} from 'react-icons/fa';

const AddDemographic = ({geoId, geo, reset, ...props}) => {
    const [loading, setLoading] = useState(false);
    const [districtOpt, setDistrictOpt] = useState();
    const [blockOpt, setBlockOpt] = useState();
    const [panchyatOpt, setPanchyatOpt] = useState();
    const [villageOpt, setVillageOpt] = useState();
    const [district, setDistrict] = useState("0");
    const [block, setBlock] = useState("0");
    const [panchyat, setPanchyat] = useState("0");
    const [village, setVillage] = useState("0");
    const [selectVill, setSelectVill] = useState([]);

    const [geoCode, setGeoCode] = useState("");
    const [geoName, setGeoName] = useState("");
    const [geoNameHindi, setGeoNameHindi] = useState("");
    const [hindiNameLoading, setHindiNameLoading] = useState(false);

    useEffect(() => {
        getDistrictList();
    }, []);

    // district, block, panchayat, village API call
    const getDistrictList = () => {
        setBlock("0");
        setPanchyat("0");
        setVillage("0");
        setSelectVill([]);
    
        let postData = [{ "org_id": 100, "user_id": "0" }];
        axios({
          method: 'post',
          url: mainurl + 'Geo/geo_district_list2',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            let list = response.data.map((row, index) => {
              return <option key={index} value={row.district_short_cd}> {row.district_name} </option>
            })
            setDistrictOpt(list);
          })
          .catch(error => {
            let notify = notification({ message: "District list not Found! Try Again", type: 'error' });
            notify();
          });
      }
    
      const getBlockList = (e) => {
        setDistrict(e.target.value);
        setBlock("0");
        setPanchyat("0");
        setVillage("0");
        setSelectVill([]);
        let postData = [{ "org_id": 100, "user_id": "0", "dist_id": e.target.value }]
        axios({
          method: 'post',
          url: mainurl + 'Geo/geo_block_list',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            let list = response.data.map((row, index) => {
              return <option key={index} value={row.block_code}> {row.block_long_name} </option>
            })
            setBlockOpt(list);
          })
          .catch(error => {
            let notify = notification({ message: "Block list not Found! Try Again", type: 'error' });
            notify();
          });
      }
    
      const getPanchyatList = (e) => {
        setBlock(e.target.value);
        setPanchyat("0");
        setVillage("0");
        setSelectVill([]);
        let postData = [{ "org_id": 100, "user_id": "0", "block_id": e.target.value }]
        axios({
          method: 'post',
          url: mainurl + 'Geo/geo_pan_list',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            let list = response.data.map((row, index) => {
              return <option key={index} value={row.panchyat_code}> {row.panchyat_name} </option>
            })
    
            setPanchyatOpt(list);
          })
          .catch(error => {
            let notify = notification({ message: "Panchayat list not Found! Try Again", type: 'error' });
            notify();
          });
      }

      const getVillageList = (e) => {
        setPanchyat(e.target.value);
        let postData = [{ "org_id": 100, "user_id": "128", "panchayat_id": e.target.value }]
        axios({
          method: 'post',
          url: mainurl + 'Geo/geo_village_list',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            let list = response.data.map(row => {
              return (
                { value: row.village_code, label: row.village_name }
              )
            })
            setVillageOpt(list);
            
          })
          .catch(error => {
            let notify = notification({ message: "Village list not Found! Try Again", type: 'error' });
            notify();
          });
      }  
      // End

    const translate_text_handler = (e) => {
        setHindiNameLoading(true);
        if(geoName.length > 1){
            
            let postData = [{ "t_text" : geoName}];
            axios({
                method: 'post',
                url: mainurl + 'Master/translate_text',
                data: postData,
                headers: { 'Content-Type': 'application/JSON', }
              })
                .then(function (response) {
                    let trans = response.data;
                    setGeoNameHindi(trans);
                    setHindiNameLoading(false);
                })
                .catch(error => {
                    setHindiNameLoading(false);
                    let notify = notification({ message: "Hindi Translation is not working! Try Again", type: 'error' });
                    notify();
                  });

        }else{
            setHindiNameLoading(false);
            let notify = notification({ message: "Please enter geographic english name first.", type: 'warn' });
            notify();
        }
    }

    const add_geographic_handler = (e) => {
        setLoading(true);
        e.preventDefault();
        if(geoId !== '6'){

          let postData = [
              {
                "org_id":"100",
                "user_id":"128",
                "geo_id": geoId,
                "dist_id": district,
                "block_id": block,
                "pan_id": panchyat,
                "vil_id": village,
                "geo_code": geoCode,
                "geo_name": geoName,
                "geo_name_h": geoNameHindi
              }
          ];
          console.log(postData);
          axios({
            method: 'post',
            url: mainurl + 'Master/master_geo_add',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
          })
            .then(function (response) {
              setLoading(false);
              if(response.data[0].retcode === "101"){
                let notify = notification({ message: "Added Successfully.", type: 'success' });
                notify();
                reset();
              }else if(response.data[0].retcode === "102"){
                let notify = notification({ message: " Already exists.", type: 'error' });
                notify();
              }
  
            })
            .catch(error => {
              setLoading(false);
                let notify = notification({ message: "Submit Failed! Try Again", type: 'error' });
                notify();
              });
        }else{

          let vill = JSON.stringify(village);
          vill = vill.replaceAll('","', ',');
          vill = vill.replaceAll('"', '');
          vill = vill.replaceAll('[', '');
          vill = vill.replaceAll(']', '');

          let sendData = [
            {
              "org_id":"100",
              "user_id":"128",
              "dist_id": district,
              "block_id": block,
              "pan_id": panchyat,
              "vill_id": vill,
              "geo_name": geoName,
              "geo_name_h": geoNameHindi
            } 
        ];
        console.log(sendData);

        axios({
          method: 'post',
          url: mainurl + 'Master/pg_add',
          data: sendData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            setLoading(false);
            if(response.data[0].retcode === "101"){
              let notify = notification({ message: "Added Successfully.", type: 'success' });
              notify();
              reset();
            }else {
              let notify = notification({ message: "Submit Failed! Try Again", type: 'error' });
              notify();
            }

          })
          .catch(error => {
            setLoading(false);
            let notify = notification({ message: "Submit Failed! Try Again", type: 'error' });
            notify();
          });

        }
    }

    const handle_vill = (options) => {
      let temp=[];
      options.map(val => {
        temp.push(val.value);
      })
      setVillage(temp);
      setSelectVill(options);
    }

    return (
        <Card className='mb-2'>
            <CardHeader>Add New {geo}</CardHeader>
            <CardBody>
                <Form onSubmit={add_geographic_handler}>
                    <Row>
                        {(geoId !== "1") &&
                        <Col md={2}>
                            <Label for="name">District</Label>
                            <Input id="district" bsSize="sm" type="select" value={district} onChange={(e) => getBlockList(e)}>
                                <option value="0"> --Select District--</option>
                                {districtOpt}
                            </Input>
                        </Col>
                        }
                        {(geoId !== "1" && geoId !== "2") &&
                        <Col xs={2} className="mb-1">
                            <Label for="block"> Block </Label>
                            <Input id="block" bsSize="sm" type="select" value={block} onChange={(e) => getPanchyatList(e)}>
                                <option value="0"> --Select Block--</option>
                                {blockOpt}
                            </Input>
                        </Col>
                        }
                        {(geoId !== "1" && geoId !== "2" && geoId !== "3" && geoId !== "5") &&
                        <Col xs={2} className="mb-1">
                            <Label for="panchyat"> Panchayat </Label>
                            <Input id="panchyat" bsSize="sm" type="select" value={panchyat} onChange={(e)=> getVillageList(e)} >
                                <option value="0"> --Select Panchayat--</option>
                                {panchyatOpt}
                            </Input>
                        </Col>
                        }
                        {(geoId === "6" ) &&
                        <Col xs={4} className="mb-1">
                            <Label for="village"> Village </Label>
                            <Select closeMenuOnSelect={false} isMulti value={selectVill} options={villageOpt} onChange={handle_vill} />
                        </Col>
                        }
                        {(geoId !== "6" ) &&
                        <Col md={2}>
                            <Label for="code"> {geo} Code</Label>
                            <Input id="code" type='text' bsSize="sm" maxLength={10} onChange={(e) => setGeoCode(e.target.value)} required />
                        </Col>
                        }
                        <Col md={2}>
                          <Label for="name"> {geo} Name</Label>
                          <Input id="name" type='text' bsSize="sm" maxLength={50} onKeyPress={(e) => !/^[a-zA-Z\s]*$/.test(e.key) && e.preventDefault()} onChange={(e) => setGeoName(e.target.value)} required />
                        </Col>
                        <Col md={2}>
                            <Label for="hName"> {geo} Name Hindi</Label>
                            <InputGroup size="sm">
                                <Input id="hName" type='text' maxLength={50} value={geoNameHindi} bsSize="sm" onChange={(e)=>setGeoNameHindi(e.target.value)} />
                                <InputGroupText className='bg-primary cursor' onClick={!hindiNameLoading ? translate_text_handler : undefined }>
                                    {hindiNameLoading ? <Spinner color="secondary" size="sm">Loading...</Spinner> : <FaHeading color="#fff" />}
                                </InputGroupText>
                            </InputGroup>
                        </Col>
                        <Col md={2}>
                            <Button className='mt-4' color="primary" size="sm"  disabled= {loading} >Add {geo} {loading && <Spinner color="secondary ms-1" size="sm" >Load...</Spinner>}</Button>
                        </Col>
                    </Row>

                </Form>
            </CardBody>
        </Card>

    )
}

export default AddDemographic;