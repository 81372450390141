import React, {useState, useEffect} from 'react';
import notification from '../../hocs/notification';
import axios from 'axios';
import mainurl from '../../../MainURL';
import {
    Col, Row, Button, Input, Label, Form, InputGroup, InputGroupText, Spinner, Card, CardHeader, CardBody, CardFooter
} from 'reactstrap';

import { FaHeading} from 'react-icons/fa';

const EditShareholder = ({back_handler, data, ...props}) => {
    const [loading, setLoading] = useState(false);
    const [uniqueId, setUniqueId] = useState(false);
    const [hindiNameLoading, setHindiNameLoading] = useState(false);

    const [shareholderName, setShareholderName] = useState(data.ac_holder_name);
    const [shareholderNameHin, setShareholderNameHin] = useState(data.f_last_name);
    const [fatherName, setFatherName] = useState(data.f_father_husband_name);
    const [fatherNameHin, setFatherNameHin] = useState(data.f_husband_name_h);
    const [age, setAge] = useState(data.age);
    const [gender, setGender] = useState(data.gender);
    const [category, setCategory] = useState(data.category_id);
    const [mobileNo, setMobileNo] = useState(data.mobile_no);
    const [altMobileNo, setAltMobileNo] = useState(data.mobile_no_2);
    const [email, setEmail] = useState(data.email);
    const [idType, setIdType] = useState(data.id_type);
    const [aadharNo, setAadharNo] = useState(data.uid);
    const [applicationFormNumber, setApplicationFormNumber] = useState(data.application_form_no);
    
    const [state, setState] = useState(data.state_id);
    const [district, setDistrict] = useState("");
    const [block, setBlock] = useState("");
    const [panchayat, setPanchayat] = useState("");
    const [village, setVillage] = useState("");
    const [pg, setPg] = useState(data.pg_id);
    
    const [accountHolderName, setAccountHolderName] = useState(data.ac_holder_name);
    const [bankName, setBankName] = useState(data.bank_name);
    const [accountNo, setAccountNo] = useState(data.bank_account_no);
    const [ifsc, setIfsc] = useState(data.ifsc_code);
    const [branchAddress, setBranchAddress] = useState(data.branch_name);

    const [nominee, setNominee] = useState(data.nominee);
    const [nomineeRel, setNomineeRel] = useState(data.nominee_relationship);

    const [amountDeposited, setAmountDeposited] = useState(data.amount_deposit);
    const [depositedDate, setDepositedDate] = useState(data.deposit_date);
    const [folioNumber, setFolioNumber] = useState(data.folio_no);
    const [certificateFrom, setCertificateFrom] = useState(data.share_certificate_from);
    const [certificateTo, setCertificateTo] = useState(data.share_certificate_to);
    const [certificateNumber, setCertificateNumber] = useState(data.share_certificate_no);

    const [bankOpt, setBankOpt] = useState("");
    const [districtOpt, setDistrictOpt] = useState("");
    const [blockOpt, setBlockOpt] = useState("");
    const [panchayatOpt, setPanchayatOpt] = useState("");
    const [villageOpt, setVillageOpt] = useState("");
    const [pgOpt, setPgOpt] = useState("");

    const [fisrtLoad, setFisrtLoad] = useState(1);

    useEffect(() => {
        get_init();
        getDistrictList();
    }, []);

    const get_init = () => {
        let postData = [{ "org_id": 100, "user_id": "0" }];
        // Bank list  
        axios({
          method: 'post',
          url: mainurl + 'Master/bank_list',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            let list = response.data.map((row, index) => {
              return <option key={index} value={row.bank_id}> {row.bank_name} </option>
            })
            setBankOpt(list);
           
          })
          .catch(error => {
            let notify = notification({ message: "Bank list not Found! Try Again", type: 'error' });
            notify();
          });
        
    }
    const pg_vo_list = (id) => {
      // PG list
      let postData = [{ "org_id": 100, "panchyat_id": id}];  
      axios({
        method: 'post',
        url: mainurl + 'Master/pg_list_panchyat_wise',
        data: postData,
        headers: { 'Content-Type': 'application/JSON', }
      })
        .then(function (response) {
          let list = response.data.map((row, index) => {
            return <option key={index} value={row.pg_no}> {row.pg_name_desc} </option>
          })
          setPgOpt(list);
         
        })
        .catch(error => {
          let notify = notification({ message: "Bank list not Found! Try Again", type: 'error' });
          notify();
        });
    }

    // district, block, panchayat, village API call
    const getDistrictList = () => {
        setBlock("");
        setPanchayat("");
        setVillage("");
    
        let postData = [{ "org_id": 100, "user_id": "0" }];
        axios({
          method: 'post',
          url: mainurl + 'Geo/geo_district_list2',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            let list = response.data.map((row, index) => {
              return <option key={index} value={row.district_short_cd}> {row.district_name} </option>
            })
            setDistrictOpt(list);
            if(fisrtLoad === 1){
              getBlockList({target: {value: data.dist_id}});
            }
           
          })
          .catch(error => {
            let notify = notification({ message: "District list not Found! Try Again", type: 'error' });
            notify();
          });
      }
    
      const getBlockList = (e) => {
        setDistrict(e.target.value);
        setBlock("");
        setPanchayat("");
        setVillage("");
        let postData = [{ "org_id": 100, "user_id": "0", "dist_id": e.target.value }]
        axios({
          method: 'post',
          url: mainurl + 'Geo/geo_block_list',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            let list = response.data.map((row, index) => {
              return <option key={index} value={row.block_code}> {row.block_long_name} </option>
            })
            setBlockOpt(list);
            if(fisrtLoad === 1){
              getPanchayatList({target: {value: data.block_id}});
            }
            
          })
          .catch(error => {
            let notify = notification({ message: "Block list not Found! Try Again", type: 'error' });
            notify();
          });
      }
    
      const getPanchayatList = (e) => {
        setBlock(e.target.value);
        setPanchayat("");
        setVillage("");
        let postData = [{ "org_id": 100, "user_id": "0", "block_id": e.target.value }]
        axios({
          method: 'post',
          url: mainurl + 'Geo/geo_pan_list',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            let list = response.data.map((row, index) => {
              return <option key={index} value={row.panchyat_code}> {row.panchyat_name} </option>
            })
    
            setPanchayatOpt(list);
            if(fisrtLoad === 1){
              getVillageList({target: {value: data.pachyt_id}});
            }
          })
          .catch(error => {
            let notify = notification({ message: "Panchayat list not Found! Try Again", type: 'error' });
            notify();
          });
      }
      const getVillageList = (e) => {
        setPanchayat(e.target.value);
        pg_vo_list(e.target.value);
        
        let postData = [{ "org_id": 100, "user_id": "128", "panchayat_id": e.target.value }]
        axios({
          method: 'post',
          url: mainurl + 'Geo/geo_village_list',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            let list = response.data.map((row, index) => {
                return <option key={index} value={row.village_code}> {row.village_name} </option>
              })
      
              setVillageOpt(list);
              if(fisrtLoad === 1){
                setVillage(data.vill_id);
                setFisrtLoad(2);
              }
            
          })
          .catch(error => {
            let notify = notification({ message: "Village list not Found! Try Again", type: 'error' });
            notify();
          });
      }
      // End
 
    
      const translate_text_handler = (id) => {
       
        setHindiNameLoading(true);
        let transTxt;
        if (id === 1){
            transTxt = shareholderName;
        }else{
            transTxt = fatherName; 
        }
        if(transTxt.length > 1){
            
            let postData = [{ "t_text" : transTxt}];
            axios({
                method: 'post',
                url: mainurl + 'Master/translate_text',
                data: postData,
                headers: { 'Content-Type': 'application/JSON', }
              })
                .then(function (response) {
                    let trans = response.data;
                    if(id === 1){
                        setShareholderNameHin(trans);
                    }else{
                        setFatherNameHin(trans);
                    }
                    setHindiNameLoading(false);
                })
                .catch(error => {
                    setHindiNameLoading(false);
                    let notify = notification({ message: "Hindi Translation is not working! Try Again", type: 'error' });
                    notify();
                  });

        }else{
            setHindiNameLoading(false);
            let notify = notification({ message: "Please enter english name first.", type: 'warn' });
            notify();
        }
    }

    const Edit_shareholder_handler = (e) => {
        e.preventDefault();
        let flag = true;

        if(pg === ""){
          flag = false;
          let notify = notification({ message: "Please select PG.", type: 'warn' });
          notify();
        }
        if(village === ""){
          flag = false;
          let notify = notification({ message: "Please select Village. ", type: 'warn' });
          notify();
        }
        if(panchayat === ""){
          flag = false;
          let notify = notification({ message: "Please select Panchayat. ", type: 'warn' });
          notify();
        }
        if(block === ""){
          flag = false;
          let notify = notification({ message: "Please select Block. ", type: 'warn' });
          notify();
        }
        if(district === ""){
          flag = false;
          let notify = notification({ message: "Please select District. ", type: 'warn' });
          notify();
        }
        if(state === ""){
          flag = false;
          let notify = notification({ message: "Please select State. ", type: 'warn' });
          notify();
        }


        if(flag){
          let postData = [
            {
              "org_id": 100,
              "user_id": '128',
              "f_id": data.f_id,
              "f_first_name": shareholderName,
              "f_last_name": shareholderNameHin,
              "age": age,
              "mobile_no": mobileNo,
              "mobile_no_2": altMobileNo,
              "gender": gender,
              "f_father_husband_name": fatherName,
              "f_husband_name_h": fatherNameHin,
              //"uid": aadharNo,
              "email": email,
              "pg_id": pg,
              "ac_holder_name": accountHolderName,
              "ifsc_code": ifsc,
              "bank_name": bankName,
              "bank_account_no": accountNo,
              "branch_name": branchAddress,
              "category_id": category,
              "nominee": nominee,
              "nominee_relationship": nomineeRel,
              //"id_type": idType,
              "amount_deposit": amountDeposited,
              "deposit_date": depositedDate,
              "folio_no": folioNumber,
              "share_certificate_from": certificateFrom,
              "share_certificate_to": certificateTo,
              "share_certificate_no": certificateNumber,
              "application_form_no": applicationFormNumber,
              "state_id": state,
              "dist_id": district,
              "block_id": block,
              "pachyt_id": panchayat,
              "vill_id": village
            }
          ];
  
          console.log(postData);
  
          axios({
            method: 'post',
            url: mainurl + 'Master/box_shareholder_edit',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
          })
            .then(function (response) {
              if(response.data[0].retcode === "101"){
                let notify = notification({ message: "Updated successfully", type: 'success' });
                notify();
                back_handler();
              }
              
            })
            .catch(error => {
              let notify = notification({ message: "submit failed! Try Again", type: 'error' });
              notify();
            });

        }

    }

    const check_id_handler = (id) => {
      let postData = [{"uid": id}];

      axios({
        method: 'post',
        url: mainurl + 'Master/check_uid',
        data: postData,
        headers: { 'Content-Type': 'application/JSON', }
      })
        .then(function (response) {
          if(response.data[0].count === "1"){
            let notify = notification({ message: "ID number already exists.", type: 'warn' });
            notify();
            setUniqueId(false);
          }else{
            setUniqueId(true);
          }

        })
        .catch(error => {
          let notify = notification({ message: "submit failed! Try Again", type: 'error' });
          notify();
        });


    }


    return(
        <Card>
            <CardHeader>Edit Shareholder</CardHeader>
            <CardBody>
                <Form onSubmit={Edit_shareholder_handler}>
                    <Row>
                      <Col xs={12}><h6 className='text-primary'>Basic Details</h6></Col>
                        <Col md={2} className="mb-2">
                            <Label for="name"> Shareholder Name</Label>
                            <Input id="name" type='text' bsSize="sm" maxLength={50} onKeyPress={(e) => !/^[a-zA-Z\s]*$/.test(e.key) && e.preventDefault()} value={shareholderName} onChange={(e) => setShareholderName(e.target.value)} required />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="hName"> Shareholder Name Hindi</Label>
                            <InputGroup size="sm">
                                <Input id="hName" type='text' maxLength={50} value={shareholderNameHin} bsSize="sm" onChange={(e)=> setShareholderNameHin(e.target.value) } />
                                <InputGroupText className='bg-primary cursor' onClick={!hindiNameLoading ? ()=>translate_text_handler(1) : undefined }>
                                    {hindiNameLoading ? <Spinner color="secondary" size="sm">Loading...</Spinner> : <FaHeading color="#fff" />}
                                </InputGroupText>
                            </InputGroup>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="fname"> Spouse Name</Label>
                            <Input id="fname" type='text' bsSize="sm" maxLength={50} onKeyPress={(e) => !/^[a-zA-Z\s]*$/.test(e.key) && e.preventDefault()} value={fatherName} onChange={(e) => setFatherName(e.target.value)} required />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="fhName"> Spouse Name Hindi</Label>
                            <InputGroup size="sm">
                                <Input id="fhName" type='text' maxLength={50} value={fatherNameHin} bsSize="sm" onChange={(e)=>setFatherNameHin(e.target.value)} />
                                <InputGroupText className='bg-primary cursor' onClick={!hindiNameLoading ? ()=>translate_text_handler(2) : undefined }>
                                    {hindiNameLoading ? <Spinner color="secondary" size="sm">Loading...</Spinner> : <FaHeading color="#fff" />}
                                </InputGroupText>
                            </InputGroup>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="gender"> Gender </Label>
                            <Input id="gender" bsSize="sm" type="select" value={gender} onChange={(e)=> setGender(e.target.value)} required>
                                <option value=""> --Select Gender--</option>
                                <option value="1"> Male </option>
                                <option value="2"> Female </option>
                            </Input>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="category"> Category </Label>
                            <Input id="category" bsSize="sm" type="select" value={category} onChange={(e)=> setCategory(e.target.value)} required>
                                <option value=""> --Select category--</option>
                                <option value="1"> BC </option>
                                <option value="2"> BC-I </option>
                                <option value="3"> BC-II </option>
                                <option value="4"> EBC </option>
                                <option value="5"> GEN </option>
                                <option value="6"> OBC </option>
                                <option value="7"> SC </option>
                                <option value="8"> ST </option>
                            </Input>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="age"> Age</Label>
                            <Input id="age" type='text' bsSize="sm" maxLength={2} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} value={age} onChange={(e) => setAge(e.target.value)} />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="mobileNo"> Mobile Number</Label>
                            <Input id="mobileNo" type='text' bsSize="sm" maxLength={10} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} value={mobileNo} onChange={(e) => setMobileNo(e.target.value)} required/>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="altMobileNo"> Alternate Mobile Number</Label>
                            <Input id="altMobileNo" type='text' bsSize="sm" maxLength={10} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} value={altMobileNo} onChange={(e) => setAltMobileNo(e.target.value)} />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="email"> Email </Label>
                            <Input id="email" type='email' bsSize="sm" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="idType"> ID Type </Label>
                            <Input id="idType" bsSize="sm" type="select" value={idType} readOnly>
                                <option value=""> --Select Type--</option>
                                <option value="Aadhar Card"> Aadhar Card </option>
                                <option value="Voter ID"> Voter Card </option>
                                <option value="Pan Card"> PAN Card </option>
                                <option value="Driving Licence"> Driving Licence </option>
                            </Input>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="aadhar"> ID Number</Label>
                            <Input id="aadhar" type='text' bsSize="sm" value={aadharNo} readOnly />
                      </Col>
                        <Col md={2} className="mb-2">
                            <Label for="applicationFormNumber"> Application Form Number</Label>
                            <Input id="applicationFormNumber" type='text' bsSize="sm" maxLength={5} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} value={applicationFormNumber} onChange={(e) => setApplicationFormNumber(e.target.value)} required />
                        </Col>
                      
                        <hr />
                        <Col xs={12}><h6 className='text-primary'>Demographic Details</h6></Col>
                        <Col md={2} className="mb-2">
                            <Label for="state"> State </Label>
                            <Input id="state" bsSize="sm" type="select" value={state} onChange={(e)=> setState(e.target.value)} required>
                                <option value=""> --Select State--</option>
                                <option value="10"> BIHAR </option>
                                
                            </Input>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="district"> District </Label>
                            <Input id="district" bsSize="sm" type="select" value={district} onChange={getBlockList} required>
                                <option value=""> --Select District--</option>
                                {districtOpt}
                            </Input>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="block"> Block </Label>
                            <Input id="block" bsSize="sm" type="select" value={block} onChange={getPanchayatList} required>
                                <option value=""> --Select Block--</option>
                                {blockOpt}
                            </Input>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="panchayat"> Panchayat </Label>
                            <Input id="panchayat" bsSize="sm" type="select" value={panchayat} onChange={getVillageList} required>
                                <option value=""> --Select Panchayat--</option>
                                {panchayatOpt}
                            </Input>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="village"> Village </Label>
                            <Input id="village" bsSize="sm" type="select" value={village} onChange={(e)=> setVillage(e.target.value)} required>
                                <option value=""> --Select Village--</option>
                                {villageOpt}
                            </Input>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="pg"> PG / VO </Label>
                            <Input id="pg" bsSize="sm" type="select" value={pg} onChange={(e)=> setPg(e.target.value)} required>
                                <option value=""> --Select PG/VO --</option>
                                {pgOpt}
                            </Input>
                        </Col>
                        
                        <hr />
                        <Col xs={12}><h6 className='text-primary'>Bank Details</h6></Col>
                        <Col md={2} className="mb-2">
                            <Label for="accountHolder"> Account Holder Name </Label>
                            <Input id="accountHolder" type='text' bsSize="sm" maxLength={50} onKeyPress={(e) => !/^[a-zA-Z\s]*$/.test(e.key) && e.preventDefault()} value={accountHolderName} onChange={(e) => setAccountHolderName(e.target.value)} />
                        </Col>

                        <Col md={2} className="mb-2">
                            <Label for="bankName"> Bank Name </Label>
                            <Input id="bankName" bsSize="sm" type="select" value={bankName} onChange={(e)=> setBankName(e.target.value)} >
                              <option value=""> --Select Bank--</option>
                              {bankOpt}
                            </Input>
                        </Col>
                        <Col md={2} className="mb-2">
                          <Label for="accountNo"> Account Number </Label>
                          <Input id="accountNo" type='text' bsSize="sm" maxLength={20} value={accountNo} onChange={(e) => setAccountNo(e.target.value)} />
                        </Col>
                        <Col md={2} className="mb-2">
                          <Label for="ifsc"> IFS Code </Label>
                          <Input id="ifsc" type='text' bsSize="sm" maxLength={15} value={ifsc} onChange={(e) => setIfsc(e.target.value)} />
                        </Col>
                        <Col md={2} className="mb-2">
                          <Label for="branchAddress"> Branch Address </Label>
                          <Input id="branchAddress" type='text' bsSize="sm" maxLength={50} value={branchAddress} onChange={(e) => setBranchAddress(e.target.value)} />
                        </Col>

                        <hr />
                        
                        <Col md={2} className="mb-2">
                          <Label for="nominee"> Nominee Name </Label>
                          <Input id="nominee" type='text' bsSize="sm" maxLength={50} onKeyPress={(e) => !/^[a-zA-Z\s]*$/.test(e.key) && e.preventDefault()} value={nominee} onChange={(e) => setNominee(e.target.value)} />
                        </Col>
                        
                        <Col md={2} className="mb-2">
                          <Label for="nomineeRel"> Nominee Relationship </Label>
                          <Input id="nomineeRel" type='text' bsSize="sm" maxLength={50} onKeyPress={(e) => !/^[a-zA-Z\s]*$/.test(e.key) && e.preventDefault()} value={nomineeRel} onChange={(e) => setNomineeRel(e.target.value)} />
                        </Col>

                        <hr />

                        <Col md={2} className="mb-2">
                            <Label for="amountDeposited"> Amount Deposited </Label>
                            <Input id="amountDeposited" type='text' maxLength={10} bsSize="sm" value={amountDeposited} onChange={(e) => setAmountDeposited(e.target.value)} />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="depositedDate"> Amount Deposited Date </Label>
                            <Input id="depositedDate" type='date' bsSize="sm" value={depositedDate} onChange={(e) => setDepositedDate(e.target.value)} />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="folioNumber"> Folio Number </Label>
                            <Input id="folioNumber" type='text' bsSize="sm" maxLength={20} value={folioNumber} onChange={(e) => setFolioNumber(e.target.value)} />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="certificateFrom"> Share Certificate From </Label>
                            <Input id="certificateFrom" type='text' bsSize="sm" value={certificateFrom} onChange={(e) => setCertificateFrom(e.target.value)} />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="certificateTo"> Share Certificate To </Label>
                            <Input id="certificateTo" type='text' bsSize="sm" value={certificateTo} onChange={(e) => setCertificateTo(e.target.value)} />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="certificateNumber"> Share Certificate Number </Label>
                            <Input id="certificateNumber" type='text' bsSize="sm" maxLength={20} value={certificateNumber} onChange={(e) => setCertificateNumber(e.target.value)} />
                        </Col>

                        <hr/>

                        <Col md={2} className="mb-2">
                            <Button color='primary' size="sm" block> Update </Button>
                        </Col>

                    </Row>

                </Form>
            </CardBody>
            <CardFooter className='text-end'>
                <Button color='primary' size="sm" onClick={back_handler}> Back </Button>
            </CardFooter>
        </Card>
    )
}

export default EditShareholder;