
import { Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import WIP from './pages/WIP';
//Home
import Home from './pages/Home';
import HomeMap from "./component/utility/home/HomeMap";
import HomeGraph from "./component/utility/home/HomeGraph";
import HomeTable from "./component/utility/home/HomeTable";
// Goat services
import Vaccination from './pages/goatServices/Vaccination';
import Deworming from "./pages/goatServices/Deworming";
import Castration from "./pages/goatServices/Castration";
import FirstAid from "./pages/goatServices/FirstAid";
import NatalCare from "./pages/goatServices/NatalCare";
import InputSaleFeed from "./pages/goatServices/InputSaleFeed";
import GoatPurchase from "./pages/goatServices/GoatPurchase";
// Shareholder
import MeetingTraining from "./pages/shareholder/MeetingTraining";
import ShedVisits from "./pages/shareholder/ShedVisits";
//Stocks
import GoatCensus from "./pages/stocks/GoatCensus";
import SaleableCensus from "./pages/stocks/SaleableCensus";
import SalePurchase from "./pages/stocks/SalePurchase";
import SalePurchaseEntry from "./pages/stocks/SalePurchaseEntry";
import InputStock from "./pages/stocks/InputStock";
//Master
import ActivityLog from "./pages/master/ActivityLog";
import TargetSet from "./pages/master/Targetset";
import ServiceRate from "./pages/master/ServiceRate";
import DemographicMaster from "./pages/master/DemographicMaster";
import InventoryMaster from "./pages/master/InventoryMaster";
import Bank from "./pages/master/Bank";
import DesignationMaster from "./pages/master/DesignationMaster";
import Uom from "./pages/master/Uom";
import Employee from "./pages/master/Employee";
import Bod from "./pages/master/Bod";
import Vendor from "./pages/master/Vendor";
import UserManagement from "./pages/master/UserManagement";
import Pashusaakhi from "./pages/master/Pashusaakhi";
import Shareholder from "./pages/master/Shareholder";
import AccessControl from "./pages/master/AccessControl";
import CategoryMaster from "./pages/master/CategoryMaster";
import Video from "./pages/master/Video";
import Buyer from "./pages/master/Buyer";
import AssetManagement from "./pages/master/AssetManagement";
import Feedback from "./pages/master/Feedback";
import CompanyDocument from "./pages/master/CompanyDocument";

//Reports
import InputSalePurchaseReport from "./pages/reports/InputSalePurchaseReport";
import InputConsumedReport from "./pages/reports/InputConsumedReport";
import ApsEarningReport from "./pages/reports/ApsEarningReport";
import SaleableGoatReport from "./pages/reports/SaleableGoatReport";
import GoatPurchaseReport from "./pages/reports/GoatPurchaseReport";
import GoatCensusReport from "./pages/reports/GoatCensusReport";
import MeetingTrainingReport from "./pages/reports/MeetingTrainingReport";


import './style/main.scss';

function App() {
  return (
    <div className="App" style={{marginTop: "50px"}}>
      <Routes>
        <Route path="/" element={ <Login/> } />
        <Route path="*" element={ <WIP/> } />

        <Route path="/home" element={ <Home/> } >
          <Route path='/home' element={<HomeMap/>} />
          <Route path='/home/graph' element={<HomeGraph/>} />
          <Route path='/home/table' element={<HomeTable/>} />
        </Route>

        <Route path="/vaccination" element={ <Vaccination/> } />
        <Route path="/deworming" element={ <Deworming/> } />
        <Route path="/castration" element={ <Castration/> } />
        <Route path="/firstAid" element={ <FirstAid/> } />
        <Route path="/natalCare" element={ <NatalCare/> } />
        <Route path="/goatPurchase" element={ <GoatPurchase/> } />
        <Route path="/inputSaleFeed" element={ <InputSaleFeed/> } />

        <Route path="/shedVisits" element={ <ShedVisits/> } />
        <Route path="/meetingTraining" element={ <MeetingTraining/> } />

        <Route path="/goatCensus" element={ <GoatCensus/> } />
        <Route path="/saleableCensus" element={ <SaleableCensus/> } />
        <Route path="/salePurchase" element={ <SalePurchase/> } />
        <Route path="/SalePurchaseEntry" element={ <SalePurchaseEntry/> } />
        <Route path="/inputStock" element={ <InputStock/> } />


        <Route path="/activityLog" element={ <ActivityLog/> } />
        <Route path="/targetSet" element={ <TargetSet/> } />
        <Route path="/serviceRate" element={ <ServiceRate/> } />
        <Route path="/demographicMaster" element={ <DemographicMaster/> } />
        <Route path="/inventoryMaster" element={ <InventoryMaster/> } />
        <Route path="/bank" element={ <Bank/> } />
        <Route path="/designationMaster" element={ <DesignationMaster/> } />
        <Route path="/uom" element={ <Uom/> } />
        <Route path="/employee" element={ <Employee/> } />
        <Route path="/bod" element={ <Bod/> } />
        <Route path="/suppliers" element={ <Vendor/> } />
        <Route path="/buyer" element={ <Buyer/> } />
        <Route path="/categoryMaster" element={ <CategoryMaster/> } />
        <Route path="/userManagement" element={ <UserManagement/> } />
        <Route path="/pashusaakhi" element={ <Pashusaakhi/> } />
        <Route path="/shareholder" element={ <Shareholder/> } />
        <Route path="/accessControl" element={ <AccessControl/> } />
        <Route path="/video" element={ <Video/> } />
        <Route path="/assetManagement" element={ <AssetManagement/> } />
        <Route path="/feedback&Suggestions" element={ <Feedback/> } />
        <Route path="/companyDocument" element={ <CompanyDocument/> } />
        
        
        <Route path="/InputSalePurchaseReport" element={ <InputSalePurchaseReport/> } />
        <Route path="/InputConsumedReport" element={ <InputConsumedReport/> } />
        <Route path="/ApsEarningReport" element={ <ApsEarningReport/> } />
        <Route path="/SaleableGoatReport" element={ <SaleableGoatReport/> } />
        <Route path="/GoatPurchaseReport" element={ <GoatPurchaseReport/> } />
        <Route path="/GoatCensusReport" element={ <GoatCensusReport/> } />
        <Route path="/MeetingTrainingReport" element={ <MeetingTrainingReport/> } />

      </Routes>
    </div>
  );
}

export default App;