import React, { useState } from 'react';
import notification from '../../hocs/notification';
import axios from 'axios';
import mainurl from '../../../MainURL';
import * as XLSX from 'xlsx/xlsx.mjs';
import {SiMicrosoftexcel} from 'react-icons/si';
import Tooltip from '@mui/material/Tooltip';

import {
  Col, Row, Card, CardHeader, CardBody, CardFooter, Input, Label, Form, Spinner, Button
} from 'reactstrap';

import DataTable from 'react-data-table-component';

const EntryViewShareholder = ({ back_handler, ...props }) => {

  const [loading, setLoading] = useState(false);
  const [toDate, setToDate] = useState('');
  const [frmDate, setFrmDate] = useState('');
  const [entryList, setEntryList] = useState('');
  const [excelList, setExcelList] = useState('');

  const entryList_handler = (e) => {
    e.preventDefault();

    let postData = [
      {
        "org_id": "100",
        "to_date": toDate,
        "frm_date": frmDate,
      }
    ];
    console.log(postData);

    axios({
      method: 'post',
      url: mainurl + 'Master/shareholder_entry_view',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        console.log(response.data);
        let list = response.data.map((row, index) => {
          return (
            {
              sl: index + 1,
              name: row.f_first_name + " ( " + row.f_last_name + " )",
              district: row.district_name,
              block: row.block_long_name,
              panchayat: row.panchyat_name,
              village: row.village_name,
              PG_VO: row.pg_name_desc,
              form_no: row.application_form_no,
              reg_date: row.reg_date
            }
          )
        })
        setEntryList(list);
        
        let excellist = response.data.map((row, index) => {
          let gen;
          let cate;
          if(row.gender === "1"){
            gen = "Male";
          }else if(row.gender === "2"){
            gen ="Female";
          }else{
            gen = "-";
          }
      
          if(row.category_id === "1"){
           cate = "BC";
          }else if(row.category_id === "2"){
           cate = "BC-I";
          }else if(row.category_id === "3"){
           cate = "BC-II";
          }else if(row.category_id === "4"){
           cate = "EBC";
          }else if(row.category_id === "5"){
           cate = "GEN";
          }else if(row.category_id === "6"){
           cate = "OBC";
          }else if(row.category_id === "7"){
           cate = "SC";
          }else if(row.category_id === "8"){
           cate = "ST";
          }else{
           cate = "-";
          } 
          return (
            {
              SL : index + 1,
              Name: row.f_first_name + " ( " + row.f_last_name + " )",
              Age: row.age,
              Gender: gen,
              Category: cate,
              Spouse_Name: row.f_father_husband_name,
              District: row.district_name,
              Block: row.block_long_name,
              Panchayat: row.panchyat_name,
              Village: row.village_name,
              PG_VO: row.pg_name_desc,
              Post_office: row.postoffice_name,
              Aadhar: row.uid,
              Application_Form_No: row.application_form_no,
              Share_amount: row.amount_deposit,
              Reg_date: row.reg_date
            }
          )
        })
        setExcelList(excellist);



      })
      .catch(error => {
        let notify = notification({ message: "Failed! Try Again", type: 'error' });
        notify();
      });

  }

  const download_excel_handler = () => {
    if(excelList.length > 0){
      let wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(excelList);

      XLSX.utils.book_append_sheet(wb, ws, "Mysheet1");

      XLSX.writeFile(wb, "shareholder_list.xlsx");
    }
  }

  return (
    <>
      <Row>
        <Col sm={12}>
          <Card>
            <CardBody>
              <Form onSubmit={entryList_handler}>
                <Row>
                  <Col xs={2}>
                    <Label for="frmDate">Registration From Date </Label>
                    <Input id="frmDate" type='date' bsSize="sm" value={frmDate} onChange={(e) => setFrmDate(e.target.value)} />
                  </Col>
                  <Col xs={2}>
                    <Label for="toDate">Registration To Date </Label>
                    <Input id="toDate" type='date' bsSize="sm" value={toDate} onChange={(e) => setToDate(e.target.value)} />
                  </Col>
                  <Col xs={2}>
                    <Button className='mt-4' color='primary' size="sm" disabled={loading}> Submit {loading && <Spinner color="secondary ms-1" size="sm" >Load...</Spinner>}</Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
        <Col sm={12}>
          <Card>
            <CardHeader>Shareholder Details</CardHeader>
            <div style={{position:'relative', height:'1px'}}>
                  <Tooltip title="Excel download">
                      <SiMicrosoftexcel className='excelIcon' size={20} onClick={download_excel_handler} />
                  </Tooltip> 
              </div>
            <CardBody>
              <DataTable
                title=""
                columns={[
                  {
                    name: '#',
                    selector: row => row.sl,
                    sortable: true,
                    width: "80px"
                  },
                  {
                    name: 'Name',
                    selector: row => row.name,
                    sortable: false,
                    width: '20%'
                  },
                  {
                    name: 'District',
                    selector: row => row.district,
                    sortable: false,
                  },
                  {
                    name: 'Block',
                    selector: row => row.block,
                    sortable: false,
                  },
                  {
                    name: 'Panchayat',
                    selector: row => row.panchayat,
                    sortable: false,
                  },
                  {
                    name: 'Village',
                    selector: row => row.village,
                    sortable: false,
                  },
                  {
                    name: 'PG / VO',
                    selector: row => row.PG_VO,
                    sortable: false,
                  },
                  {
                    name: 'Application Form No',
                    selector: row => row.form_no,
                    sortable: false,
                  },
                  {
                    name: 'Registration Date',
                    selector: row => row.reg_date,
                    sortable: false,
                  },
                  
                ]}
                data={entryList}
                striped
                highlightOnHover
                progressPending={loading}

              />
            </CardBody>
            <CardFooter className='text-end'>
                <Button color='primary' size="sm" onClick={back_handler}> Back </Button>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default EntryViewShareholder;