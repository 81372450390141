import React, { useState, useEffect } from 'react';

import axios from 'axios';
import mainurl from '../../MainURL';

import { Link } from 'react-router-dom';
import Name from './Name';
import { useNavigate } from 'react-router-dom';
import Logo from './../../assets/img/logo.png';
import {
    Row,
    Col,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Accordion,
    AccordionItem,
    AccordionHeader,
    AccordionBody

} from 'reactstrap';

import { GiHamburgerMenu } from 'react-icons/gi';
import { FaPowerOff } from 'react-icons/fa';

function Header() {
    const navigate = useNavigate();
    const [openMenu, setOpenMenu] = useState(1);
    const [menu, setMenu] = useState(false);
    const [m1, setM1] = useState(false);
    const [m2, setM2] = useState(false);
    const [m3, setM3] = useState(false);
    const [m4, setM4] = useState(false);
    const [m5, setM5] = useState(false);
    const [m6, setM6] = useState(false);

    const [logDtl, setLogDtl] = useState({});
    const [roleName, setRoleName] = useState('Admin');
    const [locationName, setLocationName] = useState('All');
    const [accessData, setAccessData] = useState('');



    const [adminAccess, setAdminAccess] = useState(false);

    useEffect(() => {
        checkLogin();
        let accData = sessionStorage.getItem('accessControl');
        if (accData === null || accData === undefined) {
            checkAccess();
        } else {
            setAccessData(JSON.parse(accData));
        }
    }, []);

    const checkAccess = () => {
        let sendData = [{ "org_id": "100", "role_id": sessionStorage.getItem('role') }]
        axios({
            method: 'post',
            url: mainurl + 'Master/get_access_control_list',
            data: sendData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                setAccessData(response.data);
                sessionStorage.setItem('accessControl', JSON.stringify(response.data));
            })
            .catch(error => {

            })
    }

    const checkLogin = () => {

        let role = sessionStorage.getItem('role');
        if (role === null || role === undefined) {
            sessionStorage.clear();
            navigate("/");
        }

        let lDtl = JSON.parse(sessionStorage.getItem('loginData'));
        setLogDtl(lDtl);

        if (role === "101") {
            setAdminAccess(true);
            setRoleName('Admin Access');
            setLocationName("ALL Loaction");
        } else if (role === "102") {
            setRoleName('District Access');
            setLocationName(lDtl.district_name + " District");
        } else if (role === "103") {
            setRoleName('Block Access');
            setLocationName(lDtl.district_name + " / " + lDtl.block_long_name);
        }
    }

    const signout_handler = () => {
        sessionStorage.clear();
        navigate("/");
    }


    return (
        <div className='headerPage bg-primary text-light'>
            <Row>
                <Col xs={5}>
                    <div className='justify-content-left align-items-center h-100 d-flex'>
                        <Link to="/home"><img className='p-1 pe-3' height="50px" src={Logo} alt="logo" /></Link>
                        <div className='d-none d-md-flex'>
                            <h6 className='m-0 fw-bold' style={{ color: "#fec400" }}>Seemanchal Jeevika Goat Producer <br />Company Limited</h6>
                        </div>
                    </div>

                </Col>
                <Col xs={7} className="">
                    <div className='justify-content-end align-items-center h-100 d-none d-md-flex'>
                        <div className='d_menu'>
                            <Link to="/home"> Home</Link>
                        </div>
                        <Dropdown isOpen={m1} onMouseEnter={() => setM1(true)} onMouseLeave={() => setM1(false)}>
                            <DropdownToggle
                                className={`d_menu ${m1 && 'bg-secondary'}`}
                                data-toggle="dropdown"
                                tag="span"
                            >
                                Goat Services
                            </DropdownToggle>
                            <DropdownMenu end>
                                {accessData[0]?.pages[0].page_view === "1" &&
                                    <div className='d_menuItem first' onClick={() => setM1(false)}>
                                        <Link to="/vaccination">Vaccination</Link>
                                    </div>
                                }
                                {accessData[0]?.pages[1].page_view === "1" &&
                                    <div className='d_menuItem' onClick={() => setM1(false)}>
                                        <Link to="/deworming">Deworming</Link>
                                    </div>
                                }
                                {accessData[0]?.pages[2].page_view === "1" &&
                                    <div className='d_menuItem' onClick={() => setM1(false)}>
                                        <Link to="/castration">Castration</Link>
                                    </div>
                                }
                                {accessData[0]?.pages[4].page_view === "1" &&
                                    <div className='d_menuItem' onClick={() => setM1(false)}>
                                        <Link to="/natalCare">Post Natal Care</Link>
                                    </div>
                                }
                                {accessData[0]?.pages[3].page_view === "1" &&
                                    <div className='d_menuItem' onClick={() => setM1(false)}>
                                        <Link to="/firstAid">Primary First Aid</Link>
                                    </div>
                                }
                                {accessData[0]?.pages[5].page_view === "1" &&
                                    <div className='d_menuItem' onClick={() => setM1(false)}>
                                        <Link to="/goatPurchase">Goat Purchase</Link>
                                    </div>
                                }

                            </DropdownMenu>
                        </Dropdown>
                        <Dropdown isOpen={m2} onMouseEnter={() => setM2(true)} onMouseLeave={() => setM2(false)}>
                            <DropdownToggle
                                className={`d_menu ${m2 && 'bg-secondary'}`}
                                data-toggle="dropdown"
                                tag="span"
                            >
                                Shareholders Services
                            </DropdownToggle>
                            <DropdownMenu end>
                                {accessData[1]?.pages[0].page_view === "1" &&
                                    <div className='d_menuItem first' onClick={() => setM2(false)}>
                                        <Link to="/meetingTraining">Meeting & Training</Link>
                                    </div>
                                }
                                {accessData[1]?.pages[1].page_view === "1" &&
                                    <div className='d_menuItem' onClick={() => setM2(false)}>
                                        <Link to="/shedVisits">Shed Visits</Link>
                                    </div>
                                }
                            </DropdownMenu>
                        </Dropdown>
                        <Dropdown isOpen={m3} onMouseEnter={() => setM3(true)} onMouseLeave={() => setM3(false)}>
                            <DropdownToggle
                                className={`d_menu ${m3 && 'bg-secondary'}`}
                                data-toggle="dropdown"
                                tag="span"
                            >
                                Stocks
                            </DropdownToggle>
                            <DropdownMenu end>
                                {accessData[2]?.pages[0].page_view === "1" &&
                                    <div className='d_menuItem first' onClick={() => setM3(false)}>
                                        <Link to="/goatCensus">Goat Census</Link>
                                    </div>
                                }
                                {accessData[2]?.pages[1].page_view === "1" &&
                                    <div className='d_menuItem' onClick={() => setM3(false)}>
                                        <Link to="/saleableCensus">Saleable Goat Census</Link>
                                    </div>
                                }
                                {accessData[2]?.pages[2].page_view === "1" &&
                                    <div className='d_menuItem' onClick={() => setM3(false)}>
                                        <Link to="/salePurchase">Input Sale & Purchase</Link>
                                    </div>
                                }
                                {accessData[2]?.pages[3].page_view === "1" &&
                                    <div className='d_menuItem' onClick={() => setM3(false)}>
                                        <Link to="/inputStock">Input Stock</Link>
                                    </div>
                                }
                                {accessData[2]?.pages[4].page_view === "1" &&
                                    <div className='d_menuItem' onClick={() => setM3(false)}>
                                        <Link to="/SalePurchaseEntry">Sale Purchase Entry</Link>
                                    </div>
                                }
                            </DropdownMenu>
                        </Dropdown>

                        <Dropdown isOpen={m4} onMouseEnter={() => setM4(true)} onMouseLeave={() => setM4(false)}>
                            <DropdownToggle
                                className={`d_menu ${m4 && 'bg-secondary'}`}
                                data-toggle="dropdown"
                                tag="span"
                            >
                                Master
                            </DropdownToggle>
                            <DropdownMenu end style={{ width: 'max-content' }}>
                                <div className='d-flex'>
                                    <div className='position-relative'>
                                        {accessData[3]?.pages[0].page_view === "1" &&
                                            <div className='d_menuItem first' onClick={() => setM4(false)}>
                                                <Link to="/shareholder">Shareholders</Link>
                                            </div>
                                        }
                                        {accessData[3]?.pages[1].page_view === "1" &&
                                            <div className='d_menuItem' onClick={() => setM4(false)}>
                                                <Link to="/pashusaakhi">Pashusakhi</Link>
                                            </div>
                                        }
                                        {accessData[3]?.pages[2].page_view === "1" &&
                                            <div className='d_menuItem' onClick={() => setM4(false)}>
                                                <Link to="/targetSet">Target set</Link>
                                            </div>
                                        }
                                        {accessData[3]?.pages[3].page_view === "1" &&
                                            <div className='d_menuItem' onClick={() => setM4(false)}>
                                                <Link to="/serviceRate">Service Rate</Link>
                                            </div>
                                        }
                                        {accessData[3]?.pages[4].page_view === "1" &&
                                            <div className='d_menuItem' onClick={() => setM4(false)}>
                                                <Link to="/demographicMaster">Demographic Master</Link>
                                            </div>
                                        }
                                        {accessData[3]?.pages[5].page_view === "1" &&
                                            <div className='d_menuItem' onClick={() => setM4(false)}>
                                                <Link to="/inventoryMaster">Inventory Master</Link>
                                            </div>
                                        }
                                        {accessData[3]?.pages[6].page_view === "1" &&
                                            <div className='d_menuItem' onClick={() => setM4(false)}>
                                                <Link to="/bod">BoD</Link>
                                            </div>
                                        }
                                        {accessData[3]?.pages[7].page_view === "1" &&
                                            <div className='d_menuItem' onClick={() => setM4(false)}>
                                                <Link to="/employee">Employee</Link>
                                            </div>
                                        }
                                        {accessData[3]?.pages[8].page_view === "1" &&
                                            <div className='d_menuItem' onClick={() => setM4(false)}>
                                                <Link to="/bank">Banks</Link>
                                            </div>
                                        }
                                        {accessData[3]?.pages[9].page_view === "1" &&
                                            <div className='d_menuItem' onClick={() => setM4(false)}>
                                                <Link to="/designationMaster">Designation Master</Link>
                                            </div>
                                        }
                                        {accessData[3]?.pages[10].page_view === "1" &&
                                            <div className='d_menuItem' onClick={() => setM4(false)}>
                                                <Link to="/uom">UOM Master</Link>
                                            </div>
                                        }

                                    </div>
                                    <div className='position-relative'>

                                        {accessData[3]?.pages[11].page_view === "1" &&
                                            <div className='d_menuItem first' onClick={() => setM4(false)}>
                                                <Link to="/suppliers">Suppliers</Link>
                                            </div>
                                        }
                                        {accessData[3]?.pages[12].page_view === "1" &&
                                            <div className='d_menuItem' onClick={() => setM4(false)}>
                                                <Link to="/buyer">Buyers</Link>
                                            </div>
                                        }
                                        {accessData[3]?.pages[16].page_view === "1" &&
                                            <div className='d_menuItem' onClick={() => setM4(false)}>
                                                <Link to="/categoryMaster">Category Master</Link>
                                            </div>
                                        }
                                        {accessData[3]?.pages[13].page_view === "1" &&
                                            <div className='d_menuItem' onClick={() => setM4(false)}>
                                                <Link to="/userManagement">User Management</Link>
                                            </div>
                                        }
                                        {accessData[3]?.pages[14].page_view === "1" &&
                                            <div className='d_menuItem' onClick={() => setM4(false)}>
                                                <Link to="/accessControl">Access Control</Link>
                                            </div>
                                        }
                                        {accessData[3]?.pages[15].page_view === "1" &&
                                            <div className='d_menuItem' onClick={() => setM4(false)}>
                                                <Link to="/activityLog">Activity Log</Link>
                                            </div>
                                        }
                                        {accessData[3]?.pages[17].page_view === "1" &&
                                            <div className='d_menuItem' onClick={() => setM4(false)}>
                                                <Link to="/video">Video Management</Link>
                                            </div>
                                        }
                                        {accessData[3]?.pages[18].page_view === "1" &&
                                            <div className='d_menuItem' onClick={() => setM4(false)}>
                                                <Link to="/companyDocument">Company Document</Link>
                                            </div>
                                        }
                                        {accessData[3]?.pages[19].page_view === "1" &&
                                            <div className='d_menuItem' onClick={() => setM4(false)}>
                                                <Link to="/assetManagement">Asset Management</Link>
                                            </div>
                                        }
                                        {accessData[3]?.pages[20].page_view === "1" &&
                                            <div className='d_menuItem' onClick={() => setM4(false)}>
                                                <Link to="/feedback&Suggestions">Feedback and Suggestions</Link>
                                            </div>
                                        }

                                    </div>
                                </div>



                            </DropdownMenu>
                        </Dropdown>

                        <Dropdown isOpen={m5} onMouseEnter={() => setM5(true)} onMouseLeave={() => setM5(false)}>
                            <DropdownToggle
                                className={`d_menu ${m5 && 'bg-secondary'}`}
                                data-toggle="dropdown"
                                tag="span"
                            >
                                Report
                            </DropdownToggle>
                            <DropdownMenu end style={{ width: 'max-content' }}>
                                <div className='d_menuItem first' onClick={() => setM5(false)}>
                                    <Link to="/GoatCensusReport">Goat Census Report</Link>
                                </div>
                                <div className='d_menuItem' onClick={() => setM5(false)}>
                                    <Link to="/GoatPurchaseReport">Goat Purchase Report</Link>
                                </div>
                                <div className='d_menuItem' onClick={() => setM5(false)}>
                                    <Link to="/InputConsumedReport">Input Consumed Report</Link>
                                </div>
                                <div className='d_menuItem' onClick={() => setM5(false)}>
                                    <Link to="/InputSalePurchaseReport">Input Purchase Report</Link>
                                </div>
                                <div className='d_menuItem' onClick={() => setM5(false)}>
                                    <Link to="/goat">Stock Report</Link>
                                </div>
                                <div className='d_menuItem' onClick={() => setM5(false)}>
                                    <Link to="/MeetingTrainingReport">Meeting & Training Reports</Link>
                                </div>
                                <div className='d_menuItem' onClick={() => setM5(false)}>
                                    <Link to="/SaleableGoatReport">Saleable Goat Report</Link>
                                </div>
                                <div className='d_menuItem' onClick={() => setM5(false)}>
                                    <Link to="/ApsEarningReport">Pashusakhi Earning Report</Link>
                                </div>
                            </DropdownMenu>
                        </Dropdown>

                        <Dropdown isOpen={m6} toggle={() => setM6(!m6)} style={{ width: "40px" }}>
                            <DropdownToggle
                                className="cursor"
                                data-toggle="dropdown"
                                tag="span"
                            >
                                <Name title={roleName !== undefined ? roleName : "A"} />
                            </DropdownToggle>
                            <DropdownMenu end >
                                <div className='text-primary text-center'><b>{logDtl.user_name_desc !== undefined ? logDtl.user_name_desc : "S"}</b></div>

                                <small className='d-block text-center'>{roleName}</small>
                                <small className='d-block text-center border-bottom pb-1'>{locationName}</small>

                                <div className='cursor text-center text-secondary' onClick={signout_handler}><FaPowerOff className="me-1" color="" size={20} /> Signout </div>
                            </DropdownMenu>
                        </Dropdown>

                    </div>
                    <div className='d-flex d-md-none justify-content-end align-items-center h-100'>
                        <GiHamburgerMenu className='me-4 cursor' size={30} onClick={() => setMenu(!menu)} />
                        <Offcanvas
                            direction="end"
                            scrollable
                            isOpen={menu}
                        >
                            <OffcanvasHeader toggle={() => setMenu(!menu)}>
                                Menu
                            </OffcanvasHeader>
                            <div className='border-bottom' style={{ fontSize: '16px' }}>
                                <div className='text-primary ps-4'><b>{logDtl.user_name_desc !== undefined ? logDtl.user_name_desc : "S"}</b></div>

                                <small className='d-block ps-4'>{roleName}</small>
                                <small className='d-block pb-1 ps-4'>{locationName}</small>

                            </div>
                            <OffcanvasBody>
                                <Accordion
                                    flush
                                    open={openMenu}
                                    toggle={(id) => setOpenMenu(id)}
                                >
                                    <AccordionItem>
                                        <AccordionHeader targetId="0">
                                            <Link to="/home">Home</Link>
                                        </AccordionHeader>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="1">
                                            Goat Services
                                        </AccordionHeader>
                                        <AccordionBody accordionId="1" className='position-relative'>
                                            {accessData[0]?.pages[0].page_view === "1" &&
                                                <div className='m_menuItem'>
                                                    <Link to="/vaccination">Vaccination</Link>
                                                </div>
                                            }
                                            {accessData[0]?.pages[1].page_view === "1" &&
                                                <div className='m_menuItem'>
                                                    <Link to="/deworming">Deworming</Link>
                                                </div>
                                            }
                                            {accessData[0]?.pages[2].page_view === "1" &&
                                                <div className='m_menuItem'>
                                                    <Link to="/castration">Castration</Link>
                                                </div>
                                            }
                                            {accessData[0]?.pages[3].page_view === "1" &&
                                                <div className='m_menuItem'>
                                                    <Link to="/firstAid">Primary First Aid</Link>
                                                </div>
                                            }
                                            {accessData[0]?.pages[4].page_view === "1" &&
                                                <div className='m_menuItem'>
                                                    <Link to="/natalCare">Post Natal Care</Link>
                                                </div>
                                            }
                                            {accessData[0]?.pages[5].page_view === "1" &&
                                                <div className='m_menuItem'>
                                                    <Link to="/goatPurchase">Goat Purchase</Link>
                                                </div>
                                            }
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="2">
                                            Shareholder Services
                                        </AccordionHeader>
                                        <AccordionBody accordionId="2" className='position-relative'>
                                            {accessData[1]?.pages[0].page_view === "1" &&
                                                <div className='m_menuItem'>
                                                    <Link to="/meetingTraining">Meeting & Training</Link>
                                                </div>
                                            }
                                            {accessData[1]?.pages[1].page_view === "1" &&
                                                <div className='m_menuItem'>
                                                    <Link to="/shedVisits">Shed Visits</Link>
                                                </div>
                                            }
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="3">
                                            Stocks
                                        </AccordionHeader>
                                        <AccordionBody accordionId="3" className='position-relative'>
                                            {accessData[2]?.pages[0].page_view === "1" &&
                                                <div className='m_menuItem'>
                                                    <Link to="/goatCensus">Goat Census</Link>
                                                </div>
                                            }
                                            {accessData[2]?.pages[1].page_view === "1" &&
                                                <div className='m_menuItem'>
                                                    <Link to="/saleableCensus">Saleable Census</Link>
                                                </div>
                                            }
                                            {accessData[2]?.pages[2].page_view === "1" &&
                                                <div className='m_menuItem'>
                                                    <Link to="/salePurchase">Input Sale & Purchase</Link>
                                                </div>
                                            }
                                            {accessData[2]?.pages[3].page_view === "1" &&
                                                <div className='m_menuItem'>
                                                    <Link to="/inputStock">Input Stock</Link>
                                                </div>
                                            }
                                            {accessData[2]?.pages[4].page_view === "1" &&
                                                <div className='m_menuItem'>
                                                    <Link to="/SalePurchaseEntry">Sale Purchase Entry</Link>
                                                </div>
                                            }
                                        </AccordionBody>
                                    </AccordionItem>

                                    <AccordionItem>
                                        <AccordionHeader targetId="4">
                                            Master
                                        </AccordionHeader>
                                        <AccordionBody accordionId="4" className='position-relative'>

                                            {accessData[3]?.pages[0].page_view === "1" &&
                                                <div className='m_menuItem'>
                                                    <Link to="/shareholder">Shareholders</Link>
                                                </div>
                                            }
                                            {accessData[3]?.pages[1].page_view === "1" &&
                                                <div className='m_menuItem'>
                                                    <Link to="/pashusaakhi">Pashusakhi</Link>
                                                </div>
                                            }
                                            {accessData[3]?.pages[2].page_view === "1" &&
                                                <div className='m_menuItem'>
                                                    <Link to="/targetSet">Target set</Link>
                                                </div>
                                            }
                                            {accessData[3]?.pages[3].page_view === "1" &&
                                                <div className='m_menuItem'>
                                                    <Link to="/serviceRate">Service Rate</Link>
                                                </div>
                                            }
                                            {accessData[3]?.pages[4].page_view === "1" &&
                                                <div className='m_menuItem'>
                                                    <Link to="/demographicMaster">Demographic Master</Link>
                                                </div>
                                            }
                                            {accessData[3]?.pages[5].page_view === "1" &&
                                                <div className='m_menuItem'>
                                                    <Link to="/inventoryMaster">Inventory Master</Link>
                                                </div>
                                            }

                                            {accessData[3]?.pages[6].page_view === "1" &&
                                                <div className='m_menuItem'>
                                                    <Link to="/bod">BoD</Link>
                                                </div>
                                            }
                                            {accessData[3]?.pages[7].page_view === "1" &&
                                                <div className='m_menuItem'>
                                                    <Link to="/employee">Employee</Link>
                                                </div>
                                            }
                                            {accessData[3]?.pages[8].page_view === "1" &&
                                                <div className='m_menuItem'>
                                                    <Link to="/bank">Banks</Link>
                                                </div>
                                            }
                                            {accessData[3]?.pages[9].page_view === "1" &&
                                                <div className='m_menuItem'>
                                                    <Link to="/designationMaster">Designation Master</Link>
                                                </div>
                                            }
                                            {accessData[3]?.pages[10].page_view === "1" &&
                                                <div className='m_menuItem'>
                                                    <Link to="/uom">UOM Master</Link>
                                                </div>
                                            }
                                            {accessData[3]?.pages[11].page_view === "1" &&
                                                <div className='m_menuItem' onClick={() => setM4(false)}>
                                                    <Link to="/suppliers">Suppliers</Link>
                                                </div>
                                            }
                                            {accessData[3]?.pages[12].page_view === "1" &&
                                                <div className='m_menuItem' onClick={() => setM4(false)}>
                                                    <Link to="/buyer">Buyers</Link>
                                                </div>
                                            }
                                            {accessData[3]?.pages[16].page_view === "1" &&
                                                <div className='m_menuItem' onClick={() => setM4(false)}>
                                                    <Link to="/categoryMaster">Category Master</Link>
                                                </div>
                                            }
                                            {accessData[3]?.pages[13].page_view === "1" &&
                                                <div className='m_menuItem' onClick={() => setM4(false)}>
                                                    <Link to="/userManagement">User Management</Link>
                                                </div>
                                            }
                                            {accessData[3]?.pages[14].page_view === "1" &&
                                                <div className='m_menuItem' onClick={() => setM4(false)}>
                                                    <Link to="/accessControl">Access Control</Link>
                                                </div>
                                            }
                                            {accessData[3]?.pages[15].page_view === "1" &&
                                                <div className='m_menuItem' onClick={() => setM4(false)}>
                                                    <Link to="/activityLog">Activity Log</Link>
                                                </div>
                                            }
                                            {accessData[3]?.pages[17].page_view === "1" &&
                                                <div className='m_menuItem' onClick={() => setM4(false)}>
                                                    <Link to="/video">Video Management</Link>
                                                </div>
                                            }
                                            {accessData[3]?.pages[18].page_view === "1" &&
                                                <div className='m_menuItem' onClick={() => setM4(false)}>
                                                    <Link to="/companyDocument">Company Document</Link>
                                                </div>
                                            }
                                            {accessData[3]?.pages[19].page_view === "1" &&
                                                <div className='m_menuItem' onClick={() => setM4(false)}>
                                                    <Link to="/assetManagement">Asset Management</Link>
                                                </div>
                                            }
                                            {accessData[3]?.pages[20].page_view === "1" &&
                                                <div className='m_menuItem' onClick={() => setM4(false)}>
                                                    <Link to="/feedback&Suggestions">Feedback and Suggestions</Link>
                                                </div>
                                            }
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="5">
                                            Report
                                        </AccordionHeader>
                                        <AccordionBody accordionId="5" className='position-relative'>

                                            <div className='m_menuItem'>
                                                <Link to="/GoatCensusReport">Goat Census Report</Link>
                                            </div>
                                            <div className='m_menuItem'>
                                                <Link to="/GoatPurchaseReport">Goat Purchase Report</Link>
                                            </div>
                                            <div className='m_menuItem'>
                                                <Link to="/InputConsumedReport">Input Consumed Report</Link>
                                            </div>
                                            <div className='m_menuItem'>
                                                <Link to="/InputSalePurchaseReport">Input Sale Purchase Report</Link>
                                            </div>
                                            <div className='m_menuItem'>
                                                <Link to="/StockReport">Stock Report</Link>
                                            </div>
                                            <div className='m_menuItem'>
                                                <Link to="/MeetingTrainingReport">Meeting & Training Report</Link>
                                            </div>
                                            <div className='m_menuItem'>
                                                <Link to="/SaleableGoatReport">Saleable Goat Report</Link>
                                            </div>
                                            <div className='m_menuItem'>
                                                <Link to="/ApsEarningReport">Pashusakhi Earning Report</Link>
                                            </div>
                                        </AccordionBody>
                                    </AccordionItem>
                                    <div className='cursor ps-3 fw-bold text-secondary' style={{ fontSize: "18px" }} onClick={signout_handler}>signout</div>
                                    <hr />
                                </Accordion>
                            </OffcanvasBody>
                        </Offcanvas>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Header;