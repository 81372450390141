import React, {useState, useEffect} from 'react';
import notification from '../../hocs/notification';
import axios from 'axios';
import mainurl from '../../../MainURL';
import {
    Col, Row, Button, Input, Label, Form, InputGroup, InputGroupText, Spinner,
} from 'reactstrap';

import { FaHeading} from 'react-icons/fa';

const EditBank = ({ geo, data, close_handler, ...props}) => {

    const [geoCode, setGeoCode] = useState("");
    const [geoName, setGeoName] = useState("");
    const [geoNameHindi, setGeoNameHindi] = useState("");
    const [hindiNameLoading, setHindiNameLoading] = useState(false);

    useEffect(() => {
        console.log(data);
            setGeoCode((data.bank_id).trim());
            setGeoName((data.bank_name).trim());
            setGeoNameHindi((data.bank_name_h).trim());
            //setGeoNameHindi((data.district_name_h).trim());
    }, [data]);

    const translate_text_handler = (e) => {
        setHindiNameLoading(true);
        if(geoName.length > 1){
            
            let postData = [{ "t_text" : geoName}];
            axios({
                method: 'post',
                url: mainurl + 'Master/translate_text',
                data: postData,
                headers: { 'Content-Type': 'application/JSON', }
              })
                .then(function (response) {
                    let trans = response.data;
                    setGeoNameHindi(trans);
                    setHindiNameLoading(false);
                })
                .catch(error => {
                    setHindiNameLoading(false);
                    let notify = notification({ message: "Hindi Translation is not working! Try Again", type: 'error' });
                    notify();
                  });

        }else{
            setHindiNameLoading(false);
            let notify = notification({ message: "Please enter geographic english name first.", type: 'warn' });
            notify();
        }
    }

    const edit_handler = (e) => {
        e.preventDefault();
        let postData = [
            {
              "org_id":"100",
              "user_id":"128",
              "action": 2,
              "bank_id": geoCode,
              "bank_name": geoName,
              "bank_name_h": geoNameHindi
            }
        ];
        console.log(postData);

        axios({
            method: 'post',
            url: mainurl + 'Master/bank_add_edit',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
          })
            .then(function (response) {
                console.log(response.data);
                close_handler();
            })
            .catch(error => {
                close_handler();
                let notify = notification({ message: "Update failed! Try Again", type: 'error' });
                notify();
              });
    }

    return(
        <>
            <Form onSubmit={edit_handler}>
                    <Row>
                       
                        <Col md={3}>
                            <Label for="name"> {geo} Name</Label>
                            <Input id="name" type='text' bsSize="sm" maxLength={50} onKeyPress={(e) => !/^[a-zA-Z\s]*$/.test(e.key) && e.preventDefault()} value={geoName} onChange={(e) => setGeoName(e.target.value)} required />
                        </Col>
                        <Col md={3}>
                            <Label for="hName"> {geo} Name Hindi</Label>
                            <InputGroup size="sm">
                                <Input id="hName" type='text' maxLength={50} value={geoNameHindi} bsSize="sm" onChange={(e)=>setGeoNameHindi(e.target.value)} />
                                <InputGroupText className='bg-primary cursor' onClick={!hindiNameLoading ? translate_text_handler : undefined }>
                                    {hindiNameLoading ? <Spinner color="secondary" size="sm">Loading...</Spinner> : <FaHeading color="#fff" />}
                                </InputGroupText>
                            </InputGroup>
                        </Col>
                        <Col md={3}>
                            <Button className='mt-4' color="primary" size="sm" >Update</Button>
                        </Col>
                    </Row>
                </Form>
        </>
    )
}

export default EditBank;