import React from 'react';

import ModPage from './ModPage';

const ModulePages = ({data, children, ...props }) => {
    return (
        <div className='p-1 mb-2 ' style={{background: "#eee", borderRadius: "5px"}}>
            <div className="d-flex p-1">
                <h6>{data.module_name}</h6>
            </div>
            {
                data.pages.map((row, index) => {
                    return (
                        <ModPage data={row} index={index}></ModPage>
                    )
                })
            }
        </div>

    )
}

export default ModulePages;