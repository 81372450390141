import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/utility/Page';
import notification from '../../component/hocs/notification';
import axios from 'axios';
import mainurl from '../../MainURL';
import DataTable from 'react-data-table-component';

import Switch from '@mui/material/Switch';

import AddUser from '../../component/utility/master/AddUser';
import EditUser from '../../component/utility/master/EditUser';

import {MdPersonAdd} from 'react-icons/md';
import {FaEdit} from 'react-icons/fa';
import * as XLSX from 'xlsx/xlsx.mjs';
import {SiMicrosoftexcel} from 'react-icons/si';
import {FaSearch} from 'react-icons/fa';
import Tooltip from '@mui/material/Tooltip';

import { Row, Col, Card, CardHeader, CardBody, Button, Input, InputGroup, InputGroupText } from 'reactstrap';

const UserManagement = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [mode, setMode] = useState(1);
    const [userTable, setUserTable] = useState('');
    const [editData, setEditData] = useState('');
    const [accData, setAccData] = useState('');
    
    const [searchTxt, setSearchTxt] = useState('');
    const [searchData, setSearchData] = useState('');


    useEffect(() => {
        // check Access
        let accData = JSON.parse(sessionStorage.getItem('accessControl'));
        setAccData(accData[3].pages[13]);
        if(accData[3].pages[13].page_view !== "1"){
            navigate("/home");
        }
        
        get_user_list();
    }, []);

    const enable_disable_handler = (row) => {
        let flag;
        if(row.active_flag === "1"){
            flag = 0;
        }else{
            flag = 1;
        }

        let postData = [{
            org_id: 100,
            user_id: row.user_id,
            active_flag: flag,
        }];

        axios({
            method: 'post',
            url: mainurl + 'Master/box_user_enable_disable',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let notify = notification({ message: "Successfully submited.", type: 'success' });
                notify();

            })
            .catch(error => {
                get_user_list();
                let notify = notification({ message: "failed! Try Again", type: 'error' });
                notify();
            });
    }

    const get_user_list = () => {
        //
        let accData = JSON.parse(sessionStorage.getItem('accessControl'));
        let acc = accData[3].pages[3];
        //
        setLoading(true);
        axios({
            method: 'post',
            url: mainurl + 'Master/box_user_list',
            data: [{"org": "100"}],
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
               console.log(response.data);
               let list = response.data.map(row => {
                    let roleName = '';
                   if(row.user_role === "101"){
                    roleName = "Admin Access"; 
                   }else if(row.user_role === "102"){
                    roleName = "District Access";
                   }else if(row.user_role === "103"){
                    roleName = "Block Access";
                   }else if(row.user_role === "104"){
                    roleName = "FPC Access";
                   }
                   let location_name = "";
                   if(row.block_id > 0){
                    location_name = row.district_name +" / "+ row.block_long_name;
                   }else if(row.dist_id > 0){
                    location_name = row.district_name;
                   }else{
                    location_name = "ALL";
                   }

                return(
                    {
                        ids: row.user_id,
                        Name: row.user_name_desc,
                        Role: roleName,
                        MobileNo: row.mobile_no,
                        Location: location_name,
                        action: <div>
                            { acc.page_edit === "1" && <FaEdit className='me-3 cursor' color="#047e04" onClick={()=> open_edit_modal(row)} size={20} />}
                            { acc.page_delete === "1" && <Switch defaultChecked={(row.active_flag === "1") ? true : false} onChange={()=>enable_disable_handler(row)} size="small" />}
                        </div>
                    }
                )
               })
               setUserTable(list);
               setSearchData(list);
               setLoading(false);
            })
            .catch(error => {
                setUserTable('');
                setSearchData('');
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const open_edit_modal = (row) => {
        setEditData(row);
        setMode(3);
    }

    const search_handler = (txt) => {
        setLoading(true);
        setSearchTxt(txt)
        if(userTable.length > 0){
            let tempSearchData = userTable.filter (item => {
                const query = txt.toLowerCase();
                return ( 
                    item.Name.toLowerCase().indexOf(query) >= 0 ||
                    item.Role.toLowerCase().indexOf(query) >= 0 ||
                    item.MobileNo.toLowerCase().indexOf(query) >= 0 ||
                    item.Location.toLowerCase().indexOf(query) >= 0 
                )
            })
            setSearchData(tempSearchData);
            setLoading(false);
        }
    }

    const download_excel_handler = () => {
        if(userTable.length > 0){
          let wb = XLSX.utils.book_new(),
          ws = XLSX.utils.json_to_sheet(userTable);
    
          XLSX.utils.book_append_sheet(wb, ws, "Mysheet1");
    
          XLSX.writeFile(wb, "User_List.xlsx");
        }
      }
    

    return(
        <>
            <Header />
            <Page title="User Management" breadcrumbs="Master / User Management">
                { mode === 1 &&
                    <Row className='p-3'>
                        <Col xs={12}>
                            { accData.page_add === "1" && <Card className='mb-3'>
                                <CardBody>
                                    <Button color='primary' size="sm" onClick={() => setMode(2)}><MdPersonAdd /> Add New User</Button>
                                </CardBody>
                            </Card>}
                            <Card className='mb-2'>

                            <CardHeader>User List</CardHeader>
                            <Col sm={{ offset: 6, size: 3 }} style={{position: 'absolute', top: '2px', right: '2px'}}>
                                <InputGroup size="sm">
                                    <InputGroupText> <FaSearch /> </InputGroupText>
                                    <Input type="search" placeholder='Search' value={searchTxt} onChange={(e)=>search_handler(e.target.value)} />
                                </InputGroup>
                            </Col>
                            <div style={{position:'relative', height:'1px'}}>
                                <Tooltip title="Excel download">
                                    <SiMicrosoftexcel className='excelIcon' size={20} onClick={download_excel_handler} />
                                </Tooltip> 
                            </div>
                            <CardBody>
                                <DataTable
                                    title=""
                                    columns={[
                                        {
                                        name: 'User Id',
                                        selector: row => row.ids,
                                        sortable: true,
                                        },
                                        {
                                        name: 'User Name',
                                        selector: row => row.Name,
                                        sortable: false,
                                        },
                                        {
                                        name: 'User Role',
                                        selector: row => row.Role,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Location',
                                        selector: row => row.Location,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Mobile Number',
                                        selector: row => row.MobileNo,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Action',
                                        selector: row => row.action,
                                        sortable: false,
                                        },
                                    ]}
                                    data={searchData}
                                    striped
                                    highlightOnHover
                                    pagination
                                    paginationPerPage="30"
                                    progressPending={loading}
                                    />
                            </CardBody>
                        </Card>
                        </Col>
                    </Row>
                }
                { mode === 2 &&
                    <Row className='p-3'>
                        <Col xs={12}>
                            <AddUser back_handler={()=> {setMode(1);get_user_list();}} />
                        </Col>
                    </Row>
                }
                { mode === 3 &&
                    <Row className='p-3'>
                        <Col xs={12} className="mb-2">
                            <EditUser data={editData} back_handler={()=> {setMode(1);get_user_list()}} />
                        </Col>
                    </Row>    
                }

            </Page>
            <Footer />
        </>
    )
}

export default UserManagement;