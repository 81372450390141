import React, {useState, useEffect} from 'react';
import notification from '../../hocs/notification';
import axios from 'axios';
import mainurl from '../../../MainURL';
import DataTable from 'react-data-table-component';
import {
    Col, Row, Button, Input, Label, Form, InputGroup, InputGroupText, Spinner, Card, CardHeader, CardBody
} from 'reactstrap';

import { FaHeading} from 'react-icons/fa';
import { MdDeleteForever} from 'react-icons/md';

const EditDemographic = ({geoId, geo, data, close_handler, reset, ...props}) => {
    const [district, setDistrict] = useState("0");
    const [block, setBlock] = useState("0");
    const [panchyat, setPanchyat] = useState("0");
    const [village, setVillage] = useState("0");

    const [geoCode, setGeoCode] = useState("");
    const [geoName, setGeoName] = useState("");
    const [geoNameHindi, setGeoNameHindi] = useState("");
    const [hindiNameLoading, setHindiNameLoading] = useState(false);

    const [loading, setLoading] = useState(false);
    const [allocatedPg, setAllocatedPg] = useState('');
    const [districtOpt, setDistrictOpt] = useState();
    const [blockOpt, setBlockOpt] = useState();
    const [panchyatOpt, setPanchyatOpt] = useState();
    const [villageOpt, setVillageOpt] = useState();
    const [district1, setDistrict1] = useState("0");
    const [block1, setBlock1] = useState("0");
    const [panchyat1, setPanchyat1] = useState("0");
    const [village1, setVillage1] = useState("0");

    useEffect(() => {
        console.log(data)
        if(geoId === "1"){
            setGeoCode((data.district_short_cd).trim());
            setGeoName((data.district_name).trim());
            setGeoNameHindi((data.district_name_h).trim());
            setDistrict((data.district_id).trim());
        }else if(geoId === "2"){
            setDistrict((data.district_name).trim());
            setGeoCode((data.block_code).trim());
            setGeoName((data.block_long_name).trim());
            setGeoNameHindi((data.block_name_h).trim()); 
            setBlock((data.block_id).trim());
        }else if(geoId === "3"){
            setDistrict((data.district_name).trim());
            setBlock((data.block_long_name).trim());
            setGeoCode((data.panchyat_code).trim());
            setGeoName((data.panchyat_name).trim());
            setGeoNameHindi((data.panchyat_name_hindi).trim()); 
            setPanchyat((data.panchayat_id).trim())
        }else if(geoId === "4"){
            setDistrict((data.district_name).trim());
            setBlock((data.block_long_name).trim());
            setPanchyat((data.panchyat_name).trim());
            setGeoCode((data.village_code).trim());
            setGeoName((data.village_name).trim());
            setGeoNameHindi((data.village_name_h).trim()); 
            setVillage((data.village_id).trim()); 
        }else if(geoId === "6"){
            setGeoCode((data.pg_id).trim());
            setGeoName((data.pg_name_desc).trim());
            setGeoNameHindi((data.pg_name_hindi).trim());
        }else if(geoId === "7"){
            get_allocated_pg_list();
            getDistrictList();
        }
        
    }, [data]);

    const get_allocated_pg_list = () => {
        setLoading(true);
        let postData = [{ "org_id" : "100", "pg_id": data.pg_id }];
            axios({
                method: 'post',
                url: mainurl + 'Master/allocated_pg_list',
                data: postData,
                headers: { 'Content-Type': 'application/JSON', }
              })
                .then(function (response) {
                    let list = response.data.map((row, index) => {
                        return(
                            {
                                sl: index + 1,
                                aps: row.aps_name,
                                district: row.district_name,
                                block: row.block_long_name,
                                panchyat: row.panchyat_name,
                                village: row.village_name,
                                action: <MdDeleteForever className='cursor' size={20} color="#f70000" onClick={() => delete_handler(row)} />
                            }
                        )
                    })
                    setAllocatedPg(list);
                    setLoading(false);
                })
                .catch(error => {
                    setLoading(false);
                    let notify = notification({ message: "Allocated PG list not found! Try Again", type: 'error' });
                    notify();
                  });
    }

    const translate_text_handler = (e) => {
        setHindiNameLoading(true);
        if(geoName.length > 1){
            
            let postData = [{ "t_text" : geoName}];
            axios({
                method: 'post',
                url: mainurl + 'Master/translate_text',
                data: postData,
                headers: { 'Content-Type': 'application/JSON', }
              })
                .then(function (response) {
                    let trans = response.data;
                    setGeoNameHindi(trans);
                    setHindiNameLoading(false);
                })
                .catch(error => {
                    setHindiNameLoading(false);
                    let notify = notification({ message: "Hindi Translation is not working! Try Again", type: 'error' });
                    notify();
                  });

        }else{
            setHindiNameLoading(false);
            let notify = notification({ message: "Please enter geographic english name first.", type: 'warn' });
            notify();
        }
    }

    const edit_geographic_handler = (e) => {
        e.preventDefault();
        let postData = [
            {
              "org_id":"100",
              "user_id":"128",
              "geo_id": geoId,
              "dist_id": district,
              "block_id": block,
              "pan_id": panchyat,
              "vil_id": village,
              "geo_code": geoCode,
              "geo_name": geoName,
              "geo_name_h": geoNameHindi
            }
        ];
        console.log(postData);

        axios({
            method: 'post',
            url: mainurl + 'Master/master_geo_edit',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
          })
            .then(function (response) {
                let notify = notification({ message: "Updated Successfully!", type: 'success' });
                notify();
                close_handler();
                reset();
            })
            .catch(error => {
                close_handler();
                let notify = notification({ message: "Update failed! Try Again", type: 'error' });
                notify();
              });
    }
    
    // district, block, panchayat, village API call
    const getDistrictList = () => {
        setBlock1("0");
        setPanchyat1("0");
        setVillage1("0");
    
        let postData = [{ "org_id": 100, "user_id": "0" }];
        axios({
          method: 'post',
          url: mainurl + 'Geo/geo_district_list2',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            let list = response.data.map((row, index) => {
              return <option key={index} value={row.district_short_cd}> {row.district_name} </option>
            })
            setDistrictOpt(list);
          })
          .catch(error => {
            let notify = notification({ message: "District list not Found! Try Again", type: 'error' });
            notify();
          });
      }
    
      const getBlockList = (e) => {
        setDistrict1(e.target.value);
        setBlock1("0");
        setPanchyat1("0");
        setVillage1("0");
        
        let postData = [{ "org_id": 100, "user_id": "0", "dist_id": e.target.value }]
        axios({
          method: 'post',
          url: mainurl + 'Geo/geo_block_list',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            let list = response.data.map((row, index) => {
              return <option key={index} value={row.block_code}> {row.block_long_name} </option>
            })
            setBlockOpt(list);
          })
          .catch(error => {
            let notify = notification({ message: "Block list not Found! Try Again", type: 'error' });
            notify();
          });
      }
    
      const getPanchyatList = (e) => {
        setBlock1(e.target.value);
        setPanchyat1("0");
        setVillage1("0");
        let postData = [{ "org_id": 100, "user_id": "0", "block_id": e.target.value }]
        axios({
          method: 'post',
          url: mainurl + 'Geo/geo_pan_list',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            let list = response.data.map((row, index) => {
              return <option key={index} value={row.panchyat_code}> {row.panchyat_name} </option>
            })
    
            setPanchyatOpt(list);
          })
          .catch(error => {
            let notify = notification({ message: "Panchayat list not Found! Try Again", type: 'error' });
            notify();
          });
      }

      const getVillageList = (e) => {
        setPanchyat1(e.target.value);
        let postData = [{ "org_id": 100, "user_id": "128", "panchayat_id": e.target.value }]
        axios({
          method: 'post',
          url: mainurl + 'Geo/geo_village_list',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            let list = response.data.map((row, index) => {
                return <option key={index} value={row.village_code}> {row.village_name} </option>
            })
            setVillageOpt(list);
            
          })
          .catch(error => {
            let notify = notification({ message: "Village list not Found! Try Again", type: 'error' });
            notify();
          });
      }  
      // End
    const delete_handler = (row) => {

        let postData = [{ "org_id": 100, "geo_map_id": row.geo_map_id, "pg_id": row.pg_id }];
        axios({
          method: 'post',
          url: mainurl + 'Master/aps_deallocation',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            if(response.data[0].retcode === "101"){
              let notify = notification({ message: "Deleted Successfully!", type: 'success' });
              notify();
              get_allocated_pg_list();
            }else if(response.data[0].retcode === "102"){
              let notify = notification({ message: "First Allocate then Try delete this record.", type: 'warn' });
              notify();
            }
            
          })
          .catch(error => {
            let notify = notification({ message: "Failed! Try Again", type: 'error' });
            notify();
          });
    }

    const allocate_pg_handler = (e) => {
        e.preventDefault();

        let postData = [{
        "org_id": "100",
        "aps_id": "0",
        "aps_name": "",
        "aps_name_h": "",
        "mobile_no": "",
        "pg_id": data.pg_id,
        "dist_id": district1,
        "block_id": block1,
        "panchyat_code": panchyat1,
        "village_id": village1,
        }]
        setDistrict1('0');
        setBlock1('0');
        setPanchyat1('0');
        setVillage1('0');
    axios({
      method: 'post',
      url: mainurl + 'Master/pg_allocation',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {

        let notify = notification({ message: "Allocated successfully.", type: 'success' });
        notify();
        get_allocated_pg_list();
      })
      .catch(error => {
        let notify = notification({ message: "Allocation Failed! Try Again", type: 'error' });
        notify();
      });
    }

    return(
        <>
        { geoId !== "7" &&
            <Form onSubmit={edit_geographic_handler}>
                <Row>
                    {(geoId !== "1" && geoId !== "6") &&
                    <Col md={2}>
                        <Label for="district">District</Label>
                        <Input id="district" type='text' value={district} bsSize="sm" readOnly />
                    </Col>
                    }
                    {(geoId !== "1" && geoId !== "2" && geoId !== "6") &&
                    <Col xs={2} className="mb-1">
                        <Label for="block"> Block </Label>
                        <Input id="block" type='text' value={block} bsSize="sm" readOnly />
                    </Col>
                    }
                    {(geoId !== "1" && geoId !== "2" && geoId !== "3" && geoId !== "5" && geoId !== "6") &&
                    <Col xs={2} className="mb-1">
                        <Label for="panchyat"> Panchayat </Label>
                        <Input id="panchyat" type='text' value={panchyat} bsSize="sm" readOnly />
                    </Col>
                    }
                    { geoId !== "6" &&
                    <Col md={2}>
                        <Label for="code"> {geo} Code</Label>
                        <Input id="code" type='text' bsSize="sm" maxLength={10} value={geoCode} onChange={(e) => setGeoCode(e.target.value)} required />
                    </Col>
                    }
                    <Col md={2}>
                        <Label for="name"> {geo} Name</Label>
                        <Input id="name" type='text' bsSize="sm" maxLength={50} onKeyPress={(e) => !/^[a-zA-Z\s]*$/.test(e.key) && e.preventDefault()} value={geoName} onChange={(e) => setGeoName(e.target.value)} required />
                    </Col>
                    <Col md={2}>
                        <Label for="hName"> {geo} Name Hindi</Label>
                        <InputGroup size="sm">
                            <Input id="hName" type='text' maxLength={50} value={geoNameHindi} bsSize="sm" onChange={(e)=>setGeoNameHindi(e.target.value)} />
                            <InputGroupText className='bg-primary cursor' onClick={!hindiNameLoading ? translate_text_handler : undefined }>
                                {hindiNameLoading ? <Spinner color="secondary" size="sm">Loading...</Spinner> : <FaHeading color="#fff" />}
                            </InputGroupText>
                        </InputGroup>
                    </Col>
                    <Col md={2}>
                        <Button className='mt-4' color="primary" size="sm" >Update</Button>
                    </Col>
                </Row>
            </Form>
            }
            { geoId === "7" &&
            
                <Row>
                    <Col className='mb-3' md={3}>
                        <div className='d-flex justify-content-between p-1'>
                            <div><b>PG / VO Name</b></div>
                            <div>{data.pg_name_desc +" ( "+ data.pg_name_hindi +" )"}</div>
                        </div>
                    </Col>
                    <hr/>
                    <Col md={12}>
                    <Card>
                        <CardHeader>Allocated PG / VO List</CardHeader>
                        <CardBody>
                            <DataTable
                                title=""
                                columns={[
                                    {
                                    name: '#',
                                    selector: row=>row.sl,
                                    sortable: true,
                                    },
                                    {
                                    name: 'Pashusakhi Name',
                                    selector: row=>row.aps,
                                    sortable: false,
                                    },
                                    {
                                    name: 'District',
                                    selector: row=>row.district,
                                    sortable: false,
                                    },
                                    {
                                    name: 'Block',
                                    selector: row=>row.block,
                                    sortable: false,
                                    },
                                    {
                                    name: 'Panchayat',
                                    selector: row=>row.panchyat,
                                    sortable: false,
                                    },
                                    {
                                    name: 'Village',
                                    selector: row=>row.village,
                                    sortable: false,
                                    },
                                    {
                                    name: 'Action',
                                    selector: row=>row.action,
                                    sortable: false,
                                    },
                                ]}
                                data={allocatedPg}
                                striped
                                highlightOnHover
                                progressPending={loading}
                            />
                        </CardBody>
                    </Card>    
                    </Col>
                    <hr/>
                    <Col xs={12}>
                        <Card>
                            <CardHeader>Allocate New Village</CardHeader>
                            <CardBody>
                            <Form onSubmit={allocate_pg_handler}>
                            <Row>
                                <Col className='mb-1' xs={2}>
                                    <Label for="name">District</Label>
                                    <Input id="district" bsSize="sm" type="select" value={district1} onChange={(e) => getBlockList(e)}>
                                        <option value="0"> --Select District--</option>
                                        {districtOpt}
                                    </Input>
                                </Col>
                                
                                <Col xs={2} className="mb-1">
                                    <Label for="block"> Block </Label>
                                    <Input id="block" bsSize="sm" type="select" value={block1} onChange={(e) => getPanchyatList(e)}>
                                        <option value="0"> --Select Block--</option>
                                        {blockOpt}
                                    </Input>
                                </Col>
                                
                                <Col xs={2} className="mb-1">
                                    <Label for="panchyat"> Panchayat </Label>
                                    <Input id="panchyat" bsSize="sm" type="select" value={panchyat1} onChange={(e)=> getVillageList(e)} >
                                        <option value="0"> --Select Panchayat--</option>
                                        {panchyatOpt}
                                    </Input>
                                </Col>
                                
                                <Col xs={2} className="mb-1">
                                    <Label for="village"> Village </Label>
                                    <Input id="panchyat" bsSize="sm" type="select" value={village1} onChange={(e)=> setVillage1(e.target.value)} >
                                        <option value="0"> --Select Village--</option>
                                        {villageOpt}
                                    </Input>
                                </Col>
                                <Col md={2} className="mb-2">
                                    <Button className='mt-4' color='primary' size="sm"> Allocate </Button>
                                </Col>
                            </Row>
                        </Form>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            }
        </>
    )
}

export default EditDemographic;