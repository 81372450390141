import React, { useEffect, useState } from 'react';
import notification from '../../hocs/notification';
import axios from 'axios';
import mainurl from '../../../MainURL';
import {
  Col, Row, Card, CardHeader, CardBody,
} from 'reactstrap';
const imageUrl = 'https://microsave.boxfarming.in/v1/h7jj24643khb3589jn5dc4/';

const ViewShareholder = ({ data, ...props }) => {

  const [gender, setGender] = useState('');
  const [category, setCategory] = useState('');
  const [totalShare, setTotalShare] = useState('');
  const [farmerImg, setFarmerImg] = useState(null);
  const [idImg, setIdImg] = useState('');
  const [passbookImg, setPassbookImg] = useState('');

  useEffect(()=>{
    if(data.gender === "1"){
      setGender("Male");
    }else if(data.gender === "2"){
      setGender("Female");
    }else{
      setGender("-");
    }

    if(data.category_id === "1"){
      setCategory("BC");
    }else if(data.category_id === "2"){
      setCategory("BC-I");
    }else if(data.category_id === "3"){
      setCategory("BC-II");
    }else if(data.category_id === "4"){
      setCategory("EBC");
    }else if(data.category_id === "5"){
      setCategory("GEN");
    }else if(data.category_id === "6"){
      setCategory("OBC");
    }else if(data.category_id === "7"){
      setCategory("SC");
    }else if(data.category_id === "8"){
      setCategory("ST");
    }else{
      setCategory("-");
    } 

    get_init();
  },[])

  const get_init = () => {
    let postData = [{"org_id": 100, "f_id": data.f_id}];
    axios({
      method: 'post',
      url: mainurl + 'Master/box_farmer_share_count',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {

        if(response.data !== null){
          setTotalShare(response.data[0].total_share);
        }else{
          setTotalShare(0);
        }
         
      })
      .catch(error => {
        let notify = notification({ message: "submit failed! Try Again", type: 'error' });
        notify();
      });

    
    axios({
      method: 'post',
      url: mainurl + 'Master/box_farmer_document',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {

        if(response.data !== null){
          if(response.data[0].farmer_image !== ''){
            setFarmerImg(imageUrl + response.data[0].farmer_image);
          }else{
            setFarmerImg(null);
          }
          if(response.data[0].id_proof_image !== ''){
            setIdImg(<a href={imageUrl + response.data[0].id_proof_image} target="_blank" >Link</a>);
          }else{
            setIdImg('NA');
          }
          if(response.data[0].passbook_image !== ''){
            setPassbookImg(<a href={imageUrl + response.data[0].passbook_image} target="_blank" >Link</a>);
          }else{
            setPassbookImg('NA');
          }

        }else{
          setFarmerImg(null);
          setIdImg('NA');
          setPassbookImg('NA');
        };
         
      })
      .catch(error => {
        let notify = notification({ message: "submit failed! Try Again", type: 'error' });
        notify();
      });
  }

  return (
    <>
      <Row>
        <Col sm={4}>
          <Card>
            <CardHeader>General Information</CardHeader>
            <CardBody>
              {/*<div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>ID</b></div>
                <div className='m-0'>{data.f_id}</div>
              </div>*/}
              <div>
                <Row>
                  <Col className='mb-2' md={{
                    offset: 4,
                    order: 2,
                    size: 4
                  }} >
                    { farmerImg === null ?
                      <img className='profileImg img-fluid' src={require('../../../assets/img/user.png')} alt="Shareholder image" /> :
                      <img className='profileImg img-fluid' src={farmerImg} alt="Shareholder image" /> 
                    }
                  </Col>
                </Row>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Application Form Number</b></div>
                <div className='m-0'>{data.application_form_no}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Name</b></div>
                <div className='m-0'>{data.f_first_name}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Name (Hindi)</b></div>
                <div className='m-0'>{data.f_last_name}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Spouse Name</b></div>
                <div className='m-0'>{data.f_father_husband_name}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Spouse Name (Hindi)</b></div>
                <div className='m-0'>{data.f_husband_name_h}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Gender</b></div>
                <div className='m-0'>{gender}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Category</b></div>
                <div className='m-0'>{category}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Mobile Number</b></div>
                <div className='m-0'>{data.mobile_no === "0" ? "-" : data.mobile_no}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Alternate Mobile Number</b></div>
                <div className='m-0'>{data.mobile_no_2 === "0" ? "-" : data.mobile_no_2}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Email</b></div>
                <div className='m-0'>{data.email === "" ? "-" : data.email}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>ID Type</b></div>
                <div className='m-0'>{data.id_type === "0" ? "-" : data.id_type}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>ID Number</b></div>
                <div className='m-0'>{'XXXX-XXXX-' + (data.uid).slice(data.uid.length - 4)}</div>
              </div>
              
            </CardBody>
          </Card>
        </Col>
        <Col sm={4}>
          <Card>
            <CardHeader>Address Information</CardHeader>
            <CardBody>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>State</b></div>
                <div className='m-0'>{data.state_name}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>District</b></div>
                <div className='m-0'>{data.district_name}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Block</b></div>
                <div className='m-0'>{data.block_long_name}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Panchayat</b></div>
                <div className='m-0'>{data.panchyat_name}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Village</b></div>
                <div className='m-0'>{data.village_name}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>PG Name</b></div>
                <div className='m-0'>{data.pg_name_desc}</div>
              </div>
            </CardBody>
          </Card>
          <Card className='mt-3'>
            <CardHeader>Uploaded Document</CardHeader>
            <CardBody>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>ID Proof</b></div>
                <div className='m-0'>{idImg}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Bank Passbook</b></div>
                <div className='m-0'>{passbookImg}</div>
              </div>
            </CardBody>
          </Card>  
        </Col>
        <Col sm={4}>
          <Card>
            <CardHeader>Bank Information</CardHeader>
            <CardBody>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Account Holder Name</b></div>
                <div className='m-0'>{data.ac_holder_name}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Bank Name</b></div>
                <div className='m-0'>{data.bank_name}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Account Number</b></div>
                <div className='m-0'>{data.bank_account_no}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>IFS Code</b></div>
                <div className='m-0'>{data.ifsc_code}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Branch</b></div>
                <div className='m-0'>{data.branch_name}</div>
              </div>
              <div className="borderDashed mt-1 mb-1"></div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Nominee</b></div>
                <div className='m-0'>{data.nominee}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Nominee Relationship</b></div>
                <div className='m-0'>{data.nominee_relationship}</div>
              </div>
              <div className="borderDashed mt-1 mb-1"></div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Amount Deposit</b></div>
                <div className='m-0'>{data.amount_deposit}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Deposit Date</b></div>
                <div className='m-0'>-</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Folio Number</b></div>
                <div className='m-0'>{data.folio_no}</div>
              </div>
              {/*<div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Share Certificate From</b></div>
                <div className='m-0'>{data.share_certificate_from}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Share Certificate To</b></div>
                <div className='m-0'>{data.share_certificate_to}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Share Certificate No.</b></div>
                <div className='m-0'>{data.share_certificate_no}</div>
              </div>*/}
              
              
            </CardBody>
          </Card>
          <Card className='mt-3'>
            <CardHeader>Share Information</CardHeader>
            <CardBody>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Total Share</b></div>
                <div className='m-0'>{totalShare}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Share Amount per Unit</b></div>
                <div className='m-0'>&#8377; 500</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Total Share Amount</b></div>
                <div className='m-0'>&#8377; {Number(totalShare) * 500}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Total Dividend Received</b></div>
                <div className='m-0'>&#8377; 0</div>
              </div>
            </CardBody>
          </Card>  
        </Col>
      </Row>
    </>
  )
}

export default ViewShareholder;