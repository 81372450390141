import React, {useState, useEffect} from 'react';
import notification from '../../hocs/notification';
import axios from 'axios';
import mainurl from '../../../MainURL';
import {
    Col, Row, Button, Input, Label, Form, InputGroup, InputGroupText, Spinner, Card, CardHeader, 
    CardBody, CardFooter
} from 'reactstrap';

import { FaHeading} from 'react-icons/fa';

const AddVendor = ({back_handler, ...props}) => {
    const [loading, setLoading] = useState(false);
    const [hindiNameLoading, setHindiNameLoading] = useState(false);
    const [name, setName] = useState("");
    const [nameHin, setNameHin] = useState("");
    const [contactPerson, setContactPerson] = useState("");
    const [landlineNo, setLandlineNo] = useState("");
    const [mobileNo, setMobileNo] = useState("");
    const [pan, setPan] = useState("");
    const [gstin, setGstin] = useState("");
    const [cin, setCin] = useState("");
    const [tanNo, setTanNo] = useState("");
    const [email, setEmail] = useState("");
    const [website, setWebsite] = useState("");
    
    const [state, setState] = useState("");
    const [district, setDistrict] = useState("");
    const [city, setCity] = useState("");
    const [address, setAddress] = useState("");
    const [pincode, setPincode] = useState("");
    
    const [bankName, setBankName] = useState("");
    const [accountNo, setAccountNo] = useState("");
    const [accountType, setAccountType] = useState("");
    const [ifsc, setIfsc] = useState("");
    const [branchAddress, setBranchAddress] = useState("");

    const [bankOpt, setBankOpt] = useState("");
    const [districtOpt, setDistrictOpt] = useState("");

    useEffect(() => {
        get_init();
        getDistrictList();
    }, []);

    const get_init = () => {
      let postData = [{ "org_id": 100, "user_id": "0"}];
        // Bank list  
        axios({
          method: 'post',
          url: mainurl + 'Master/bank_list',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            let list = response.data.map((row, index) => {
              return <option key={index} value={row.bank_id}> {row.bank_name} </option>
            })
            setBankOpt(list);
           
          })
          .catch(error => {
            let notify = notification({ message: "Bank list not Found! Try Again", type: 'error' });
            notify();
          });
    }

    // district, block, panchayat, village API call
    const getDistrictList = () => {
    
        let postData = [{ "org_id": 100, "user_id": "0" }];
        axios({
          method: 'post',
          url: mainurl + 'Geo/geo_district_list2',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            let list = response.data.map((row, index) => {
              return <option key={index} value={row.district_short_cd}> {row.district_name} </option>
            })
            setDistrictOpt(list);
           
          })
          .catch(error => {
            let notify = notification({ message: "District list not Found! Try Again", type: 'error' });
            notify();
          });
      }
      
      // End
 
    
      const translate_text_handler = (e) => {
        setHindiNameLoading(true);
        if(name.length > 1){
            
            let postData = [{ "t_text" : name}];
            axios({
                method: 'post',
                url: mainurl + 'Master/translate_text',
                data: postData,
                headers: { 'Content-Type': 'application/JSON', }
              })
                .then(function (response) {
                    let trans = response.data;
                    setNameHin(trans);
                    setHindiNameLoading(false);
                })
                .catch(error => {
                    setHindiNameLoading(false);
                    let notify = notification({ message: "Hindi Translation is not working! Try Again", type: 'error' });
                    notify();
                  });

        }else{
            setHindiNameLoading(false);
            let notify = notification({ message: "Please enter geographic english name first.", type: 'warn' });
            notify();
        }
    }

    const add_handler = (e) => {
        setLoading(true);
        e.preventDefault();

        let postData = [
            {
                "org_id": "100",
                "user_id":"128",
                "vandor_name_desc": name, 
                "vandor_name_hindi": nameHin, 
                "contact_person": contactPerson, 
                "landline_no": landlineNo, 
                "mobile_no": mobileNo, 
                "pan_no": pan, 
                "gstin": gstin, 
                "cin": cin, 
                "tan_no": tanNo, 
                "email_id": email, 
                "website": website, 
                "state_id": state, 
                "dist_id": district, 
                "city": city, 
                "pincode": pincode, 
                "address": address, 
                "bank_id": bankName, 
                "account_no": accountNo, 
                "account_type": accountType, 
                "ifsc_code": ifsc, 
                "branch": branchAddress 
            }
        ];

        console.log(postData);

        axios({
            method: 'post',
            url: mainurl + 'Master/box_vandor_add',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
          })
            .then(function (response) {
                if(response.data[0].retcode === "101"){
                    setLoading(false);
                    let notify = notification({ message: "Added successfully", type: 'success' });
                    notify();
                    back_handler();
                }else if(response.data[0].retcode === "102"){
                    setLoading(false);
                    let notify = notification({ message: "GSTIN Number already exists.", type: 'error' });
                    notify();
                }
              
            })
            .catch(error => {
                setLoading(false);
              let notify = notification({ message: "submit failed! Try Again", type: 'error' });
              notify();
            });

    }


    return(
        <Card>
            <CardHeader>Add New Supplier</CardHeader>
            <CardBody>
                <Form onSubmit={add_handler}>
                    <Row>
                        <Col md={2} className="mb-2">
                            <Label for="name"> Supplier Name</Label>
                            <Input id="name" type='text' bsSize="sm" maxLength={50} onKeyPress={(e) => !/^[a-zA-Z\s]*$/.test(e.key) && e.preventDefault()} value={name} onChange={(e) => setName(e.target.value)} required />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="hName"> Supplier Name (Hindi)</Label>
                            <InputGroup size="sm">
                                <Input id="hName" type='text' maxLength={50} value={nameHin} bsSize="sm" onChange={(e)=>setNameHin(e.target.value)} />
                                <InputGroupText className='bg-primary cursor' onClick={!hindiNameLoading ? translate_text_handler : undefined }>
                                    {hindiNameLoading ? <Spinner color="secondary" size="sm">Loading...</Spinner> : <FaHeading color="#fff" />}
                                </InputGroupText>
                            </InputGroup>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="contact_person"> Contact Person </Label>
                            <Input id="contact_person" type='text' bsSize="sm" maxLength={50} onKeyPress={(e) => !/^[a-zA-Z\s]*$/.test(e.key) && e.preventDefault()} value={contactPerson} onChange={(e) => setContactPerson(e.target.value)} required />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="landline_no"> Landline Number</Label>
                            <Input id="landline_no" type='text'  maxLength={10} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} bsSize="sm" value={landlineNo} onChange={(e) => setLandlineNo(e.target.value)} required />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="name"> Mobile Number</Label>
                            <Input id="name" type='text' maxLength={10} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} bsSize="sm" value={mobileNo} onChange={(e) => setMobileNo(e.target.value)} required />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="name"> PAN Number</Label>
                            <Input id="name" type='text' bsSize="sm"  maxLength={16}  value={pan} onChange={(e) => setPan(e.target.value)} required />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="gstin"> GSTIN</Label>
                            <Input id="gstin" type='text' bsSize="sm" maxLength={25} value={gstin} onChange={(e) => setGstin(e.target.value)} required />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="cin"> CIN</Label>
                            <Input id="cin" type='text' bsSize="sm" maxLength={25} value={cin} onChange={(e) => setCin(e.target.value)} required />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="tan_no"> TAN Number</Label>
                            <Input id="tan_no" type='text' bsSize="sm" maxLength={25} value={tanNo} onChange={(e) => setTanNo(e.target.value)} required />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="email"> Email </Label>
                            <Input id="email" type='email' bsSize="sm" value={email} onChange={(e) => setEmail(e.target.value)} required />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="website"> Website </Label>
                            <Input id="website" type='text' bsSize="sm" value={website} onChange={(e) => setWebsite(e.target.value)} required />
                        </Col>
                        
                        <hr />
                        <Col md={2} className="mb-2">
                            <Label for="state"> State </Label>
                            <Input id="state" bsSize="sm" type="select" value={state} onChange={(e)=> setState(e.target.value)} required>
                                <option value=""> --Select State--</option>
                                <option value="10"> BIHAR </option>
                                
                            </Input>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="district"> District </Label>
                            <Input id="district" bsSize="sm" type="select" value={district} onChange={(e)=> setDistrict(e.target.value)} required>
                                <option value=""> --Select District--</option>
                                {districtOpt}
                            </Input>
                        </Col>
                        
                        <Col md={2} className="mb-2">
                            <Label for="city"> City </Label>
                            <Input id="city" type='text' bsSize="sm" maxLength={50} onKeyPress={(e) => !/^[a-zA-Z\s]*$/.test(e.key) && e.preventDefault()} value={city} onChange={(e) => setCity(e.target.value)} required />
                        </Col>
                        <hr />
                        <Col md={2} className="mb-2">
                            <Label for="address"> Address </Label>
                            <Input id="address" type='text' bsSize="sm" maxLength={150} value={address} onChange={(e) => setAddress(e.target.value)} required />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="pincode"> Pincode </Label>
                            <Input id="pincode" type='text' bsSize="sm" maxLength={6} value={pincode} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} onChange={(e) => setPincode(e.target.value)} required />
                        </Col>

                        <hr />

                        <Col md={2} className="mb-2">
                            <Label for="bankName"> Bank Name </Label>
                            <Input id="bankName" bsSize="sm" type="select" value={bankName} onChange={(e)=> setBankName(e.target.value)} required>
                                <option value=""> --Select Bank--</option>
                                {bankOpt}
                            </Input>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="accountNo"> Account Number </Label>
                            <Input id="accountNo" type='text' bsSize="sm" maxLength={20} value={accountNo} onChange={(e) => setAccountNo(e.target.value)} required />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="accountType"> Account Type </Label>
                            <Input id="accountType" bsSize="sm" type="select" value={accountType} onChange={(e)=> setAccountType(e.target.value)} required>
                                <option value=""> --Select Account Type--</option>
                                <option value="saving"> Saving </option>
                                <option value="current"> Current </option>
                            </Input>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="ifsc"> IFSC Code </Label>
                            <Input id="ifsc" type='text' bsSize="sm" maxLength={15} value={ifsc} onChange={(e) => setIfsc(e.target.value)} required />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="branchAddress"> Branch Address </Label>
                            <Input id="branchAddress" type='text' bsSize="sm" maxLength={50} value={branchAddress} onChange={(e) => setBranchAddress(e.target.value)} required />
                        </Col>

                        <hr />
                        <Col md={2} className="mb-2">
                            <Button color='primary' size="sm" block disabled= {loading}> Submit {loading && <Spinner color="secondary ms-1" size="sm" >Load...</Spinner>} </Button>
                        </Col>
                    </Row>

                </Form>
            </CardBody>
            <CardFooter className='text-end'>
                <Button color='primary' size="sm" onClick={back_handler}> Back </Button>
            </CardFooter>
        </Card>
    )
}

export default AddVendor;