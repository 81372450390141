import React, { useState, useEffect } from 'react';
import notification from '../../hocs/notification';
import axios from 'axios';
import mainurl from '../../../MainURL';
import {
  Col, Row, Button, Input, Label, Form, InputGroup, InputGroupText, Spinner, Card, CardHeader, CardBody, CardFooter
} from 'reactstrap';

import { FaHeading } from 'react-icons/fa';

const EditPashusakhi = ({ back_handler, data, ...props }) => {

  const [code, setCode] = useState(data.aps_id);
  const [name, setName] = useState(data.aps_name);
  const [nameHin, setNameHin] = useState(data.aps_name_h);
  const [hindiNameLoading, setHindiNameLoading] = useState(false);
  const [mobileNo, setMobileNo] = useState(data.mobile_no);
  

  useEffect(() => {
    
  }, []);

  const translate_text_handler = (e) => {
    setHindiNameLoading(true);
    if (name.length > 1) {
      
      let postData = [{ "t_text" : name}];
      axios({
        method: 'post',
        url: mainurl + 'Master/translate_text',
        data: postData,
        headers: { 'Content-Type': 'application/JSON', }
      })
        .then(function (response) {
          let trans = response.data;
          setNameHin(trans);
          setHindiNameLoading(false);
        })
        .catch(error => {
          setHindiNameLoading(false);
          let notify = notification({ message: "Hindi Translation is not working! Try Again", type: 'error' });
          notify();
        });

    } else {
      setHindiNameLoading(false);
      let notify = notification({ message: "Please enter english name first.", type: 'warn' });
      notify();
    }
  }

  const edit_handler = (e) => {
    e.preventDefault();

    let postData = [{
      "org_id": "100",
      "aps_id": code,
      "aps_name": name,
      "aps_name_h": nameHin,
      "mobile_no": mobileNo,
    }]

    console.log(postData);

    axios({
      method: 'post',
      url: mainurl + 'Master/box_master_aps_edit',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {

        let notify = notification({ message: "Updated successfully.", type: 'success' });
        notify();
        back_handler()
      })
      .catch(error => {
        let notify = notification({ message: "Update Failed! Try Again", type: 'error' });
        notify();
      });
  }

  return (
    <Card>
      <CardHeader>Edit Pashusakhi</CardHeader>
      <CardBody>
        <Form onSubmit={edit_handler}>
          <Row>
            <Col md={2} className="mb-2">
              <Label for="code"> Pashusakhi Code</Label>
              <Input id="code" type='text' bsSize="sm" value={code}  readOnly />
            </Col>
            <Col md={2} className="mb-2">
              <Label for="name"> Pashusakhi Name</Label>
              <Input id="name" type='text' bsSize="sm" onKeyPress={(e) => !/^[a-zA-Z\s]*$/.test(e.key) && e.preventDefault()} value={name} onChange={(e) => setName(e.target.value)} required />
            </Col>
            <Col md={2} className="mb-2">
              <Label for="hName"> Pashusakhi Name Hindi</Label>
              <InputGroup size="sm">
                <Input id="hName" type='text' value={nameHin} bsSize="sm" onChange={(e) => setNameHin(e.target.value)} />
                <InputGroupText className='bg-primary cursor' onClick={!hindiNameLoading ? translate_text_handler : undefined}>
                  {hindiNameLoading ? <Spinner color="secondary" size="sm">Loading...</Spinner> : <FaHeading color="#fff" />}
                </InputGroupText>
              </InputGroup>
            </Col>
            <Col md={2} className="mb-2">
              <Label for="mobileNo"> Mobile Number</Label>
              <Input id="mobileNo" type='text' bsSize="sm" maxLength={10} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} value={mobileNo} onChange={(e) => setMobileNo(e.target.value)} required />
            </Col>
            <hr />
            <Col md={2} className="mb-2">
              <Button color='primary' size="sm" block> Update </Button>
            </Col>
          </Row>
        </Form>
      </CardBody>
      <CardFooter className='text-end'>
        <Button color='primary' size="sm" onClick={back_handler}> Back </Button>
      </CardFooter>
    </Card>
  )
}

export default EditPashusakhi;