import React, {useState, useEffect} from 'react';
import notification from '../../hocs/notification';
import axios from 'axios';
import mainurl from '../../../MainURL';
import {
    Col, Row, Button, Input, Label, Form, Spinner, Card, CardHeader, CardBody, CardFooter
} from 'reactstrap';

const AddUser = ({back_handler, ...props}) => {
    const [loading, setLoading] = useState(false);
    const [userName, setUserName] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('');
    const [district, setDistrict] = useState("0");
    const [block, setBlock] = useState("0");

    const [districtOpt, setDistrictOpt] = useState();
    const [blockOpt, setBlockOpt] = useState();
    const [varify, setVarify] = useState(false);

    useEffect(() => {
        getDistrictList();
    }, []);

    const getDistrictList = () => {
        setBlock("0");

        let postData = [{ "org_id": 100, "user_id": "0" }];
        axios({
            method: 'post',
            url: mainurl + 'Geo/geo_district_list2',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return <option key={index} value={row.district_short_cd}> {row.district_name} </option>
                })
                setDistrictOpt(list);
            })
            .catch(error => {
                let notify = notification({ message: "District list not Found! Try Again", type: 'error' });
                notify();
            });
    }

    const getBlockList = (e) => {
        setDistrict(e.target.value);
        setBlock("0");
        let postData = [{ "org_id": 100, "user_id": "0", "dist_id": e.target.value }]
        axios({
            method: 'post',
            url: mainurl + 'Geo/geo_block_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return <option key={index} value={row.block_code}> {row.block_long_name} </option>
                })
                setBlockOpt(list);
            })
            .catch(error => {
                let notify = notification({ message: "Block list not Found! Try Again", type: 'error' });
                notify();
            });
    }

    const role_handler = (e) => {
        setRole(e.target.value);
        setDistrict("0");
        setBlock("0");
    }
    const varifyPassword = (e) => {
        if(e.target.value === password ){
            setVarify(true);
        }else{
            setVarify(false);
        }
    }

    const add_handler = (e) => {
        e.preventDefault();
        setLoading(true);
        let postData = [{
            "org_id": "100",
            "user_pwd": password,
            "user_name_desc": userName,
            "user_role": role,
            "mobile_no": mobileNo,
            "email_id": email,
            "address" : address,
            "state_id": 0,
            "dist_id": district,
            "block_id": block,

        }];

        console.log(postData);
        axios({
            method: 'post',
            url: mainurl + 'Master/box_user_add',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let notify = notification({ message: "Added successfully.", type: 'success' });
                notify();
                back_handler();
                setLoading(false);
            })
            .catch(error => {
                let notify = notification({ message: "Submit Failed! Try Again", type: 'error' });
                notify();
                setLoading(false);
            });
    }

    return (
        <Card>
            <CardHeader>Add User</CardHeader>
            <CardBody>
                <Form onSubmit={add_handler}>
                    <Row>
                        <Col xs={12} >
                            <h6>User Information</h6>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="name"> User Name</Label>
                            <Input id="name" type='text' bsSize="sm" value={userName} onChange={(e) => setUserName(e.target.value)} required />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="mobileNo"> Mobile Number</Label>
                            <Input id="mobileNo" type='text' bsSize="sm" value={mobileNo} onChange={(e) => setMobileNo(e.target.value)} required />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="email"> Email</Label>
                            <Input id="email" type='email' bsSize="sm" value={email} onChange={(e) => setEmail(e.target.value)} required />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="address"> Address</Label>
                            <Input id="address" type='text' bsSize="sm" value={address} onChange={(e) => setAddress(e.target.value)} required />
                        </Col>

                        <Col xs={12} className="mt-3">
                            <h6>Set Role</h6>
                        </Col>

                        <Col md={2} className="mb-2">
                            <Label for="role"> Role</Label>
                            <Input id="role" bsSize="sm" type="select" value={role} onChange={role_handler}>
                                <option value=""> --Select Role--</option>
                                <option value="101"> Admin </option>
                                <option value="104"> FPC </option>
                                <option value="102"> District </option>
                                <option value="103"> Block </option>
                            </Input>
                        </Col>

                        {(role === "102" || role === "103") &&
                            <Col md={2}>
                                <Label for="name">District</Label>
                                <Input id="district" bsSize="sm" type="select" value={district} onChange={(e) => getBlockList(e)}>
                                    <option value="0"> --Select District--</option>
                                    {districtOpt}
                                </Input>
                            </Col>
                        }
                        {role === "103" &&
                            <Col xs={2} className="mb-1">
                                <Label for="block"> Block </Label>
                                <Input id="block" bsSize="sm" type="select" value={block} onChange={(e) => setBlock(e.target.value)}>
                                    <option value="0"> --Select Block--</option>
                                    {blockOpt}
                                </Input>
                            </Col>
                        }

                        <Col xs={12} className="mt-3">
                            <h6>Set Password</h6>
                        </Col>

                        <Col md={2} className="mb-2">
                            <Label for="password"> Password</Label>
                            <Input id="password" type='password' bsSize="sm" value={password} onChange={(e) => setPassword(e.target.value)} required />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="crfPassword"> Confirm Password</Label>
                            <Input id="crfPassword" type='password' bsSize="sm" invalid={!varify} valid={varify} onChange={varifyPassword} required />
                        </Col>


                        <Col md={2} className="mb-2">
                            <Button className='mt-4' color="primary" size="sm" disabled= {loading}>Add {loading && <Spinner color="secondary ms-1" size="sm" >Load...</Spinner>} </Button>
                        </Col>

                    </Row>
                </Form>

            </CardBody>
            <CardFooter className='text-end'>
                <Button color='primary' size="sm" onClick={back_handler}> Back </Button>
            </CardFooter>
        </Card>

    )
}
export default AddUser;