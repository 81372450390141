import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/utility/Page';
import { Doughnut } from 'react-chartjs-2';
import notification from '../../component/hocs/notification';
import MapView from '../../component/utility/MapView';
import TopList from '../../component/utility/TopList';
import DataTable from 'react-data-table-component';
import { getCurrentMonthYear } from '../../component/hocs/dateServices';

import * as XLSX from 'xlsx/xlsx.mjs';
import {SiMicrosoftexcel} from 'react-icons/si';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import mainurl from '../../MainURL';

import { Row, Col, Card, CardHeader, CardBody, Button, FormGroup, Label, Input } from 'reactstrap';

const ShedVisits = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState("");
  const [mainData, setMainData] = useState(null);
  const [barColor, setBarColor] = useState(null);
  const [barData, setBarData] = useState(null);
  const [barLabels, setBarLabels] = useState(null);
  const [graphLabel, setGraphLabel] = useState(null);
  const [ranking, setRanking] = useState(null);
  const [location, setLocation] = useState("District");
  const [districtOpt, setDistrictOpt] = useState();
  const [blockOpt, setBlockOpt] = useState();
  const [panchyatOpt, setPanchyatOpt] = useState();
  const [district, setDistrict] = useState("");
  const [block, setBlock] = useState("");
  const [panchyat, setPanchyat] = useState("");
  const [monthYear, setMonthYear] = useState(getCurrentMonthYear());

  const [dist_role, setDist_role] = useState(false);
  const [block_role, setBlock_role] = useState(false);

  const [chartTotal, setChartTotal] = useState('');
  const [chartLoad, setChartLoad] = useState(false);

useEffect(() => {
  // check Access
  let accData = JSON.parse(sessionStorage.getItem('accessControl'));
    
  if(accData[1].pages[1].page_view !== "1"){
    navigate("/home");
  }
  //
  checkRole();
}, [monthYear]);

const checkRole = () => {
  let role = sessionStorage.getItem('role');
  if( role === "101" ){
    init_start();
  }else if( role === "102"){
    let dist = sessionStorage.getItem('dist');
    getDistrictOpt(dist);
    setDist_role(true);
    getBlockList({target:{value: dist}});
  }else if( role === "103"){
    let dist = sessionStorage.getItem('dist');
    let block = sessionStorage.getItem('block');
    setDist_role(true);
    setBlock_role(true);
    getDistrictOpt(dist);
    getBlockOpt({target:{value: dist}}, block);
    getPanchyatList({target:{value: block}});

  }
}

// get geo list
const getDistrictOpt = (dist) => {
  let postData = [{ "org_id": 100, "user_id": "0" }];
  axios({
    method: 'post',
    url: mainurl + 'Geo/geo_district_list2',
    data: postData,
    headers: { 'Content-Type': 'application/JSON', }
  })
    .then(function (response) {
      let list = response.data.map((row, index) => {
        return <option key={index} value={row.district_short_cd}> {row.district_name} </option>
      })
      setDistrictOpt(list);
      setDistrict(dist);
    })
    .catch(error => {
      let notify = notification({ message: "District list not Found! Try Again", type: 'error' });
      notify();
    });
}

const getBlockOpt = (e, block) => {
  let postData = [{ "org_id": 100, "user_id": "0", "dist_id": e.target.value }]
  axios({
    method: 'post',
    url: mainurl + 'Geo/geo_block_list',
    data: postData,
    headers: { 'Content-Type': 'application/JSON', }
  })
    .then(function (response) {
      let list = response.data.map((row, index) => {
        return <option key={index} value={row.block_code}> {row.block_long_name} </option>
      })
      setBlockOpt(list);
      setBlock(block);
    })
    .catch(error => {
      let notify = notification({ message: "Block list not Found! Try Again", type: 'error' });
      notify();
    });
}

//

  const init_start = () => {
    setChartLoad(true);
    setDistrict("");
    setBlock("");
    setPanchyat("");
    setLoading(true);

    let year = new Date(monthYear).getFullYear();
    let month = new Date(monthYear).getMonth() + 1;
    let postData = [{ "org_id": 100, "user_id": 128, "ops_type": 7, "year_id": year, "month_id": month, "geo_type": 0, "geo_id": 0 }]
    axios({
      method: 'post',
      url: mainurl + 'Geo/g2',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        setMainData(response.data);
        // table data
        let tableData = response.data.map((row, index) => {
          return (
            {
              SRL: index + 1,
              Location: row.district_name,
              ShedVisits: Math.round(row.actual_value)
            }
          )
        })
        setTableData(tableData);

        //graph data
        let barColor = [];
        let barData = [];
        let barLabels = [];
        let checkLabel = true;
        let total = 0;
        let ranking = response.data.map((row, index) => {
          if (index > 5) {
            checkLabel = false;
          }
          let color = "";
          if ((row.district_name).toUpperCase() === "ARARIA") {
            barColor.push("#0052cc");
            color = "#0052cc";
          } else if (row.district_name.toUpperCase() === "KATIHAR") {
            barColor.push("#fba10f");
            color = "#fba10f";
          } else if (row.district_name.toUpperCase() === "KISHANGANJ") {
            barColor.push("#26c287");
            color = "#26c287";
          } else if (row.district_name.toUpperCase() === "PURNIA") {
            barColor.push("#dc3912");
            color = "#dc3912";
          }
          barData.push(Math.round(row.actual_value));
          barLabels.push(row.district_name);
          total = total + Math.round(row.actual_value);
          return (
            {
              name: row.district_name,
              value: Math.round(row.actual_value),
              color: color,
            }
          )
        })

        let sortRanking = ranking.sort(function (a, b) {
          return b.value - a.value;
        });
        let maxVal;
        let finalRanking = sortRanking.map((row, index) => {

          if (index === 0) {
            maxVal = row.value;
          }
          return (
            <TopList name={row.name} rank={index + 1} value={row.value} max={maxVal} color={row.color} />
          )
        })

        let districtOpt = response.data.map((row, index) => {
          return (
            <option key={index} value={row.dist_id}> {row.district_name} </option>
          )
        })
        setDistrictOpt(districtOpt);
        setLoading(false);

        setBarColor(barColor);
        setBarData(barData);
        if(total > 0 ){
          setChartTotal(total);
        }else{
          setChartTotal("No Data Found!");
        }
        setChartLoad(false);
        setBarLabels(barLabels);
        setGraphLabel(checkLabel);
        setRanking(finalRanking);
        setLocation("District");
        //Ranking

      }).catch(error => {
        setTableData("");
        setLoading(false);
        //let notify = notification({ message: "Data not Found! Try Again", type: 'error' });
        //notify();
      });
  }

  const dynamicColors = () => {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    return "rgb(" + r + "," + g + "," + b + ")";
  };

  const getBlockList = (e) => {
    setChartLoad(true);
    setLoading(true);
    setDistrict(e.target.value);
    setBlock("");
    setPanchyat("");

    let year = new Date(monthYear).getFullYear();
    let month = new Date(monthYear).getMonth() + 1;
    let postData = [{ "org_id": 100, "user_id": 128, "ops_type": 7, "year_id": year, "month_id": month, "geo_type": 1, "geo_id": e.target.value }]

    axios({
      method: 'post',
      url: mainurl + 'Geo/g2',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        // table data
        console.log(response.data)
        let tableData = response.data.map((row, index) => {
          return (
            {
              SRL: index + 1,
              Location: row.block_long_name,
              ShedVisits: Math.round(row.actual_value)
            }
          )
        })

        setTableData(tableData);

        //graph data
        let barColor = [];
        let barData = [];
        let barLabels = [];
        let checkLabel = true;
        let total = 0;
        let ranking = response.data.map((row, index) => {
          if (index > 5) {
            checkLabel = false;
          }
          let color = dynamicColors();
          barColor.push(color);

          barData.push(Math.round(row.actual_value));
          barLabels.push(row.block_long_name);
          total = total + Math.round(row.actual_value);
          return (
            {
              name: row.block_long_name,
              value: Math.round(row.actual_value),
              color: color
            }
          )
        })

        let sortRanking = ranking.sort(function (a, b) {
          return b.value - a.value;
        });
        let maxVal;
        let finalRanking = sortRanking.map((row, index) => {

          if (index === 0) {
            maxVal = row.value;
          }
          return (
            <TopList name={row.name} rank={index + 1} value={row.value} max={maxVal} color={row.color} />
          )
        })

        let blockOpt = response.data.map((row, index) => {
          return (
            <option key={index} value={row.block_id}> {row.block_long_name} </option>
          )
        })

        setBlockOpt(blockOpt);
        setLoading(false);
        //
        setBarColor(barColor);
        setBarData(barData);
        if(total > 0 ){
          setChartTotal(total);
        }else{
          setChartTotal("No Data Found!");
        }
        setChartLoad(false);
        setBarLabels(barLabels);
        setGraphLabel(checkLabel);
        setRanking(finalRanking);
        setLocation("Block");
        //Ranking

      }).catch(error => {
        setTableData("");
        setLoading(false);
        //let notify = notification({ message: "Data not Found! Try Again", type: 'error' });
        //notify();
      });
  }
  const getPanchyatList = (e) => {
    setChartLoad(true);
    setLoading(true);
    setBlock(e.target.value);
    setPanchyat("");
    let year = new Date(monthYear).getFullYear();
    let month = new Date(monthYear).getMonth() + 1;
    let postData = [{ "org_id": 100, "user_id": 128, "ops_type": 7, "year_id": year, "month_id": month, "geo_type": 2, "geo_id": e.target.value }]

    axios({
      method: 'post',
      url: mainurl + 'Geo/g2',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        // table data

        let tableData = response.data.map((row, index) => {
          return (
            {
              SRL: index + 1,
              Location: row.panchyat_name,
              ShedVisits: Math.round(row.actual_value)
            }
          )
        })
        setTableData(tableData)

        //graph data
        let barColor = [];
        let barData = [];
        let barLabels = [];
        let checkLabel = true;
        let total = 0;
        let ranking = response.data.map((row, index) => {
          if (index > 5) {
            checkLabel = false;
          }
          let color = dynamicColors();
          barColor.push(color);

          barData.push(Math.round(row.actual_value));
          barLabels.push(row.panchyat_name);
          total = total + Math.round(row.actual_value);
          return (
            {
              name: row.panchyat_name,
              value: Math.round(row.actual_value),
              color: color
            }
          )
        })

        let sortRanking = ranking.sort(function (a, b) {
          return b.value - a.value;
        });
        let maxVal;
        let finalRanking = sortRanking.map((row, index) => {

          if (index === 0) {
            maxVal = row.value;
          }
          return (
            <TopList name={row.name} rank={index + 1} value={row.value} max={maxVal} color={row.color} />
          )
        })

        let panchayatOpt = response.data.map((row, index) => {
          return (
            <option key={index} value={row.panchayat_id}> {row.panchyat_name} </option>
          )
        })
        setPanchyatOpt(panchayatOpt);
        setLoading(false);
        //
        setBarColor(barColor);
        setBarData(barData);
        if(total > 0 ){
          setChartTotal(total);
        }else{
          setChartTotal("No Data Found!");
        }
        setChartLoad(false);
        setBarLabels(barLabels);
        setGraphLabel(checkLabel);
        setRanking(finalRanking);
        setLocation("Panchayat");

        //Ranking

      }).catch(error => {
        setTableData("");
        setLoading(false);
        //let notify = notification({ message: "Data not Found! Try Again", type: 'error' });
        //notify();
      });
  }

  const getVillageList = (e) => {
    setChartLoad(true);
    setLoading(true);
    setPanchyat(e.target.value);
    let year = new Date(monthYear).getFullYear();
    let month = new Date(monthYear).getMonth() + 1;
    let postData = [{ "org_id": 100, "user_id": 128, "ops_type": 7, "year_id": year, "month_id": month, "geo_type": 3, "geo_id": e.target.value }]

    axios({
      method: 'post',
      url: mainurl + 'Geo/g2',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        // table data
        console.log(response.data)
        let tableData = response.data.map((row, index) => {
          return (
            {
              SRL: index + 1,
              Location: row.village_name,
              ShedVisits: Math.round(row.actual_value)
            }
          )
        })

        setTableData(tableData)

        //graph data
        let barColor = [];
        let barData = [];
        let barLabels = [];
        let checkLabel = true;
        let total = 0;
        let ranking = response.data.map((row, index) => {
          if (index > 5) {
            checkLabel = false;
          }
          let color = dynamicColors();
          barColor.push(color);

          barData.push(Math.round(row.actual_value));
          barLabels.push(row.village_name);
          total = total + Math.round(row.actual_value);
          return (
            {
              name: row.village_name,
              value: Math.round(row.actual_value),
              color: color
            }
          )
        })

        let sortRanking = ranking.sort(function (a, b) {
          return b.value - a.value;
        });
        let maxVal;
        let finalRanking = sortRanking.map((row, index) => {

          if (index === 0) {
            maxVal = row.value;
          }
          return (
            <TopList name={row.name} rank={index + 1} value={row.value} max={maxVal} color={row.color} />
          )
        })


        setLoading(false);
        //
        setBarColor(barColor);
        setBarData(barData);
        if(total > 0 ){
          setChartTotal(total);
        }else{
          setChartTotal("No Data Found!");
        }
        setChartLoad(false);
        setBarLabels(barLabels);
        setGraphLabel(checkLabel);
        setRanking(finalRanking);
        setLocation("Village");

        //Ranking

      }).catch(error => {
        setTableData("");
        setLoading(false);
        //let notify = notification({ message: "Data not Found! Try Again", type: 'error' });
        //notify();
      });
  }
  const download_excel_handler = () => {
    if(tableData.length > 0){
      let wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(tableData);

      XLSX.utils.book_append_sheet(wb, ws, "Mysheet1");

      XLSX.writeFile(wb, "ShedVisits.xlsx");
    }
  }

  return (
    <div>
      <Header />
      <Page title="Shed Visits" breadcrumbs="Shareholder Services / Shed Visits">
        <div style={{ width: 'auto', position: 'absolute', top: '60px', right: '20px' }}>
          <Input
            type="month"
            bsSize="sm"
            defaultValue={monthYear}
            onChange={(e) => setMonthYear(e.target.value)}
            style={{ width: '165px' }}
          />
        </div>
        <Row className='p-3'>
          <Col md={4}>
            <Card>
              <CardHeader>Graph View <small className="text-muted text-capitalize"> In Last 1 Month</small></CardHeader>
              <CardBody className="fixHeight">
                <Row className='text-center'>
                  <Col xs={{
                    offset: 1,
                    size: 9
                  }}>
                    {!chartLoad && <Doughnut
                      data={{
                        maintainAspectRatio: true,
                        responsive: true,
                        labels: barLabels,
                        datasets: [
                          {
                            data: barData,
                            backgroundColor: barColor
                          }
                        ]
                      }}
                      options={{
                        legend: {
                          display: graphLabel,
                          position: "bottom",

                        },
                        responsive: true,
                        elements: {
                          arc: {
                            borderWidth: 0
                          }
                        },
                      }}
                      plugins={[{
                        beforeDraw: function (chart) {
                          let width = chart.width;
                          let height = chart.chartArea.height;
                          let ctx = chart.ctx;
                          ctx.restore();
                          var fontSize = (height / 160).toFixed(2);
                          ctx.font = fontSize + "em sans-serif";
                          ctx.textBaseline = "top";
                          var text = chartTotal,
                            textX = Math.round((width - ctx.measureText(text).width) / 2),
                            textY = (height / 2.1) + chart.legend.height;
                          ctx.fillText(text, textX, textY);
                          ctx.save();
                        }
                      }]}
                    />}
                  </Col>
                </Row>

              </CardBody>
            </Card>
          </Col>
          <Col md={4} className="">
            <Card className="">
              <CardHeader>Map View <small className="text-muted text-capitalize"> In Last 1 Month</small></CardHeader>
              <CardBody className="fixHeight">
                <Row>
                  <Col xs={11}>
                    <MapView serviceName='Shed Visits' data={mainData} />
                  </Col>
                </Row>

              </CardBody>
            </Card>
          </Col>
          <Col md={4} className="">
            <Card className="">
              <CardHeader>On The Top <small className="text-muted text-capitalize"> In Last 1 Month</small></CardHeader>
              <CardBody className="fixHeight">
                <Row className='mb-2'>
                  <Col className="text-capitalize" xs={4}><b>{location}</b></Col>
                  <Col className="text-center" xs={6} > <b>Value</b></Col>
                  <Col xs={2}> <b>#</b></Col>
                </Row>
                {ranking}
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row className='p-3'>
          <Col lg={12} md={12} sm={12} xs={12} className="mb-1">
            <Card>
              <CardHeader>Table View</CardHeader>
              <CardBody>
                <Row>

                  <Col xs={3} className="">
                    <FormGroup>
                      <Label for="district">District</Label>
                      <Input id="district" type="select" bsSize="sm" value={district} onChange={getBlockList} disabled={dist_role}>
                        <option value="">--select--</option>
                        {districtOpt}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col xs={3} className="">
                    <FormGroup>
                      <Label for="block">Block</Label>
                      <Input id="block" type="select" bsSize="sm" value={block} onChange={getPanchyatList} disabled={block_role}>
                        <option value="">--select--</option>
                        {blockOpt}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col xs={3} className="">
                    <FormGroup>
                      <Label for="panchayat">Panchayat</Label>
                      <Input id="panchayat" type="select" value={panchyat} bsSize="sm" onChange={getVillageList}>
                        <option value="">--select--</option>
                        {panchyatOpt}
                      </Input>
                    </FormGroup>
                  </Col>

                  <Col xs={3} className="">
                    <Button className="mt-4" size="sm" onClick={checkRole}>Reset Table</Button>
                  </Col>

                </Row>
                <hr />
                <div style={{position:'relative', height:'1px'}}>
                  <Tooltip title="Excel download">
                    <SiMicrosoftexcel className='excelIcon' size={20} onClick={download_excel_handler} />
                  </Tooltip> 
                </div>
                <DataTable
                  title=""
                  columns={[
                    {
                      name: 'SRL',
                      selector: row=>row.SRL,
                      sortable: true,
                      width: '80px'
                    },
                    {
                      name: location,
                      selector: row=>row.Location,
                      sortable: false,
                    },
                    {
                      name: 'ShedVisits',
                      selector: row=>row.ShedVisits,
                      sortable: false,
                    },

                  ]}
                  data={tableData}
                  pagination
                  striped
                  highlightOnHover
                  progressPending={loading}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

      </Page>
      <Footer />
    </div>
  )
}

export default ShedVisits;