import React, { useState } from 'react';

import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/utility/Page';

import { Col, Row, Card, CardBody, Button } from 'reactstrap';

import InputPurchaseEntry from '../../component/utility/stock/InputPurchaseEntry';
import InputSaleEntry from '../../component/utility/stock/InputSaleEntry';
import GoatPurchaseEntry from '../../component/utility/stock/GoatPurchaseEntry';


const SalePurchaseEntry = () => {
    const [mode, setMode] = useState(1);

    return (
        <>
            <Header />
            <Page title="Sale Purchase Entry" breadcrumbs="Stocks / Sale Purchase Entry">
                
                <Row className="p-3">
                    <Col xs={12}>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col xs={12}>
                                        <Button className="me-2" color={mode === 1 ? "primary": "light"} size="sm" onClick={()=>setMode(1)}> Input Purchase </Button>
                                        <Button className="me-2" color={mode === 2 ? "primary": "light"} size="sm" onClick={()=>setMode(2)}> Input Sale </Button>
                                        <Button className="me-2" color={mode === 3 ? "primary": "light"} size="sm" onClick={()=>setMode(3)}> Goat Purchase </Button>
                                    </Col> 
                                </Row>
                            </CardBody>
                        </Card>    
                    </Col>
                </Row>

                { mode === 1 && 
                    <InputPurchaseEntry />
                }

                { mode === 2 && 
                    <InputSaleEntry />
                }
                
                { mode === 3 && 
                    <GoatPurchaseEntry />
                }

            </Page>
            
            <Footer />
        </>
    )
}
export default SalePurchaseEntry;