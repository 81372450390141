import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import notification from '../../component/hocs/notification';
import mainurl from '../../MainURL';
import axios from 'axios';
import Page from '../../component/utility/Page';
import DataTable from 'react-data-table-component';

import { FaEdit, FaCoins } from 'react-icons/fa';
import { MdDeleteForever, MdPersonAdd } from 'react-icons/md';
import { BsEyeFill, } from 'react-icons/bs';

import {
    Col, Row, Card, CardHeader, CardBody, Button, Input, Label, Modal, ModalBody, ModalHeader
} from 'reactstrap';
import * as XLSX from 'xlsx/xlsx.mjs';
import { SiMicrosoftexcel } from 'react-icons/si';
import { AiOutlineFundView } from 'react-icons/ai';
import Tooltip from '@mui/material/Tooltip';
import AddShareholder from '../../component/utility/master/AddShareholder';
import EditShareholder from '../../component/utility/master/EditShareholder';
import ViewShareholder from '../../component/utility/master/ViewShareholder';
import EntryViewShareholder from '../../component/utility/master/EntryViewShareholder';
import ShareManagement from '../../component/utility/master/ShareMangement';

const Shareholder = () => {
    const navigate = useNavigate();
    const [breadcrum, setBreadcrum] = useState("Master / Shareholder");
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState('');
    const [excelData, setExcelData] = useState('');
    const [districtOpt, setDistrictOpt] = useState();
    const [blockOpt, setBlockOpt] = useState();
    const [panchyatOpt, setPanchyatOpt] = useState();
    const [villageOpt, setVillageOpt] = useState();
    const [pgOpt, setPgOpt] = useState();
    const [district, setDistrict] = useState("0");
    const [block, setBlock] = useState("0");
    const [panchyat, setPanchyat] = useState("0");
    const [village, setVillage] = useState("0");
    const [pg, setPg] = useState("0");


    const [dist_role, setDist_role] = useState(false);
    const [block_role, setBlock_role] = useState(false);
    const [accData, setAccData] = useState('');
    const [mode, setMode] = useState(1);

    const [editData, setEditData] = useState('');
    const [viewModal, setViewModal] = useState(false);
    const [modalData, setModalData] = useState(false);

    useEffect(() => {
        // check Access
        let accData = JSON.parse(sessionStorage.getItem('accessControl'));
        setAccData(accData[3].pages[0]);
        if (accData[3].pages[0].page_view !== "1") {
            navigate("/home");
        }
        //
        checkRole();
        getDistrictList();
    }, []);

    const checkRole = () => {
        let role = sessionStorage.getItem('role');
        if (role === "101") {
            getDistrictList();
        } else if (role === "102") {
            let dist = sessionStorage.getItem('dist');
            setDist_role(true);
            getBlockList({ target: { value: dist } });
        } else if (role === "103") {
            let dist = sessionStorage.getItem('dist');
            let block = sessionStorage.getItem('block');
            setDist_role(true);
            setBlock_role(true);
            getBlockList({ target: { value: dist } });
            getPanchayatList({ target: { value: block } });

        }
    }

    const getDistrictList = () => {

        let postData = [{ "org_id": 100, "user_id": "0" }];
        axios({
            method: 'post',
            url: mainurl + 'Geo/geo_district_list2',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return <option key={index} value={row.district_short_cd}> {row.district_name} </option>
                })

                setDistrictOpt(list);
            })
            .catch(error => {
                let notify = notification({ message: "District list not Found! Try Again", type: 'error' });
                notify();
            });
    }

    const getBlockList = (e) => {
        setDistrict(e.target.value);
        setBlock("0");
        setPanchyat("0");
        setVillage("0");
        setPg("0");
        let postData = [{ "org_id": 100, "user_id": "0", "dist_id": e.target.value }]
        axios({
            method: 'post',
            url: mainurl + 'Geo/geo_block_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return <option key={index} value={row.block_code}> {row.block_long_name} </option>
                })
                setBlockOpt(list);
            })
            .catch(error => {
                let notify = notification({ message: "Block list not Found! Try Again", type: 'error' });
                notify();
            });
    }

    const getPanchayatList = (e) => {
        setBlock(e.target.value);
        setPanchyat("0");
        setVillage("0");
        setPg("0");
        let postData = [{ "org_id": 100, "user_id": "0", "block_id": e.target.value }]
        axios({
            method: 'post',
            url: mainurl + 'Geo/geo_pan_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return <option key={index} value={row.panchyat_code}> {row.panchyat_name} </option>
                })

                setPanchyatOpt(list);
            })
            .catch(error => {
                let notify = notification({ message: "Panchayat list not Found! Try Again", type: 'error' });
                notify();
            });
    }
    const getVillageList = (e) => {
        setPanchyat(e.target.value);
        pg_vo_list(e.target.value);
        setVillage("0");
        let postData = [{ "org_id": 100, "user_id": "0", "panchayat_id": e.target.value }]
        axios({
            method: 'post',
            url: mainurl + 'Geo/geo_village_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return <option key={index} value={row.village_code}> {row.village_name} </option>
                })

                setVillageOpt(list);
            })
            .catch(error => {
                let notify = notification({ message: "Village list not Found! Try Again", type: 'error' });
                notify();
            });
    }

    const pg_vo_list = (id) => {
        // PG list
        let postData = [{ "org_id": 100, "panchyat_id": id }];
        axios({
            method: 'post',
            url: mainurl + 'Master/pg_list_pan_wise',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return <option key={index} value={row.pg_no}> {row.pg_name_desc} </option>
                })
                setPgOpt(list);

            })
            .catch(error => {
                let notify = notification({ message: "PG not Found! Try Again", type: 'error' });
                notify();
            });
    }

    const submit_handler = () => {
        setLoading(true);
        setTableData("");
        setExcelData("");
        let postData = [{
            "org_id": "100",
            "user_id": "128",
            "dist_id": district,
            "block_id": block,
            "pachyt_id": panchyat,
            "vill_id": village,
            "pg_id": pg
        }]
        console.log(postData)
        axios({
            method: 'post',
            url: mainurl + 'Master/box_master_farmer_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                if (response.data.length > 0) {
                    let list = response.data.map((row, index) => {
                        let mobile = '';
                        if(row.mobile_no == 0){
                            mobile = 'NA';
                        }else{
                            mobile = row.mobile_no;
                        }
                        return (
                            {
                                sl: index + 1,
                                ids: row.f_id,
                                Shareholder_Name: row.f_first_name + " (" + row.f_last_name + ") ",
                                Father_Husband_Name: row.f_father_husband_name,
                                Mobile: mobile,
                                PG_Name: row.pg_name_desc,
                                action: <div>
                                    <BsEyeFill className='me-3 cursor' color="#0052cc" onClick={() => open_view_modal(row)} size={20} />
                                    {accData.page_edit === "1" && <FaEdit className='me-3 cursor' color="#047e04" size={20} onClick={() => open_edit_modal(row)} />}
                                    {accData.page_delete === "1" && <MdDeleteForever color="#f70000" size={20} />}
                                </div>
                            }

                        )
                    })
                    setTableData(list);
                    setLoading(false);
                    //set excel data
                    let excel_list = response.data.map((row, index) => {
                        let gender = '';
                        let category = '';
                        if (row.gender === "1") {
                            gender = "Male";
                        } else if (row.gender === "2") {
                            gender = "Female";
                        } else {
                            gender = "-";
                        }

                        if (row.category_id === "1") {
                            category = "BC";
                        } else if (row.category_id === "2") {
                            category = "BC-I";
                        } else if (row.category_id === "3") {
                            category = "BC-II";
                        } else if (row.category_id === "4") {
                            category = "EBC";
                        } else if (row.category_id === "5") {
                            category = "GEN";
                        } else if (row.category_id === "6") {
                            category = "OBC";
                        } else if (row.category_id === "7") {
                            category = "SC";
                        } else if (row.category_id === "8") {
                            category = "ST";
                        } else {
                            category = "-";
                        }
                        return (
                            {
                                sl: index + 1,
                                Shareholder_Id: row.f_id,
                                Shareholder_Name: row.f_first_name + " (" + row.f_last_name + ") ",
                                Father_Husband_Name: row.f_father_husband_name + " (" + row.f_husband_name_h + ") ",
                                Age: row.age,
                                Gender: gender,
                                Category: category,
                                Mobile: row.mobile_no,
                                Alt_Mobile: row.mobile_no_2,
                                Email: row.email,
                                Id_type: row.id_type,
                                Id_No: row.uid,
                                Application_form_no: row.application_form_no,

                                State: row.state_name,
                                District: row.district_name,
                                Block: row.block_long_name,
                                Panchayat: row.panchyat_name,
                                Village: row.village_name,
                                PG_Name: row.pg_name_desc,

                                Account_holder_name: row.ac_holder_name,
                                Bank_Name: row.bank_name,
                                Bank_account_no: row.bank_account_no,
                                ifsc_code: row.ifsc_code,
                                Branch_name: row.branch_name,
                                Nominee: row.nominee,
                                Nominee_relationship: row.nominee_relationship,
                                Amount_deposit: row.amount_deposit,
                                Deposit_date: row.deposit_date,
                                Folio_no: row.folio_no,
                                Share_certificate_from: row.share_certificate_from,
                                Share_certificate_to: row.share_certificate_to,
                                Share_certificate_no: row.share_certificate_no,

                            }

                        )
                    })
                    setExcelData(excel_list);
                }

            })
            .catch(error => {
                setTableData("");
                setExcelData("");
                setLoading(false);
                //let notify = notification({ message: "Shareholder list not Found! Try Again", type: 'error' });
                //notify();
            });

    }
    const open_view_modal = (row) => {
        let temp = <ViewShareholder data={row} />;
        setModalData(temp);
        setViewModal(true);
    }
    const open_edit_modal = (row) => {
        setEditData(row);
        setMode(3);
    }

    const download_excel_handler = () => {
        if (excelData.length > 0) {
            let wb = XLSX.utils.book_new(),
                ws = XLSX.utils.json_to_sheet(excelData);

            XLSX.utils.book_append_sheet(wb, ws, "Mysheet1");

            XLSX.writeFile(wb, "Shareholder_List.xlsx");
        }
    }
    return (
        <>
            <Header />
            <Page title="Shareholder"
                breadcrumbs={breadcrum}>
                {mode === 1 &&
                    <Row className='p-3'>
                        <Col xs={12}>
                            {accData.page_add === "1" && <Col xs={12} className="mb-2">
                                <Card>
                                    <CardBody>
                                        <Button color='primary' size="sm" onClick={() => { setMode(2); setBreadcrum("Master / Shareholder / Add"); }}><MdPersonAdd /> Add New Shareholder</Button>
                                        <Button className='ms-4' color='primary' size="sm" onClick={() => { setMode(4); setBreadcrum("Master / Shareholder / New Entry") }}><AiOutlineFundView /> View New Entry</Button>
                                        <Button className='ms-4' color='primary' size="sm" onClick={() => { setMode(5); setBreadcrum("Master / Shareholder / Share Management") }}><FaCoins /> Share Management</Button>
                                    </CardBody>
                                </Card>
                            </Col>}
                            <Card>
                                <CardHeader>Shareholder List</CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col xs={2} className="mb-2">
                                            <Label className="me-sm-2" for="district"> District </Label>
                                            <Input id="district" bsSize="sm" type="select" value={district} onChange={getBlockList} disabled={dist_role}>
                                                <option value="0"> --Select District--</option>
                                                {districtOpt}
                                            </Input>
                                        </Col>
                                        <Col xs={2} className="mb-2">
                                            <Label className="me-sm-2" for="block"> Block </Label>
                                            <Input id="block" bsSize="sm" type="select" value={block} onChange={getPanchayatList} disabled={block_role}>
                                                <option value="0"> --Select Block--</option>
                                                {blockOpt}
                                            </Input>
                                        </Col>
                                        <Col xs={2} className="mb-2">
                                            <Label className="me-sm-2" for="panchayat"> Panchayat </Label>
                                            <Input id="panchayat" bsSize="sm" type="select" value={panchyat} onChange={getVillageList}>
                                                <option value="0"> --Select Panchayat--</option>
                                                {panchyatOpt}
                                            </Input>
                                        </Col>
                                        <Col md={2} className="mb-2">
                                            <Label className="me-sm-2" for="pg"> PG / VO </Label>
                                            <Input id="pg" bsSize="sm" type="select" value={pg} onChange={(e) => setPg(e.target.value)} required>
                                                <option value="0"> --Select PG/VO --</option>
                                                {pgOpt}
                                            </Input>
                                        </Col>
                                        <Col xs={2} className="mb-2">
                                            <Label className="me-sm-2" for="village"> Village </Label>
                                            <Input id="village" bsSize="sm" type="select" value={village} onChange={(e) => setVillage(e.target.value)}>
                                                <option value="0"> --Select Village--</option>
                                                {villageOpt}
                                            </Input>
                                        </Col>
                                        <Col xs={2} className="mb-2">
                                            <Button className="mt-4" color="primary" size="sm" onClick={submit_handler}> Submit </Button>
                                        </Col>

                                        <hr />

                                        <Col xs={12}>
                                            <Card>
                                                <div style={{ position: 'relative', height: '1px' }}>
                                                    <Tooltip title="Excel download">
                                                        <SiMicrosoftexcel className='excelIcon' size={20} onClick={download_excel_handler} />
                                                    </Tooltip>
                                                </div>
                                                <CardBody>
                                                    <DataTable
                                                        title=""
                                                        columns={[
                                                            {
                                                                name: '#',
                                                                selector: row => row.sl,
                                                                sortable: true,
                                                                width: "80px"
                                                            },
                                                            {
                                                                name: 'Id',
                                                                selector: row => row.ids,
                                                                sortable: true,
                                                            },
                                                            {
                                                                name: 'Name',
                                                                selector: row => row.Shareholder_Name,
                                                                sortable: false,
                                                                width: '20%'
                                                            },
                                                            {
                                                                name: 'Mobile Number',
                                                                selector: row => row.Mobile,
                                                                sortable: false,
                                                            },
                                                            {
                                                                name: 'Spouse Name',
                                                                selector: row => row.Father_Husband_Name,
                                                                sortable: false,
                                                            },
                                                            {
                                                                name: 'PG Name',
                                                                selector: row => row.PG_Name,
                                                                sortable: false,
                                                            },
                                                            {
                                                                name: 'Action',
                                                                selector: row => row.action,
                                                                sortable: false,
                                                            },
                                                        ]}
                                                        data={tableData}
                                                        striped
                                                        highlightOnHover
                                                        pagination
                                                        paginationPerPage="100"
                                                        progressPending={loading}

                                                    />

                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                }

                {mode === 2 &&
                    <Row className='p-3'>
                        <Col xs={12} className="mb-2">
                            <AddShareholder back_handler={() => { setMode(1); setBreadcrum("Master / Shareholder"); }} />
                        </Col>
                    </Row>
                }

                {mode === 3 &&
                    <Row className='p-3'>
                        <Col xs={12} className="mb-2">
                            <EditShareholder data={editData} back_handler={() => { setMode(1); setBreadcrum("Master / Shareholder"); }} />
                        </Col>
                    </Row>
                }

                {mode === 4 &&
                    <Row className='p-3'>
                        <Col xs={12} className="mb-2">
                            <EntryViewShareholder back_handler={() => { setMode(1); setBreadcrum("Master / Shareholder"); }} />
                        </Col>
                    </Row>
                }
                {mode === 5 &&
                    <Row className='p-3'>
                        <Col xs={12} className="mb-2">
                            <ShareManagement back_handler={() => { setMode(1); setBreadcrum("Master / Shareholder"); }} />
                        </Col>
                    </Row>
                }

            </Page>
            <Modal
                fullscreen={false}
                centered
                scrollable
                size="xl"
                isOpen={viewModal}
                toggle={() => setViewModal(!viewModal)}
            >
                <ModalHeader toggle={() => setViewModal(!viewModal)}>
                    View Shareholder
                </ModalHeader>
                <ModalBody>
                    {modalData}
                </ModalBody>
            </Modal>
            <Footer />
        </>
    )
}

export default Shareholder;