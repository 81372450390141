import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import notification from '../../component/hocs/notification';
import mainurl from '../../MainURL';
import axios from 'axios';
import Page from '../../component/utility/Page';
import DataTable from 'react-data-table-component';
import EditDesignation from '../../component/utility/master/EditDesignation';
import {
    Col, Row, Card, CardHeader, CardBody, Button, Input, Label, Form, InputGroup, InputGroupText, Spinner,
    Modal, ModalBody, ModalHeader
} from 'reactstrap';
import * as XLSX from 'xlsx/xlsx.mjs';
import {SiMicrosoftexcel} from 'react-icons/si';
import Tooltip from '@mui/material/Tooltip';

import { FaHeading} from 'react-icons/fa';
import {FaEdit} from 'react-icons/fa';
import {MdDeleteForever, } from 'react-icons/md';

const DesignationMaster = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [designationTable, setDesignationTable] = useState("");
    const [editModel, setEditModel] = useState(false);
    const [modelData, setModelData] = useState("");

    
    const [geoName, setGeoName] = useState("");
    const [geoNameHindi, setGeoNameHindi] = useState("");
    const [hindiNameLoading, setHindiNameLoading] = useState(false);
    const [accData, setAccData] = useState('');

    useEffect(() => {
        // check Access
        let accData = JSON.parse(sessionStorage.getItem('accessControl'));
        setAccData(accData[3].pages[9]);
        if(accData[3].pages[9].page_view !== "1"){
            navigate("/home");
        }
        
        get_designation_list();
    }, []);

    const get_designation_list = () => {
        //
        let accData = JSON.parse(sessionStorage.getItem('accessControl'));
        let acc = accData[3].pages[3];
        //
        let postData = [{ "org_id": 100, "user_id": "0" }];
        axios({
          method: 'post',
          url: mainurl + 'Master/box_designation_list',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            console.log(response.data);
            let list = response.data.map(row => {
                return(
                    {
                        id: row.desig_id,
                        name: row.desig_name,
                        nameHindi:row.desig_name_h,
                        action: <div>
                            { acc.page_edit === "1" && <FaEdit className='me-3 cursor' color="#047e04" onClick={()=> open_edit_modal(row)} size={20} />}
                            { acc.page_delete === "1" && <MdDeleteForever color="#f70000" size={20} />}
                        </div>
                    }
                )
            })

            setDesignationTable(list);
          })
          .catch(error => {
            //let notify = notification({ message: "Designation list not Found! Try Again", type: 'error' });
            //notify();
          });
    }

    const translate_text_handler = (e) => {
        setHindiNameLoading(true);
        if(geoName.length > 1){
           
            let postData = [{ "t_text" : geoName}];
            axios({
                method: 'post',
                url: mainurl + 'Master/translate_text',
                data: postData,
                headers: { 'Content-Type': 'application/JSON', }
              })
                .then(function (response) {
                    let trans = response.data;
                    setGeoNameHindi(trans);
                    setHindiNameLoading(false);
                })
                .catch(error => {
                    setHindiNameLoading(false);
                    let notify = notification({ message: "Hindi Translation is not working! Try Again", type: 'error' });
                    notify();
                  });

        }else{
            setHindiNameLoading(false);
            let notify = notification({ message: "Please enter english name first.", type: 'warn' });
            notify();
        }
    }

    const add_designation_handler = (e) => {
        e.preventDefault();
        let postData = [
            {
              "org_id":"100",
              "user_id":"128",
              "action": 1,
              "desig_id": "",
              "desig_name": geoName,
              "desig_name_h": geoNameHindi
            }
        ];
        console.log(postData);

        axios({
            method: 'post',
            url: mainurl + 'Master/box_designation_add_edit',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
          })
            .then(function (response) {
                get_designation_list();
                setGeoName('');
                setGeoNameHindi('');
                let notify = notification({ message: "Designation added successfully", type: 'success' });
                notify();

            })
            .catch(error => {
                let notify = notification({ message: "Add failed! Try Again", type: 'error' });
                notify();
              });
    
    }

    const open_edit_modal = (row) => {
        let temp = <EditDesignation  geo="Designation" data={row} close_handler={close_modal_handler} />

        setModelData(temp);
        setEditModel(true);
    }

    const close_modal_handler = () => {
        setEditModel(false);
        get_designation_list();
    }

    const download_excel_handler = () => {
        if(designationTable.length > 0){
          let wb = XLSX.utils.book_new(),
          ws = XLSX.utils.json_to_sheet(designationTable);
    
          XLSX.utils.book_append_sheet(wb, ws, "Mysheet1");
    
          XLSX.writeFile(wb, "Designation_List.xlsx");
        }
      }

    return (
        <>
            <Header />
            <Page title="Designation Master" breadcrumbs="Master / Designation Master">
                <Row className="p-3">
                    <Col xs={12}>
                        { accData.page_add === "1" && <Card className='mb-2'>
                            <CardHeader>Add New Designation</CardHeader>
                            <CardBody>
                                <Form onSubmit={add_designation_handler}>
                                    <Row>
                                        
                                        <Col md={2}>
                                            <Label for="name"> Designation Name</Label>
                                            <Input id="name" type='text' maxLength={50} bsSize="sm" value={geoName} onChange={(e) => setGeoName(e.target.value)} required />
                                        </Col>
                                        <Col md={2}>
                                            <Label for="hName"> Designation Name Hindi</Label>
                                            <InputGroup size="sm">
                                                <Input id="hName" type='text' maxLength={50} value={geoNameHindi} bsSize="sm" onChange={(e) => setGeoNameHindi(e.target.value)} />
                                                <InputGroupText className='bg-primary cursor' onClick={!hindiNameLoading ? translate_text_handler : undefined}>
                                                    {hindiNameLoading ? <Spinner color="secondary" size="sm">Loading...</Spinner> : <FaHeading color="#fff" />}
                                                </InputGroupText>
                                            </InputGroup>
                                        </Col>
                                        <Col md={2}>
                                            <Button className='mt-4' color="primary" size="sm" >Add Designation</Button>
                                        </Col>
                                    </Row>

                                </Form>
                            </CardBody>
                        </Card>}

                        <Card className='mb-2'>
                            <CardHeader>Designation Master</CardHeader>
                            <div style={{position:'relative', height:'1px'}}>
                                <Tooltip title="Excel download">
                                    <SiMicrosoftexcel className='excelIcon' size={20} onClick={download_excel_handler} />
                                </Tooltip> 
                            </div>
                            <CardBody>
                                <DataTable
                                    title=""
                                    columns={[
                                        {
                                        name: 'Designation Id',
                                        selector: 'id',
                                        sortable: true,
                                        },
                                        {
                                        name: 'Designation Name',
                                        selector: 'name',
                                        sortable: false,
                                        },
                                        {
                                        name: 'Designation Name (Hindi)',
                                        selector: 'nameHindi',
                                        sortable: false,
                                        },
                                        {
                                        name: 'Action',
                                        selector: 'action',
                                        sortable: false,
                                        },
                                    ]}
                                    data={designationTable}
                                    striped
                                    highlightOnHover
                                    pagination
                                    progressPending={loading}
                                    />
                            </CardBody>
                        </Card> 
                    </Col>
                </Row>

            </Page>
            <Modal
                centered
                scrollable
                size="lg"
                isOpen={editModel}
                toggle={() => setEditModel(!editModel)}
            >
                <ModalHeader toggle={() => setEditModel(!editModel)}>
                   Edit Designation
                </ModalHeader>
                <ModalBody>
                    {modelData}
                </ModalBody>
            </Modal>
            <Footer />
        </>
    )
}

export default DesignationMaster;