import React, { useEffect, useState } from 'react';
import notification from '../../hocs/notification';
import mainurl from '../../../MainURL';
import axios from 'axios';
import {
  Row,
  Col,
  Input,
  Button,
} from 'reactstrap';


const TargetSetSend = ({data, srl, reload, add, ...restProps }) => {
  const [year, setYear] = useState();
  const [month, setMonth] = useState();
  const [aps_id, setAps_id] = useState();
  const [vaccination, setVaccination] = useState();
  const [deworming, setDeworming] = useState();
  const [castration, setCastration] = useState();
  const [natal_care, setNatal_care] = useState();

  useEffect( () => {
    setYear(data.year);
    setMonth(data.month);
    setAps_id(data.aps_id);
    setVaccination(data.target_list[0]?.t_val);
    setDeworming(data.target_list[1]?.t_val);
    setCastration(data.target_list[2]?.t_val);
    setNatal_care(data.target_list[8]?.t_val);
    
  }, [])

  let sendTargetSet = (e) => {
    e.preventDefault();

    if(vaccination > 999 || deworming > 999 || castration > 999 ||  natal_care > 999){
      let notify = notification({ message: "Invalid Value!", type: 'success' });
			notify();
    }
    
    let postData = [
      {"org_id":100,
      "year_id":year,
      "month_id":month,
      "aps_id":aps_id,
      "target_list":[
        {"key_id":1,"target_value":vaccination},
        {"key_id":2,"target_value":deworming},
        {"key_id":3,"target_value":castration},
        {"key_id":8,"target_value":natal_care},
        ]
      }
    ];
    console.log(postData)
    axios({
      method: 'post',
      url: mainurl +'Geo/master_target_set',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
    .then(function (response) {
      reload();
      let notify = notification({ message: "send successful", type: 'success' });
			notify();
      
    })
    .catch(error => {
      let notify = notification({ message: "Data not Send! Try Again", type: 'error' });
			notify();
    });

  }

  return (
    <form onSubmit={(e) => sendTargetSet(e)}>
    <Row className='p-1 '>
      <Col xs="1"> {srl} </Col>
      <Col xs="2"> {data.aps_name} ( {data.aps_id} ) </Col>
      <Col xs="2"> <Input bsSize="sm" name="vaccination" defaultValue={Number(data.target_list[0]?.t_val)} 
        onChange={(e)=> setVaccination(e.target.value)} type="text" maxLength={3} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} /> 
      </Col>
      <Col xs="2"> <Input bsSize="sm" name="deworming" defaultValue={Number(data.target_list[1]?.t_val)} 
        onChange={(e)=>setDeworming(e.target.value)} type="text" maxLength={3} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} /> 
      </Col>
      <Col xs="2"> <Input bsSize="sm" name="castration" defaultValue={Number(data.target_list[2]?.t_val)} 
        onChange={(e)=>setCastration(e.target.value)} type="text" maxLength={3} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} /> 
      </Col>
      <Col xs="2"> <Input bsSize="sm" name="natal_care" defaultValue={Number(data.target_list[7]?.t_val)} 
        onChange={(e)=>setNatal_care(e.target.value)} type="text" maxLength={3} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}  />
      </Col>
      {add === "1" && <Col xs="1"> <Button type="submit" color="success" outline size="sm" > Set </Button> </Col>}
    </Row>
    </form>
  );
};

export default TargetSetSend;