import React from 'react';
import notification from '../hocs/notification';
import mainurl from '../../MainURL';
import axios from 'axios';
import {
    Col, Row, Input, Label, FormGroup, 
} from 'reactstrap';

const ModPage = ({data, index, ...props}) => {

    const edit_view_handler = (e) => {
        let value = e.target.checked ? 1 : 0; 
        let postData = [{
            org_id: 100,
            role_id: data.role_id,
            page_id: data.page_id,
            val: value,
            flag: 3
        }];

        axios({
            method: 'post',
            url: mainurl + 'Master/box_access_control_edit',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let notify = notification({ message: "Data Send Successfully.", type: 'success' });
                notify();
            })
            .catch(error => {
                let notify = notification({ message: "Data not Send! Try Again", type: 'error' });
                notify();
            })  
    }

    const edit_add_handler = (e) => {
        let value = e.target.checked ? 1 : 0; 
        let postData = [{
            org_id: 100,
            role_id: data.role_id,
            page_id: data.page_id,
            val: value,
            flag: 1
        }];

        axios({
            method: 'post',
            url: mainurl + 'Master/box_access_control_edit',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let notify = notification({ message: "Data Send Successfully.", type: 'success' });
                notify();
            })
            .catch(error => {
                let notify = notification({ message: "Data not Send! Try Again", type: 'error' });
                notify();
            })

    }
    const edit_edit_handler = (e) => {
        let value = e.target.checked ? 1 : 0; 
        let postData = [{
            org_id: 100,
            role_id: data.role_id,
            page_id: data.page_id,
            val: value,
            flag: 2
        }];

        axios({
            method: 'post',
            url: mainurl + 'Master/box_access_control_edit',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let notify = notification({ message: "Data Send Successfully.", type: 'success' });
                notify();
            })
            .catch(error => {
                let notify = notification({ message: "Data not Send! Try Again", type: 'error' });
                notify();
            })

    }
    const edit_delete_handler = (e) => {
        let value = e.target.checked ? 1 : 0; 
        let postData = [{
            org_id: 100,
            role_id: data.role_id,
            page_id: data.page_id,
            val: value,
            flag: 4
        }];

        axios({
            method: 'post',
            url: mainurl + 'Master/box_access_control_edit',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let notify = notification({ message: "Data Send Successfully.", type: 'success' });
                notify();
            })
            .catch(error => {
                let notify = notification({ message: "Data not Send! Try Again", type: 'error' });
                notify();
            })

    }
    return (
        <Row>
            <Col xs={4} className="ps-4">
                <span>{index+1}</span>
                <span className="ps-3">{data.page_name}</span>
            </Col>
            <Col xs={5}>
                {data && <Row>
                    <Col xs={3}>
                        <FormGroup check>
                            <Input id="view" name="view" type="checkbox" onChange={edit_view_handler} defaultChecked={data?.page_view === "1" ? true : false } />
                            <Label check for="view">View</Label>
                        </FormGroup>
                    </Col>
                    <Col xs={3}>
                        <FormGroup check>
                            <Input id="add" name="add" type="checkbox" onChange={edit_add_handler} defaultChecked={data?.page_add === "1" ? true : false } />
                            <Label check for="add">Add</Label>
                        </FormGroup>
                    </Col>
                    <Col xs={3}>
                        <FormGroup check>
                            <Input id="edit" name="edit" type="checkbox" onChange={edit_edit_handler} defaultChecked={data?.page_edit === "1" ? true : false } />
                            <Label check for="edit">Edit</Label>
                        </FormGroup>
                    </Col>
                    <Col xs={3}>
                        <FormGroup check>
                            <Input id="delete" name="delete" type="checkbox" onChange={edit_delete_handler} defaultChecked={data?.page_delete === "1" ? true : false } />
                            <Label check for="delete">Delete</Label>
                        </FormGroup>
                    </Col>
                </Row>}
            </Col>
        </Row>
    )
}
export default ModPage;