import React, { useEffect, useState } from 'react';
import notification from '../../hocs/notification';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import mainurl from '../../../MainURL';

import ShareTransfer from './ShareTransfer';
import { BiTransfer } from 'react-icons/bi';
import { BsCashCoin } from 'react-icons/bs';

import {
    Col, Row, Card, CardHeader, CardBody, CardFooter, Input, Label, Form, Spinner, Button
} from 'reactstrap';
import ShareWithdraw from './ShareWithdraw';

const ShareManagement = ({ back_handler, ...props }) => {

    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState('');
    const [innerMode, setInnerMode] = useState(1);
    const [editData, setEditData] = useState('');

    const [districtOpt, setDistrictOpt] = useState();
    const [blockOpt, setBlockOpt] = useState();
    const [panchyatOpt, setPanchyatOpt] = useState();
    const [villageOpt, setVillageOpt] = useState();
    const [pgOpt, setPgOpt] = useState();
    const [district, setDistrict] = useState("0");
    const [block, setBlock] = useState("0");
    const [panchyat, setPanchyat] = useState("0");
    const [village, setVillage] = useState("0");
    const [pg, setPg] = useState("0");


    useEffect(() => {
        getDistrictList();
    }, [])

    const open_shareTransfer_modal = (row) => {
        setEditData(row);
        setInnerMode(2);
    }

    const open_shareWithdraw_modal = (row) => {
        setEditData(row);
        setInnerMode(3);
    }

    const get_farmer_list = () => {
        setLoading(true);
        setTableData("");
        let postData = [{
            "org_id": "100",
            "user_id": "128",
            "dist_id": district,
            "block_id": block,
            "pachyt_id": panchyat,
            "vill_id": village,
            "pg_id": pg
        }]
        console.log(postData)
        axios({
            method: 'post',
            url: mainurl + 'Master/box_master_farmer_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                if (response.data.length > 0) {
                    let list = response.data.map((row, index) => {
                        return (
                            {
                                sl: index + 1,
                                ids: row.f_id,
                                Shareholder_Name: row.f_first_name + " (" + row.f_last_name + ") ",
                                Father_Husband_Name: row.f_father_husband_name,
                                Mobile: row.mobile_no,
                                PG_Name: row.pg_name_desc,
                                action: <div>
                                    <BiTransfer className='me-3 cursor' color="#047e04" size={20} onClick={() => open_shareTransfer_modal(row)} />
                                    <BsCashCoin className='me-3 cursor' color="#047e04" size={20} onClick={() => open_shareWithdraw_modal(row)} />
                                </div>
                            }

                        )
                    })
                    setTableData(list);
                    setLoading(false);

                }

            })
            .catch(error => {
                setTableData("");
                setLoading(false);
                //let notify = notification({ message: "Shareholder list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const getDistrictList = () => {

        let postData = [{ "org_id": 100, "user_id": "0" }];
        axios({
            method: 'post',
            url: mainurl + 'Geo/geo_district_list2',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return <option key={index} value={row.district_short_cd}> {row.district_name} </option>
                })

                setDistrictOpt(list);
            })
            .catch(error => {
                let notify = notification({ message: "District list not Found! Try Again", type: 'error' });
                notify();
            });
    }

    const getBlockList = (e) => {
        setDistrict(e.target.value);
        setBlock("0");
        setPanchyat("0");
        setVillage("0");
        setPg("0");
        let postData = [{ "org_id": 100, "user_id": "0", "dist_id": e.target.value }]
        axios({
            method: 'post',
            url: mainurl + 'Geo/geo_block_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return <option key={index} value={row.block_code}> {row.block_long_name} </option>
                })
                setBlockOpt(list);
            })
            .catch(error => {
                let notify = notification({ message: "Block list not Found! Try Again", type: 'error' });
                notify();
            });
    }

    const getPanchayatList = (e) => {
        setBlock(e.target.value);
        setPanchyat("0");
        setVillage("0");
        setPg("0");
        let postData = [{ "org_id": 100, "user_id": "0", "block_id": e.target.value }]
        axios({
            method: 'post',
            url: mainurl + 'Geo/geo_pan_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return <option key={index} value={row.panchyat_code}> {row.panchyat_name} </option>
                })

                setPanchyatOpt(list);
            })
            .catch(error => {
                let notify = notification({ message: "Panchayat list not Found! Try Again", type: 'error' });
                notify();
            });
    }
    const getVillageList = (e) => {
        setPanchyat(e.target.value);
        pg_vo_list(e.target.value);
        setVillage("0");
        let postData = [{ "org_id": 100, "user_id": "0", "panchayat_id": e.target.value }]
        axios({
            method: 'post',
            url: mainurl + 'Geo/geo_village_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return <option key={index} value={row.village_code}> {row.village_name} </option>
                })

                setVillageOpt(list);
            })
            .catch(error => {
                let notify = notification({ message: "Village list not Found! Try Again", type: 'error' });
                notify();
            });
    }

    const pg_vo_list = (id) => {
        // PG list
        let postData = [{ "org_id": 100, "panchyat_id": id }];
        axios({
            method: 'post',
            url: mainurl + 'Master/pg_list_pan_wise',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return <option key={index} value={row.pg_no}> {row.pg_name_desc} </option>
                })
                setPgOpt(list);

            })
            .catch(error => {
                let notify = notification({ message: "PG not Found! Try Again", type: 'error' });
                notify();
            });
    }

    return (
        <>
            { innerMode === 1 && <Row>

                <Col sm={12} className="mb-3">
                    <Card>
                        <CardHeader>Share Management </CardHeader>
                        <CardBody>
                            <Row>
                                <Col xs={2} className="mb-2">
                                    <Label className="me-sm-2" for="district"> District </Label>
                                    <Input id="district" bsSize="sm" type="select" value={district} onChange={getBlockList} >
                                        <option value="0"> --Select District--</option>
                                        {districtOpt}
                                    </Input>
                                </Col>
                                <Col xs={2} className="mb-2">
                                    <Label className="me-sm-2" for="block"> Block </Label>
                                    <Input id="block" bsSize="sm" type="select" value={block} onChange={getPanchayatList} >
                                        <option value="0"> --Select Block--</option>
                                        {blockOpt}
                                    </Input>
                                </Col>
                                <Col xs={2} className="mb-2">
                                    <Label className="me-sm-2" for="panchayat"> Panchayat </Label>
                                    <Input id="panchayat" bsSize="sm" type="select" value={panchyat} onChange={getVillageList}>
                                        <option value="0"> --Select Panchayat--</option>
                                        {panchyatOpt}
                                    </Input>
                                </Col>
                                <Col md={2} className="mb-2">
                                    <Label className="me-sm-2" for="pg"> PG / VO </Label>
                                    <Input id="pg" bsSize="sm" type="select" value={pg} onChange={(e) => setPg(e.target.value)} required>
                                        <option value="0"> --Select PG/VO --</option>
                                        {pgOpt}
                                    </Input>
                                </Col>
                                <Col xs={2} className="mb-2">
                                    <Label className="me-sm-2" for="village"> Village </Label>
                                    <Input id="village" bsSize="sm" type="select" value={village} onChange={(e) => setVillage(e.target.value)}>
                                        <option value="0"> --Select Village--</option>
                                        {villageOpt}
                                    </Input>
                                </Col>
                                <Col xs={2} className="mb-2">
                                    <Button className="mt-4" color="primary" size="sm" onClick={get_farmer_list}> Submit </Button>
                                </Col>
                            </Row>

                        </CardBody>
                    </Card>
                </Col>

                <Col xs={12}>
                    <Card>
                        <CardBody>
                            <DataTable
                                title=""
                                columns={[
                                    {
                                        name: '#',
                                        selector: row => row.sl,
                                        sortable: true,
                                        width: "80px"
                                    },
                                    {
                                        name: 'Id',
                                        selector: row => row.ids,
                                        sortable: true,
                                    },
                                    {
                                        name: 'Name',
                                        selector: row => row.Shareholder_Name,
                                        sortable: false,
                                        width: '20%'
                                    },
                                    {
                                        name: 'Mobile Number',
                                        selector: row => row.Mobile,
                                        sortable: false,
                                    },
                                    {
                                        name: 'Spouse Name',
                                        selector: row => row.Father_Husband_Name,
                                        sortable: false,
                                    },
                                    {
                                        name: 'PG Name',
                                        selector: row => row.PG_Name,
                                        sortable: false,
                                    },
                                    {
                                        name: 'Action',
                                        selector: row => row.action,
                                        sortable: false,
                                    },
                                ]}
                                data={tableData}
                                striped
                                highlightOnHover
                                pagination
                                paginationPerPage="100"
                                progressPending={loading}
                            />
                        </CardBody>
                        <CardFooter className='text-end'>
                            <Button color='primary' size="sm" onClick={back_handler}> Back Shareholder Page</Button>
                        </CardFooter>
                    </Card>
                </Col>

            </Row> }

            { innerMode === 2 && 
                <Row className='p-3'>
                    <Col xs={12} className="mb-2">
                        <ShareTransfer data={editData} back_handler={() => { setInnerMode(1);}} />
                    </Col>
                </Row>
            }

            { innerMode === 3 && 
                <Row className='p-3'>
                    <Col xs={12} className="mb-2">
                        <ShareWithdraw data={editData} back_handler={() => { setInnerMode(1);}} />
                    </Col>
                </Row>
            }
        </>
    )
}

export default ShareManagement;