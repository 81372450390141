import React from 'react';
import {
  Col,
  Row,
} from 'reactstrap';

import {GiGoat, GiMiracleMedecine } from 'react-icons/gi';
import {FaThermometer,FaArrowCircleUp, FaArrowCircleDown, } from 'react-icons/fa';
import {MdPointOfSale } from 'react-icons/md';
import {RiScissorsCutFill } from 'react-icons/ri';
import Natal from '../../../assets/img/natal.png';

const Dbox = ({text, val, typ, ...restProps }) => {
  return (
    <Col xs={6} className={(typ === "5" || typ === "6")? "" : "mb-2" }>
      <div className='d-box'>
        <Row>
          <Col xs={8}>
            <small>{text}</small>
            {typ !== "6" && <h5>{val}</h5>}
            {typ === "6" && <h5> &#8377; {val}</h5>}
            
          </Col>
          <Col xs={4} className="d-flex align-items-center">
              {typ === "1" && <GiGoat color="#fba10f" size={30}/>}
              {typ === "2" && <FaThermometer color="#fba10f" size={30}/>}
              {typ === "3" && <GiMiracleMedecine color="#fba10f" size={35}/>}
              {typ === "4" && <FaArrowCircleDown color="#fba10f" size={30}/>}
              {typ === "5" && <FaArrowCircleUp color="#fba10f" size={30}/>}
              {typ === "6" && <MdPointOfSale color="#fba10f" size={30}/>}
              {typ === "7" && <RiScissorsCutFill color="#fba10f" size={30}/>}
              {typ === "8" && <img src={Natal} alt="." height="40px" />}
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default Dbox;