import React, { useState, useEffect } from 'react';
import notification from '../../hocs/notification';
import axios from 'axios';
import mainurl from '../../../MainURL';
import DataTable from 'react-data-table-component';
import {
  Col, Row, Button, Input, Label, Form, Card, CardHeader, CardBody, CardFooter
} from 'reactstrap';

import { MdDeleteForever, } from 'react-icons/md';

const AllocationPashusakhi = ({ back_handler, data, ...props }) => {

  const [loading, setLoading] = useState(false);
  const [allocationList, setAllocationList] = useState('');

  const [pg, setPg] = useState("0");
  const [district, setDistrict] = useState("0");
  const [block, setBlock] = useState("0");
  const [panchayat, setPanchayat] = useState("0");
  const [village, setVillage] = useState("0");

  const [districtOpt, setDistrictOpt] = useState("");
  const [blockOpt, setBlockOpt] = useState("");
  const [panchayatOpt, setPanchayatOpt] = useState("");
  const [villageOpt, setVillageOpt] = useState("");
  const [pgOpt, setPgOpt] = useState("");
  const [fisrtLoad, setFisrtLoad] = useState(1);

  useEffect(() => {
    aps_allocation_list();
    getDistrictList();
  }, []);

  const aps_allocation_list = () => {
    setLoading(true);
    setAllocationList('');
    let postData = [{ "org_id": 100, "aps_id": data.aps_id }];
    axios({
      method: 'post',
      url: mainurl + 'Master/aps_allocation_list',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        let list = response.data.map((row, index) => {
          return (
            {
              sl: index + 1,
              district: row.district_name,
              block: row.block_long_name,
              panchayat: row.panchyat_name,
              village: row.village_name,
              pg: row.pg_name_desc,
              action: <MdDeleteForever className='cursor' size={20} color="#f70000" onClick={() => delete_handler(row)} />
            }
          )
        })
        setAllocationList(list);
        setLoading(false);
      })
      .catch(error => {
        setAllocationList('');
        setLoading(false);
        let notify = notification({ message: "Allocation list not Found! Try Again", type: 'error' });
        notify();
      });
  }

  const delete_handler = (row) => {

    let postData = [{ "org_id": 100, "geo_map_id": row.geo_map_id, "pg_id": row.pg_id  }];
    axios({
      method: 'post',
      url: mainurl + 'Master/aps_deallocation',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        if(response.data[0].retcode === "101"){
          let notify = notification({ message: "Deleted Successfully!", type: 'success' });
          notify();
          aps_allocation_list();
        }else if(response.data[0].retcode === "102"){
          let notify = notification({ message: "First Allocate then Try delete this record.", type: 'warn' });
          notify();
        }
      })
      .catch(error => {
        let notify = notification({ message: "Failed! Try Again", type: 'error' });
        notify();
      });
  }

  const pg_vo_list = (id) => {

    let postData = [{ "org_id": 100, "panchyat_id": id }];
    axios({
      method: 'post',
      url: mainurl + 'Master/pg_list_panchyat_wise',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        let list = response.data.map(row => {
          let flag = true;
          if (row.aps_id === '0') {
            flag = false;
          }
          return (
            <option value={row.pg_no} disabled={flag}>{row.pg_name_desc +" ( "+ row.aps_id + " )"}</option>
          )
        })
        setPgOpt(list);
      })
      .catch(error => {
        let notify = notification({ message: "PG / VO list not Found! Try Again", type: 'error' });
        notify();
      });
  }

  // district, block, panchayat, village API call
  const getDistrictList = () => {
    setBlock("");
    setPanchayat("");
    setVillage("");

    let postData = [{ "org_id": 100, "user_id": "0" }];
    axios({
      method: 'post',
      url: mainurl + 'Geo/geo_district_list2',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        let list = response.data.map((row, index) => {
          return <option key={index} value={row.district_short_cd}> {row.district_name} </option>
        })
        setDistrictOpt(list);
        if(fisrtLoad === 1){
          getBlockList({target: {value: data.dist_id}});
        }
      })
      .catch(error => {
        let notify = notification({ message: "District list not Found! Try Again", type: 'error' });
        notify();
      });
  }

  const getBlockList = (e) => {
    setDistrict(e.target.value);
    setBlock("");
    setPanchayat("");
    setVillage("");
    let postData = [{ "org_id": 100, "user_id": "0", "dist_id": e.target.value }]
    axios({
      method: 'post',
      url: mainurl + 'Geo/geo_block_list',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        let list = response.data.map((row, index) => {
          return <option key={index} value={row.block_code}> {row.block_long_name} </option>
        })
        setBlockOpt(list);
        if(fisrtLoad === 1){
          getPanchayatList({target: {value: data.block_id}});
        }
      })
      .catch(error => {
        let notify = notification({ message: "Block list not Found! Try Again", type: 'error' });
        notify();
      });
  }

  const getPanchayatList = (e) => {
    setBlock(e.target.value);
    setPanchayat("");
    setVillage("");
    let postData = [{ "org_id": 100, "user_id": "0", "block_id": e.target.value }]
    axios({
      method: 'post',
      url: mainurl + 'Geo/geo_pan_list',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        let list = response.data.map((row, index) => {
          return <option key={index} value={row.panchyat_code}> {row.panchyat_name} </option>
        })

        setPanchayatOpt(list);
        if(fisrtLoad === 1){
          getVillageList({target: {value: data.panchyat_code}});
        }
      })
      .catch(error => {
        let notify = notification({ message: "Panchayat list not Found! Try Again", type: 'error' });
        notify();
      });
  }

  const getVillageList = (e) => {
    pg_vo_list(e.target.value);
    setPanchayat(e.target.value);

    let postData = [{ "org_id": 100, "user_id": "128", "panchayat_id": e.target.value }]
    axios({
      method: 'post',
      url: mainurl + 'Geo/geo_village_list',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        let list = response.data.map((row, index) => {
          return <option key={index} value={row.village_code}> {row.village_name} </option>
        })

        setVillageOpt(list);
        if(fisrtLoad === 1){
          setVillage(data.village_code);
          setFisrtLoad(2);
        }

      })
      .catch(error => {
        let notify = notification({ message: "Village list not Found! Try Again", type: 'error' });
        notify();
      });
  }
  // End

  const allocate_handler = (e) => {
    e.preventDefault();

    let postData = [{
      "org_id": "100",
      "aps_id": data.aps_id,
      "aps_name": data.aps_name,
      "aps_name_h": data.aps_name_h,
      "mobile_no": data.mobile_no,
      "pg_id": pg,
      "dist_id": district,
      "block_id": block,
      "panchyat_code": panchayat,
      "village_id": village,
    }]

    console.log(postData);
    setDistrict('0');
    setBlock('0');
    setPanchayat('0');
    setVillage('0');
    setPg('0');
    axios({
      method: 'post',
      url: mainurl + 'Master/aps_allocation',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {

        let notify = notification({ message: "Allocated successfully.", type: 'success' });
        notify();
        aps_allocation_list();
      })
      .catch(error => {
        let notify = notification({ message: "Allocation Failed! Try Again", type: 'error' });
        notify();
      });
  }

  return (
    <Row>
      <Col xs={12}>
        <Card className='mb-3'>
          <CardHeader>Allocation / De-allocation Pashusakhi</CardHeader>
          <CardBody>
            <Row>
              <Col className='mb-3' md={3}>
                <div className='d-flex justify-content-between p-1'>
                  <div><b>Pashusakhi Code</b></div>
                  <div>{data.aps_id}</div>
                </div>
                <div className='d-flex justify-content-between p-1'>
                  <div><b>Pashusakhi Name</b></div>
                  <div>{data.aps_name}</div>
                </div>
                <div className='d-flex justify-content-between p-1'>
                  <div><b>Pashusakhi Name Hindi</b></div>
                  <div>{data.aps_name_h}</div>
                </div>
              </Col>
              <hr/>
              <Col md={12}>
                <DataTable
                  title=""
                  columns={[
                    {
                      name: '#',
                      selector: row => row.sl,
                      width: "5%",
                      sortable: true,
                    },
                    {
                      name: 'District',
                      selector: row => row.district,
                      sortable: true,
                    },
                    {
                      name: 'Block',
                      selector: row => row.block,
                      sortable: false,
                    },
                    {
                      name: 'Panchayat',
                      selector: row => row.panchayat,
                      sortable: false,
                    },
                    {
                      name: 'Village',
                      selector: row => row.village,
                      sortable: false,
                    },
                    {
                      name: 'PG/VO',
                      selector: row => row.pg,
                      sortable: false,
                    },
                    {
                      name: 'Action',
                      selector: row => row.action,
                      sortable: false,
                    },
                  ]}
                  data={allocationList}
                  striped
                  highlightOnHover
                  progressPending={loading}
                />
              </Col>
            </Row>
          </CardBody>

        </Card>

        <Card>
          <CardHeader>Allocate New PG / VO </CardHeader>
          <CardBody>
            <Form onSubmit={allocate_handler}>
              <Row>

                <Col md={2} className="mb-2">
                  <Label for="name">District</Label>
                  <Input id="district" bsSize="sm" type="select" value={district} onChange={(e) => getBlockList(e)}>
                    <option value="0"> --Select District--</option>
                    {districtOpt}
                  </Input>
                </Col>
                <Col xs={2} className="mb-2">
                  <Label for="block"> Block </Label>
                  <Input id="block" bsSize="sm" type="select" value={block} onChange={(e) => getPanchayatList(e)}>
                    <option value="0"> --Select Block--</option>
                    {blockOpt}
                  </Input>
                </Col>

                <Col xs={2} className="mb-2">
                  <Label for="panchyat"> Panchayat </Label>
                  <Input id="panchyat" bsSize="sm" type="select" value={panchayat} onChange={(e) => getVillageList(e)} >
                    <option value="0"> --Select Panchayat--</option>
                    {panchayatOpt}
                  </Input>
                </Col>
                <Col xs={2} className="mb-2">
                  <Label for="village"> Village </Label>
                  <Input id="village" bsSize="sm" type="select" value={village} onChange={(e) => setVillage(e.target.value)} >
                    <option value="0"> --Select Village--</option>
                    {villageOpt}
                  </Input>
                </Col>
                <Col md={3} className="mb-2">
                  <Label for="pg"> PG Name </Label>
                  <Input id="pg" bsSize="sm" type="select" value={pg} onChange={(e) => setPg(e.target.value)} required>
                    <option value=""> --Select PG--</option>
                    {pgOpt}
                  </Input>
                </Col>

                <hr />
                <Col md={2} className="mb-2">
                  <Button color='primary' size="sm" block> Allocate </Button>
                </Col>
              </Row>
            </Form>
          </CardBody>
          <CardFooter className='text-end'>
            <Button color='primary' size="sm" onClick={back_handler}> Back </Button>
          </CardFooter>
        </Card>
      </Col>
    </Row>
  )
}

export default AllocationPashusakhi;