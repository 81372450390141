import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import notification from '../../component/hocs/notification';
import mainurl from '../../MainURL';
import axios from 'axios';
import Page from '../../component/utility/Page';
import DataTable from 'react-data-table-component';

import * as XLSX from 'xlsx/xlsx.mjs';
import {SiMicrosoftexcel} from 'react-icons/si';
import Tooltip from '@mui/material/Tooltip';
import {
    Col, Row, Card, CardHeader, CardBody
} from 'reactstrap';

import {MdHideImage} from 'react-icons/md';
import {BsFillImageFill } from 'react-icons/bs';

const Feedback = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState("");
    
    const [accData, setAccData] = useState('');

    useEffect(() => {
        // check Access
        let accData = JSON.parse(sessionStorage.getItem('accessControl'));
        setAccData(accData[3].pages[20]);
        if(accData[3].pages[20].page_view !== "1"){
            navigate("/home");
        }
        
    }, []);

    useEffect(() => {
        if(accData){
            get_list();
        }
    }, [accData]);

    const get_list = () => {
        let postData = [{ "org_id": 100, "user_id": '0' }];
        axios({
          method: 'post',
          url: mainurl + 'Master/box_feedback_view',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            
            let list = response.data.map(row => {
                let imgPath = <MdHideImage color="#f70000" size={20} />;
                if(row.image_id !== null){
                    imgPath = <a href={row.image_id+'.png'} target="_blank"><BsFillImageFill className='cursor' color="#0052cc" size={20} /></a>
                }
                return(
                    {
                        ids: row.feedback_id,
                        name: row.aps_name +" ("+ row.aps_id +")",
                        type:row.type_id,
                        type2:row.type_id2,
                        image: imgPath
                    }
                )
            })

            setTableData(list);
          })
          .catch(error => {
            //let notify = notification({ message: "Feedback and Suggestions list not Found! Try Again", type: 'error' });
            //notify();
          });
    }

    const download_excel_handler = () => {
        if(tableData.length > 0){
          let wb = XLSX.utils.book_new(),
          ws = XLSX.utils.json_to_sheet(tableData);
    
          XLSX.utils.book_append_sheet(wb, ws, "Mysheet1");
    
          XLSX.writeFile(wb, "Feedback&Suggestions.xlsx");
        }
      }

    return (
        <>
            <Header />
            <Page title="Feedback and Suggestions" breadcrumbs="Master / Feedback and Suggestions">
                <Row className="p-3">
                    <Col xs={12}>
                        <Card className='mb-2'>
                            <CardHeader>Feedback and Suggestions</CardHeader>
                            <div style={{position:'relative', height:'1px'}}>
                                <Tooltip title="Excel download">
                                    <SiMicrosoftexcel className='excelIcon' size={20} onClick={download_excel_handler} />
                                </Tooltip> 
                            </div>
                            <CardBody>
                                <DataTable
                                    title=""
                                    columns={[
                                        {
                                        name: '#',
                                        selector: row=>row.ids,
                                        sortable: true,
                                        width: '80px'
                                        },
                                        {
                                        name: 'Pashusakhi Name',
                                        selector: row=>row.name,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Type',
                                        selector: row=>row.type,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Type - 2',
                                        selector: row=>row.type2,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Image',
                                        selector: row=>row.image,
                                        sortable: false,
                                        }
                                    ]}
                                    data={tableData}
                                    striped
                                    highlightOnHover
                                    progressPending={loading}
                                    />
                            </CardBody>
                        </Card> 
                    </Col>
                </Row>

            </Page>
            <Footer />
        </>
    )
}

export default Feedback;