import React, { useState, useEffect } from 'react';
import { Col, Row, } from 'reactstrap';
import bihar from '../../assets/geo-data/bihar';
import CheckboxSVGMap from '../hocs/svgMap';


const MapView = ({ serviceName, data, ...restProps }) => {
  const [mapModal, setMapModal] = useState(false);
  const [currLoc, setCurrLoc] = useState();
  const [currValue, setCurrValue] = useState();
  const [mapColor, setMapColor] = useState('');

  useEffect(() => {

    let d = sessionStorage.getItem('dist');
    if(d === '179'){
        setMapColor('ar');
    }else if(d === '192'){
        setMapColor('kr');
    }else if(d === '205'){
        setMapColor('pu');
    }else{
        setMapColor('all');
    }

}, []);
  
  function onMapHover(event){

    if(event.target.attributes['aria-checked'].value === 'true'){
      
      if(data !== null && data !== undefined){
        console.log("aaya")
        let distName = '';  let cVal = '';
        data.map(row => {
          if(event.target.id.toLowerCase() === row.district_name.toLowerCase()){
            distName = event.target.id; 
            cVal = Math.abs(row.actual_value);
          }
        })
        setCurrLoc(distName);
        setCurrValue(cVal);
        setMapModal(true);
      }
    }
  }
  return (
    <Row className="mapView">
      <Col xs={12}>
        <CheckboxSVGMap className={mapColor} selectedLocationIds={['Araria', 'Katihar', 'Purnia']} 
          onLocationMouseOver = {onMapHover} 
          onLocationMouseOut = {() => setMapModal(false)}
          map={bihar} 
        />
      </Col>
      {mapModal === true &&
        <div className="custPopup">
          <div className="text-center p-1">
              <div className="text-primary fw-bold ">
                  {currLoc}
              </div>
              <div className='d-flex justify-content-between'>
                  <div className="" > {serviceName}: </div>
                  <div className="pl-0">{currValue}</div>
              </div>
          </div>
        </div>
        }
    </Row>
  );
};

export default MapView;