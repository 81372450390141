import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import notification from '../../component/hocs/notification';
import mainurl from '../../MainURL';
import axios from 'axios';
import Page from '../../component/utility/Page';

import TargetSetSend from '../../component/utility/master/TargetSetSend';
import { getMaxMonthYear, getCurrentMonthYear } from '../../component/hocs/dateServices';

import {
  Col,
  Row,
  Card,
  CardBody,
  Input,
  Label,
  Button,
  Spinner,
} from 'reactstrap';

const minMonth = getCurrentMonthYear();
const maxMonth = getMaxMonthYear(3);
const TargetSet = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState('');
  const [filterData, setFilterData] = useState('');
  const [districtOpt, setDistrictOpt] = useState();
  const [blockOpt, setBlockOpt] = useState();
  const [panchyatOpt, setPanchyatOpt] = useState();
  const [district, setDistrict] = useState("0");
  const [block, setBlock] = useState("0");
  const [panchyat, setPanchyat] = useState("0");
  const [filterTxt, setFilterTxt] = useState("");
  const [monthYear, setMonthYear] = useState(getCurrentMonthYear());
  const [accData, setAccData] = useState('');

  const [dist_role, setDist_role] = useState(false);
  const [block_role, setBlock_role] = useState(false);

  useEffect(() => {
    // check Access
    let accData = JSON.parse(sessionStorage.getItem('accessControl'));
    setAccData(accData[3].pages[2]);
    if(accData[3].pages[2].page_view !== "1"){
        navigate("/home");
    }
    //
    checkRole();
  }, []);

  const checkRole = () => {
    let role = sessionStorage.getItem('role');
    if (role === "101") {
        getDistrictList();
    } else if (role === "102") {
        let dist = sessionStorage.getItem('dist');
        setDist_role(true);
        getBlockList({ target: { value: dist } });
    } else if (role === "103") {
        let dist = sessionStorage.getItem('dist');
        let block = sessionStorage.getItem('block');
        setDist_role(true);
        setBlock_role(true);
        getBlockList({ target: { value: dist } });
        getPanchayatList({ target: { value: block } });

    }
}

  const submit_handler = (e) => {
    e.preventDefault();
    reload();
  }

  const reload = () => {
    setTableData("");
    setFilterData("");
    setLoading(true);

    let year = new Date(monthYear).getFullYear();
    let month = new Date(monthYear).getMonth() + 1;
    let postData = [{ "org_id": 100, "month_id": month, "year_id": year, "dist_id": district, "block_id": block, "pan_id": panchyat }];
    axios({
      method: 'post',
      url: mainurl + 'Geo/master_target_get',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        let result = response.data;

        result.sort((a, b) => {
          if (a.aps_id < b.aps_id) return -1
          return a.aps_id > b.aps_id ? 1 : 0
        })

        let temp_aps_id = result[0].aps_id;
        let temp_target_list = [];
        let temp_format_data = [];
        let format_data = [];

        console.log(result);

        result.map(row => {
          if (row.aps_id == temp_aps_id) {
            let t = { t_key: row.key_id, t_val: row.target_value };
            temp_target_list.push(t);

            temp_format_data = {
              aps_name: row.aps_name_h,
              aps_id: row.aps_id,
              pg_name: row.pg_name_desc,
              month: row.month,
              year: row.year,
              target_list: temp_target_list
            }
          } else {
            format_data.push(temp_format_data);
            console.log(format_data);
            temp_format_data = [];
            temp_target_list = [];
            temp_aps_id = row.aps_id;
            //
            let t = { t_key: row.key_id, t_val: row.target_value };
            temp_target_list.push(t);

            temp_format_data = {
              aps_name: row.aps_name_h,
              aps_id: row.aps_id,
              pg_name: row.pg_name_desc,
              month: row.month,
              year: row.year,
              target_list: temp_target_list
            }

          }

        });
        format_data.push(temp_format_data);
        console.log(format_data)
        setTableData(format_data);
        setFilterData(format_data);
        setLoading(false);
      })
      .catch(error => {
        //let notify = notification({ message: "Data not Found! Try Again", type: 'error' });
        //notify();
        setTableData('');
        setFilterData('');
        setLoading(false);
      });
  }

  const getDistrictList = () => {
    let postData = [{ "org_id": 100, "user_id": "0" }];
    axios({
      method: 'post',
      url: mainurl + 'Geo/geo_district_list2',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        let list = response.data.map((row, index) => {
          return <option key={index} value={row.district_short_cd}> {row.district_name} </option>
        })

        setDistrictOpt(list);
      })
      .catch(error => {
        let notify = notification({ message: "District list not Found! Try Again", type: 'error' });
        notify();
      });
  }

  const getBlockList = (e) => {
    setDistrict(e.target.value);
    setBlock("0");
    setPanchyat("0");
    let postData = [{ "org_id": 100, "user_id": "0", "dist_id": e.target.value }]
    axios({
      method: 'post',
      url: mainurl + 'Geo/geo_block_list',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        let list = response.data.map((row, index) => {
          return <option key={index} value={row.block_code}> {row.block_long_name} </option>
        })

        setBlockOpt(list);
      })
      .catch(error => {
        let notify = notification({ message: "Block list not Found! Try Again", type: 'error' });
        notify();
      });
  }

  const getPanchayatList = (e) => {
    setPanchyat("0");
    setBlock(e.target.value);

    let postData = [{ "org_id": 100, "user_id": "0", "block_id": e.target.value }]
    axios({
      method: 'post',
      url: mainurl + 'Geo/geo_pan_list',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        let list = response.data.map((row, index) => {
          return <option key={index} value={row.panchyat_code}> {row.panchyat_name} </option>
        })

        setPanchyatOpt(list);
      })
      .catch(error => {
        let notify = notification({ message: "Panchayat list not Found! Try Again", type: 'error' });
        notify();
      });
  }

  const filterHandler = (e) => {
    setFilterTxt(e.target.value);
    if (tableData.length > 0) {
      const query = e.target.value.toLowerCase();
      let filterData = tableData.filter(row => {

        return (
          row.aps_name.toLowerCase().indexOf(query) >= 0
        )
      })
      setFilterData(filterData);
    }
  }

  const clearHandler = () => {
    setFilterData(tableData);
    setFilterTxt("")
  }

  return (
    <div>
      <Header />
      <Page title="Target set" breadcrumbs="Master / Target set">

        <Row className='p-3 pb-1'>
          <Col xs={12} className="mb-1">
            <Card>
              <CardBody>
                <form onSubmit={submit_handler}>
                  <Row>
                    <Col className="mb-1">
                      <Label className="me-sm-2" for="district"> Month - Year </Label>
                      <Input
                        type="month"
                        name="monthYear"
                        bsSize="sm"
                        min={minMonth} max={maxMonth}
                        defaultValue={monthYear}
                        onChange={(e) => setMonthYear(e.target.value)}
                        required
                      />
                    </Col>
                    <Col className="mb-1">
                      <Label className="me-sm-2" for="district"> District </Label>
                      <Input id="district" bsSize="sm" type="select" value={district} onChange={getBlockList} disabled={dist_role} required >
                        <option value="0"> --Select District--</option>
                        {districtOpt}
                      </Input>
                    </Col>
                    <Col className="mb-1">
                      <Label className="me-sm-2" for="block"> Block </Label>
                      <Input id="block" bsSize="sm" type="select" value={block} onChange={getPanchayatList} disabled={block_role}>
                        <option value="0"> --Select Block--</option>
                        {blockOpt}
                      </Input>
                    </Col>
                    <Col className="mb-1">
                      <Label className="me-sm-2" for="panchayat"> Panchayat </Label>
                      <Input id="panchayat" bsSize="sm" type="select" value={panchyat} onChange={(e) => setPanchyat(e.target.value)}>
                        <option value="0"> --Select Panchayat--</option>
                        {panchyatOpt}
                      </Input>
                    </Col>
                    <Col className="mb-1">
                      <Button className="mt-4" size="sm" color="primary" > Submit </Button>
                    </Col>

                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row className='p-3 pb-1 pt-1'>
          <Col sm={{ offset: 9, size: 3 }} className="mb-1 d-flex">
            <Input placeholder="Search by Pashusakhi Name" bsSize="sm" value={filterTxt} onChange={filterHandler} />
            <Button className="ms-2" size="sm" color="danger" onClick={clearHandler}>
              Clear
            </Button>
          </Col>
        </Row>

        <Row className='p-3 pb-1 pt-1'>
          <Col lg={12} md={12} sm={12} xs={12} className="mb-1">
            <Card>
              <CardBody>
                <Row className="p-1 mb-2" style={{ fontWeight: "500" }}>
                  <Col xs="1"> SRL </Col>
                  <Col xs="2"> Pashusakhi </Col>
                  <Col xs="2"> Vaccination </Col>
                  <Col xs="2"> Deworming </Col>
                  <Col xs="2"> Castration </Col>
                  <Col xs="2"> Natal Care </Col>
                  <Col xs="1"> Set </Col>
                </Row>
                <div className='text-center'>
                  {loading && <Spinner color="primary" size="lg" > Loading... </Spinner>}
                </div>
                {filterData &&
                  filterData.map((row, index) => {
                    return <TargetSetSend key={index} data={row} reload={reload} srl={index + 1} add={accData.page_add} />
                  })
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
      <Footer />
    </div>

  );
}

export default TargetSet;
