import React, { useEffect, useState } from 'react';
import notification from '../../hocs/notification';
import axios from 'axios';
import mainurl from '../../../MainURL';
import Select from 'react-select';

import {ImArrowRight} from 'react-icons/im';

import {
  Col, Row, Card, CardHeader, CardBody, CardFooter, Input, Label, Form, Spinner, Button
} from 'reactstrap';

const ShareTransfer = ({data, back_handler, ...props }) => {

  const [loading, setLoading] = useState(false);
  const [transDate, setTransDate] = useState('');
  const [noShare, setNoShare] = useState('');
  const [remarks, setRemarks] = useState('');
  const [totalShare, setTotalShare] = useState(0);

  const [districtOpt, setDistrictOpt] = useState();
  const [blockOpt, setBlockOpt] = useState();
  const [panchyatOpt, setPanchyatOpt] = useState();
  const [villageOpt, setVillageOpt] = useState();
  const [pgOpt, setPgOpt] = useState();
  const [district, setDistrict] = useState("0");
  const [block, setBlock] = useState("0");
  const [panchyat, setPanchyat] = useState("0");
  const [village, setVillage] = useState("0");
  const [pg, setPg] = useState("0");

  const [farmerFr, setFarmerfr] = useState(data.f_id);
  const [farmerTo, setFarmerTo] = useState("0");
  const [nameTo, setNameTo] = useState("0");
  const [nameHinTo, setNameHinTo] = useState("0");
  const [spoNameTo, setSpoNameTo] = useState("0");
  const [spoNameHinTo, setSpoNameHinTo] = useState("0");
  const [appNoTo, setAppNoTo] = useState("0");


  const [farmerList, setFarmerList] = useState("");

  useEffect(() => {
    getDistrictList();
    total_share_handler();
  }, [])

  const getDistrictList = () => {

    let postData = [{ "org_id": 100, "user_id": "0" }];
    axios({
      method: 'post',
      url: mainurl + 'Geo/geo_district_list2',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        let list = response.data.map((row, index) => {
          return <option key={index} value={row.district_short_cd}> {row.district_name} </option>
        })

        setDistrictOpt(list);
      })
      .catch(error => {
        let notify = notification({ message: "District list not Found! Try Again", type: 'error' });
        notify();
      });
  }

  const getBlockList = (e) => {
    setDistrict(e.target.value);
    setBlock("0");
    setPanchyat("0");
    setVillage("0");
    setPg("0");
    let postData = [{ "org_id": 100, "user_id": "0", "dist_id": e.target.value }]
    axios({
      method: 'post',
      url: mainurl + 'Geo/geo_block_list',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        let list = response.data.map((row, index) => {
          return <option key={index} value={row.block_code}> {row.block_long_name} </option>
        })
        setBlockOpt(list);
      })
      .catch(error => {
        let notify = notification({ message: "Block list not Found! Try Again", type: 'error' });
        notify();
      });
  }

  const getPanchayatList = (e) => {
    setBlock(e.target.value);
    setPanchyat("0");
    setVillage("0");
    setPg("0");
    let postData = [{ "org_id": 100, "user_id": "0", "block_id": e.target.value }]
    axios({
      method: 'post',
      url: mainurl + 'Geo/geo_pan_list',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        let list = response.data.map((row, index) => {
          return <option key={index} value={row.panchyat_code}> {row.panchyat_name} </option>
        })

        setPanchyatOpt(list);
      })
      .catch(error => {
        let notify = notification({ message: "Panchayat list not Found! Try Again", type: 'error' });
        notify();
      });
  }
  const getVillageList = (e) => {
    setPanchyat(e.target.value);
    pg_vo_list(e.target.value);
    setVillage("0");
    let postData = [{ "org_id": 100, "user_id": "0", "panchayat_id": e.target.value }]
    axios({
      method: 'post',
      url: mainurl + 'Geo/geo_village_list',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        let list = response.data.map((row, index) => {
          return <option key={index} value={row.village_code}> {row.village_name} </option>
        })

        setVillageOpt(list);
      })
      .catch(error => {
        let notify = notification({ message: "Village list not Found! Try Again", type: 'error' });
        notify();
      });
  }

  const pg_vo_list = (id) => {
    // PG list
    let postData = [{ "org_id": 100, "panchyat_id": id }];
    axios({
      method: 'post',
      url: mainurl + 'Master/pg_list_pan_wise',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        let list = response.data.map((row, index) => {
          return <option key={index} value={row.pg_no}> {row.pg_name_desc} </option>
        })
        setPgOpt(list);

      })
      .catch(error => {
        let notify = notification({ message: "PG not Found! Try Again", type: 'error' });
        notify();
      });
  }

  const farmer_handler = () => {
    setFarmerList("");
    setFarmerTo("");
    setNameTo("");
    setNameHinTo("");
    setAppNoTo("");
    setSpoNameTo("");
    setSpoNameHinTo("");
    let postData = [{
      "org_id": "100",
      "user_id": "128",
      "dist_id": district,
      "block_id": block,
      "pachyt_id": panchyat,
      "vill_id": village,
      "pg_id": pg
    }]
    console.log(postData)
    axios({
      method: 'post',
      url: mainurl + 'Master/box_master_farmer_list',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        if (response.data.length > 0) {
          let list = response.data.map((row, index) => {
            return (
              { value: row.f_id, 
                label: row.f_first_name, 
                name_hin:row.f_last_name, 
                app_no: row.application_form_no, 
                spo_name: row.f_father_husband_name,
                spo_name_hin: row.f_husband_name_h,
              }
            )
          })

          setFarmerList(list);
          setFarmerTo("");
          setNameTo("");
          setNameHinTo("");
          setAppNoTo("");
          setSpoNameTo("");
          setSpoNameHinTo("");
        }
      })
      .catch(error => {
        
        setFarmerTo("");
        setNameTo("");
        setNameHinTo("");
        setAppNoTo("");
        setSpoNameTo("");
        setSpoNameHinTo("");
        //let notify = notification({ message: "Shareholder list not Found! Try Again", type: 'error' });
        //notify();
      });
  }

  const total_share_handler = () => {
    let postData = [{"org_id": 100, "farmer_id":farmerFr }];

    axios({
      method: 'post',
      url: mainurl + 'Geo/share_count_by_farmer',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        setTotalShare(response.data[0].total_share);
      })
      .catch(error => {
        let notify = notification({ message: "Failed! Try Again", type: 'error' });
        notify();
        setLoading(false);
      });
  }

  const share_transfer_handler = (e) => {
    e.preventDefault();
    if(farmerTo.value !== undefined && farmerTo.value !== null ){

      setLoading(true);
      let postData = [{ 
        "org_id": 100, 
        "to_farmer_id": farmerTo.value,
        "fr_farmer_id": farmerFr,
        "trans_date": transDate,
        "no_share": noShare,
        "remark": remarks,
        "trans_type": 1,
      }];
      axios({
        method: 'post',
        url: mainurl + 'Master/share_transfer_withdraw',
        data: postData,
        headers: { 'Content-Type': 'application/JSON', }
      })
        .then(function (response) {
          if(response.data[0].retcode === "101"){
            let notify = notification({ message: "Share Transfer successfully", type: 'success' });
            notify();
            back_handler();
            setLoading(false);
          }
        })
        .catch(error => {
          let notify = notification({ message: "Failed! Try Again", type: 'error' });
          notify();
          setLoading(false);
        });
    }else{
      let notify = notification({ message: "Select Farmer to transfer.", type: 'warn' });
      notify();
    } 
  }

  const handle_farmer = (options) => {
    //console.log(options);
    setFarmerTo(options);
    setNameTo(options.label);
    setNameHinTo(options.name_hin);
    setAppNoTo(options.app_no);
    setSpoNameTo(options.spo_name);
    setSpoNameHinTo(options.spo_name_hin);
  }

  return (
    <>
      <Row>
      <Col sm={5} className="mb-3">
          <Card>
            <CardHeader>Seller Details </CardHeader>
            <CardBody>
                <Row>
                  <Col xs={12}>
                  <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Application Form Number</b></div>
                <div className='m-0'>{data.application_form_no}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Name</b></div>
                <div className='m-0'>{data.f_first_name}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Name (Hindi)</b></div>
                <div className='m-0'>{data.f_last_name}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Spouse Name</b></div>
                <div className='m-0'>{data.f_father_husband_name}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Spouse Name (Hindi)</b></div>
                <div className='m-0'>{data.f_husband_name_h}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>No of share</b></div>
                <div className='m-0'>{totalShare}</div>
              </div>
                  </Col>
                </Row>
            </CardBody>
          </Card>
        </Col>
        <Col sm={2} className="mb-3">
          <div className='d-flex justify-content-center align-items-center h-100'>
            <ImArrowRight size={40} color="#00a500" />
          </div>
        </Col>
        <Col sm={5} className="mb-3">
          <Card>
            <CardHeader>Buyer Details </CardHeader>
            <CardBody>
                <Row>
                  <Col xs={12}>
                  <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Application Form Number</b></div>
                <div className='m-0'>{appNoTo}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Name</b></div>
                <div className='m-0'>{nameTo}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Name (Hindi)</b></div>
                <div className='m-0'>{nameHinTo}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Spouse Name</b></div>
                <div className='m-0'>{spoNameTo}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Spouse Name (Hindi)</b></div>
                <div className='m-0'>{spoNameHinTo}</div>
              </div>
                  </Col>
                </Row>
            </CardBody>
          </Card>
        </Col>


        <Col sm={12} className="mb-3">
          <Card>
            <CardHeader>Share Transfer </CardHeader>
            <CardBody>
              <Row>
                <Col xs={2} className="mb-2">
                  <Label className="me-sm-2" for="district"> District </Label>
                  <Input id="district" bsSize="sm" type="select" value={district} onChange={getBlockList} >
                    <option value="0"> --Select District--</option>
                    {districtOpt}
                  </Input>
                </Col>
                <Col xs={2} className="mb-2">
                  <Label className="me-sm-2" for="block"> Block </Label>
                  <Input id="block" bsSize="sm" type="select" value={block} onChange={getPanchayatList} >
                    <option value="0"> --Select Block--</option>
                    {blockOpt}
                  </Input>
                </Col>
                <Col xs={2} className="mb-2">
                  <Label className="me-sm-2" for="panchayat"> Panchayat </Label>
                  <Input id="panchayat" bsSize="sm" type="select" value={panchyat} onChange={getVillageList}>
                    <option value="0"> --Select Panchayat--</option>
                    {panchyatOpt}
                  </Input>
                </Col>
                <Col md={2} className="mb-2">
                  <Label className="me-sm-2" for="pg"> PG / VO </Label>
                  <Input id="pg" bsSize="sm" type="select" value={pg} onChange={(e) => setPg(e.target.value)} required>
                    <option value="0"> --Select PG/VO --</option>
                    {pgOpt}
                  </Input>
                </Col>
                <Col xs={2} className="mb-2">
                  <Label className="me-sm-2" for="village"> Village </Label>
                  <Input id="village" bsSize="sm" type="select" value={village} onChange={(e) => setVillage(e.target.value)}>
                    <option value="0"> --Select Village--</option>
                    {villageOpt}
                  </Input>
                </Col>
                <Col xs={2} className="mb-2">
                  <Button className="mt-4" color="primary" size="sm" onClick={farmer_handler}> Submit </Button>
                </Col>
              </Row>
              <Form onSubmit={share_transfer_handler}>
                <Row>
                  <Col xs={2}>
                    <Label for="shareholder"> Transfer To </Label>
                    <Select closeMenuOnSelect={false} value={farmerTo} isMulti={false}
                      options={farmerList} onChange={handle_farmer} required/>
                  </Col>
                  <Col xs={2}>
                    <Label for="transDate">Transfer Date </Label>
                    <Input id="transDate" type='date' bsSize="sm" value={transDate} 
                    onChange={(e) => setTransDate(e.target.value)} required/>
                  </Col>
                  <Col xs={2}>
                    <Label for="noShare">Number of Share </Label>
                    <Input id="noShare" type='number' bsSize="sm" value={noShare} min={0} max={totalShare} 
                    onChange={(e) => setNoShare(e.target.value)} required/>
                  </Col>
                  <Col xs={4}>
                    <Label for="remarks"> Remarks </Label>
                    <Input id="remarks" type='textarea' bsSize="sm" value={remarks} row={3}
                    onChange={(e) => setRemarks(e.target.value)} />
                  </Col>
                  <Col xs={2}>
                    <Button className='mt-4' color='primary' size="sm" disabled={loading}> Transfer {loading && <Spinner color="secondary ms-1" size="sm" >Load...</Spinner>}</Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
            <CardFooter className='text-end'>
              <Button color='primary' size="sm" onClick={back_handler}> Back </Button>
            </CardFooter>
          </Card>
        </Col>
        
      </Row>
    </>
  )
}

export default ShareTransfer;