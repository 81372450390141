import React, { useEffect, useState } from 'react';
import notification from '../../hocs/notification';
import axios from 'axios';
import mainurl from '../../../MainURL';

import {
  Col, Row, Card, CardHeader, CardBody, CardFooter, Input, Label, Form, Spinner, Button
} from 'reactstrap';

const ShareWithdraw = ({data, back_handler, ...props }) => {

  const [loading, setLoading] = useState(false);
  const [noShare, setNoShare] = useState('');
  const [shareAmount, setShareAmount] = useState('');
  const [remarks, setRemarks] = useState('');
  const [totalShare, setTotalShare] = useState('');
  const [farmerFr, setFarmerfr] = useState(data.f_id);
  const [transDate, setTransDate] = useState('');

  useEffect(() => {
    total_share_handler();
  }, [])
  const total_share_handler = () => {
    let postData = [{"org_id": 100, "farmer_id":farmerFr }];

    axios({
      method: 'post',
      url: mainurl + 'Geo/share_count_by_farmer',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        setTotalShare(response.data[0].total_share);
      })
      .catch(error => {
        let notify = notification({ message: "Failed! Try Again", type: 'error' });
        notify();
        setLoading(false);
      });
  }

  const share_withdraw_handler = (e) => {
    e.preventDefault();
      setLoading(true);
      let postData = [{ 
        "org_id": 100, 
        "to_farmer_id": 0,
        "fr_farmer_id": farmerFr,
        "trans_date": transDate,
        "no_share": noShare,
        "remark": remarks,
        "trans_type": 2,
      }];
      axios({
        method: 'post',
        url: mainurl + 'Master/share_transfer_withdraw',
        data: postData,
        headers: { 'Content-Type': 'application/JSON', }
      })
        .then(function (response) {
          if(response.data[0].retcode === "101"){
            let notify = notification({ message: "Share withdraw successfully", type: 'success' });
            notify();
            back_handler();
            setLoading(false);
          }
        })
        .catch(error => {
          let notify = notification({ message: "Failed! Try Again", type: 'error' });
          notify();
          setLoading(false);
        });
    
  }

  return (
    <>
      <Row>
      <Col sm={{ offset: 3, size: 6 }} className="mb-3">
          <Card>
            <CardHeader>Withdrawer Details </CardHeader>
            <CardBody>
                <Row>
                  <Col xs={12}>
                  <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Application Form Number</b></div>
                <div className='m-0'>{data.application_form_no}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Name</b></div>
                <div className='m-0'>{data.f_first_name}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Name (Hindi)</b></div>
                <div className='m-0'>{data.f_last_name}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Spouse Name</b></div>
                <div className='m-0'>{data.f_father_husband_name}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Spouse Name (Hindi)</b></div>
                <div className='m-0'>{data.f_husband_name_h}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>No of share</b></div>
                <div className='m-0'>{totalShare}</div>
              </div>
                  </Col>
                </Row>
        
            </CardBody>
          </Card>
        </Col>


        <Col sm={12} className="mb-3">
          <Card>
            <CardHeader>Share Withdraw </CardHeader>
            <CardBody>
              <Form onSubmit={share_withdraw_handler}>
                <Row>
                  <Col xs={2}>
                    <Label for="withdrawDate"> Withdraw Date </Label>
                    <Input id="withdrawDate" type='date' bsSize="sm" value={transDate} 
                    onChange={(e) => setTransDate(e.target.value)} required/>
                  </Col>
                  <Col xs={2}>
                    <Label for="noShare">Number of Share </Label>
                    <Input id="noShare" type='number' bsSize="sm" value={noShare} min={0} max={totalShare}
                    onChange={(e) => setNoShare(e.target.value)} required/>
                  </Col>
                  <Col xs={2}>
                    <Label for="shareAmount"> Share Amount </Label>
                    <Input id="shareAmount" type='number' bsSize="sm" value={shareAmount} 
                    onChange={(e) => setShareAmount(e.target.value)} required/>
                  </Col>
                  
                  <Col xs={4}>
                    <Label for="remarks"> Remarks </Label>
                    <Input id="remarks" type='textarea' bsSize="sm" value={remarks} row={3}
                    onChange={(e) => setRemarks(e.target.value)} />
                  </Col>
                  <Col xs={2}>
                    <Button className='mt-4' color='primary' size="sm" disabled={loading}> Withdraw {loading && <Spinner color="secondary ms-1" size="sm" >Load...</Spinner>}</Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
            <CardFooter className='text-end'>
              <Button color='primary' size="sm" onClick={back_handler}> Back </Button>
            </CardFooter>
          </Card>
        </Col>
        
      </Row>
    </>
  )
}

export default ShareWithdraw;