import React, { useState, useEffect } from 'react';
import notification from '../../hocs/notification';
import mainurl from '../../../MainURL';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { Row, Col, FormGroup, Label, Input, Card, CardBody, Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import * as XLSX from 'xlsx/xlsx.mjs';
import {SiMicrosoftexcel} from 'react-icons/si';
import {BsEyeFill} from 'react-icons/bs';
import Tooltip from '@mui/material/Tooltip';

const HomeTable = () => {
  const [loading, setLoading] = useState(false);
  const [districtOpt, setDistrictOpt] = useState();
  const [blockOpt, setBlockOpt] = useState();
  const [panchyatOpt, setPanchyatOpt] = useState();
  const [district, setDistrict] = useState("");
  const [block, setBlock] = useState("");
  const [panchyat, setPanchyat] = useState("");
  const [tableData, setTableData] = useState();
  const [location, setlLocation] = useState();

  const [dist_role, setDist_role] = useState(false);
  const [block_role, setBlock_role] = useState(false);

  const [viewModal,setViewModal] = useState(false);
  const [farmerList,setFarmerList] = useState("");

  useEffect(() => {
    checkRole();
  }, []);

  const checkRole = () => {
    let role = sessionStorage.getItem('role');
    if (role === "101") {
      init_start();
    } else if (role === "102") {
      let dist = sessionStorage.getItem('dist');
      getDistrictOpt(dist);
      setDist_role(true);
      getBlockList({ target: { value: dist } });
    } else if (role === "103") {
      let dist = sessionStorage.getItem('dist');
      let block = sessionStorage.getItem('block');
      setDist_role(true);
      setBlock_role(true);
      getDistrictOpt(dist);
      getBlockOpt({ target: { value: dist } }, block);
      getPanchyatList({ target: { value: block } });

    }
  }

  // get geo list
  const getDistrictOpt = (dist) => {
    let postData = [{ "org_id": 100, "user_id": "0" }];
    axios({
      method: 'post',
      url: mainurl + 'Geo/geo_district_list2',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        let list = response.data.map((row, index) => {
          return <option key={index} value={row.district_short_cd}> {row.district_name} </option>
        })
        setDistrictOpt(list);
        setDistrict(dist);
      })
      .catch(error => {
        //let notify = notification({ message: "District list not Found! Try Again", type: 'error' });
        //notify();
      });
  }

  const getBlockOpt = (e, block) => {
    let postData = [{ "org_id": 100, "user_id": "0", "dist_id": e.target.value }]
    axios({
      method: 'post',
      url: mainurl + 'Geo/geo_block_list',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        let list = response.data.map((row, index) => {
          return <option key={index} value={row.block_code}> {row.block_long_name} </option>
        })
        setBlockOpt(list);
        setBlock(block);
      })
      .catch(error => {
        //let notify = notification({ message: "Block list not Found! Try Again", type: 'error' });
        //notify();
      });
  }

  //

  const init_start = () => {
    setDistrictOpt('');
    setBlockOpt('');
    setPanchyatOpt('');
    getDistrictList();
  }

  const getDistrictList = () => {
    setLoading(true);
    setBlockOpt('');
    setPanchyatOpt('');
    let postData = [{ "org_id": "100", "user_id": "128", "ops_type": "1", "geo_id": "10" }]
    // Pashushaki
    let aps = [];
    let sendData = [{ "org_id": "100", "user_id": "128", "geo_type": "1", "geo_id": "10" }];
    axios({
      method: 'post',
      url: mainurl + 'Geo/g2_b',
      data: sendData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        response.data.map(r => {
          aps.push(r.aps_c_dist)
        })

      }).catch(error => {
        //let notify = notification({ message: "Data not Found! Try Again", type: 'error' });
        //notify();
      })
    // Farmer
    axios({
      method: 'post',
      url: mainurl + 'Geo/geo_farmer_count',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        let tableData = response.data.map((row, index) => {
          return (
            {
              SRL: index + 1,
              Location: row.district_name,
              Shareholder: row.farmer_c_dist,
              Pashusakhi: aps[index],
            }
          )
        })

        setTableData(tableData);

        let districtOpt = response.data.map((row, index) => {
          return (
            <option value={row.district_short_cd}> {row.district_name} </option>
          )
        })

        setlLocation("District");
        setDistrictOpt(districtOpt);
        setLoading(false);


      }).catch(error => {
        setTableData('');
        setLoading(false);

        //let notify = notification({ message: "District list not Found! Try Again", type: 'error' });
        //notify();
      })


  }

  const getBlockList = (e) => {
    setLoading(true);
    setPanchyatOpt('');
    setDistrict(e.target.value);

    // Pashushaki
    let aps = [];
    let sendData = [{ "org_id": "100", "user_id": "128", "geo_type": "2", "geo_id": e.target.value }];
    axios({
      method: 'post',
      url: mainurl + 'Geo/g2_b',
      data: sendData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        response.data.map(r => {
          aps.push(r.aps_c_block)
        })

        //Farmer

        let postData = [{ "org_id": "100", "user_id": "128", "ops_type": "2", "geo_id": e.target.value }];

        axios({
          method: 'post',
          url: mainurl + 'Geo/geo_farmer_count',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            console.log(response.data);
            let blockOpt = response.data.map(row => {
              return (
                <option value={row.block_code}> {row.block_long_name} </option>
              )
            })

            setBlockOpt(blockOpt);

            let tableData = response.data.map((row, index) => {
              console.log(aps[index])
              return {
                SRL: index + 1,
                Location: row.block_long_name,
                Shareholder: row.farmer_c_block,
                Pashusakhi: aps[index],
              }
            });
            setlLocation("Block");
            setTableData(tableData);
            setLoading(false);

          }).catch(error => {
            setTableData('');
            setLoading(false);
            //let notify = notification({ message: "Block list not Found! Try Again", type: 'error' });
            //notify();
          })

      }).catch(error => {
        //let notify = notification({ message: "Data not Found! Try Again", type: 'error' });
        //notify();
      })



  }

  const getPanchyatList = (e) => {
    setLoading(true);
    setBlock(e.target.value);

    // Pashushaki
    let aps = [];
    let sendData = [{ "org_id": "100", "user_id": "128", "geo_type": "3", "geo_id": e.target.value }];
    axios({
      method: 'post',
      url: mainurl + 'Geo/g2_b',
      data: sendData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        response.data.map(r => {
          aps.push(r.aps_c_panch)
        })
        let postData = [{ "org_id": "100", "user_id": "128", "ops_type": "3", "geo_id": e.target.value }]

    axios({
      method: 'post',
      url: mainurl + 'Geo/geo_farmer_count',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        console.log(response.data);
        let panchyatOpt = response.data.map(row => {
          return (
            <option value={row.panchyat_code}> {row.panchyat_name} </option>
          )
        })

        setPanchyatOpt(panchyatOpt);

        let tableData = response.data.map((row, index) => {
          return {
            SRL: index + 1,
            Location: row.panchyat_name,
            Shareholder: row.farmer_c_panch,
            Pashusakhi: aps[index],
          }
        });
        setlLocation("Panchayat");
        setTableData(tableData);
        setLoading(false);

      }).catch(error => {
        setTableData('');
        setLoading(false);
        //let notify = notification({ message: "Panchayat list not Found! Try Again", type: 'error' });
        //notify();
      })

      }).catch(error => {
        //let notify = notification({ message: "Data not Found! Try Again", type: 'error' });
        //notify();
      })

    
  }

  const getVillageList = (e) => {
    setLoading(true);
    setPanchyat(e.target.value);

    
    let postData = [{ "org_id": "100", "user_id": "128", "ops_type": "4", "geo_id": e.target.value }]

    axios({
      method: 'post',
      url: mainurl + 'Geo/geo_farmer_count',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        console.log(response.data);

        let tableData = response.data.map((row, index) => {
          return {
            SRL: index + 1,
            Location: row.village_name,
            Shareholder: <div>{row.farmer_count_village} <BsEyeFill className='me-3 cursor' color="#0052cc" onClick={()=>shareholder_list(e.target.value, row.village_code)} size={20} /></div>,
            Pashusakhi: "-",
          }
        });
        setlLocation("Village");
        setTableData(tableData);
        setLoading(false);

      }).catch(error => {
        setTableData('');
        setLoading(false);
        //let notify = notification({ message: "Village list not Found! Try Again", type: 'error' });
        //notify();
      })

  }

  const shareholder_list = (pan, vill) => {
    setFarmerList("");
    let postData = [{"org_id": "100", "user_id": "128", "dist_id": district, "block_id": block, "pachyt_id": pan, "vill_id":vill}];
    axios({
      method: 'post',
      url: mainurl + 'Master/box_master_farmer_list',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        console.log(response.data);

        let list = response.data.map((row, index) => {
          return {
            SRL: index + 1,
            FarmerID: row.f_id,
            Name: row.f_first_name +' ('+ row.f_last_name +')',
            Village: row.village_name
          }
        });

        setFarmerList(list);
        setViewModal(true);


      }).catch(error => {
        setFarmerList("");
        //let notify = notification({ message: "Farmers list not Found! Try Again", type: 'error' });
        //notify();
      })
  }

  const download_excel_handler = () => {
    if(tableData.length > 0){
      let wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(tableData);

      XLSX.utils.book_append_sheet(wb, ws, "Mysheet1");

      XLSX.writeFile(wb, "shareholder_APS_count.xlsx");
    }
  }

  return (
    <Row className='mt-2'>
      <Col lg="12" md="12" sm="12">
        <Card>
          <CardBody>
            <form>
              <Row>
                <Col xs={3} className="mb-3">
                  <FormGroup>
                    <Label for="district">District</Label>
                    <Input id="district" type="select" bsSize="sm" value={district} onChange={getBlockList} disabled={dist_role}>
                      <option value="">--select--</option>
                      {districtOpt}
                    </Input>
                  </FormGroup>
                </Col>
                <Col xs={3} className="mb-3">
                  <FormGroup>
                    <Label for="block">Block</Label>
                    <Input id="block" type="select" bsSize="sm" value={block} onChange={getPanchyatList} disabled={block_role}>
                      <option value="">--select--</option>
                      {blockOpt}
                    </Input>
                  </FormGroup>
                </Col>
                <Col xs={3} className="mb-3">
                  <FormGroup>
                    <Label for="panchayat">Panchayat</Label>
                    <Input id="panchayat" type="select" bsSize="sm" value={panchyat} onChange={getVillageList}>
                      <option value="">--select--</option>
                      {panchyatOpt}
                    </Input>
                  </FormGroup>
                </Col>

                <Col xs={3} className="mb-3">
                  <Button className="mt-4" size="sm" onClick={checkRole}>Reset Table</Button>
                </Col>

              </Row>
            </form>
            <div style={{position:'relative', height:'1px'}}>
              <Tooltip title="Excel download">
                <SiMicrosoftexcel className='excelIcon' size={20} onClick={download_excel_handler} />
              </Tooltip> 
            </div>
            <DataTable
              title=""
              columns={[
                {
                  name: 'SRL',
                  selector: 'SRL',
                  sortable: true,
                  width: '80px'
                },
                {
                  name: location,
                  selector: 'Location',
                  sortable: false,
                },
                {
                  name: 'Shareholder',
                  selector: 'Shareholder',
                  sortable: false,
                },
                (location !== 'Village') && {
                  name: 'Pashusakhi',
                  selector: 'Pashusakhi',
                  sortable: false,
                  hide: true 
                },
                

              ]}
              data={tableData}
              pagination
              striped
              highlightOnHover
              progressPending={loading}
            />
          </CardBody>
        </Card>
      </Col>
      <Modal
        centered
        scrollable
        size="lg"
        isOpen={viewModal}
        toggle={() => setViewModal(!viewModal)}
    >
        <ModalHeader toggle={() => setViewModal(!viewModal)}>
            Farmer List
        </ModalHeader>
        <ModalBody>
        <DataTable
              title=""
              columns={[
                {
                  name: 'SRL',
                  selector: row => row.SRL,
                  sortable: true,
                  width: '80px'
                },
                {
                  name: 'Farmer Id',
                  selector: row => row.FarmerID,
                  sortable: false,
                  width: '120px'
                },
                {
                  name: 'Shareholder Name',
                  selector: row => row.Name,
                  sortable: false,
                },
                {
                  name: 'Village',
                  selector: row => row.Village,
                  sortable: false,
                },

              ]}
              data={farmerList}
              pagination
              striped
              highlightOnHover
              progressPending={!farmerList}
            />
            
        </ModalBody>
    </Modal>

    </Row>
  )
}

export default HomeTable;