import React from 'react';
import {
  Col,
  Row,
  Progress,
} from 'reactstrap';


const TopList = ({ name, rank, value, max, color, ...restProps }) => {
  return (
    <Row className='mb-2'>
      <Col className="text-capitalize" xs={4}>{name.toLowerCase()}</Col>
      <Col xs={6}> 
        <Progress
          animated
          color="#000"
          max={max}
          value={value}
        >{value}</Progress>
      </Col>
      <Col xs={2}> {rank}</Col>
    </Row>
  );
};

export default TopList;