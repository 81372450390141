import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import notification from '../../component/hocs/notification';
import mainurl from '../../MainURL';
import axios from 'axios';
import Page from '../../component/utility/Page';
import DataTable from 'react-data-table-component';
import { getCurrentMonthYear } from '../../component/hocs/dateServices';

import * as XLSX from 'xlsx/xlsx.mjs';
import { SiMicrosoftexcel } from 'react-icons/si';
import { FaSearch } from 'react-icons/fa';
import Tooltip from '@mui/material/Tooltip';
import { Col, Row, Card, CardBody, Input, InputGroup, InputGroupText, Label, Button } from 'reactstrap';

const columns = [
    {
        name: '#',
        selector: row => row.sl,
        sortable: true,
        width: "5%"
    },
    {
        name: 'Date',
        selector: row => row.date,
        sortable: true,
    },
    {
        name: 'Type',
        selector: row => row.type,
        sortable: false,
    },
    {
        name: 'Pashusakhi Id ',
        selector: row => row.ids,
        sortable: false,
    },
    {
        name: 'Pashusakhi Name ',
        selector: row => row.name,
        sortable: false,
    },
    {
        name: 'Medicine Name',
        selector: row => row.desc,
        sortable: false,
        width: "20%"
    },
    {
        name: 'Amount',
        selector: row => row.amount,
        sortable: false,
    },
    {
        name: 'Current Balance',
        selector: row => row.stock,
        sortable: false,
    },
]
const SalePurchase = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState('');
    const [excelData, setExcelData] = useState('');
    const [filter, setFilter] = useState('');
    const [searchTxt, setSearchTxt] = useState('');
    const [searchData, setSearchData] = useState('');

    const [districtOpt, setDistrictOpt] = useState();
    const [blockOpt, setBlockOpt] = useState();
    const [district, setDistrict] = useState("0");
    const [block, setBlock] = useState("0");

    const [monthYear, setMonthYear] = useState(getCurrentMonthYear());

    const [dist_role, setDist_role] = useState(false);
  const [block_role, setBlock_role] = useState(false);
  const [count, setCount] = useState(1);

    useEffect(() => {
        // check Access
        let accData = JSON.parse(sessionStorage.getItem('accessControl'));

        if (accData[2].pages[2].page_view !== "1") {
            navigate("/home");
        }
        //
        checkRole();
        getDistrictList();
    }, [filter]);
    const checkRole = () => {
        let role = sessionStorage.getItem('role');
        if( role === "101" ){
          getDistrictList();
          setCount(2);
        }else if( role === "102"){
          let dist = sessionStorage.getItem('dist');
          setDist_role(true);
          getBlockList({target:{value: dist}});
          setCount(2);
        }else if( role === "103"){
          let dist = sessionStorage.getItem('dist');
          setDist_role(true);
          setBlock_role(true);
          getBlockList({target:{value: dist}});
    
        }
      }

    const getDistrictList = () => {
        setBlock("0");

        let postData = [{ "org_id": 100, "user_id": "0" }];
        axios({
            method: 'post',
            url: mainurl + 'Geo/geo_district_list2',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return <option key={index} value={row.district_short_cd}> {row.district_name} </option>
                })
                setDistrictOpt(list);
            })
            .catch(error => {
                let notify = notification({ message: "District list not Found! Try Again", type: 'error' });
                notify();
            });
    }

    const getBlockList = (e) => {
        setDistrict(e.target.value);
        setBlock("0");
        let postData = [{ "org_id": 100, "user_id": "0", "dist_id": e.target.value }]
        axios({
            method: 'post',
            url: mainurl + 'Geo/geo_block_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return <option key={index} value={row.block_code}> {row.block_long_name} </option>
                })
                setBlockOpt(list);
                if (count === 1) {
                    setCount(2);
                    let block = sessionStorage.getItem('block');
                    setBlock(block);
                }
            })
            .catch(error => {
                let notify = notification({ message: "Block list not Found! Try Again", type: 'error' });
                notify();
            });
    }

    const submit_handler = () => {
        initLoad();
    }

    const initLoad = () => {
        setTableData('');
        setSearchData('');
        setLoading(true);
        let year = new Date(monthYear).getFullYear();
        let month = new Date(monthYear).getMonth() + 1;

        let postData = [{ "org_id": '100', "user_id": "0", "month": month, "year": year, "dist_id": district, "block_id": block }];

        axios({
            method: 'post',
            url: mainurl + 'Master/mis_sale_purchase',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                if (response.data !== null) {
                    let list = response.data.map((row, index) => {
                        let subject = "Purchase";
                        if (row.stock_type === "2") {
                            subject = "Sale";
                        }
                        return (
                            {
                                key: index,
                                sl: index + 1,
                                date: row.activity_date.split(" ")[0].split('-').reverse().join('-'),
                                type: subject,
                                ids: row.aps_id,
                                name: row.aps_name,
                                desc: row.description,
                                amount: <span>&#8377; {Number(row.price).toFixed(2)}</span>,
                                stock: <span>&#8377; {Number(row.current_stock).toFixed(2)}</span>,

                            }
                        )
                    })
                    setTableData(list);
                    setSearchData(list);
                    setLoading(false);
                    //excel Data
                    let excelData = response.data.filter(i => i.stock_type == filter).map((row, index) => {
                        let subject = "Purchase";
                        if (row.stock_type === "2") {
                            subject = "Sale";
                        }
                        return (
                            {
                                SLN: index + 1,
                                Date: row.activity_date.split(" ")[0].split('-').reverse().join('-'),
                                Type: subject,
                                APS_ID: row.aps_id,
                                APS_Name: row.aps_name,
                                Description: row.description,
                                Amount: Number(row.price).toFixed(2),
                                Current_Balance: Number(row.current_stock).toFixed(2),

                            }
                        )
                    })
                    setExcelData(excelData);

                } else {
                    setTableData('');
                    setSearchData('');
                    setLoading(false);
                    //let notify = notification({ message: "No Data Found! Try Again", type: 'error' });
                    //notify();
                }
            })
            .catch(error => {
                setTableData('');
                setSearchData('');
                setLoading(false);
                //let notify = notification({ message: "No Data Found! Try Again", type: 'error' });
                //notify();
            });

    }

    const filter_handler = (txt) => {
        setLoading(true);
        setFilter(txt);
        if (tableData.length > 0) {
            let tempsearchData = tableData.filter(item => {
                const query = txt.toLowerCase();
                return (
                    item.type.toLowerCase().indexOf(query) >= 0 
                )
            })

            setSearchData(tempsearchData);
            setLoading(false);
        }

    }

    const search_handler = (txt) => {
        setLoading(true);
        setSearchTxt(txt)
        if (tableData.length > 0) {
            let tempsearchData = tableData.filter(item => {
                const query = txt.toLowerCase();
                return (

                    item.name.toLowerCase().indexOf(query) >= 0 ||
                    item.ids.toLowerCase().indexOf(query) >= 0
                )
            })

            setSearchData(tempsearchData);
            setLoading(false);
        }
    }

    const download_excel_handler = () => {
        if (excelData.length > 0) {
            let wb = XLSX.utils.book_new(),
                ws = XLSX.utils.json_to_sheet(excelData);

            XLSX.utils.book_append_sheet(wb, ws, "Mysheet1");

            XLSX.writeFile(wb, "SalePurchase.xlsx");
        }
    }

    return (
        <>
            <Header />
            <Page title="Input Sale & Purchase" breadcrumbs="Stocks / Input Sale & Purchase">
                
                <Row className="p-3">
                    <Col xs={12}>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col xs={3} className="mb-1">
                                        <Label className="me-sm-2" for="date"> Month-Year </Label>
                                        <Input
                                            type="month"
                                            bsSize="sm"
                                            defaultValue={monthYear}
                                            onChange={(e) => setMonthYear(e.target.value)}
                                        />
                                    </Col>
                                    <Col xs={3} className="mb-1">
                                        <Label className="me-sm-2" for="district"> District </Label>
                                        <Input id="district" bsSize="sm" type="select" value={district} onChange={(e) => getBlockList(e)} disabled={dist_role}>
                                            <option value="0"> --Select District--</option>
                                            {districtOpt}
                                        </Input>
                                    </Col>
                                    <Col xs={3} className="mb-1">
                                        <Label className="me-sm-2" for="block"> Block </Label>
                                        <Input id="block" bsSize="sm" type="select" value={block} onChange={(e) => setBlock(e.target.value)} disabled={block_role}>
                                            <option value="0"> --Select Block--</option>
                                            {blockOpt}
                                        </Input>
                                    </Col>
                                    <Col xs={3} className="mb-1">
                                        <Button className="mt-4" color="primary" size="sm" onClick={submit_handler}> Submit </Button>
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col sm={3}>
                                        <Input id="block" bsSize="sm" type="select" value={filter} onChange={(e) => filter_handler(e.target.value)}>
                                            <option value="">Sale & Purchase </option>
                                            <option value="Sale"> Sale </option>
                                            <option value="Purchase"> Purchase </option>
                                        </Input>
                                    </Col>
                                    <Col sm={{ offset: 6, size: 3 }}>
                                        <InputGroup size="sm">
                                            <InputGroupText> <FaSearch /> </InputGroupText>
                                            <Input type="search" placeholder='Search' value={searchTxt} onChange={(e) => search_handler(e.target.value)} />
                                        </InputGroup>
                                    </Col>
                                </Row>

                                <hr />
                                <div style={{ position: 'relative', height: '1px' }}>
                                    <Tooltip title="Excel download">
                                        <SiMicrosoftexcel className='excelIcon' size={20} onClick={download_excel_handler} />
                                    </Tooltip>
                                </div>
                                <DataTable
                                    title=""
                                    columns={columns}
                                    data={searchData}
                                    striped
                                    highlightOnHover
                                    progressPending={loading}

                                />
                            </CardBody>
                        </Card>

                    </Col>
                </Row>
            </Page>

            <Footer />
        </>
    )
}
export default SalePurchase;