import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import notification from '../../component/hocs/notification';
import mainurl from '../../MainURL';
import axios from 'axios';
import Page from '../../component/utility/Page';
import DataTable from 'react-data-table-component';
// 
import * as XLSX from 'xlsx/xlsx.mjs';
import {SiMicrosoftexcel} from 'react-icons/si';
import Tooltip from '@mui/material/Tooltip'; 

import {
    Col,
    Row,
    Card,
    CardBody,
    Form,
    Button,
    Input,
    Label,
} from 'reactstrap';

import { FaRupeeSign } from "react-icons/fa";


const InputStock = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState('');
    const [excelData, setExcelData] = useState('');
    const [districtOpt, setDistrictOpt] = useState();
    const [blockOpt, setBlockOpt] = useState();
    const [panchyatOpt, setPanchyatOpt] = useState();
    const [district, setDistrict] = useState("");
    const [block, setBlock] = useState("");
    const [panchyat, setPanchyat] = useState("");
    const [pashusakhi, setPashusakhi] = useState("");
    const [pashusakhiOpt, setPashusakhiOpt] = useState("");
    const [pashusakhiName, setPashusakhiName] = useState("");

    const [dist_role, setDist_role] = useState(false);
    const [block_role, setBlock_role] = useState(false);

    useEffect(() => {
        // check Access
        let accData = JSON.parse(sessionStorage.getItem('accessControl'));
        
        if(accData[2].pages[3].page_view !== "1"){
        navigate("/home");
        }
        //
        checkRole();
        getDistrictList();
    }, []);

    const checkRole = () => {
        let role = sessionStorage.getItem('role');
        if( role === "101" ){
          getDistrictList();
        }else if( role === "102"){
          let dist = sessionStorage.getItem('dist');
          setDist_role(true);
          getBlockList({target:{value: dist}});
        }else if( role === "103"){
          let dist = sessionStorage.getItem('dist');
          let block = sessionStorage.getItem('block');
          setDist_role(true);
          setBlock_role(true);
          getBlockList({target:{value: dist}});
          getPanchayatList({target:{value: block}});
    
        }
    }

    const getDistrictList = () => {
        setTableData("");
        let postData = [{ "org_id": 100, "user_id": "0" }];
        axios({
            method: 'post',
            url: mainurl + 'Geo/geo_district_list2',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return <option key={index} value={row.district_short_cd}> {row.district_name} </option>
                })

                setDistrictOpt(list)
            })
            .catch(error => {
                let notify = notification({ message: "District list not Found! Try Again", type: 'error' });
                notify();
            });
    }

    const getBlockList = (e) => {
        setBlock("");
        setPanchyat("");
        setTableData("");
        setExcelData("");
        setDistrict(e.target.value);
        let postData = [{ "org_id": 100, "user_id": "0", "dist_id": e.target.value }]
        axios({
            method: 'post',
            url: mainurl + 'Geo/geo_block_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return <option key={index} value={row.block_code}> {row.block_long_name} </option>
                })

                setBlockOpt(list);
            })
            .catch(error => {
                let notify = notification({ message: "Block list not Found! Try Again", type: 'error' });
                notify();
            });
    }

    const getPanchayatList = (e) => {
        setPanchyat("");
        setTableData("");
        setExcelData("")
        setBlock(e.target.value);

        let postData = [{ "org_id": 100, "user_id": "0", "block_id": e.target.value }]
        axios({
            method: 'post',
            url: mainurl + 'Geo/geo_pan_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return <option key={index} value={row.panchyat_code}> {row.panchyat_name} </option>
                })

                setPanchyatOpt(list);
            })
            .catch(error => {
                let notify = notification({ message: "Panchayat list not Found! Try Again", type: 'error' });
                notify();
            });
    }
    const getPashusakhiList = (e) => {
        setTableData("");
        setExcelData("");
        setPashusakhi("");
        setPashusakhiName("");
        setPanchyat(e.target.value);

        let postData = [{ "org_id": 101, "user_id": 128, "geo_type_id": "3", "geo_id": e.target.value }]
        axios({
            method: 'post',
            url: mainurl + 'Geo/geo_aps_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    let name = row.aps_name + '[' + row.aps_id + ']';
                    return <option key={index} value={row.aps_id}> {name} </option>
                })

                setPashusakhiOpt(list);
            })
            .catch(error => {
                let notify = notification({ message: "pashusakhi list not Found! Try Again", type: 'error' });
                notify();
            });
    }

    const submit_handler = (e) => {
        e.preventDefault();
        reload();
    }

    const reload = () => {
        setLoading(true);
        let postData = [{ "org_id": 101, "user_id": 128, "aps_id": pashusakhi }];
        let totalAmt = 0;
        let totalAmt2 = 0;
        axios({
            method: 'post',
            url: mainurl + 'Activity/input_stock_details',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let tableData = response.data.map(row => {
                    let amt = Number(Number(row.medicine_stock) * Number(row.price)).toFixed(2);
                    totalAmt = Number(totalAmt) + Number(amt);
                    return {
                        medicineName: row.description,
                        stock: row.medicine_stock,
                        unit: row.uom_name,
                        price_unit: row.price,
                        price: <div><FaRupeeSign /> {amt}</div>,
                        /*action: <div >
                            <Form className='d-flex' onSubmit={(e) => stock_add_handler(e, row)}>
                                <Input type="number" placeholder="Quantity" style={{ width: "110px", marginRight: "10px" }} required />
                                <Input type="price" placeholder="Price/Unit" style={{ width: "110px", marginRight: "10px" }} required />
                                <Button size="sm">Add</Button>
                            </Form>
                        </div>*/
                    }
                })
                tableData.push({
                    medicineName: <b>Total</b>,
                    stock: "",
                    unit: "",
                    price_unit: "",
                    price: <b><FaRupeeSign />{totalAmt.toFixed(2)}</b>
                })

                setTableData(tableData);
                setLoading(false);

                // excel export data
                let excelData = response.data.map(row => {
                    let amt = Number(Number(row.medicine_stock) * Number(row.price)).toFixed(2);
                    totalAmt2 = Number(totalAmt2) + Number(amt);
                    return {
                        medicineName: row.description,
                        stock: row.medicine_stock,
                        unit: row.uom_name,
                        price_unit: row.price,
                        price: amt,
                    }
                })
                excelData.push({
                    medicineName: <b>Total</b>,
                    stock: "",
                    unit: "",
                    price_unit: "",
                    price: totalAmt2.toFixed(2),
                })
                setExcelData(excelData);
            })
            .catch(error => {
                setTableData("");
                setLoading(false);
                //let notify = notification({ message: "Stock not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const stock_add_handler = (e, dtl) => {
        e.preventDefault();

        let postData = [{ "org_id": 101, "user_id": 128, "aps_id": pashusakhi, "medicine_id": dtl.medicine_id, "qty": e.target[0].value, "unit_price": e.target[1].value }];
        axios({
            method: 'post',
            url: mainurl + 'Activity/input_stock_update',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                reload();
                let notify = notification({ message: "Stock added successfully!", type: 'success' });
                notify();

            })
            .catch(error => {
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            });

    }
    const download_excel_handler = () => {
        if(excelData.length > 0){
          let wb = XLSX.utils.book_new(),
          ws = XLSX.utils.json_to_sheet(excelData);
    
          XLSX.utils.book_append_sheet(wb, ws, "Mysheet1");
    
          XLSX.writeFile(wb, "InputStock.xlsx");
        }
      }

    return (
        <div>
            <Header />

            <Page title="Input Stock" breadcrumbs="Stock / Input Stock">
                <Row className='p-3'>
                    <Col xs={12}>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col xs={2} className="mb-1">
                                        <Label className="me-sm-2" for="district"> District </Label>
                                        <Input id="district" bsSize="sm" type="select" value={district} onChange={getBlockList} disabled={dist_role}>
                                            <option value=""> --Select District--</option>
                                            {districtOpt}
                                        </Input>
                                    </Col>
                                    <Col xs={2} className="mb-1">
                                        <Label className="me-sm-2" for="block"> Block </Label>
                                        <Input id="block" bsSize="sm" type="select" value={block} onChange={getPanchayatList} disabled={block_role}>
                                            <option value=""> --Select Block--</option>
                                            {blockOpt}
                                        </Input>
                                    </Col>
                                    <Col xs={2} className="mb-1">
                                        <Label className="me-sm-2" for="panchayat"> Panchayat </Label>
                                        <Input id="panchayat" bsSize="sm" type="select" value={panchyat} onChange={getPashusakhiList}>
                                            <option value=""> --Select Panchayat--</option>
                                            {panchyatOpt}
                                        </Input>
                                    </Col>
                                    <Col xs={3} className="mb-1">
                                        <Label className="me-sm-2" for="pashusakhi"> Pashusakhi </Label>
                                        <Input id="pashusakhi" bsSize="sm" type="select" value={pashusakhi} onChange={(e) => { setPashusakhi(e.target.value); setPashusakhiName(e.target.selectedOptions[0].text); setTableData("") }}>
                                            <option value=""> --Select Pashusakhi--</option>
                                            {pashusakhiOpt}
                                        </Input>
                                    </Col>
                                    <Col xs={2} className="mb-1">
                                        <Button className="mt-4" color="primary" size="sm" onClick={submit_handler} disabled={!pashusakhi}> Submit </Button>
                                    </Col>
                                </Row>
                                <hr />

                                <Row>
                                    <Col xs={12}>
                                    <div style={{position:'relative', height:'1px'}}>
                                        <Tooltip title="Excel download">
                                            <SiMicrosoftexcel className='excelIcon' size={20} onClick={download_excel_handler} />
                                        </Tooltip> 
                                    </div>
                                        <h5>
                                            <b>Pashusakhi Name:</b> {pashusakhiName}
                                        </h5>
                                        <DataTable
                                            title=""
                                            columns={[
                                                {
                                                    name: 'Input Name',
                                                    selector: row=>row.medicineName,
                                                    sortable: true,
                                                    wrap: true,
                                                },
                                                {
                                                    name: 'Stock Quantity',
                                                    selector: row=>row.stock,
                                                    sortable: false,
                                                },
                                                {
                                                    name: 'Saleable Unit',
                                                    selector: row=>row.unit,
                                                    sortable: false,
                                                },
                                                {
                                                    name: 'Price/Unit',
                                                    selector: row=>row.price_unit,
                                                    sortable: false,
                                                },
                                                {
                                                    name: 'Stock Amount',
                                                    selector: row=>row.price,
                                                    sortable: false,
                                                },

                                            ]}
                                            data={tableData}
                                            striped
                                            highlightOnHover
                                            progressPending={loading}
                                        />

                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Page>
            <Footer />
        </div>
    );

}

export default InputStock;
