import React, {useState, useEffect} from 'react';
import notification from '../../hocs/notification';
import axios from 'axios';
import mainurl from '../../../MainURL';
import {
    Col, Row, Button, Input, Label, Form, InputGroup, InputGroupText, Spinner, Card, CardHeader, 
    CardBody, CardFooter
} from 'reactstrap';
import Select from 'react-select';

import { FaHeading, FaCheck, FaTimes } from 'react-icons/fa';

import FileBase64 from 'react-file-base64';

const AddShareholder = ({back_handler, ...props}) => {
    const [loading, setLoading] = useState(false);
    const [uniqueId, setUniqueId] = useState(false);
    const [hindiNameLoading, setHindiNameLoading] = useState(false);

    const [shareholderName, setShareholderName] = useState("");
    const [shareholderNameHin, setShareholderNameHin] = useState("");
    const [fatherName, setFatherName] = useState("");
    const [fatherNameHin, setFatherNameHin] = useState("");
    const [dob, setDob] = useState("");
    const [age, setAge] = useState("");
    const [gender, setGender] = useState("");
    const [category, setCategory] = useState("");
    const [mobileNo, setMobileNo] = useState("");
    const [altMobileNo, setAltMobileNo] = useState("");
    const [email, setEmail] = useState("");
    const [education, setEducation] = useState("");
    const [idType, setIdType] = useState("");
    const [aadharNo, setAadharNo] = useState("");
    const [applicationFormNumber, setApplicationFormNumber] = useState("");
    
    const [state, setState] = useState("");
    const [district, setDistrict] = useState("");
    const [block, setBlock] = useState("");
    const [panchayat, setPanchayat] = useState("");
    const [village, setVillage] = useState("");
    const [pg, setPg] = useState("");
    const [postOffice, setPostOffice] = useState("");
    const [pincode, setPincode] = useState("");
    
    const [accountHolderName, setAccountHolderName] = useState("");
    const [bankName, setBankName] = useState("");
    const [accountNo, setAccountNo] = useState("");
    const [ifsc, setIfsc] = useState("");
    const [branchAddress, setBranchAddress] = useState("");

    const [nominee, setNominee] = useState("");
    const [nomineeRel, setNomineeRel] = useState("");

    const [amountDeposited, setAmountDeposited] = useState("");
    const [depositedDate, setDepositedDate] = useState("");
    const [folioNumber, setFolioNumber] = useState("");
    const [certificateFrom, setCertificateFrom] = useState("");
    const [certificateTo, setCertificateTo] = useState("");
    const [certificateNumber, setCertificateNumber] = useState("");

    const [bankOpt, setBankOpt] = useState("");
    const [districtOpt, setDistrictOpt] = useState("");
    const [blockOpt, setBlockOpt] = useState("");
    const [panchayatOpt, setPanchayatOpt] = useState("");
    const [villageOpt, setVillageOpt] = useState("");
    const [pgOpt, setPgOpt] = useState("");
    const [postOfficeOpt, setPostOfficeOpt] = useState("");
    const [pincodeOpt, setPincodeOpt] = useState("");
    
    const [f_photo, setF_photo] = useState("");
    const [fExt, setFExt] = useState("");
    const [f_valid, setF_valid] = useState(0);
    const [id_photo, setId_photo] = useState("");
    const [idExt, setIdExt] = useState("");
    const [id_valid, setId_valid] = useState(0);
    const [passbook_photo, setPassbook_photo] = useState("");
    const [passbookExt, setPassbookExt] = useState("");
    const [pass_valid, setPass_valid] = useState(0);

    useEffect(() => {
        get_init();
        getDistrictList();
        getPostofficeList();
    }, []);

    const get_init = () => {
        let postData = [{ "org_id": 100, "user_id": "0" }];
        // Bank list  
        axios({
          method: 'post',
          url: mainurl + 'Master/bank_list',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            let list = response.data.map((row, index) => {
              return <option key={index} value={row.bank_id}> {row.bank_name} </option>
            })
            setBankOpt(list);
           
          })
          .catch(error => {
            let notify = notification({ message: "Bank list not Found! Try Again", type: 'error' });
            notify();
          });
    }
    const pg_vo_list = (e) => {
      setPgOpt('');
      setVillage(e.target.value);
      // PG list
      let postData = [{"org_id":100, "dist_id":district, "block_id": block, "pan_id": panchayat, "vill_id": e.target.value}];  
      axios({
        method: 'post',
        url: mainurl + 'Master/pg_list_village_wise',
        data: postData,
        headers: { 'Content-Type': 'application/JSON', }
      })
        .then(function (response) {
          if(response.data.length > 0){
            let list = response.data.map((row, index) => {
              return <option key={index} value={row.pg_id}> {row.pg_name_desc} </option>
            })
            setPgOpt(list);
          }else{
            let notify = notification({ message: "PG list not Found! Try Again", type: 'error' });
            notify();
          }
         
        })
        .catch(error => {
          let notify = notification({ message: "PG list not Found! Try Again", type: 'error' });
          notify();
        });
    }

    // district, block, panchayat, village And Postoffice API call
    const getDistrictList = () => {
        setBlock("");
        setPanchayat("");
        setVillage("");
    
        let postData = [{ "org_id": 100, "user_id": "0" }];
        axios({
          method: 'post',
          url: mainurl + 'Geo/geo_district_list2',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            let list = response.data.map((row, index) => {
              return <option key={index} value={row.district_short_cd}> {row.district_name} </option>
            })
            setDistrictOpt(list);
           
          })
          .catch(error => {
            let notify = notification({ message: "District list not Found! Try Again", type: 'error' });
            notify();
          });
      }
    
      const getBlockList = (e) => {
        setDistrict(e.target.value);
        setBlock("");
        setPanchayat("");
        setVillage("");
        let postData = [{ "org_id": 100, "user_id": "0", "dist_id": e.target.value }]
        axios({
          method: 'post',
          url: mainurl + 'Geo/geo_block_list',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            let list = response.data.map((row, index) => {
              return <option key={index} value={row.block_code}> {row.block_long_name} </option>
            })
            setBlockOpt(list);
            
          })
          .catch(error => {
            let notify = notification({ message: "Block list not Found! Try Again", type: 'error' });
            notify();
          });
      }
    
      const getPanchayatList = (e) => {
        setBlock(e.target.value);
        setPanchayat("");
        setVillage("");
        let postData = [{ "org_id": 100, "user_id": "0", "block_id": e.target.value }]
        axios({
          method: 'post',
          url: mainurl + 'Geo/geo_pan_list',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            let list = response.data.map((row, index) => {
              return <option key={index} value={row.panchyat_code}> {row.panchyat_name} </option>
            })
    
            setPanchayatOpt(list);
            
          })
          .catch(error => {
            let notify = notification({ message: "Panchayat list not Found! Try Again", type: 'error' });
            notify();
          });
      }
      const getVillageList = (e) => {
        setPanchayat(e.target.value);

        let postData = [{ "org_id": 100, "user_id": "128", "panchayat_id": e.target.value }]
        axios({
          method: 'post',
          url: mainurl + 'Geo/geo_village_list',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            let list = response.data.map((row, index) => {
                return <option key={index} value={row.village_code}> {row.village_name} </option>
              })
      
              setVillageOpt(list);
            
          })
          .catch(error => {
            let notify = notification({ message: "Village list not Found! Try Again", type: 'error' });
            notify();
          });
      }

      const getPostofficeList = (id) => {
        setPostOfficeOpt('');
        setPincodeOpt('');
        setPostOffice('');
        setPincode('');
        let postData = [{ "org_id": 100 }];
        axios({
          method: 'post',
          url: mainurl + 'Geo/geo_postoffice_list',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            let list = response.data.map((row, index) => {
              let name = row.postoffice_name +" ("+row.pincode+ ")"
              return (
                { value: row.postoffice_id, label: name, pincode: row.pincode }
              )
            })
            setPostOfficeOpt(list);

          })
          .catch(error => {
            let notify = notification({ message: "Post office list not Found! Try Again", type: 'error' });
            notify();
          });
      }
      // End
 
    
      const translate_text_handler = (id) => {
       
        setHindiNameLoading(true);
        let transTxt;
        if (id === 1){
            transTxt = shareholderName;
        }else{
            transTxt = fatherName; 
        }
        if(transTxt.length > 1){
            let postData = [{ "t_text" : transTxt}];
            axios({
                method: 'post',
                url: mainurl + 'Master/translate_text',
                data: postData,
                headers: { 'Content-Type': 'application/JSON', }
              })
                .then(function (response) {
                    let trans = response.data;
                    if(id === 1){
                        setShareholderNameHin(trans);
                    }else{
                        setFatherNameHin(trans);
                    }
                    setHindiNameLoading(false);
                })
                .catch(error => {
                    setHindiNameLoading(false);
                    let notify = notification({ message: "Hindi Translation is not working! Try Again", type: 'error' });
                    notify();
                  });

        }else{
            setHindiNameLoading(false);
            let notify = notification({ message: "Please enter english name first.", type: 'warn' });
            notify();
        }
    }

    const add_shareholder_handler = (e) => {
        setLoading(true);
        e.preventDefault();
        let flag = true;

        if(!uniqueId){
          flag = false;
          let notify = notification({ message: "ID number already exists.", type: 'warn' });
          notify();
        }
        if(education === ""){
          flag = false;
          let notify = notification({ message: "Please select Education.", type: 'warn' });
          notify();
        }
        if(postOffice === ""){
          flag = false;
          let notify = notification({ message: "Please select Post office.", type: 'warn' });
          notify();
        }
        if(pg === ""){
          flag = false;
          let notify = notification({ message: "Please select PG.", type: 'warn' });
          notify();
        }
        if(village === ""){
          flag = false;
          let notify = notification({ message: "Please select Village. ", type: 'warn' });
          notify();
        }
        if(panchayat === ""){
          flag = false;
          let notify = notification({ message: "Please select Panchayat. ", type: 'warn' });
          notify();
        }
        if(block === ""){
          flag = false;
          let notify = notification({ message: "Please select Block. ", type: 'warn' });
          notify();
        }
        if(district === ""){
          flag = false;
          let notify = notification({ message: "Please select District. ", type: 'warn' });
          notify();
        }
        if(state === ""){
          flag = false;
          let notify = notification({ message: "Please select State. ", type: 'warn' });
          notify();
        }


        if(flag){
          let postData = [
            {
              "org_id": 100,
              "user_id": '128',
              "f_first_name": shareholderName,
              "f_last_name": shareholderNameHin,
              "dob": dob,
              "age": age,
              "mobile_no": mobileNo,
              "mobile_no_2": altMobileNo,
              "gender": gender,
              "f_father_husband_name": fatherName,
              "f_husband_name_h": fatherNameHin,
              "uid": aadharNo,
              "email": email,
              "pg_id": pg,
              "ac_holder_name": accountHolderName,
              "ifsc_code": ifsc,
              "bank_name": bankName,
              "bank_account_no": accountNo,
              "branch_name": branchAddress,
              "category_id": category,
              "nominee": nominee,
              "nominee_relationship": nomineeRel,
              "id_type": idType,
              "amount_deposit": amountDeposited,
              "deposit_date": depositedDate,
              "folio_no": folioNumber,
              "share_certificate_from": certificateFrom,
              "share_certificate_to": certificateTo,
              "share_certificate_no": certificateNumber,
              "application_form_no": applicationFormNumber,
              "education": education,
              "state_id": state,
              "dist_id": district,
              "block_id": block,
              "pachyt_id": panchayat,
              "vill_id": village,
              "postoffice_id": postOffice.value,
              "f_photo": f_photo,
              "f_photo_ext": fExt,
              "id_photo": id_photo,
              "id_photo_ext": idExt,
              "passbook_photo": passbook_photo,
              "passbook_photo_ext": passbookExt
            }
          ];
  
          console.log(postData);
  
          axios({
            method: 'post',
            url: mainurl + 'Master/box_shareholder_add',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
          })
            .then(function (response) {
              setLoading(false);
              if(response.data[0].retcode === "101"){
                let notify = notification({ message: "Added successfully", type: 'success' });
                notify();
                back_handler();
              }else if(response.data[0].retcode === "102"){
                let notify = notification({ message: "Application Form Number already exists.", type: 'error' });
                notify();
              }
              
            })
            .catch(error => {
              setLoading(false);
              let notify = notification({ message: "submit failed! Try Again", type: 'error' });
              notify();
            });

        }else{
          setLoading(false);
        }
    }

    const check_id_handler = (id) => {
      let postData = [{"uid": id}];

      axios({
        method: 'post',
        url: mainurl + 'Master/check_uid',
        data: postData,
        headers: { 'Content-Type': 'application/JSON', }
      })
        .then(function (response) {
          if(response.data[0].count === "1"){
            let notify = notification({ message: "ID number already exists.", type: 'warn' });
            notify();
            setUniqueId(false);
          }else{
            setUniqueId(true);
          }

        })
        .catch(error => {
          let notify = notification({ message: "submit failed! Try Again", type: 'error' });
          notify();
        });
    }

    const ageCalculateHandler = (e) => {
      
      setDob(e.target.value)
      let birth = e.target.value;

      let yearThen = parseInt(birth.substring(0,4), 10);
      let monthThen = parseInt(birth.substring(5,7), 10);
      let dayThen = parseInt(birth.substring(8,10), 10);
      let today = new Date();
      let birthday = new Date(yearThen, monthThen-1, dayThen);
      let differenceInMilisecond = today.valueOf() - birthday.valueOf();
      let age = Math.floor(differenceInMilisecond / 31536000000);
      setAge(age);
    }

    const reverseAgeCalculateHandler = (e) => {
      setAge(e.target.value)
      var today = new Date();
      
      let cyear = e.target.value;
      let cmonth = today.getMonth();
      let cday = today.getDate();

      
      var year = today.getFullYear();
      var month = today.getMonth();
      var day = today.getDate();
      
      var cdob = new Date(year - cyear, month - cmonth, day - cday - 1);

      var fyear = cdob.getFullYear();
      var fmonth = cdob.getMonth();
      var fday = cdob.getDate();

      let ldob = (fyear + '-' + fmonth + '-' + fday);
      setDob(ldob);
    }

    const handle_postoffice = (options) => {
      setPostOffice(options);
      setPincode(options.pincode);
    }

    const shareholder_image_handler = (files) => {
      console.log(files);
      if( files.size.split(' ')[0] < 500 ){
        
        let imgtype = (files.type).split('/')[1];

        if(imgtype == 'png' || imgtype == 'jpg' || imgtype == 'jpeg' || imgtype == 'bmp' ){
          setF_photo(files.base64); 
          setFExt(imgtype);
          setF_valid(1);
        }else{
          let notify = notification({ message: "Only image file accepted.", type: 'error' });
          notify();
          setF_valid(2);
        } 
      }else{
        let notify = notification({ message: "Image should be less then 500 kb.", type: 'error' });
        notify();
        setF_valid(2);
      }
    }
    const id_image_handler = (files) => {
      console.log(files);
      if( files.size.split(' ')[0] < 500 ){
        
        let imgtype = (files.type).split('/')[1];

        if(imgtype == 'png' || imgtype == 'jpg' || imgtype == 'jpeg' || imgtype == 'bmp' ){
          setId_photo(files.base64); 
          setIdExt(imgtype);
          setId_valid(1);
        }else{
          let notify = notification({ message: "Only image file accepted.", type: 'error' });
          notify();
          setId_valid(2);
        } 
      }else{
        let notify = notification({ message: "Image should be less then 500 kb.", type: 'error' });
        notify();
        setId_valid(2);
      }
    }
    const passbook_image_handler = (files) => {
      console.log(files);
      if( files.size.split(' ')[0] < 500 ){
        
        let imgtype = (files.type).split('/')[1];

        if(imgtype == 'png' || imgtype == 'jpg' || imgtype == 'jpeg' || imgtype == 'bmp' ){
          setPassbook_photo(files.base64); 
          setPassbookExt(imgtype);
          setPass_valid(1);
        }else{
          let notify = notification({ message: "Only image file accepted.", type: 'error' });
          notify();
          setPass_valid(2);
        } 
      }else{
        let notify = notification({ message: "Image should be less then 500 kb.", type: 'error' });
        notify();
        setPass_valid(2);
      }
    }


    return(
        <Card>
            <CardHeader>Add New Shareholder</CardHeader>
            <CardBody>
                <Form onSubmit={add_shareholder_handler}>
                    <Row>
                      <Col xs={12}><h6 className='text-primary'>Basic Details</h6></Col>
                        <Col md={2} className="mb-2">
                            <Label for="name"> Shareholder Name</Label>
                            <Input id="name" type='text' bsSize="sm" maxLength={50} onKeyPress={(e) => !/^[a-zA-Z\s]*$/.test(e.key) && e.preventDefault()} value={shareholderName} onChange={(e) => setShareholderName(e.target.value)} required />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="hName"> Shareholder Name Hindi</Label>
                            <InputGroup size="sm">
                                <Input id="hName" type='text' maxLength={50} value={shareholderNameHin} bsSize="sm" onChange={(e)=> setShareholderNameHin(e.target.value) } />
                                <InputGroupText className='bg-primary cursor' onClick={!hindiNameLoading ? ()=>translate_text_handler(1) : undefined }>
                                    {hindiNameLoading ? <Spinner color="secondary" size="sm">Loading...</Spinner> : <FaHeading color="#fff" />}
                                </InputGroupText>
                            </InputGroup>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="fname"> Spouse Name</Label>
                            <Input id="fname" type='text' bsSize="sm" maxLength={50} onKeyPress={(e) => !/^[a-zA-Z\s]*$/.test(e.key) && e.preventDefault()} value={fatherName} onChange={(e) => setFatherName(e.target.value)} required />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="fhName"> Spouse Name Hindi</Label>
                            <InputGroup size="sm">
                                <Input id="fhName" type='text' maxLength={50} value={fatherNameHin} bsSize="sm" onChange={(e)=>setFatherNameHin(e.target.value)} />
                                <InputGroupText className='bg-primary cursor' onClick={!hindiNameLoading ? ()=>translate_text_handler(2) : undefined }>
                                    {hindiNameLoading ? <Spinner color="secondary" size="sm">Loading...</Spinner> : <FaHeading color="#fff" />}
                                </InputGroupText>
                            </InputGroup>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="gender"> Gender </Label>
                            <Input id="gender" bsSize="sm" type="select" value={gender} onChange={(e)=> setGender(e.target.value)} required>
                                <option value="0"> --Select Gender--</option>
                                <option value="1"> Male </option>
                                <option value="2"> Female </option>
                            </Input>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="category"> Category </Label>
                            <Input id="category" bsSize="sm" type="select" value={category} onChange={(e)=> setCategory(e.target.value)} required>
                                <option value="0"> --Select category--</option>
                                <option value="1"> BC </option>
                                <option value="2"> BC-I </option>
                                <option value="3"> BC-II </option>
                                <option value="4"> EBC </option>
                                <option value="5"> GEN </option>
                                <option value="6"> OBC </option>
                                <option value="7"> SC </option>
                                <option value="8"> ST </option>
                            </Input>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="dob"> DOB</Label>
                            <Input id="dob" type='date' bsSize="sm" value={dob} onChange={ageCalculateHandler} required/>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="age"> Age</Label>
                            <Input id="age" type='text' bsSize="sm" maxLength={2} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} value={age} onChange={reverseAgeCalculateHandler} required/>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="mobileNo"> Mobile Number</Label>
                            <Input id="mobileNo" type='text' bsSize="sm" maxLength={10} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} value={mobileNo} onChange={(e) => setMobileNo(e.target.value)} required/>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="altMobileNo"> Alternate Mobile Number</Label>
                            <Input id="altMobileNo" type='text' bsSize="sm" maxLength={10} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} value={altMobileNo} onChange={(e) => setAltMobileNo(e.target.value)} />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="email"> Email </Label>
                            <Input id="email" type='email' bsSize="sm" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="education"> Education </Label>
                            <Input id="education" bsSize="sm" type="select" value={education} onChange={(e)=> setEducation(e.target.value)} required>
                                <option value=""> --Select Education--</option>
                                <option value="Under Matriculation"> Under Matriculation </option>
                                <option value="Matriculation"> Matriculation </option>
                                <option value="Intermediate"> Intermediate </option>
                                <option value="Graduation"> Graduation </option>
                                <option value="Post Graduation"> Post Graduation </option>
                            </Input>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="idType"> ID Type </Label>
                            <Input id="idType" bsSize="sm" type="select" value={idType} onChange={(e)=> setIdType(e.target.value)} required>
                                <option value=""> --Select Type--</option>
                                <option value="Aadhar Card"> Aadhar Card </option>
                                <option value="Voter ID"> Voter Card </option>
                                <option value="Pan Card"> PAN Card </option>
                                <option value="Driving Licence"> Driving Licence </option>
                            </Input>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="aadhar"> ID Number</Label>
                            <Input id="aadhar" type='text' bsSize="sm" maxLength={16} value={aadharNo} onChange={(e) => setAadharNo(e.target.value)} onBlur={(e) => check_id_handler(e.target.value)} invalid={!uniqueId} valid={uniqueId} required />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="applicationFormNumber"> Application Form Number</Label>
                            <Input id="applicationFormNumber" type='text' bsSize="sm" maxLength={5} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} value={applicationFormNumber} onChange={(e) => setApplicationFormNumber(e.target.value)} required />
                        </Col>
                      
                        <hr />
                        <Col xs={12}><h6 className='text-primary'>Demographic Details</h6></Col>
                        <Col md={2} className="mb-2">
                            <Label for="state"> State </Label>
                            <Input id="state" bsSize="sm" type="select" value={state} onChange={(e)=> setState(e.target.value)} required>
                                <option value=""> --Select State--</option>
                                <option value="10"> BIHAR </option>
                                
                            </Input>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="district"> District </Label>
                            <Input id="district" bsSize="sm" type="select" value={district} onChange={getBlockList} required>
                                <option value=""> --Select District--</option>
                                {districtOpt}
                            </Input>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="block"> Block </Label>
                            <Input id="block" bsSize="sm" type="select" value={block} onChange={getPanchayatList} required>
                                <option value=""> --Select Block--</option>
                                {blockOpt}
                            </Input>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="panchayat"> Panchayat </Label>
                            <Input id="panchayat" bsSize="sm" type="select" value={panchayat} onChange={getVillageList} required>
                                <option value=""> --Select Panchayat--</option>
                                {panchayatOpt}
                            </Input>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="village"> Village </Label>
                            <Input id="village" bsSize="sm" type="select" value={village} onChange={pg_vo_list} required>
                                <option value=""> --Select Village--</option>
                                {villageOpt}
                            </Input>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="pg"> PG / VO </Label>
                            <Input id="pg" bsSize="sm" type="select" value={pg} onChange={(e)=> setPg(e.target.value)} required>
                                <option value=""> --Select PG/VO --</option>
                                {pgOpt}
                            </Input>
                        </Col>
                        <Col md={3} className="mb-2">
                            <Label for="postOffice"> Post Office </Label>
                            <Select closeMenuOnSelect={true} value={postOffice} isMulti={false} placeholder={<div>Select PostOffice</div>}
                            options={postOfficeOpt} onChange={handle_postoffice} required/> 
                            
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="pincode"> Pincode </Label>
                            <Input id="pincode" type='text' bsSize="sm" value={pincode} readOnly/>
                        </Col>
                        
                        <hr />
                        <Col xs={12}><h6 className='text-primary'>Bank Details</h6></Col>
                        <Col md={2} className="mb-2">
                            <Label for="accountHolder"> Account Holder Name </Label>
                            <Input id="accountHolder" type='text' bsSize="sm"  maxLength={50} onKeyPress={(e) => !/^[a-zA-Z\s]*$/.test(e.key) && e.preventDefault()} value={accountHolderName} onChange={(e) => setAccountHolderName(e.target.value)} />
                        </Col>

                        <Col md={2} className="mb-2">
                            <Label for="bankName"> Bank Name </Label>
                            <Input id="bankName" bsSize="sm" type="select" maxLength={50} value={bankName} onChange={(e)=> setBankName(e.target.value)} >
                              <option value=""> --Select Bank--</option>
                              {bankOpt}
                            </Input>
                        </Col>
                        <Col md={2} className="mb-2">
                          <Label for="accountNo"> Account Number </Label>
                          <Input id="accountNo" type='text' bsSize="sm" maxLength={20} value={accountNo} onChange={(e) => setAccountNo(e.target.value)} />
                        </Col>
                        <Col md={2} className="mb-2">
                          <Label for="ifsc"> IFS Code </Label>
                          <Input id="ifsc" type='text' bsSize="sm" maxLength={15} value={ifsc} onChange={(e) => setIfsc(e.target.value)} />
                        </Col>
                        <Col md={2} className="mb-2">
                          <Label for="branchAddress"> Branch Address </Label>
                          <Input id="branchAddress" type='text' bsSize="sm" maxLength={50} value={branchAddress} onChange={(e) => setBranchAddress(e.target.value)} />
                        </Col>

                        <hr />
                        
                        <Col md={2} className="mb-2">
                          <Label for="nominee"> Nominee Name </Label>
                          <Input id="nominee" type='text' bsSize="sm" maxLength={50} onKeyPress={(e) => !/^[a-zA-Z\s]*$/.test(e.key) && e.preventDefault()} value={nominee} onChange={(e) => setNominee(e.target.value)} />
                        </Col>
                        
                        <Col md={2} className="mb-2">
                          <Label for="nomineeRel"> Nominee Relationship </Label>
                          <Input id="nomineeRel" bsSize="sm" type="select" value={nomineeRel} onChange={(e)=> setNomineeRel(e.target.value)} required>
                                <option value=""> --Select Relationship--</option>
                                <option value="Husband"> Husband </option>
                                <option value="Son"> Son </option>
                                <option value="Father"> Father </option>
                                <option value="Mother"> Mother </option>
                                <option value="Brother"> Brother </option>
                                <option value="Father-in-law"> Father-in-law </option>
                                <option value="Daughter"> Daughter </option>
                                <option value="Sister"> Sister </option>
                            </Input>
                        </Col>

                        <hr />

                        <Col md={2} className="mb-2">
                            <Label for="amountDeposited"> Amount Deposited </Label>
                            <Input id="amountDeposited" type='text' bsSize="sm" maxLength={10} value={amountDeposited} onChange={(e) => setAmountDeposited(e.target.value)} />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="depositedDate"> Amount Deposited Date </Label>
                            <Input id="depositedDate" type='date' bsSize="sm" value={depositedDate} onChange={(e) => setDepositedDate(e.target.value)} />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="folioNumber"> Folio Number </Label>
                            <Input id="folioNumber" type='text' bsSize="sm" maxLength={20} value={folioNumber} onChange={(e) => setFolioNumber(e.target.value)} />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="certificateFrom"> Share From </Label>
                            <Input id="certificateFrom" type='text' bsSize="sm" value={certificateFrom} onChange={(e) => setCertificateFrom(e.target.value)} />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="certificateTo"> Share To </Label>
                            <Input id="certificateTo" type='text' bsSize="sm" value={certificateTo} onChange={(e) => setCertificateTo(e.target.value)} />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="certificateNumber"> Share Certificate Number </Label>
                            <Input id="certificateNumber" type='text' bsSize="sm" maxLength={20} value={certificateNumber} onChange={(e) => setCertificateNumber(e.target.value)} />
                        </Col>

                        <hr/>
                        <Col xs={12}><h6 className='text-primary'>Document Upload</h6></Col>
                        <Col xs={12} className="mb-2"><b>Note: </b> Only Image file (jpg, jpeg, png, bmp) should be uploaded. And file should be less then 500 KB.</Col>
                        <Col md={3} className="mb-2">
                          <Label for="certificateNumber"> Sharholder Photo Uplaod </Label>
                          <div className='v_status'>
                            {f_valid === 1 && <FaCheck size={20} color="#00a500" />}
                            {f_valid === 2 && <FaTimes size={20} color="#ff4136" />}
                          </div>
                          <FileBase64
                              multiple={false}
                              onDone={shareholder_image_handler}
                          />
                        </Col>
                        <Col md={3} className="mb-2">
                          <Label for="certificateNumber"> ID Proof Uplaod </Label>
                          <div className='v_status'>
                            {id_valid === 1 && <FaCheck size={20} color="#00a500" />}
                            {id_valid === 2 && <FaTimes size={20} color="#ff4136" />}
                          </div>
                          <FileBase64
                              multiple={false}
                              onDone={id_image_handler} 
                          />
                        </Col>
                        <Col md={3} className="mb-2">
                          <Label for="certificateNumber"> Bank Passbook Uplaod </Label>
                          <div className='v_status'>
                            {pass_valid === 1 && <FaCheck size={20} color="#00a500" />}
                            {pass_valid === 2 && <FaTimes size={20} color="#ff4136" />}
                          </div>
                          <FileBase64
                              multiple={false}
                              onDone={passbook_image_handler} 
                          />
                        </Col>

                        <hr/>

                        <Col md={2} className="mb-2">
                            <Button color='primary' size="sm" block disabled= {loading}> Submit {loading && <Spinner color="secondary ms-1" size="sm" >Load...</Spinner>}</Button>
                        </Col>

                    </Row>

                </Form>
            </CardBody>
            <CardFooter className='text-end'>
                <Button color='primary' size="sm" onClick={back_handler}> Back </Button>
            </CardFooter>
        </Card>
    )
}

export default AddShareholder;