import React, { useState, useEffect } from 'react';
import notification from '../../hocs/notification';

import DataTable from 'react-data-table-component';
import mainUrl from '../../../MainURL';
import axios from 'axios';

import { NumInWords } from '../../hocs/dateServices';
import Tooltip from '@mui/material/Tooltip';
import { Row, Col, Card, CardHeader, CardBody, Button, Form, Input, Label, Spinner, Modal, ModalBody, ModalHeader } from 'reactstrap';

import { getTodayDate } from '../../hocs/dateServices';
import { FaCheck, FaTimes, FaEye } from 'react-icons/fa';

const imageUrl = 'https://microsave.boxfarming.in/v1/h7jj24643khb3589jn5dc4/';

const GenrateBankAdvice = ({ back, ...props }) => {

    const [loading, setLoading] = useState(false);
    const [frmDate, setFrmDate] = useState(getTodayDate());
    const [toDate, setToDate] = useState(getTodayDate());

    const [selectedRows, setSelectedRows] = useState('');
    const [tableData, setTableData] = useState('');
    const [viewModal, setViewModal] = useState(false);
    const [modalData, setModalData] = useState('');
    const [modalTitle, setModalTitle] = useState('');

    const [districtOpt, setDistrictOpt] = useState();
    const [blockOpt, setBlockOpt] = useState();
    const [panchyatOpt, setPanchyatOpt] = useState();
    const [district, setDistrict] = useState("0");
    const [block, setBlock] = useState("0");
    const [panchyat, setPanchyat] = useState("0");

    const [toggledClearRows, setToggleClearRows] = useState(false);
    const [dist_role, setDist_role] = useState(false);
    const [block_role, setBlock_role] = useState(false);
    const [count, setCount] = useState(1);

    useEffect(() => {
        getDistrictList();
    }, []);
    //
    const getDistrictList = () => {
        setBlock("0");
        setPanchyat("0");
        let postData = [{ "org_id": 100, "user_id": "0" }];
        axios({
            method: 'post',
            url: mainUrl + 'Geo/geo_district_list2',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return <option key={index} value={row.district_short_cd}> {row.district_name} </option>
                })
                setDistrictOpt(list);
            })
            .catch(error => {
                let notify = notification({ message: "District list not Found! Try Again", type: 'error' });
                notify();
            });
    }

    const getBlockList = (e) => {
        setDistrict(e.target.value);
        setBlockOpt('');
        setPanchyatOpt('');
        setBlock("0");
        setPanchyat("0");
        let postData = [{ "org_id": 100, "user_id": "0", "dist_id": e.target.value }];
        if (e.target.value !== "0") {
            axios({
                method: 'post',
                url: mainUrl + 'Geo/geo_block_list',
                data: postData,
                headers: { 'Content-Type': 'application/JSON', }
            })
                .then(function (response) {
                    let list = response.data.map((row, index) => {
                        return <option key={index} value={row.block_code}> {row.block_long_name} </option>
                    })
                    setBlockOpt(list);
                    if (count === 1) {
                        setCount(2);
                        let block = sessionStorage.getItem('block');
                        getPanchayatList({ target: { value: block } });
                    }
                })
                .catch(error => {
                    let notify = notification({ message: "Block list not Found! Try Again", type: 'error' });
                    notify();
                });
        }
    }

    const getPanchayatList = (e) => {
        setBlock(e.target.value);
        setPanchyatOpt('');
        setPanchyat("0");
        let postData = [{ "org_id": 100, "user_id": "0", "block_id": e.target.value }]
        if (e.target.value !== "0") {
            axios({
                method: 'post',
                url: mainUrl + 'Geo/geo_pan_list',
                data: postData,
                headers: { 'Content-Type': 'application/JSON', }
            })
                .then(function (response) {
                    let list = response.data.map((row, index) => {
                        return <option key={index} value={row.panchyat_code}> {row.panchyat_name} </option>
                    })

                    setPanchyatOpt(list);
                })
                .catch(error => {
                    let notify = notification({ message: "Panchayat list not Found! Try Again", type: 'error' });
                    notify();
                });
        }
    }

    //

    const purchase_list_handler = (e) => {
        e.preventDefault();
        setLoading(true);
        get_list_handler();
    }

    const get_list_handler = () => {
        setSelectedRows([]);
        let postData = [{ "org_id": 100, "frm_date": frmDate, "to_date": toDate, "t_type": 2, "dist_id": district, "block_id": block, "pan_id": panchyat }];
        axios({
            method: 'post',
            url: mainUrl + 'Activity/goat_sale_purchase_view',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                if (response.data !== null) {

                    let list = response.data.map((row, index) => {
                        let bankinfo = <div>
                            <div><b>Account No:</b> {row.account_no}</div>
                            <div><b>IFSC code:</b> {row.ifsc_code}</div>
                            <div><b>Bank:</b> {row.bank_name}</div>
                            <div><b>branch:</b> {row.branch}</div>
                        </div>;
                        let receipt = 'NA'; let passbook = 'NA';
                        if (row.receipt_img !== '' && row.receipt_img !== null) {
                            receipt = <a href={imageUrl + row.receipt_img} target="_blank" >View</a>;
                        } else {
                            receipt = 'NA';
                        }
                        if (row.bank_passbook_img !== '' && row.bank_passbook_img !== null) {
                            passbook = <a href={imageUrl + row.bank_passbook_img} target="_blank" >View</a>;
                        } else {
                            passbook = 'NA';
                        }
                        let validate;
                        if (row.v_flag === '0') {
                            validate = <span className='text-Muted'>Not Verified</span>
                        } else if (row.v_flag === '1') {
                            validate = <div><span className='text-success'>Verified</span> <FaCheck size={20} color="#00a500" /></div>;
                        } else if (row.v_flag === '2') {
                            validate = <Tooltip title={row.remark}>
                                <span className='text-danger'>Rejected</span>
                                <FaTimes size={20} color="#ff4136" />
                            </Tooltip>;
                        }

                        return {
                            t_id: row.trans_id,
                            t_amt: row.trans_amt,
                            t_acc: row.account_no,
                            t_ifcs: row.ifsc_code,
                            t_bank: row.bank_name,
                            t_branch: row.branch,
                            user: row.aps_name + ' (' + row.user_id + ')',
                            farmer: row.f_first_name,
                            act_date: row.trans_date.split(" ")[0].split('-').reverse().join('-'),
                            qty: row.trans_qty,
                            weight: row.trans_weight + ' kg',
                            amt: <div>&#8377; {row.trans_amt}</div>,
                            receipt: receipt,
                            passbook: passbook,
                            animal_dtl: <FaEye size={20} color="#00a500" onClick={() => detail_view_handler(row.animal_detail)} />,
                            bank_dtl: <Tooltip title={bankinfo}>
                                <a className='cursor'>See</a>
                            </Tooltip>,
                            v_flag: validate

                        }
                    })

                    setTableData(list);
                    setLoading(false);
                } else {
                    setTableData('');
                    setLoading(false);
                }

            })
            .catch(error => {
                let notify = notification({ message: "Goat purchase list not Found! Try Again", type: 'error' });
                notify();
                setLoading(false);
            });
    }


    const detail_view_handler = (data) => {

        let header = <Row>
            <Col><b>Gender</b></Col>
            <Col><b>Age</b></Col>
            <Col><b>Weight</b></Col>
            <Col><b>Price / Kg</b></Col>
        </Row>
        if (data !== null) {
            let m_data = data.map(row => {
                return (
                    <Row>
                        <Col>{row.gender}</Col>
                        <Col>{row.age_group}</Col>
                        <Col>{row.weight} Kg</Col>
                        <Col>&#8377; {Number(row.price).toFixed(2)}</Col>
                    </Row>
                )
            });

            m_data.unshift(header);
            setModalData(m_data);
        } else {
            setModalData("No record found!");
        }
        setModalTitle("Animal Details");
        setViewModal(true);
    }

    /*const handleRowSelected = React.useCallback(state => {
        setSelectedRows(state.selectedRows);
        //console.log(state.selectedRows)
    }, []);*/

    const handleRowSelected = ({ selectedRows }) => {
        setSelectedRows(selectedRows);
    };

    const genrate_bank_advice_handler = () => {
        if (selectedRows.length > 0) {
            let amt = 0;
            let trans_list = [];
            let list = selectedRows.map(row => {
                amt = amt + Number(row.t_amt);
                trans_list.push(row.t_id);
                return (
                    {
                        "trans_id": row.t_id,
                        "farmer_name": row.farmer,
                        "bank": row.t_bank,
                        "branch": row.t_branch,
                        "account_no": row.t_acc,
                        "ifsc_code": row.t_ifcs,
                        "amount": row.t_amt
                    }
                )
            })

            let all_trans_id = JSON.stringify(trans_list);
            all_trans_id = all_trans_id.replaceAll('","', ',');
            all_trans_id = all_trans_id.replaceAll('"', '');
            all_trans_id = all_trans_id.replaceAll('[', '');
            all_trans_id = all_trans_id.replaceAll(']', '');

            let amt_word = NumInWords(amt);

            let postData = [{ p_list: list, total_amount: amt, amt_in_word: amt_word, all_trans_id: all_trans_id }];

            console.log(postData);

            setSelectedRows('');
            setToggleClearRows(!toggledClearRows);
            axios({
                method: 'post',
                url: mainUrl + 'Activity/goat_purchase_payment_pdf',
                data: postData,
                headers: { 'Content-Type': 'application/JSON', }
            })
                .then(function (response) {
                    get_list_handler();
                    let notify = notification({ message: "Bank advice generated successfully.", type: 'success' });
                    notify();
                })
                .catch(error => {
                    let notify = notification({ message: "Failed! Try Again", type: 'error' });
                    notify();
                });

        }
    }

    return (
        <>

            <div className='p-3'>
                <Card>
                    <CardBody>
                        <Form onSubmit={purchase_list_handler}>
                            <Row>
                                <Col xs={2}>
                                    <Label for="frmDate"> From Date </Label>
                                    <Input id="frmDate" type='date' bsSize="sm" max={toDate} value={frmDate} onChange={(e) => setFrmDate(e.target.value)} />
                                </Col>
                                <Col xs={2}>
                                    <Label for="toDate"> To Date </Label>
                                    <Input id="toDate" type='date' bsSize="sm" max={getTodayDate()} value={toDate} onChange={(e) => setToDate(e.target.value)} />
                                </Col>
                                <Col xs={2} className="mb-1">
                                    <Label className="me-sm-2" for="district"> District </Label>
                                    <Input id="district" bsSize="sm" type="select" value={district} onChange={(e) => getBlockList(e)} disabled={dist_role}>
                                        <option value="0"> --All District--</option>
                                        {districtOpt}
                                    </Input>
                                </Col>
                                <Col xs={2} className="mb-1">
                                    <Label className="me-sm-2" for="block"> Block </Label>
                                    <Input id="block" bsSize="sm" type="select" value={block} onChange={(e) => getPanchayatList(e)} disabled={block_role}>
                                        <option value="0"> --All Block--</option>
                                        {blockOpt}
                                    </Input>
                                </Col>
                                <Col xs={2} className="mb-1">
                                    <Label className="me-sm-2" for="panchayat"> Panchayat </Label>
                                    <Input id="panchayat" bsSize="sm" type="select" value={panchyat} onChange={(e) => setPanchyat(e.target.value)}>
                                        <option value="0"> --All Panchayat--</option>
                                        {panchyatOpt}
                                    </Input>
                                </Col>
                                <Col xs={1}>
                                    <Button className='mt-4' color='primary' size="sm" disabled={loading}> Submit {loading && <Spinner color="secondary ms-1" size="sm" >Load...</Spinner>}</Button>
                                </Col>
                                <Col xs={1} className="text-end">
                                    <Button className='mt-4' size="sm" onClick={back}>Back</Button>
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader>Goat Purchase Details</CardHeader>
                    <CardBody>
                        <DataTable
                            title=""
                            columns={[
                                {
                                    name: 'Pashusakhi',
                                    selector: row => row.user,
                                    sortable: false,
                                    width: '9%',
                                    wrap: true
                                },
                                {
                                    name: 'Sharholder',
                                    selector: row => row.farmer,
                                    sortable: false,
                                    width: '9%',
                                    wrap: true
                                },
                                {
                                    name: 'Date',
                                    selector: row => row.act_date,
                                    sortable: false,
                                },
                                {
                                    name: 'Quantity',
                                    selector: row => row.qty,
                                    sortable: false,
                                },
                                {
                                    name: 'Weight',
                                    selector: row => row.weight,
                                    sortable: false,
                                },
                                {
                                    name: 'Amount',
                                    selector: row => row.amt,
                                    sortable: false,
                                },
                                {
                                    name: 'Receipt',
                                    selector: row => row.receipt,
                                    sortable: false,
                                },
                                {
                                    name: 'Passbook',
                                    selector: row => row.passbook,
                                    sortable: false,
                                },
                                {
                                    name: 'Bank Details',
                                    selector: row => row.bank_dtl,
                                    sortable: false,
                                },
                                {
                                    name: 'Animal Details',
                                    selector: row => row.animal_dtl,
                                    sortable: false,
                                },
                                {
                                    name: 'Validate',
                                    selector: row => row.v_flag,
                                    sortable: false,
                                    width: '9%',
                                    wrap: true
                                },

                            ]}
                            data={tableData}
                            striped
                            highlightOnHover
                            selectableRows
                            onSelectedRowsChange={handleRowSelected}
                            progressPending={loading}
                            clearSelectedRows={toggledClearRows}
                        />
                        <div className='text-end mt-3'>
                            <Button color='primary' size="sm" onClick={genrate_bank_advice_handler}
                                disabled={selectedRows.length > 0 ? false : true}>Genrate Bank Advice</Button>
                        </div>

                    </CardBody>
                </Card>
            </div>
            <div className='text-end p-3'>
                <Button className="" size="sm" onClick={back}>Back</Button>
            </div>
            <Modal
                fullscreen={false}
                centered
                scrollable
                size="md"
                isOpen={viewModal}
                toggle={() => setViewModal(!viewModal)}
            >
                <ModalHeader toggle={() => setViewModal(!viewModal)}>
                    {modalTitle}
                </ModalHeader>
                <ModalBody>
                    {modalData}
                </ModalBody>
            </Modal>

        </>
    )
}

export default GenrateBankAdvice;