import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import notification from '../../component/hocs/notification';
import mainurl from '../../MainURL';
import axios from 'axios';
import Page from '../../component/utility/Page';
import DataTable from 'react-data-table-component';

import { FaRupeeSign } from "react-icons/fa";
import {
    Col,
    Row,
    Card,
    CardBody,
    Button,
    Input,
    Modal,
    ModalBody,
    ModalHeader
} from 'reactstrap';

const ServiceRate = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState('');
    const [rateModel, setRateModel] = useState(false);
    const [crrKeyId, setCrrKeyId] = useState('');
    const [crrService, setCrrService] = useState('');
    const [crrRate, setCrrRate] = useState('');
    const [accData, setAccData] = useState('');

    useEffect(() => {
        // check Access
        let accData = JSON.parse(sessionStorage.getItem('accessControl'));
        setAccData(accData[3].pages[3]);
        if(accData[3].pages[3].page_view !== "1"){
            navigate("/home");
        }
        //
        update_service_rate();
    }, []);


    const update_service_rate = () => {
        setTableData('');
        setLoading(true);
        let accData = JSON.parse(sessionStorage.getItem('accessControl'));
        let acc = accData[3].pages[3];

        let postData = [{ "org_id": 100, "user_id": "ARAR3638" }];

        axios({
            method: 'post',
            url: mainurl + 'Geo/activity_price',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let tableData = []
                response.data.map(row => {
                    if (row.key_id === "1") {
                        let temp = {
                            srl: "1",
                            service: "Vaccination",
                            rate: <div><FaRupeeSign /> {row.key_price}</div>,
                            action:  acc.page_add === "1" && <Button color="secondary" size="sm" onClick={()=>rateModelOpen(row.key_id, 'Vaccination')} disabled>Change Rate</Button>,
                        }
                        tableData.push(temp);
                    }
                    if (row.key_id === "2") {
                        let temp = {
                            srl: "2",
                            service: "De-Worming",
                            rate: <div><FaRupeeSign /> {row.key_price}</div>,
                            action: acc.page_add === "1" && <Button color="secondary" size="sm" onClick={()=>rateModelOpen(row.key_id, "De-Worming")} disabled>Change Rate</Button>
                        }
                        tableData.push(temp);
                    }
                    if (row.key_id === "3") {
                        let temp = {
                            srl: "3",
                            service: "Castration",
                            rate: <div><FaRupeeSign /> {row.key_price}</div>,
                            action: acc.page_add === "1" && <Button color="secondary" size="sm" onClick={()=>rateModelOpen(row.key_id, "Castration")} disabled>Change Rate</Button>
                        }
                        tableData.push(temp);
                    }
                    if (row.key_id === "6") {
                        let temp = {
                            srl: "4",
                            service: "Goat Purchase",
                            rate: <div><FaRupeeSign /> {row.key_price}</div>,
                            action: acc.page_add === "1" && <Button color="secondary" size="sm" onClick={()=>rateModelOpen(row.key_id, "Goat Purchase")} >Change Rate</Button>
                        }
                        tableData.push(temp);
                    }
                    if (row.key_id === "7") {
                        let temp = {
                            srl: "5",
                            service: "Shade Visit",
                            rate: <div><FaRupeeSign /> {row.key_price}</div>,
                            action: acc.page_add === "1" && <Button color="secondary" size="sm" onClick={()=>rateModelOpen(row.key_id, "Shade Visit")} disabled>Change Rate</Button>
                        }
                        tableData.push(temp);
                    }
                    if (row.key_id === "8") {
                        let temp = {
                            srl: "6",
                            service: "Post Natal",
                            rate: <div><FaRupeeSign /> {row.key_price}</div>,
                            action: acc.page_add === "1" && <Button color="secondary" size="sm" onClick={()=>rateModelOpen(row.key_id, "Post Natal")} disabled>Change Rate</Button>
                        }
                        tableData.push(temp);
                    }
                    if (row.key_id === "9") {
                        let temp = {
                            srl: "7",
                            service: "First Aid",
                            rate: <div><FaRupeeSign /> {row.key_price}</div>,
                            action: acc.page_add === "1" && <Button color="secondary" size="sm" onClick={()=>rateModelOpen(row.key_id, "First Aid")} disabled>Change Rate</Button>
                        }
                        tableData.push(temp);
                    }

                })

                setTableData(tableData);
                setLoading(false);

            })
            .catch(error => {
                setTableData('');
                setLoading(false);
                //let notify = notification({ message: "Data not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const rateModelOpen = (id, name) => {
        setRateModel(true);
        setCrrKeyId(id);
        setCrrService(name);
    }

    const rate_change_handler = (e) => {

        e.preventDefault();

        let postData = [{ "org_id": 100, "user_id": "ARAR3638", "key_id": crrKeyId, "key_price": crrRate }]
        axios({
            method: 'post',
            url: mainurl + 'Geo/activity_price_update',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let notify = notification({ message: "Rate change successfully.", type: 'success' });
                notify();
                setRateModel(true);
                update_service_rate();
            })
            .catch(error => {
                let notify = notification({ message: "Data not Send! Try Again", type: 'error' });
                notify();
            });
    }

    return (
        <div>
            <Header />

            <Page title="Service Rate" breadcrumbs="Master / Service Rate">
                <Row className='p-3'>
                    <Col>
                        <Card>
                            <CardBody>
                                <DataTable
                                    title=""
                                    columns={[
                                        {
                                            name: 'SRL',
                                            selector: 'srl',
                                            sortable: true,
                                            width: '80px'
                                        },
                                        {
                                            name: 'Service',
                                            selector: 'service',
                                            sortable: false,
                                        },
                                        {
                                            name: 'Current Rate',
                                            selector: 'rate',
                                            sortable: false,
                                        },
                                        {
                                            name: 'Action',
                                            selector: 'action',
                                            sortable: false,
                                        },

                                    ]}
                                    data={tableData}
                                    striped
                                    highlightOnHover
                                    progressPending={loading}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Page>

            <Modal
                centered
                scrollable
                isOpen={rateModel}
                toggle={() => setRateModel(!rateModel)}
            >
                <ModalHeader toggle={() => setRateModel(!rateModel)}>
                    Change Rate ( {crrService} )
                </ModalHeader>
                <ModalBody>
                    <form onSubmit={rate_change_handler}>
                        <Row>
                            <Col>
                                <Input type="number" step=".01" onChange={(e) => setCrrRate(e.target.value)} />
                            </Col>
                            <Col>
                                <Button color="secondary" size="sm" >Change Rate</Button>
                            </Col>
                        </Row>
                    </form>
                </ModalBody>
            </Modal>
            <Footer />
        </div>
    )

}

export default ServiceRate;