import React, { useState, useEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';
import Header from '../component/layout/Header';
import Page from '../component/utility/Page';
import TopBox from '../component/utility/home/TopBox';
import classnames from 'classnames';
import notification from './../component/hocs/notification';

import { getTodayDate } from '../component/hocs/dateServices';

import axios from 'axios';
import mainurl from '../MainURL';

import {
    Row, Col,
} from 'reactstrap';

const Home = () => {
    const [activeTab, setActiveTab] = useState("1");
    const [totDistrict, setTotDistrict] = useState("0");
    const [totBlock, setTotBlock] = useState("0");
    const [totShareholder, setTotShareholder] = useState("0");
    const [totpashusakhi, setTotpashusakhi] = useState("0");

    useEffect(() => {
        let role = sessionStorage.getItem('role');
        if(role === "101"){
            admin_dashboard_stats();
        }else if(role === "102"){
            dist_dashboard_stats();
        }else if(role === "103"){
            block_dashboard_stats();
        }
        
        aps_count_handler();
    }, []);

    const admin_dashboard_stats = () => {

        let sendData = [{ "org_id": "100", "user_id": "0" }]
        axios({
            method: 'post',
            url: mainurl + 'Geo/db_geo_stat',
            data: sendData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                
                response.data.map(row => {
                    if (row.geo_stat_id === "1") {
                        setTotDistrict(row.value);
                    } else if (row.geo_stat_id === "2") {
                        setTotBlock(row.value);
                    } else if (row.geo_stat_id === "5") {
                        setTotShareholder(row.value);
                    } /*else if (row.geo_stat_id === "6") {
                        setTotpashusakhi(row.value);
                    }*/
                })

            }).catch(error => {
                let notify = notification({ message: "Data not Found! Try Again", type: 'error' });
                notify();
            })
    }

    const dist_dashboard_stats = () => {
        let dist_id = sessionStorage.getItem('dist');
        setTotDistrict(1);
        /*// APS Count
        let sendData = [{ "org_id": "100", "user_id": "128", "geo_type": "1", "geo_id": "10" }];
        axios({
            method: 'post',
            url: mainurl + 'Geo/g2_b',
            data: sendData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                response.data.map(row => {
                    if(dist_id === row.district_short_cd){
                        setTotpashusakhi(row.aps_c_dist); 
                    }
                })


            }).catch(error => {
                let notify = notification({ message: "Data not Found! Try Again", type: 'error' });
                notify();
            });*/

        // Shareholder Count
        let postData = [{ "org_id": "100", "user_id": "128", "ops_type": "1", "geo_id": "10" }];

        axios({
            method: 'post',
            url: mainurl + 'Geo/geo_farmer_count',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                response.data.map(row => {
                    if(dist_id === row.district_short_cd){
                        setTotShareholder(row.farmer_c_dist); 
                    }
                })
            }).catch(error => {
                let notify = notification({ message: "Data not Found! Try Again", type: 'error' });
                notify();
            })
            // Block Count
            let postData1 = [{ "org_id": 100, "user_id": "0", "dist_id": dist_id }]
            axios({
              method: 'post',
              url: mainurl + 'Geo/geo_block_list',
              data: postData1,
              headers: { 'Content-Type': 'application/JSON', }
            })
              .then(function (response) {
                setTotBlock(response.data.length);
              })
              .catch(error => {
                let notify = notification({ message: "Block count not Found! Try Again", type: 'error' });
                notify();
              });
    }

    const block_dashboard_stats = () => {
        let dist_id = sessionStorage.getItem('dist');
        let block_id = sessionStorage.getItem('block');
        setTotDistrict(1);
        setTotBlock(1);
        /*// APS Count
        let sendData = [{ "org_id": "100", "user_id": "128", "geo_type": "2", "geo_id": dist_id }];
        axios({
            method: 'post',
            url: mainurl + 'Geo/g2_b',
            data: sendData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                response.data.map(row => {
                    if(block_id === row.block_code){
                        setTotpashusakhi(row.aps_c_block); 
                    }
                })


            }).catch(error => {
                let notify = notification({ message: "Data not Found! Try Again", type: 'error' });
                notify();
            });*/

        // Shareholder Count
        let postData = [{ "org_id": "100", "user_id": "128", "ops_type": "2", "geo_id": dist_id }];

        axios({
            method: 'post',
            url: mainurl + 'Geo/geo_farmer_count',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                response.data.map(row => {
                    if(block_id === row.block_code){
                        setTotShareholder(row.farmer_c_block); 
                    }
                })
            }).catch(error => {
                let notify = notification({ message: "Data not Found! Try Again", type: 'error' });
                notify();
            })
            
    }

    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    }

    const aps_count_handler = () => {
        let dt = new Date();
        dt.setDate(dt.getDate() - 15);
        let cYear = dt.getFullYear();
        let cMonth = (dt.getMonth() + 1).toString().padStart(2, "0");
        let cdate = dt.getDate();

        // formate yyyy-mm-dd
        let fmDate = cYear + "-" + cMonth + "-" + cdate;
        let sendData = [{"org_id": 100, "fm_date": fmDate, "to_date": getTodayDate(), "dist_id": sessionStorage.getItem('dist'), "block_id":sessionStorage.getItem('block')}];
            axios({
                method: 'post',
                url: mainurl + 'Master/bx_datewise_active_user',
                data: sendData,
                headers: { 'Content-Type': 'application/JSON', }
            })
                .then(function (response) {
                    if(response.data !== null){
                        setTotpashusakhi(response.data[1].aps);
                    }
    
                }).catch(error => {
                    //let notify = notification({ message: "Data not Found! Try Again", type: 'error' });
                    //notify();
                });
    }


    return (
        <div>
            <Header />
            <Page title="">
                <TopBox dist={totDistrict} block={totBlock} farmer={totShareholder} aps={totpashusakhi} />

                <Row className='p-2'>
                    <Col xs={12}>

                        <div className='homeTab'>
                            <Link
                                className={classnames({ active: activeTab === '1' })}
                                onClick={() => { toggle('1'); }}
                                to="/home"
                            >
                                MAP View
                            </Link>
                            { sessionStorage.getItem('role') === "101" &&
                            <Link
                                className={classnames({ active: activeTab === '2' })}
                                onClick={() => { toggle('2'); }}
                                to="/home/graph"
                            >
                                Graph View
                            </Link>
                            }
                            <Link
                                className={classnames({ active: activeTab === '3' })}
                                onClick={() => { toggle('3'); }}
                                to="/home/table"
                            >
                                Table View
                            </Link>
                        </div>
                        <Outlet />
                    </Col>

                </Row>
            </Page>
        </div>
    )
}

export default Home;