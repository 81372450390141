import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import notification from '../../component/hocs/notification';
import mainurl from '../../MainURL';
import axios from 'axios';
import Page from '../../component/utility/Page';
import DataTable from 'react-data-table-component';
import { Col, Row, Card, CardHeader, CardBody, Button, Input, Label, } from 'reactstrap';

import Switch from '@mui/material/Switch';
import AddPashusaakhi from '../../component/utility/master/AddPashusaakhi';
import EditPashusakhi from '../../component/utility/master/EditPashusakhi';
import AllocationPashusakhi from '../../component/utility/master/AllocationPashusakhi';

import * as XLSX from 'xlsx/xlsx.mjs';
import {SiMicrosoftexcel} from 'react-icons/si';
import Tooltip from '@mui/material/Tooltip';

import {FaEdit, FaSignInAlt} from 'react-icons/fa';
import {MdPersonAdd} from 'react-icons/md';

const Pashusaakhi = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [mode, setMode] = useState(1);
    const [pashusaakhiTable, setPashusaakhiTable] = useState('');
    const [accData, setAccData] = useState('');
    const [editData, setEditData] = useState('');

    const [districtOpt, setDistrictOpt] = useState();
    const [blockOpt, setBlockOpt] = useState();
    const [aps, setAps] = useState("");
    const [district, setDistrict] = useState("");
    const [block, setBlock] = useState("");

    const [dist_role, setDist_role] = useState(false);
    const [block_role, setBlock_role] = useState(false);

    useEffect(() => { 
        
        // check Access
        let accData = JSON.parse(sessionStorage.getItem('accessControl'));
        setAccData(accData[3].pages[1]);
        if(accData[3].pages[1].page_view !== "1"){
            navigate("/home");
        }
        checkRole();
        getDistrictList();
    }, []);

    const checkRole = () => {
        let role = sessionStorage.getItem('role');
        if( role === "101" ){
          getDistrictList();
        }else if( role === "102"){
          let dist = sessionStorage.getItem('dist');
          setDist_role(true);
          getBlockList({target:{value: dist}});
        }else if( role === "103"){
          let dist = sessionStorage.getItem('dist');
          let block = sessionStorage.getItem('block');
          setDist_role(true);
          setBlock_role(true);
          getBlockList({target:{value: dist}});
          setBlock(block);
    
        }
    }

    const enable_disable_handler = (row) => {
        let flag;
        if(row.active_flag === "1"){
            flag = 0;
        }else{
            flag = 1;
        }

        let postData = [{
            org_id: 100,
            aps_id: row.aps_id,
            active_flag: flag,
        }];

        axios({
            method: 'post',
            url: mainurl + 'Master/box_aps_enable_disable',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let notify = notification({ message: "Successfully submited.", type: 'success' });
                notify();
                get_aps_list();
            })
            .catch(error => {
                get_aps_list();
                let notify = notification({ message: "failed! Try Again", type: 'error' });
                notify();
            });
    }
    const getDistrictList = () => {

        let postData = [{ "org_id": 100, "user_id": "0" }];
        axios({
            method: 'post',
            url: mainurl + 'Geo/geo_district_list2',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return <option key={index} value={row.district_short_cd}> {row.district_name} </option>
                })

                setDistrictOpt(list);
            })
            .catch(error => {
                let notify = notification({ message: "District list not Found! Try Again", type: 'error' });
                notify();
            });
    }

    const getBlockList = (e) => {
        setDistrict(e.target.value);
        setBlock("");
        let postData = [{ "org_id": 100, "user_id": "0", "dist_id": e.target.value }]
        axios({
            method: 'post',
            url: mainurl + 'Geo/geo_block_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return <option key={index} value={row.block_code}> {row.block_long_name} </option>
                })
                setBlockOpt(list);
            })
            .catch(error => {
                let notify = notification({ message: "Block list not Found! Try Again", type: 'error' });
                notify();
            });
    }

    const submit_handler = () => {
        get_aps_list();
    }

    const get_aps_list = () => {
        setLoading(true);
        setPashusaakhiTable('');
        let postData = [{ "org_id": 100, "dist_id": district, "block_id": block, "aps_id": aps }];
        axios({
          method: 'post',
          url: mainurl + 'Master/box_master_aps_list',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            
            let list = response.data.map((row, index) => {
                return(
                    {
                        sl: index + 1,
                        ids: row.aps_id,
                        APS_Name: row.aps_name +" ("+ row.aps_name_h + ")",
                        Mobile: row.mobile_no,
                        PG: row.pg_name_desc,
                        Location: row.district_name +' / '+ row.block_long_name +' / '+ row.panchyat_name +' / '+ row.village_name,
                        action: <div>
                                    { accData.page_edit === "1" && <FaEdit className='me-3 cursor' color="#047e04" onClick={()=> open_edit_modal(row)} size={20} />}
                                    { accData.page_edit === "1" && <FaSignInAlt className='me-3 cursor' color="#fba10f" onClick={()=> open_allocation_modal(row)} size={20} />}
                                    { accData.page_delete === "1" && <Switch defaultChecked={(row.active_flag === "1") ? true : false} onChange={()=>enable_disable_handler(row)} size="small" />}
                            </div>

                    }
                )
            })
            setPashusaakhiTable(list);
            setLoading(false);
           
          })
          .catch(error => {
            setLoading(false);
            setPashusaakhiTable('');
            //let notify = notification({ message: "Pashusaakhi list not Found! Try Again", type: 'error' });
            //notify();
        });
    }

    const open_edit_modal = (row) => {
        setEditData(row);
        setMode(3);
    }
    const open_allocation_modal = (row) => {
        setEditData(row);
        setMode(4);
    }

    const download_excel_handler = () => {
        if(pashusaakhiTable.length > 0){
          let wb = XLSX.utils.book_new(),
          ws = XLSX.utils.json_to_sheet(pashusaakhiTable);
    
          XLSX.utils.book_append_sheet(wb, ws, "Mysheet1");
    
          XLSX.writeFile(wb, "Pashusakhi_list.xlsx");
        }
      }

    return(
        <>
            <Header />
            <Page title="Pashusakhi" breadcrumbs="Master / Pashusakhi">
            {mode === 1 &&
                
                <Row className='p-3'>
                    <Col xs={12} className="mb-2">
                        <Card>
                            <CardBody>
                            { accData.page_add === "1" && <Button color='primary' size="sm" onClick={() => setMode(2)}><MdPersonAdd /> Add New Pashusakhi</Button>}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={12}>
                        <Card>
                            <CardHeader>Pashusakhi List</CardHeader>
                            <div style={{position:'relative', height:'1px'}}>
                                <Tooltip title="Excel download">
                                    <SiMicrosoftexcel className='excelIcon' size={20} onClick={download_excel_handler} />
                                </Tooltip> 
                            </div>
                            <CardBody>
                            
                            <Row>
                                <Col xs={2} className="mb-2">
                                    <Label className="me-sm-2" for="aps"> Pashusaakhi ID </Label>
                                    <Input id="aps" bsSize="sm" type="text" value={aps} onChange={(e)=> setAps(e.target.value)} />
                                        
                                    
                                </Col>
                                <Col xs={2} className="mb-2">
                                    <Label className="me-sm-2" for="district"> District </Label>
                                    <Input id="district" bsSize="sm" type="select" value={district} onChange={getBlockList} disabled={dist_role}>
                                        <option value=""> --Select District--</option>
                                        {districtOpt}
                                    </Input>
                                </Col>
                                <Col xs={2} className="mb-2">
                                    <Label className="me-sm-2" for="block"> Block </Label>
                                    <Input id="block" bsSize="sm" type="select" value={block} onChange={(e)=> setBlock(e.target.value)} disabled={block_role}>
                                        <option value=""> --Select Block--</option>
                                        {blockOpt}
                                    </Input>
                                </Col>
                                <Col xs={2} className="mb-2">
                                    <Button className="mt-4" color="primary" size="sm" onClick={submit_handler}> Submit </Button>
                                </Col>
                            </Row>
                            <hr/>
                            <DataTable
                                title=""
                                columns={[
                                    {
                                    name: '#',
                                    selector: row=>row.sl,
                                    width: "5%",
                                    sortable: true,
                                    },
                                    {
                                    name: 'Id',
                                    selector: row=>row.ids,
                                    sortable: true,
                                    },
                                    {
                                    name: 'Name',
                                    selector: row=>row.APS_Name,
                                    sortable: false,
                                    width: '20%'
                                    },
                                    {
                                    name: 'Mobile Number',
                                    selector: row=>row.Mobile,
                                    sortable: false,
                                    },
                                    {
                                    name: 'PG/VO',
                                    selector: row=>row.PG,
                                    sortable: false,
                                    },
                                    {
                                    name: 'Location',
                                    selector: row=>row.Location,
                                    sortable: false,
                                    width: "30%"
                                    },
                                    {
                                    name: 'Action',
                                    selector: row=>row.action,
                                    sortable: false,
                                    },
                                ]}
                                data={pashusaakhiTable}
                                striped
                                highlightOnHover
                                progressPending={loading}
                                />
                                
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            }

            { mode === 2 &&
                <Row className='p-3'>
                    <Col xs={12} className="mb-2">
                        <AddPashusaakhi back_handler={()=> {setMode(1);get_aps_list()}} />
                    </Col>
                </Row>    
            }
            { mode === 3 &&
                <Row className='p-3'>
                    <Col xs={12} className="mb-2">
                        <EditPashusakhi data={editData} back_handler={()=> {setMode(1);get_aps_list()}} />
                    </Col>
                </Row>    
            }
            { mode === 4 &&
                <Row className='p-3'>
                    <Col xs={12} className="mb-2">
                        <AllocationPashusakhi data={editData} back_handler={()=> {setMode(1);get_aps_list()}} />
                    </Col>
                </Row>    
            }

            </Page>
            <Footer />
        </>
    )
}

export default Pashusaakhi;