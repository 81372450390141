import React, { useState, useEffect } from 'react';
import Page from '../../component/utility/Page';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import notification from '../../component/hocs/notification';

import DataTable from 'react-data-table-component';
import mainUrl from '../../MainURL';
import axios from 'axios';
import * as XLSX from 'xlsx/xlsx.mjs';
import {SiMicrosoftexcel} from 'react-icons/si';

import Tooltip from '@mui/material/Tooltip';
import { Row, Col, Card, CardHeader, CardBody, Input, Spinner, Form, Label, Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

import { FaCheck, FaTimes, FaEye, FaFilePdf } from 'react-icons/fa';

import {getTodayDate} from '../../component/hocs/dateServices';

const imageUrl = 'https://microsave.boxfarming.in/v1/h7jj24643khb3589jn5dc4/';

const GoatPurchaseReport = () => {

    const [loading, setLoading] = useState(false);
    const [excelList, setExcelList] = useState('');
    const [frmDate, setFrmDate] = useState(getTodayDate());
    const [toDate, setToDate] = useState(getTodayDate());
    const [tableData, setTableData] = useState('');
    const [viewModal, setViewModal] = useState(false);
    const [modalData, setModalData] = useState('');
    const [modalTitle, setModalTitle] = useState('');

    const [dist, setDist] = useState('0');
    const [block, setBlock] = useState('0');

    const checkRole = () => {
        let role = sessionStorage.getItem('role');
        if( role === "102"){
          let cdist = sessionStorage.getItem('dist');
          setDist(cdist);
        }else if( role === "103"){
          let cdist = sessionStorage.getItem('dist');
          let cblock = sessionStorage.getItem('block');
          setDist(cdist);
          setBlock(cblock);
    
        }
      }

    const purchase_list_handler = (e) => {
        e.preventDefault();
        get_purchase_list();
    }

    const get_purchase_list = () => {
        setLoading(true);
        let postData = [{ "org_id": 100, "frm_date": frmDate, "to_date": toDate, "t_type": 1, "dist_id": dist, "block_id": block }];
        axios({
            method: 'post',
            url: mainUrl + 'Activity/goat_sale_puechase_view',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    let bankinfo = <div>
                        <div><b>Account No:</b> {row.account_no}</div>
                        <div><b>IFSC code:</b> {row.ifsc_code}</div>
                        <div><b>Bank:</b> {row.bank_name}</div>
                        <div><b>Branch:</b> {row.branch}</div>
                    </div>;
                    let receipt = 'NA'; let passbook = 'NA';
                    if (row.receipt_img !== '' && row.receipt_img !== null) {
                        receipt = <a href={imageUrl + row.receipt_img} target="_blank" >View</a>;
                    } else {
                        receipt = 'NA';
                    }
                    if (row.bank_passbook_img !== '' && row.bank_passbook_img !== null) {
                        passbook = <a href={imageUrl + row.bank_passbook_img} target="_blank" >View</a>;
                    } else {
                        passbook = 'NA';
                    }
                    let validate;
                    if (row.v_flag === '0') {
                        validate = "Validation pending at Block level";
                    } else if (row.v_flag === '1') {
                        validate = <Tooltip title={row.remark}>
                            <span className='text-success'>Approved <FaCheck size={20} color="#00a500" /></span>
                        </Tooltip>;
                    } else if (row.v_flag === '2') {
                        validate = <Tooltip title={row.remark}>
                            <span className='text-danger'>Rejected <FaTimes size={20} color="#ff4136" /></span>
                        </Tooltip>;
                    } else if (row.v_flag === '3') {
                        validate = <div><a href={imageUrl + row.payment_detail[0].payment_init_pdf} target="_blank" ><FaFilePdf color="#ff4136" size={20} /></a> <span className='text-success'> Payment initiated</span></div>
                    } else if (row.v_flag === '4') {
                        let details = <div>
                                <div><b>Transaction Id:</b> {row.payment_detail[0].bank_transaction_id}</div>
                                <div><b>Cheque No:</b> {row.payment_detail[0].cheque_no}</div>
                                <div><b>Payment Date:</b> {row.payment_detail[0].payment_paid_date}</div>
                            </div>;
                            validate = <Tooltip title={details}>
                                <span className='text-success'> Payment Done</span>
                            </Tooltip>
                         
                    }

                    return {
                        sl: index + 1,
                        user: row.aps_name + ' (' + row.user_id + ')',
                        farmer: row.f_first_name,
                        shareholder: row.shareholder === "0" ? "No" : "Yes",
                        act_date: row.trans_date.split(" ")[0].split('-').reverse().join('-'),
                        qty: <div>{row.trans_qty} <FaEye className='ms-2 cursor' size={20} color="#00a500" onClick={() => detail_view_handler(row.animal_detail)} /></div> ,
                        weight: row.trans_weight + ' kg',
                        amt: <div>&#8377; {row.trans_amt}</div>,
                        receipt: receipt,
                        passbook: passbook,
                        bank_dtl: <Tooltip title={bankinfo}>
                            <a className='cursor'>See</a>
                        </Tooltip>,
                        v_flag: validate

                    }
                })

                setTableData(list);
                setLoading(false);

                let excellist = response.data.map((row, index) => {
                    let status;
                    if (row.v_flag === '0') {
                        status = "Validation pending at Block level";
                    } else if (row.v_flag === '1') {
                        status = "Approved" 
                    } else if (row.v_flag === '2') {
                        status = "Rejected"
                    } else if (row.v_flag === '3') {
                        status = "Payment initiated"
                    } else if (row.v_flag === '4') {
                        status = "Payment Done"
                    }

                    return {
                        sl: index + 1,
                        Pashusakhi: row.aps_name + ' (' + row.user_id + ')',
                        Shareholder_Name: row.f_first_name,
                        shareholder: row.shareholder === "0" ? "No" : "Yes",
                        Purchase_Date: row.trans_date.split(" ")[0].split('-').reverse().join('-'),
                        Quantity: row.trans_qty,
                        weight: row.trans_weight + ' kg',
                        Amount: row.trans_amt,
                        Account_No: row.account_no,
                        IFSC_code: row.ifsc_code,
                        Bank_Name: row.bank_name,
                        Branch: row.branch,
                        Status: status,
                        Remarks: row.remark

                    }
                })

                setExcelList(excellist);

            })
            .catch(error => {
                let notify = notification({ message: "Goat purchase list not Found! Try Again", type: 'error' });
                notify();
                setLoading(false);
            });
    }

    const detail_view_handler = (data) => {

        let header = <Row>
            <Col><b>Gender</b></Col>
            <Col><b>Age</b></Col>
            <Col><b>Weight</b></Col>
            <Col><b>Rate/kg</b></Col>
        </Row>
        if (data !== null) {
            let m_data = data.map(row => {
                return (
                    <Row>
                        <Col>{row.gender}</Col>
                        <Col>{row.age_group}</Col>
                        <Col>{row.weight} kg</Col>
                        <Col>&#8377; {Number(row.price).toFixed(2)}</Col>
                    </Row>
                )
            });

            m_data.unshift(header);
            setModalData(m_data);
        } else {
            setModalData("No record found!");
        }
        setModalTitle("Animal Details");
        setViewModal(true);
    }

    const download_excel_handler = () => {
        if(excelList.length > 0){
          let wb = XLSX.utils.book_new(),
          ws = XLSX.utils.json_to_sheet(excelList);
    
          XLSX.utils.book_append_sheet(wb, ws, "Mysheet1");
    
          XLSX.writeFile(wb, "Goat_Purchase_List.xlsx");
        }
      }


    return (
        <>
        <Header />
        <Page title="Goat Purchase Report" breadcrumbs="Report / Goat Purchase Report">
            <div className='p-3'>
                <Card className='mb-3'>
                    <CardBody>
                        <Form onSubmit={purchase_list_handler}>
                            <Row>
                                <Col xs={2}>
                                    <Label for="frmDate"> From Date </Label>
                                    <Input id="frmDate" type='date' max={toDate} bsSize="sm" value={frmDate} onChange={(e) => setFrmDate(e.target.value)} />
                                </Col>
                                <Col xs={2}>
                                    <Label for="toDate"> To Date </Label>
                                    <Input id="toDate" type='date' max={getTodayDate()} bsSize="sm" value={toDate} onChange={(e) => setToDate(e.target.value)} />
                                </Col>
                                <Col xs={2}>
                                    <Button className='mt-4' color='primary' size="sm" disabled={loading}> Submit {loading && <Spinner color="secondary ms-1" size="sm" >Load...</Spinner>}</Button>
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader>Goat Purchase Report</CardHeader>
                    <div style={{position:'relative', height:'1px'}}>
                        <Tooltip title="Excel download">
                            <SiMicrosoftexcel className='excelIcon' size={20} onClick={download_excel_handler} />
                        </Tooltip> 
                    </div>
                    <CardBody>
                        <DataTable
                            title=""
                            columns={[
                                {
                                    name: '#',
                                    selector: row => row.sl,
                                    sortable: true,
                                    width: '60px'
                                },
                                {
                                    name: 'Pashusakhi',
                                    selector: row => row.user,
                                    sortable: false,
                                    width: '9%',
                                    wrap: true
                                },
                                {
                                    name: 'Shareholder Name',
                                    selector: row => row.farmer,
                                    sortable: false,
                                    width: '9%',
                                    wrap: true
                                },
                                {
                                    name: 'Shareholder',
                                    selector: row => row.shareholder,
                                    sortable: false,
                                    wrap: true
                                },
                                {
                                    name: 'Date',
                                    selector: row => row.act_date,
                                    sortable: false,
                                },
                                {
                                    name: 'Goat count',
                                    selector: row => row.qty,
                                    sortable: false,
                                },
                                {
                                    name: 'Weight',
                                    selector: row => row.weight,
                                    sortable: false,
                                },
                                {
                                    name: 'Amount',
                                    selector: row => row.amt,
                                    sortable: false,
                                },
                                {
                                    name: 'Receipt',
                                    selector: row => row.receipt,
                                    sortable: false,
                                },
                                {
                                    name: 'Passbook',
                                    selector: row => row.passbook,
                                    sortable: false,
                                },
                                {
                                    name: 'Bank Details',
                                    selector: row => row.bank_dtl,
                                    sortable: false,
                                },
                                {
                                    name: 'Status',
                                    selector: row => row.v_flag,
                                    sortable: false,
                                    width: '11%',
                                    wrap: true
                                },

                            ]}
                            data={tableData}
                            striped
                            highlightOnHover
                            pagination
                            progressPending={loading}
                        />



                    </CardBody>
                </Card>
            </div>
            <Modal
                fullscreen={false}
                centered
                scrollable
                size="md"
                isOpen={viewModal}
                toggle={() => setViewModal(!viewModal)}
            >
                <ModalHeader toggle={() => setViewModal(!viewModal)}>
                    {modalTitle}
                </ModalHeader>
                <ModalBody>
                    {modalData}
                </ModalBody>
            </Modal>
            </Page>
            
            <Footer /> 
        </>
    )
}

export default GoatPurchaseReport;