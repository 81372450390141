import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import notification from '../../component/hocs/notification';
import mainurl from '../../MainURL';
import axios from 'axios';
import Page from '../../component/utility/Page';
import DataTable from 'react-data-table-component';
import EditUom from '../../component/utility/master/EditUom';
import {
    Col, Row, Card, CardHeader, CardBody, Button, Input, Label, Form, InputGroup, InputGroupText, Spinner,
    Modal, ModalBody, ModalHeader
} from 'reactstrap';

import { FaHeading} from 'react-icons/fa';
import {FaEdit} from 'react-icons/fa';
import {MdDeleteForever, } from 'react-icons/md';

const Uom = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [uomTable, setUomTable] = useState("");
    const [editModel, setEditModel] = useState(false);
    const [modelData, setModelData] = useState("");
    const [accData, setAccData] = useState('');

    
    const [geoName, setGeoName] = useState("");
    const [geoNameHindi, setGeoNameHindi] = useState("");
    const [hindiNameLoading, setHindiNameLoading] = useState(false);

    useEffect(() => {
        // check Access
        let accData = JSON.parse(sessionStorage.getItem('accessControl'));
        setAccData(accData[3].pages[10]);
        if(accData[3].pages[10].page_view !== "1"){
            navigate("/home");
        }
    }, []);
    useEffect(() => {
        get_UOM_list();
    }, [accData]);

    const get_UOM_list = () => {
        //
        let accData = JSON.parse(sessionStorage.getItem('accessControl'));
        let acc = accData[3].pages[3];
        //
        let postData = [{ "org_id": 100, "user_id": "0" }];
        axios({
          method: 'post',
          url: mainurl + 'Master/box_uom_list',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            console.log(response.data);
            let list = response.data.map(row => {
                return(
                    {
                        id: row.uom_id,
                        name: row.uom_name,
                        nameHindi:row.uom_name_h,
                        action: <div>
                            { acc.page_edit === "1" && <FaEdit className='me-3 cursor' color="#047e04" onClick={()=> open_edit_modal(row)} size={20} />}
                            { acc.page_delete === "1" && <MdDeleteForever color="#f70000" size={20} />}
                        </div>
                    }
                )
            })

            setUomTable(list);
          })
          .catch(error => {
            //let notify = notification({ message: "UOM list not Found! Try Again", type: 'error' });
            //notify();
          });
    }

    const translate_text_handler = (e) => {
        setHindiNameLoading(true);
        if(geoName.length > 1){
            
            let postData = [{ "t_text" : geoName}];
            axios({
                method: 'post',
                url: mainurl + 'Master/translate_text',
                data: postData,
                headers: { 'Content-Type': 'application/JSON', }
              })
                .then(function (response) {
                    let trans = response.data;
                    setGeoNameHindi(trans);
                    setHindiNameLoading(false);
                })
                .catch(error => {
                    setHindiNameLoading(false);
                    let notify = notification({ message: "Hindi Translation is not working! Try Again", type: 'error' });
                    notify();
                  });

        }else{
            setHindiNameLoading(false);
            let notify = notification({ message: "Please enter geographic english name first.", type: 'warn' });
            notify();
        }
    }

    const add_UOM_handler = (e) => {
        e.preventDefault();
        let postData = [
            {
              "org_id":"100",
              "user_id":"128",
              "uom_name": geoName,
              "uom_name_h": geoNameHindi
            }
        ];
        console.log(postData);

        axios({
            method: 'post',
            url: mainurl + 'Master/box_uom_add',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
          })
            .then(function (response) {
                console.log(response.data);
            })
            .catch(error => {
                let notify = notification({ message: "Add failed! Try Again", type: 'error' });
                notify();
              });
    
    }

    const open_edit_modal = (row) => {
        let temp = <EditUom  geo="UOM" data={row} close_handler={close_modal_handler} />

        setModelData(temp);
        setEditModel(true);
    }

    const close_modal_handler = () => {
        setEditModel(false);
        get_UOM_list();
    }

    return (
        <>
            <Header />
            <Page title="Unit Of Measurment" breadcrumbs="Master / Unit Of Measurment">
                <Row className="p-3">
                    <Col xs={12}>
                        { accData.page_add === "1" && <Card className='mb-2'>
                            <CardHeader>Add New UOM</CardHeader>
                            <CardBody>
                                <Form onSubmit={add_UOM_handler}>
                                    <Row>
                                        
                                        <Col md={2}>
                                            <Label for="name"> UOM Name</Label>
                                            <Input id="name" type='text' bsSize="sm" maxLength={50} onChange={(e) => setGeoName(e.target.value)} required />
                                        </Col>
                                        <Col md={2}>
                                            <Label for="hName"> UOM Name Hindi</Label>
                                            <InputGroup size="sm">
                                                <Input id="hName" type='text' maxLength={50} value={geoNameHindi} bsSize="sm" onChange={(e)=>setGeoNameHindi(e.target.value)} />
                                                <InputGroupText className='bg-primary cursor' onClick={!hindiNameLoading ? translate_text_handler : undefined}>
                                                    {hindiNameLoading ? <Spinner color="secondary" size="sm">Loading...</Spinner> : <FaHeading color="#fff" />}
                                                </InputGroupText>
                                            </InputGroup>
                                        </Col>
                                        <Col md={2}>
                                            <Button className='mt-4' color="primary" size="sm" >Add UOM</Button>
                                        </Col>
                                    </Row>

                                </Form>
                            </CardBody>
                        </Card>}

                        <Card className='mb-2'>
                            <CardHeader>UOM Master</CardHeader>
                            <CardBody>
                                <DataTable
                                    title=""
                                    columns={[
                                        {
                                        name: 'UOM Id',
                                        selector: 'id',
                                        sortable: true,
                                        },
                                        {
                                        name: 'UOM Name',
                                        selector: 'name',
                                        sortable: false,
                                        },
                                        {
                                        name: 'UOM Name (Hindi)',
                                        selector: 'nameHindi',
                                        sortable: false,
                                        },
                                        {
                                        name: 'Action',
                                        selector: 'action',
                                        sortable: false,
                                        },
                                    ]}
                                    data={uomTable}
                                    striped
                                    highlightOnHover
                                    progressPending={loading}
                                    />
                            </CardBody>
                        </Card> 
                    </Col>
                </Row>

            </Page>
            <Modal
                centered
                scrollable
                size="lg"
                isOpen={editModel}
                toggle={() => setEditModel(!editModel)}
            >
                <ModalHeader toggle={() => setEditModel(!editModel)}>
                   Edit UOM
                </ModalHeader>
                <ModalBody>
                    {modelData}
                </ModalBody>
            </Modal>
            <Footer />
        </>
    )
}

export default Uom;