import React, { useState, useEffect } from 'react';
import notification from '../../hocs/notification';
import MapPill from './MapPill';
import Dbox from './Dbox';
import CheckboxSVGMap from './../../hocs/svgMap/index';
import bihar from '../../../assets/geo-data/bihar';
import { getCurrentMonthYear, getTodayDate } from '../../hocs/dateServices';
import { Row, Col, Card, CardBody, CardHeader, } from 'reactstrap';
//

import mainurl from '../../../MainURL';
import axios from 'axios';
import { Doughnut } from 'react-chartjs-2';

const plugins = []

const HomeMap = () => {
    const [mapModal, setMapModal] = useState(false);
    const [distAPS, setDistAPS] = useState();
    const [distFarmer, setDistFarmer] = useState();
    const [performanceData, setPerformanceData] = useState({});
    const [goatSale, setGoatSale] = useState();
    const [goatCount, setGoatCount] = useState();
    const [currDistName, setCurrDistName] = useState();
    const [currFarmers, setCurrFarmers] = useState();
    const [currAps, setCurrAps] = useState();
    const [currGCount, setCurrGCount] = useState();
    const [mapColor, setMapColor] = useState('');
    const [goatCensus, setGoatCensus] = useState('');
    const [demoCenterCount, setDemoCenterCount] = useState('');
    const [currDCount, setCurrDCount] = useState();

    const [chartLoad, setChartLoad] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [activeCount, setActiveCount] = useState(0);


    useEffect(() => {
        map_data();
        performance_indicator();
        active_user()

        let d = sessionStorage.getItem('dist');
        if(d === '179'){
            setMapColor('ar');
        }else if(d === '192'){
            setMapColor('kr');
        }else if(d === '205'){
            setMapColor('pu');
        }else{
            setMapColor('all');
        }

    }, []);

    const active_user = () => {
        setChartLoad(true);
        let dt = new Date();
        dt.setDate(dt.getDate() - 15);
        let cYear = dt.getFullYear();
        let cMonth = (dt.getMonth() + 1).toString().padStart(2, "0");
        let cdate = dt.getDate();

        // formate yyyy-mm-dd
        let fmDate = cYear + "-" + cMonth + "-" + cdate;

        let sendData = [{"org_id": 100, "fm_date": fmDate, "to_date": getTodayDate(), "dist_id": sessionStorage.getItem('dist'), "block_id":sessionStorage.getItem('block')}];
        axios({
            method: 'post',
            url: mainurl + 'Master/bx_datewise_active_user',
            data: sendData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                if(response.data !== null){
                    setActiveCount(response.data[0].aps);
                    setTotalCount(response.data[1].aps);
                    setChartLoad(false);
                }else{
                    setTotalCount("No Data Found!");
                }

            }).catch(error => {
                //let notify = notification({ message: "Data not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const map_data = () => {
        // Demo-Center count
        let sendData1 = [{ "org_id": "100", "user_id": "128", "d_type": "1", "geo_id": "10" }];
        axios({
            method: 'post',
            url: mainurl + 'Master/db_demo_center_count',
            data: sendData1,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                if(response.data !== null){
                    let demo_c = [];
                    response.data.map(row => {
                        demo_c.push(row.demo_c_count);
                    })
    
                    setDemoCenterCount(demo_c);
                }else{
                    let demo_c = [
                        {"demo_c_count":0,	"district_short_cd":"179",	"district_name": "Araria"},
                        {"demo_c_count":0,	"district_short_cd":"192",	"district_name": "Katihar"},
                        {"demo_c_count":0,	"district_short_cd":"205",	"district_name": "Purnia"}
                    ];
                    setDemoCenterCount(demo_c);
                }

            }).catch(error => {
                //let notify = notification({ message: "Data not Found! Try Again", type: 'error' });
                //notify();
            });

        // APS Count
        let sendData = [{ "org_id": "100", "user_id": "128", "geo_type": "1", "geo_id": "10" }];
        axios({
            method: 'post',
            url: mainurl + 'Geo/g2_b',
            data: sendData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let aps = [];
                response.data.map(row => {
                    aps.push(row.aps_c_dist);
                })

                setDistAPS(aps)

            }).catch(error => {
                //let notify = notification({ message: "Data not Found! Try Again", type: 'error' });
                //notify();
            });

        // Shareholder Count
        let postData = [{ "org_id": "100", "user_id": "128", "ops_type": "1", "geo_id": "10" }];

        axios({
            method: 'post',
            url: mainurl + 'Geo/geo_farmer_count',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                setDistFarmer(response.data);
            }).catch(error => {
                //let notify = notification({ message: "Data not Found! Try Again", type: 'error' });
                //notify();
            })

        // Goat Count
        let postData1 = [{ "org_id": "100", "user_id": "128", "d_type": "1", "geo_id": "10" }];

        axios({
            method: 'post',
            url: mainurl + 'Master/db_goat_count',
            data: postData1,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                setGoatCount(response.data);
            }).catch(error => {
                //let notify = notification({ message: "Data not Found! Try Again", type: 'error' });
                //notify();
            })
    }

    const performance_indicator = () => {
        let monthYear = getCurrentMonthYear();
        let year = new Date(monthYear).getFullYear();
        let month = new Date(monthYear).getMonth() + 1;
        let dist_id = sessionStorage.getItem('dist');
        let block_id = sessionStorage.getItem('block');
        let postData = [{ "org_id": "100", "user_id": "128", "month_id": month, "year_id": year, "dist_id": dist_id, "block_id": block_id }];

        axios({
            method: 'post',
            url: mainurl + 'Master/box_dashboard_w1',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let key = {};
                response.data.map(row => {
                    key['key_' + row.activity_type_id] = Math.abs(row.count);
                })
                console.log(key);
                setPerformanceData(key);

            }).catch(error => {
                //let notify = notification({ message: "Data not Found! Try Again", type: 'error' });
                //notify();
            })
            
        let postData1 = [{ "org_id": 100, "user_id": 128, "ops_type": 101 }];
        axios({
            method: 'post',
            url: mainurl + 'Geo/g1',
            data: postData1,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                console.log(response.data);
                let goatSale = response.data[0][101][0].actual_value;
                setGoatSale(goatSale);

            }).catch(error => {
                //let notify = notification({ message: "Data not Found! Try Again", type: 'error' });
                //notify();
            })

            //
            // Goat Count
        let postData2 = "";
        if(block_id > 0){
            postData2 =[{ "org_id": "100", "user_id": "128", "d_type": "3", "geo_id": block_id }]
        }else if(dist_id > 0){
            postData2 =[{ "org_id": "100", "user_id": "128", "d_type": "2", "geo_id": dist_id }] 
        }else{
            postData2 =[{ "org_id": "100", "user_id": "128", "d_type": "1", "geo_id": "10" }]  
        }

        axios({
            method: 'post',
            url: mainurl + 'Master/db_goat_count',
            data: postData2,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let count = 0;
               response.data.map(r=>{
                count = count + Number(r.goat_count);
               })
               setGoatCensus(count);
            }).catch(error => {
                //let notify = notification({ message: "Data not Found! Try Again", type: 'error' });
                //notify();
            })
    }

    const onMapHover = (event) => {
        if (event.target.attributes['aria-checked'].value === 'true') {
            if (distFarmer !== null && distFarmer !== undefined) {
            
                let distName = ''; let farmers = '-'; let aps = '-'; let g_count = '-'; let d_count = '-';
                distName = event.target.id;
                distFarmer.map((row, index) => {
                    if (event.target.id.toLowerCase() === row.district_name.toLowerCase()) {
                        
                        if(sessionStorage.getItem('role') === '102' || sessionStorage.getItem('role') === '103'){
                            if(row.district_short_cd === sessionStorage.getItem('dist')){
                                farmers = row.farmer_c_dist;
                                aps = distAPS[index];
                                g_count = goatCount[index].goat_count;
                                d_count = demoCenterCount[index].demo_c_count;
                            }
                        }else{
                            farmers = row.farmer_c_dist;
                            aps = distAPS[index];
                            g_count = goatCount[index].goat_count;
                            d_count = demoCenterCount[index].demo_c_count;
                        }
                    }

                })
                setCurrDistName(distName);
                setCurrFarmers(farmers);
                setCurrAps(aps);
                setCurrGCount(g_count);
                setCurrDCount(d_count);

                setMapModal(true);
                
            }
        }

    }

    return (
        <Row className="mt-1">
            <Col md="4">
                <Card>
                    <CardHeader>
                        Bihar Map<small className="text-muted text-capitalize"> District Wise Details</small>
                    </CardHeader>
                    <CardBody>

                        {(mapColor === "ar" || mapColor === "all") && <MapPill text="Araria" bgColor="primary" />}
                        {(mapColor === "kr" || mapColor === "all") && <MapPill text="Katihar" bgColor="secondary" />}
                        {(mapColor === "pu" || mapColor === "all") && <MapPill text="Purnia" bgColor="danger" />}
                        {mapColor.length > 0 && <CheckboxSVGMap className={mapColor} selectedLocationIds={['Araria', 'Katihar', 'Purnia']}
                            onLocationMouseOver={onMapHover}
                            onLocationMouseOut={() => setMapModal(false)}
                            map={bihar}
                        />}
                        {mapModal === true &&
                            <div className="custPopup">
                                <div className="text-center">
                                    <div className="text-primary fw-bold ">
                                        {currDistName}
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <div className="" > Shareholder: </div>
                                        <div className="pl-0">{currFarmers}</div>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <div className="" > Pashusakhi: </div>
                                        <div className="pl-0">{currAps}</div>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <div className="" > Goat Census: </div>
                                        <div className="pl-0">{currGCount}</div>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <div className="" > Demo center: </div>
                                        <div className="pl-0">{currDCount}</div>
                                    </div>

                                </div>
                            </div>
                        }

                    </CardBody>
                </Card>
            </Col>
            <Col md={4}>
                <Card className='h-100'>
                    <CardHeader>
                        Performance Indicator<small className="text-muted text-capitalize"> Current Finacial Year </small>
                    </CardHeader>
                    <CardBody>
                        <Row>
                        <Dbox typ="1" text="Goat Census" symbol={false} val={goatCensus?goatCensus:"0"} />
                        <Dbox typ="2" text="De-Worming" symbol={false} val={performanceData.key_2?performanceData.key_2:"0"} />
                        <Dbox typ="3" text="Vaccination" symbol={false} val={performanceData.key_1?performanceData.key_1:"0"} />
                        <Dbox typ="7" text="Castration" symbol={false} val={performanceData.key_3?performanceData.key_3:"0"} />
                        <Dbox typ="8" text="Post Natal" symbol={false} val={performanceData.key_8?performanceData.key_8:"0"} />
                        <Dbox typ="4" text="Goat Sale" symbol={false} val={goatSale?goatSale:"0"} />
                        <Dbox typ="5" text="Goat Purchase" symbol={false} val={performanceData.key_6?performanceData.key_6:"0"} />
                        <Dbox typ="6" text="Input Sale" symbol={true} val={performanceData.key_5?performanceData.key_5:"0"} />

                        </Row>
                    </CardBody>

                </Card>

            </Col>
            <Col md={4}>
                <Card className='h-100'>
                    <CardHeader>
                        Active Pashusakhi<small className="text-muted text-capitalize"> Last 15 Days </small>
                    </CardHeader>
                    <CardBody className='d-flex justify-content-center align-items-center'>
                        <div style={{width:"80%"}}>

                            {!chartLoad && <Doughnut
                                data={{
                                    maintainAspectRatio: true,
                                    responsive: true,
                                    labels: ["Active", "Inactive"],
                                    datasets: [
                                        {
                                            data: [activeCount, (totalCount - activeCount) ],
                                            backgroundColor: [ '#0052cc', '#A9A9A9']
                                        }
                                    ]
                                }}
                                options={{
                                    legend: {
                                        display: true,
                                        position: "bottom",

                                        },
                                        responsive: true,
                                        elements: {
                                        arc: {
                                            borderWidth: 0
                                        }
                                    },
                                }}
                                plugins={[{
                                    beforeDraw: function(chart) {
                                     var width = chart.width,
                                         height = chart.height,
                                         ctx = chart.ctx;
                                         ctx.restore();
                                         var fontSize = (height / 160).toFixed(2);
                                         ctx.font = fontSize + "em sans-serif";
                                         ctx.textBaseline = "top";
                                         var text = totalCount,
                                         textX = Math.round((width - ctx.measureText(text).width) / 2),
                                         textY = height / 2;
                                         ctx.fillText(text, textX, textY);
                                         ctx.save();
                                    } 
                                }]}
                            />}
                        </div>
                    </CardBody>

                </Card>

            </Col>
        </Row>
    )
}

export default HomeMap;