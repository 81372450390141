import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import notification from '../../hocs/notification';
import mainurl from '../../../MainURL';
import axios from 'axios';
import Select from 'react-select';
import { Col, Row, Card, CardBody, Input, CardHeader, Label, Button, Form, Spinner } from 'reactstrap';
import SaleItem from './SaleItem';


const InputSaleEntry = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [districtOpt, setDistrictOpt] = useState();
    const [blockOpt, setBlockOpt] = useState();
    const [villageOpt, setVillageOpt] = useState();
    const [panchyatOpt, setPanchyatOpt] = useState();
    const [pashusakhiOpt, setPashusakhiOpt] = useState();
    const [farmerOpt, setFarmerOpt] = useState();
    const [district, setDistrict] = useState("0");
    const [block, setBlock] = useState("0");
    const [panchyat, setPanchyat] = useState("0");
    const [pashusakhi, setPashusakhi] = useState("0");
    const [pashusakhiName, setPashusakhiName] = useState("");
    const [village, setVillage] = useState("0");
    const [farmer, setFarmer] = useState("0");

    const [medicineRow, setMedicineRow] = useState("");
    const [qtyMax, setQtyMax] = useState(0);
    const [medicineOpt, setMedicineOpt] = useState("");
    const [medicine, setMedicine] = useState("");
    const [medicineName, setMedicineName] = useState("");
    const [qty, setQty] = useState("");
    const [price, setPrice] = useState("");
    const [source, setSource] = useState("");
    const [sourceOpt, setSourceOpt] = useState("");
    const [saleDate, setSaleDate] = useState("");

    const [saleList, setSaleList] = useState("");


    const [dist_role, setDist_role] = useState(false);
    const [block_role, setBlock_role] = useState(false);
    const [count, setCount] = useState(1);

    useEffect(() => {
        // check Access
        let accData = JSON.parse(sessionStorage.getItem('accessControl'));

        if (accData[2].pages[2].page_view !== "1") {
            navigate("/home");
        }
        //
        
        checkRole();
        getDistrictList();
    }, []);

    const checkRole = () => {
        let role = sessionStorage.getItem('role');
        if (role === "101") {
            getDistrictList();
            setCount(2);
        } else if (role === "102") {
            let dist = sessionStorage.getItem('dist');
            setDist_role(true);
            getBlockList({ target: { value: dist } });
            setCount(2);
        } else if (role === "103") {
            let dist = sessionStorage.getItem('dist');
            setDist_role(true);
            setBlock_role(true);
            getBlockList({ target: { value: dist } });

        }
    }

    const getDistrictList = () => {
        setBlock("0");

        let postData = [{ "org_id": 100, "user_id": "0" }];
        axios({
            method: 'post',
            url: mainurl + 'Geo/geo_district_list2',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return <option key={index} value={row.district_short_cd}> {row.district_name} </option>
                })
                setDistrictOpt(list);
            })
            .catch(error => {
                let notify = notification({ message: "District list not Found! Try Again", type: 'error' });
                notify();
            });
    }

    const getBlockList = (e) => {
        setDistrict(e.target.value);
        setBlock("0");
        let postData = [{ "org_id": 100, "user_id": "0", "dist_id": e.target.value }]
        axios({
            method: 'post',
            url: mainurl + 'Geo/geo_block_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return <option key={index} value={row.block_code}> {row.block_long_name} </option>
                })
                setBlockOpt(list);
                if (count === 1) {
                    setCount(2);
                    let block = sessionStorage.getItem('block');
                    setBlock(block);
                }
            })
            .catch(error => {
                let notify = notification({ message: "Block list not Found! Try Again", type: 'error' });
                notify();
            });
    }
    const getPanchayatList = (e) => {
        setBlock(e.target.value);
        setPanchyat("0");
        let postData = [{ "org_id": 100, "user_id": "0", "block_id": e.target.value }]
        axios({
            method: 'post',
            url: mainurl + 'Geo/geo_pan_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return <option key={index} value={row.panchyat_code}> {row.panchyat_name} </option>
                })

                setPanchyatOpt(list);
            })
            .catch(error => {
                let notify = notification({ message: "Panchayat list not Found! Try Again", type: 'error' });
                notify();
            });
    }

    const getPashusakhiList = (e) => {
        setPashusakhi("0");
        setPashusakhiName("");
        setPanchyat(e.target.value);

        let postData = [{ "org_id": 101, "user_id": 128, "geo_type_id": "3", "geo_id": e.target.value }]
        axios({
            method: 'post',
            url: mainurl + 'Geo/geo_aps_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    let name = row.aps_name + '[' + row.aps_id + ']';
                    return <option key={index} value={row.aps_id}> {name} </option>
                })

                setPashusakhiOpt(list);
            })
            .catch(error => {
                let notify = notification({ message: "pashusakhi list not Found! Try Again", type: 'error' });
                notify();
            });
    }
    const getVillageList = (e) => {
        setVillage("0");
        setVillageOpt("");
        setPashusakhi(e.target.value);
        getMedicineList(e.target.value);

        let postData = [{ "org_id": 100, "user_id": e.target.value }]
        axios({
            method: 'post',
            url: mainurl + 'Geo/geo_village',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return <option key={index} value={row.village_code}> {row.village_name} </option>
                })

                setVillageOpt(list);
            })
            .catch(error => {
                let notify = notification({ message: "pashusakhi list not Found! Try Again", type: 'error' });
                notify();
            });
    }
    const getFarmerList = (e) => {
        setFarmerOpt("");
        setVillage(e.target.value);

        let postData = [{ "org_id": 100, "user_id": pashusakhi, "ops_type": 1 }]
        axios({
            method: 'post',
            url: mainurl + 'Geo/farmer_list2',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return <option key={index} value={row.f_id}> {row.f_first_name} </option>
                })

                setFarmerOpt(list);
            })
            .catch(error => {
                let notify = notification({ message: "Farmer list not Found! Try Again", type: 'error' });
                notify();
            });
    }

    const getMedicineList = (aps) => {
        let postData = [{ "org_id": 100, "user_id": 128, "aps_id": aps }];
        axios({
            method: 'post',
            url: mainurl + 'Activity/input_stock_details',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                if(response.data != null){
                    let list = response.data.map((row) => {
                        return { value: row.medicine_id, label: row.description, stock:row.medicine_stock }
                    })
                    setMedicineOpt(list);
                }else{
                    setMedicineOpt('');
                }
            })
            .catch(error => {
                setMedicineOpt('');
                let notify = notification({ message: "medicine list not Found! Try Again", type: 'error' });
                notify();
            });
    }

    const add_handler = (e) => {
        e.preventDefault();
        let list = {
            "medicine_id": medicine,
            "medicineName": medicineName,
            "goat_count": qty,
            "activity_charges": price,
            "activity_date": saleDate,
            "medicine_count": 1
        };

        let prevList = [...saleList];
        prevList.push(list);
        setSaleList(prevList);

        console.log(prevList);

        setMedicine('0');
        setQty('');
        setPrice('');
        setSaleDate('');

    }

    const sale_item_delete = (id) => {
        let prevList = [...saleList];
        prevList.splice(id, 1);
        setSaleList(prevList);
    }

    const sale_submit_handler = () => {
        let flag = true;

        if (village === "0") {
            flag = false;
            let notify = notification({ message: "Please select Village. ", type: 'warn' });
            notify();
        }
        if (panchyat === "0") {
            flag = false;
            let notify = notification({ message: "Please select Panchayat. ", type: 'warn' });
            notify();
        }
        if (block === "0") {
            flag = false;
            let notify = notification({ message: "Please select Block. ", type: 'warn' });
            notify();
        }
        if (district === "0") {
            flag = false;
            let notify = notification({ message: "Please select District. ", type: 'warn' });
            notify();
        }
        if (pashusakhi === "0") {
            flag = false;
            let notify = notification({ message: "Please select Pashusakhi. ", type: 'warn' });
            notify();
        }
        if (farmer === "0") {
            flag = false;
            let notify = notification({ message: "Please select Shareholder. ", type: 'warn' });
            notify();
        }
        if (saleList.length < 1) {
            flag = false;
            let notify = notification({ message: "Please Add Medicine List. ", type: 'warn' });
            notify();
        }

        if (flag) {
            setLoading(true);
            let postData = [{
                "org_id": 100,
                "user_id": pashusakhi,
                "farmer_id": farmer,
                "activity_type": "005",
                "medicine_list": saleList
            }];
            console.log(postData);

            axios({
                method: 'post',
                url: mainurl + 'Activity/input_sale_web',
                data: postData,
                headers: { 'Content-Type': 'application/JSON', }
            })
                .then(function (response) {
                    setLoading(false);
                    if (response.data[0].retcode == 101) {
                        setDistrict('0');
                        setBlock('0');
                        setBlockOpt('');
                        setPanchyat('0');
                        setPanchyatOpt('');
                        setPashusakhi('0');
                        setPashusakhiOpt('');
                        setVillage('0');
                        setVillageOpt('');
                        setFarmer('0');
                        setFarmerOpt('');
                        setSaleList('');
                        let notify = notification({ message: "Added sucessfully.", type: 'success' });
                        notify();
                    }
                })
                .catch(error => {
                    setLoading(false);
                    let notify = notification({ message: "Failed! Try Again", type: 'error' });
                    notify();
                });
        }
    }

    const medicine_handler = (options) => {
        setMedicineRow(options);
        setMedicine(options.value);
        setMedicineName(options.label);
        setQtyMax(Number(options.stock));   
    }

    return (
        <>
            <Row className="p-3">
                <Col xs={12}>
                    <Card>
                        <CardHeader>Input Sale Entry</CardHeader>
                        <CardBody>
                        <Form onSubmit={add_handler}>
                            <Row>
                                <Col xs={2} className="mb-1">
                                    <Label className="me-sm-2" for="district"> District </Label>
                                    <Input id="district" bsSize="sm" type="select" value={district} onChange={(e) => getBlockList(e)} disabled={dist_role} required>
                                        <option value="0"> --Select District--</option>
                                        {districtOpt}
                                    </Input>
                                </Col>
                                <Col xs={2} className="mb-1">
                                    <Label className="me-sm-2" for="block"> Block </Label>
                                    <Input id="block" bsSize="sm" type="select" value={block} onChange={getPanchayatList} disabled={block_role} required>
                                        <option value="0"> --Select Block--</option>
                                        {blockOpt}
                                    </Input>
                                </Col>
                                <Col xs={2} className="mb-1">
                                    <Label className="me-sm-2" for="panchayat"> Panchayat </Label>
                                    <Input id="panchayat" bsSize="sm" type="select" value={panchyat} onChange={getPashusakhiList} required>
                                        <option value="0"> --Select Panchayat--</option>
                                        {panchyatOpt}
                                    </Input>
                                </Col>
                                <Col xs={3} className="mb-1">
                                    <Label className="me-sm-2" for="pashusakhi"> Pashusakhi </Label>
                                    <Input id="pashusakhi" bsSize="sm" type="select" value={pashusakhi} onChange={getVillageList} required>
                                        <option value="0"> --Select Pashusakhi--</option>
                                        {pashusakhiOpt}
                                    </Input>
                                </Col>
                                <Col xs={2} className="mb-1">
                                    <Label className="me-sm-2" for="village"> Village </Label>
                                    <Input id="village" bsSize="sm" type="select" value={village} onChange={getFarmerList} required>
                                        <option value="0"> --Select Village--</option>
                                        {villageOpt}
                                    </Input>
                                </Col>
                                <Col xs={2} className="mb-1">
                                    <Label className="me-sm-2" for="farmer"> Farmer </Label>
                                    <Input id="farmer" bsSize="sm" type="select" value={farmer} onChange={(e) => setFarmer(e.target.value)} required>
                                        <option value="0"> --Select Farmer--</option>
                                        {farmerOpt}
                                    </Input>
                                </Col>

                                <Col xs={12} className="mt-3 ">
                                    <hr />
                                </Col>

                                    <Col xs={3}>
                                        <Label className="me-sm-2" for="medicine"> Medicine </Label>
                                        <Label className="me-sm-2 text-primary"> Stock: {qtyMax} </Label>
                                        <Select closeMenuOnSelect={true} value={medicineRow} isMulti={false} placeholder={<div>Select Medicine</div>}
                                            options={medicineOpt} onChange={medicine_handler} required />
                                        
                                    </Col>
                                    <Col xs={1}>
                                        <Label className="me-sm-2" for="qty"> Quantity </Label>
                                        <Input id="qty" bsSize="sm" min={0} max={qtyMax} type="number" value={qty} onChange={(e) => setQty(e.target.value)} required />
                                    </Col>
                                    <Col xs={1}>
                                        <Label className="me-sm-2" for="price"> Price/Unit </Label>
                                        <Input id="price" bsSize="sm" type="text" value={price} onChange={(e) => setPrice(e.target.value)} required />
                                    </Col>
                                    <Col xs={2}>
                                        <Label className="me-sm-2" for="saleDate"> Sale Date </Label>
                                        <Input id="saleDate" bsSize="sm" type="date" value={saleDate} onChange={(e) => setSaleDate(e.target.value)} required/>
                                    </Col>
                                    <Col xs={2} className="mb-1">
                                        <Button className="mt-4" color="primary" size="sm" > Add </Button>
                                    </Col>
                            </Row>
                        </Form>
                        </CardBody>
                    </Card>

                    <Card className='mt-3'>
                        <CardHeader>Sale List</CardHeader>
                        <CardBody>
                            <Row>
                                <Col xs={1}> <b>SL No</b> </Col>
                                <Col xs={2}> <b>Medicine</b> </Col>
                                <Col xs={1}> <b>Quantity</b> </Col>
                                <Col xs={1}> <b>Price/unit</b> </Col>
                                <Col xs={1}> <b>Sale Date</b> </Col>
                                <Col xs={1}> <b>Action</b> </Col>
                            </Row>
                            <hr />
                            {saleList.length > 0 ? saleList.map((row, index) => {
                                return (
                                    <SaleItem val={row} index={index} delete_item={() => sale_item_delete(index)} />
                                )
                            })
                                :
                                <div>No list Found!</div>

                            }

                            <Row>
                                <Col xs="12">
                                    <Button className="mt-4" color="primary" size="" onClick={sale_submit_handler} disabled={loading}> Submit </Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}
export default InputSaleEntry;