import React from 'react';

import {
    Row,
    Col,

} from 'reactstrap';

function Footer() {

    return (
        <div className='footerPage'>
            <Row className='text-center'>
                <Col xs={12}>
                    <small className='text-muted p-2'>&copy; All Rights Reserved By BRLPS, Patna</small>
                </Col>
            </Row>
            
        </div>
    )
}

export default Footer;