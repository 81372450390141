import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import notification from '../../component/hocs/notification';
import mainurl from '../../MainURL';
import axios from 'axios';
import Page from '../../component/utility/Page';
import DataTable from 'react-data-table-component';
import EditAsset from '../../component/utility/master/EditAsset';
import * as XLSX from 'xlsx/xlsx.mjs';
import {SiMicrosoftexcel} from 'react-icons/si';
import Tooltip from '@mui/material/Tooltip';
import {
    Col, Row, Card, CardHeader, CardBody, Button, Input, Label, Form, InputGroup, InputGroupText, Spinner,
    Modal, ModalBody, ModalHeader
} from 'reactstrap';
import { getTodayDate } from '../../component/hocs/dateServices';


const ApsEarningReport = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState("");
    const [frmDate, setFrmDate] = useState(getTodayDate());
    const [toDate, setToDate] = useState(getTodayDate());

    const [accData, setAccData] = useState('');

    useEffect(() => {
        // check Access
        let accData = JSON.parse(sessionStorage.getItem('accessControl'));
        setAccData(accData[3].pages[8]);
        if(accData[3].pages[8].page_view !== "1"){
            navigate("/home");
        }
        
    }, []);

    const submit_handler = (e) => {
        e.preventDefault();
    }

    const download_excel_handler = () => {
        if(tableData.length > 0){
          let wb = XLSX.utils.book_new(),
          ws = XLSX.utils.json_to_sheet(tableData);
    
          XLSX.utils.book_append_sheet(wb, ws, "Mysheet1");
    
          XLSX.writeFile(wb, "CompanyDocument.xlsx");
        }
      }

    return (
        <>
            <Header />
            <Page title="Pashusakhi Earning Report" breadcrumbs="Report / Pashusakhi Earning Report">
                <Row className="p-3">
                    <Col xs={12}>
                    <Card className='mb-2'>
                            <CardBody>
                                <Form onSubmit={submit_handler}>
                                    <Row>
                                        <Col md={2}>
                                            <Label for="frmDate"> From Date </Label>
                                            <Input id="frmDate" type='date' bsSize="sm" max={toDate} value={frmDate} onChange={(e) => setFrmDate(e.target.value)} required />
                                        </Col>
                                        <Col md={2}>
                                            <Label for="toDate"> To Date </Label>
                                            <Input id="toDate" type='date' bsSize="sm" max={getTodayDate()} value={toDate} onChange={(e) => setToDate(e.target.value)} required />
                                        </Col>
                                        
                                        <Col md={2}>
                                            <Button className='mt-4' color="primary" size="sm" >Submit</Button>
                                        </Col>
                                    </Row>

                                </Form>
                            </CardBody>
                        </Card>

                        <Card className='mb-2'>
                            <CardHeader>Pashusakhi Earning</CardHeader>
                            <div style={{position:'relative', height:'1px'}}>
                                <Tooltip title="Excel download">
                                    <SiMicrosoftexcel className='excelIcon' size={20} onClick={download_excel_handler} />
                                </Tooltip> 
                            </div>
                            <CardBody>
                                <DataTable
                                    title=""
                                    columns={[
                                        {
                                        name: 'Id',
                                        selector: row=>row.ids,
                                        sortable: true,
                                        },
                                        {
                                        name: 'Document Number',
                                        selector: row=>row.name,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Document Number',
                                        selector: row=>row.number,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Description',
                                        selector: row=>row.desc,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Issue Date',
                                        selector: row=>row.issue_date,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Expiry Date',
                                        selector: row=>row.expiry_date,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Action',
                                        selector: row=>row.action,
                                        sortable: false,
                                        },
                                    ]}
                                    data={tableData}
                                    striped
                                    highlightOnHover
                                    progressPending={loading}
                                    />
                            </CardBody>
                        </Card> 
                    </Col>
                </Row>

            </Page>
            
            <Footer />
        </>
    )
}

export default ApsEarningReport;