import React from 'react';
import {
  Col, Row, Card, CardHeader, CardBody,
} from 'reactstrap';


const ViewVendor = ({ data, ...props }) => {

  return (
    <>
      <Row>
        <Col sm={4}>
          <Card>
            <CardHeader>General Information</CardHeader>
            <CardBody>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>ID</b></div>
                <div className='m-0'>{data.vandor_id}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Name</b></div>
                <div className='m-0'>{data.vandor_name_desc}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Name (Hindi)</b></div>
                <div className='m-0'>{data.vandor_name_hindi}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Contact Person</b></div>
                <div className='m-0'>{data.contact_person}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Landline Number</b></div>
                <div className='m-0'>{data.landline_no}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Mobile Number</b></div>
                <div className='m-0'>{data.mobile_no}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>PAN Number</b></div>
                <div className='m-0'>{data.pan_no}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>GSTIN Number</b></div>
                <div className='m-0'>{data.gstin}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>CIN Number</b></div>
                <div className='m-0'>{data.cin}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>TAN Number</b></div>
                <div className='m-0'>{data.tan_no}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Email</b></div>
                <div className='m-0'>{data.email_id}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Website</b></div>
                <div className='m-0'>{data.website}</div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col sm={4}>
          <Card>
            <CardHeader>Address Information</CardHeader>
            <CardBody>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>State</b></div>
                <div className='m-0'>{data.state_name}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>District</b></div>
                <div className='m-0'>{data.district_name}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>City</b></div>
                <div className='m-0'>{data.city}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Address</b></div>
                <div className='m-0'>{data.address}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Pincode</b></div>
                <div className='m-0'>{data.pincode}</div>
              </div>

            </CardBody>
          </Card>  
        </Col>
        <Col sm={4}>
          <Card>
            <CardHeader>Bank Information</CardHeader>
            <CardBody>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Bank Name</b></div>
                <div className='m-0'>{data.bank_name}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Account Number</b></div>
                <div className='m-0'>{data.account_no}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Account Type</b></div>
                <div className='m-0'>{data.account_type}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>IFS Code</b></div>
                <div className='m-0'>{data.ifsc_code}</div>
              </div>
              <div className='d-flex justify-content-between mb-1'>
                <div className='text-muted'><b>Branch</b></div>
                <div className='m-0'>{data.branch}</div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default ViewVendor;