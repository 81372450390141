import React from 'react';
import Header from '../component/layout/Header';
import Footer from '../component/layout/Footer';
import Page from '../component/utility/Page';
import {
    Row,
    Col,
} from 'reactstrap';

function WIP() {
    return (
        <div>
            <Header />
            <Page title="Work in progress" breadcrumbs="Home / Work in progress">
                <Row className='p-2'>
                    <Col xs="12" className='text-center'>
                        <img className='img-fluid' alt="loading..." src={require('./../assets/img/wip.gif')} />
                    <h1>Work in Progress</h1> 
                    </Col>
                </Row>
            </Page>
            <Footer />
        </div>
    )
}

export default WIP;