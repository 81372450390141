import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import notification from '../../component/hocs/notification';
import mainurl from '../../MainURL';
import axios from 'axios';
import Page from '../../component/utility/Page';
import DataTable from 'react-data-table-component';
import EditAsset from '../../component/utility/master/EditAsset';
import * as XLSX from 'xlsx/xlsx.mjs';
import {SiMicrosoftexcel} from 'react-icons/si';
import Tooltip from '@mui/material/Tooltip';
import {
    Col, Row, Card, CardHeader, CardBody, Button, Input, Label, Form, InputGroup, InputGroupText, Spinner,
    Modal, ModalBody, ModalHeader
} from 'reactstrap';

import FileBase64 from 'react-file-base64';
import {FaEdit} from 'react-icons/fa';
import {MdDeleteForever, } from 'react-icons/md';

const CompanyDocument = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState("");
    const [editModel, setEditModel] = useState(false);
    const [modelData, setModelData] = useState("");

    const [docName, setDocName] = useState('');
    const [docNumber, setDocNumber] = useState('');
    const [docDesc, setDocDesc] = useState('');
    const [issueDate, setIssueDate] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [fileLink, setFileLink] = useState('');
    const [fileExt, setFileExt] = useState('');

    const [accData, setAccData] = useState('');

    useEffect(() => {
        // check Access
        let accData = JSON.parse(sessionStorage.getItem('accessControl'));
        setAccData(accData[3].pages[18]);
        if(accData[3].pages[18].page_view !== "1"){
            navigate("/home");
        }
        
    }, []);

    useEffect(() => {
        if(accData){
            get_list();
        }
    }, [accData]);

    const get_list = () => {
        let postData = [{ "org_id": 100 }];
        axios({
          method: 'post',
          url: mainurl + 'Master/box_company_doc_list',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            let list = response.data.map(row => {
                return(
                    {
                        ids: row.document_id,
                        name: row.name,
                        number: row.number,
                        desc: row.desc,
                        issue_date: row.issue_date.split(" ")[0].split('-').reverse().join('-'),
                        expiry_date: row.expiry_date.split(" ")[0].split('-').reverse().join('-'),
                        file_link: <a href={row.file_link} target="_blank">Link</a>,
                        action: <div>
                            { accData.page_edit === "1" && <FaEdit className='me-3 cursor' color="#047e04" onClick={()=> open_edit_modal(row)} size={20} />}
                            { accData.page_delete === "1" && <MdDeleteForever color="#f70000" size={20} />}
                        </div>
                    }
                )
            })

            setTableData(list);
          })
          .catch(error => {
            //let notify = notification({ message: "Document list not Found! Try Again", type: 'error' });
            //notify();
          });
    }


    const add_document_handler = (e) => {
        e.preventDefault();
        let postData = [
            {
              "org_id":"100",
              "name": docName,
              "number": docNumber,
              "desc": docDesc,
              "issue_date": issueDate,
              "expiry_date": expiryDate,
              "file_link": fileLink,
              "file_ext": fileExt
            }
        ];
        console.log(postData);

       axios({
            method: 'post',
            url: mainurl + 'Master/box_company_doc_add',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
          })
            .then(function (response) {
                if(response.data[0].retcode === "101"){
                    let notify = notification({ message: "Added successfully.", type: 'success' });
                    notify();
                    
                }
            })
            .catch(error => {
                let notify = notification({ message: "Add failed! Try Again", type: 'error' });
                notify();
              });
    
    }

    const open_edit_modal = (row) => {
        let temp = <EditAsset  geo="Asset" data={row} close_handler={close_modal_handler} />

        setModelData(temp);
        setEditModel(true);
    }

    const close_modal_handler = () => {
        setEditModel(false);
        get_list();
    }

    const download_excel_handler = () => {
        if(tableData.length > 0){
          let wb = XLSX.utils.book_new(),
          ws = XLSX.utils.json_to_sheet(tableData);
    
          XLSX.utils.book_append_sheet(wb, ws, "Mysheet1");
    
          XLSX.writeFile(wb, "CompanyDocument.xlsx");
        }
      }

    return (
        <>
            <Header />
            <Page title="Company Document" breadcrumbs="Master / Company Document">
                <Row className="p-3">
                    <Col xs={12}>
                    { accData.page_add === "1" && <Card className='mb-2'>
                            <CardHeader>Add New Document</CardHeader>
                            <CardBody>
                                <Form onSubmit={add_document_handler}>
                                    <Row>
                                        <Col md={2}>
                                            <Label for="docName">Document Name</Label>
                                            <Input id="docName" type='text' bsSize="sm" maxLength={50} value={docName} onChange={(e) => setDocName(e.target.value)} required />
                                        </Col>
                                        <Col md={2}>
                                            <Label for="docNumber">Document Number</Label>
                                            <Input id="docNumber" type='text' bsSize="sm" maxLength={50} value={docNumber} onChange={(e) => setDocNumber(e.target.value)} required />
                                        </Col>
                                        
                                        <Col md={2}>
                                            <Label for="issueDate"> Issue Date </Label>
                                            <Input id="issueDate" type='date' bsSize="sm" value={issueDate} onChange={(e) => setIssueDate(e.target.value)} required />
                                        </Col>
                                        <Col md={2}>
                                            <Label for="expiryDate"> Expiry Date </Label>
                                            <Input id="expiryDate" type='date' bsSize="sm" value={expiryDate} onChange={(e) => setExpiryDate(e.target.value)} required />
                                        </Col>
                                        <Col md={3}>
                                            <Label for="file"> File </Label><br/>
                                            <FileBase64
                                                class="form-control"
                                                multiple={ false }
                                                onDone={ (files)=> {setFileLink(files.base64); setFileExt((files.file.name).split('.')[1])} } />
                                        </Col>
                                        <Col md={4}>
                                            <Label for="docDesc"> Description </Label>
                                            <Input id="docDesc" type='textarea' row={2} bsSize="sm" maxLength={250} value={docDesc} onChange={(e) => setDocDesc(e.target.value)} required />
                                        </Col>
                                        
                                        <Col md={2}>
                                            <Button className='mt-4' color="primary" size="sm" >Add Document</Button>
                                        </Col>
                                    </Row>

                                </Form>
                            </CardBody>
                        </Card>}

                        <Card className='mb-2'>
                            <CardHeader>Company Document List</CardHeader>
                            <div style={{position:'relative', height:'1px'}}>
                                <Tooltip title="Excel download">
                                    <SiMicrosoftexcel className='excelIcon' size={20} onClick={download_excel_handler} />
                                </Tooltip> 
                            </div>
                            <CardBody>
                                <DataTable
                                    title=""
                                    columns={[
                                        {
                                        name: 'Id',
                                        selector: row=>row.ids,
                                        sortable: true,
                                        },
                                        {
                                        name: 'Document Number',
                                        selector: row=>row.name,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Document Number',
                                        selector: row=>row.number,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Description',
                                        selector: row=>row.desc,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Issue Date',
                                        selector: row=>row.issue_date,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Expiry Date',
                                        selector: row=>row.expiry_date,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Document Link',
                                        selector: row=>row.file_link,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Action',
                                        selector: row=>row.action,
                                        sortable: false,
                                        },
                                    ]}
                                    data={tableData}
                                    striped
                                    highlightOnHover
                                    progressPending={loading}
                                    />
                            </CardBody>
                        </Card> 
                    </Col>
                </Row>

            </Page>
            <Modal
                centered
                scrollable
                size="lg"
                isOpen={editModel}
                toggle={() => setEditModel(!editModel)}
            >
                <ModalHeader toggle={() => setEditModel(!editModel)}>
                   Edit Document
                </ModalHeader>
                <ModalBody>
                    {modelData}
                </ModalBody>
            </Modal>
            <Footer />
        </>
    )
}

export default CompanyDocument;