import React from 'react';
import {
  Col,
  Row,
} from 'reactstrap';
import {MdDeleteForever} from 'react-icons/md';

const PurchaseItem = ({val, index, delete_item, ...restProps }) => {
  return (
    <Row className='mb-1 '>
        <Col xs={1}> {index + 1} </Col>
        <Col xs={2}> {val.medicineName} </Col>
        <Col xs={1}> {val.qty} </Col>
        <Col xs={1}>&#8377; {Number(val.unit_price).toFixed(2)} </Col>
        <Col xs={2}> {val.source} </Col>
        <Col xs={1}> {val.date} </Col>
        <Col xs={1}> <MdDeleteForever color="#f70000" className='cursor' size={20} onClick={delete_item} /> </Col>
    </Row>
  );
};

export default PurchaseItem;