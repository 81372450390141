import React, {useState, useEffect} from 'react';
import notification from '../../hocs/notification';
import axios from 'axios';
import mainurl from '../../../MainURL';
import {
    Col, Row, Button, Input, Label, Form, InputGroup, InputGroupText, Spinner, Card, CardHeader, CardBody, CardFooter
} from 'reactstrap';

import { FaHeading} from 'react-icons/fa';

const EditBod = ({data, back_handler, ...props}) => {
    console.log(data);
    const [hindiNameLoading, setHindiNameLoading] = useState(false);
    const [name, setName] = useState(data.bod_name_desc);
    const [nameHin, setNameHin] = useState(data.bod_name_hindi);
    const [gender, setGender] = useState(data.gender);
    const [category, setCategory] = useState(data.caste);
    const [mobileNo, setMobileNo] = useState(data.mobile_no);
    const [aadharNo, setAadharNo] = useState(data.uid_no);
    const [pan, setPan] = useState(data.pan_no);
    const [email, setEmail] = useState(data.email_id);
    const [education, setEducation] = useState(data.education);
    const [designation, setsetConstitutionType] = useState(data.constitution_type);
    const [joiningDate, setJoiningDate] = useState(data.ele_nom_date);
    
    const [state, setState] = useState(data.state_id);
    const [district, setDistrict] = useState("");
    const [block, setBlock] = useState("");
    const [panchayat, setPanchayat] = useState("");
    const [village, setVillage] = useState("");
    const [address, setAddress] = useState(data.address);
    const [pincode, setPincode] = useState(data.pincode);
    
    const [bankName, setBankName] = useState(data.bank_id);
    const [accountNo, setAccountNo] = useState(data.account_no);
    const [accountType, setAccountType] = useState(data.account_type);
    const [ifsc, setIfsc] = useState(data.ifsc_code);
    const [branchAddress, setBranchAddress] = useState(data.branch);

    const [constitutionType, setConstitutionType] = useState(data.constitution_type);
    const [bankOpt, setBankOpt] = useState("");
    const [districtOpt, setDistrictOpt] = useState("");
    const [blockOpt, setBlockOpt] = useState("");
    const [panchayatOpt, setPanchayatOpt] = useState("");
    const [villageOpt, setVillageOpt] = useState("");
    const [fisrtLoad, setFisrtLoad] = useState(1);

    useEffect(() => {
        get_init();
        getDistrictList();
    }, []);

    const get_init = () => {
      let postData = [{ "org_id": 100, "user_id": "0"}];
        // Bank list  
        axios({
          method: 'post',
          url: mainurl + 'Master/bank_list',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            let list = response.data.map((row, index) => {
              return <option key={index} value={row.bank_id}> {row.bank_name} </option>
            })
            setBankOpt(list);
           
          })
          .catch(error => {
            let notify = notification({ message: "Bank list not Found! Try Again", type: 'error' });
            notify();
          });
    }

    // district, block, panchayat, village API call
    const getDistrictList = () => {
        
    
        let postData = [{ "org_id": 100, "user_id": "0" }];
        axios({
          method: 'post',
          url: mainurl + 'Geo/geo_district_list2',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            let list = response.data.map((row, index) => {
              return <option key={index} value={row.district_short_cd}> {row.district_name} </option>
            })
            setDistrictOpt(list);
            if(fisrtLoad === 1){
              getBlockList({target: {value: data.dist_id}});
            }
           
          })
          .catch(error => {
            let notify = notification({ message: "District list not Found! Try Again", type: 'error' });
            notify();
          });
      }
    
      const getBlockList = (e) => {
        console.log(e)
        setDistrict(e.target.value);

        let postData = [{ "org_id": 100, "user_id": "0", "dist_id": e.target.value }]
        axios({
          method: 'post',
          url: mainurl + 'Geo/geo_block_list',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            let list = response.data.map((row, index) => {
              return <option key={index} value={row.block_code}> {row.block_long_name} </option>
            })
            setBlockOpt(list);
            if(fisrtLoad === 1){
              getPanchayatList({target: {value: data.block_id}});
            }
            
          })
          .catch(error => {
            let notify = notification({ message: "Block list not Found! Try Again", type: 'error' });
            notify();
          });
      }
    
      const getPanchayatList = (e) => {
        setBlock(e.target.value);
        setPanchayat("");
        setVillage("");
        let postData = [{ "org_id": 100, "user_id": "0", "block_id": e.target.value }]
        axios({
          method: 'post',
          url: mainurl + 'Geo/geo_pan_list',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            let list = response.data.map((row, index) => {
              return <option key={index} value={row.panchyat_code}> {row.panchyat_name} </option>
            })
    
            setPanchayatOpt(list);
            if(fisrtLoad === 1){
              getVillageList({target: {value: data.pan_id}});
            }
            
          })
          .catch(error => {
            let notify = notification({ message: "Panchayat list not Found! Try Again", type: 'error' });
            notify();
          });
      }
      const getVillageList = (e) => {
        setPanchayat(e.target.value);
        
        let postData = [{ "org_id": 100, "user_id": "128", "panchayat_id": e.target.value }]
        axios({
          method: 'post',
          url: mainurl + 'Geo/geo_village_list',
          data: postData,
          headers: { 'Content-Type': 'application/JSON', }
        })
          .then(function (response) {
            let list = response.data.map((row, index) => {
                return <option key={index} value={row.village_code}> {row.village_name} </option>
              })
      
              setVillageOpt(list);
              if(fisrtLoad === 1){
                setVillage(data.vil_id);
                setFisrtLoad(2);
              }
            
          })
          .catch(error => {
            let notify = notification({ message: "Village list not Found! Try Again", type: 'error' });
            notify();
          });
      }
      // End
 
    
      const translate_text_handler = (e) => {
        setHindiNameLoading(true);
        if(name.length > 1){
            
            let postData = [{ "t_text" : name}];
            axios({
                method: 'post',
                url: mainurl + 'Master/translate_text',
                data: postData,
                headers: { 'Content-Type': 'application/JSON', }
              })
                .then(function (response) {
                    let trans = response.data;
                    setNameHin(trans);
                    setHindiNameLoading(false);
                })
                .catch(error => {
                    setHindiNameLoading(false);
                    let notify = notification({ message: "Hindi Translation is not working! Try Again", type: 'error' });
                    notify();
                  });

        }else{
            setHindiNameLoading(false);
            let notify = notification({ message: "Please enter geographic english name first.", type: 'warn' });
            notify();
        }
    }

    const edit_handler = (e) => {
        e.preventDefault();

        let postData = [
            {
                "org_id": "100",
                "user_id":"128",
                "bod_id": data.bod_id,
                "bod_name_desc": name, 
                "bod_name_hindi": nameHin, 
                "gender": gender, 
                "mobile_no": mobileNo, 
                "uid_no": aadharNo, 
                "pan_no": pan, 
                "email_id": email, 
                "education": education, 
                "caste": category, 
                "constitution_type": constitutionType, 
                "ele_nom_date": joiningDate, 
                "state_id": state, 
                "dist_id": district, 
                "block_id": block, 
                "pan_id": panchayat, 
                "vil_id": village, 
                "pincode": pincode, 
                "address": address, 
                "bank_id": bankName, 
                "account_no": accountNo, 
                "account_type": accountType, 
                "ifsc_code": ifsc, 
                "branch": branchAddress 
            }
        ];

        console.log(postData);

        axios({
            method: 'post',
            url: mainurl + 'Master/box_bod_edit',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
          })
            .then(function (response) {
              let notify = notification({ message: "updated successfully", type: 'success' });
              notify();
              back_handler();
              
            })
            .catch(error => {
              let notify = notification({ message: "Update failed! Try Again", type: 'error' });
              notify();
            });

    }

    return(
        <Card>
            <CardHeader>Add New Board Of Director</CardHeader>
            <CardBody>
                <Form onSubmit={edit_handler}>
                    <Row>
                        <Col md={2} className="mb-2">
                            <Label for="name"> BOD Name</Label>
                            <Input id="name" type='text' bsSize="sm" maxLength={50} onKeyPress={(e) => !/^[a-zA-Z\s]*$/.test(e.key) && e.preventDefault()} value={name} onChange={(e) => setName(e.target.value)} required />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="hName"> BOD Name</Label>
                            <InputGroup size="sm">
                                <Input id="hName" type='text' value={nameHin} bsSize="sm" maxLength={50} onChange={(e)=>setNameHin(e.target.value)} />
                                <InputGroupText className='bg-primary cursor' onClick={!hindiNameLoading ? translate_text_handler : undefined }>
                                    {hindiNameLoading ? <Spinner color="secondary" size="sm">Loading...</Spinner> : <FaHeading color="#fff" />}
                                </InputGroupText>
                            </InputGroup>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="gender"> Gender </Label>
                            <Input id="gender" bsSize="sm" type="select" value={gender} onChange={(e)=> setGender(e.target.value)} required>
                                <option value=""> --Select Gender--</option>
                                <option value="Male"> Male </option>
                                <option value="Female"> Female </option>
                            </Input>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="category"> Category </Label>
                            <Input id="category" bsSize="sm" type="select" value={category} onChange={(e)=> setCategory(e.target.value)} required>
                                <option value=""> --Select category--</option>
                                <option value="general"> General </option>
                                <option value="ST/SC"> ST/SC </option>
                                <option value="OBC"> OBC </option>
                            </Input>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="name"> Mobile Number</Label>
                            <Input id="name" type='text' bsSize="sm" maxLength={10} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} value={mobileNo} onChange={(e) => setMobileNo(e.target.value)} required />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="name"> Aadhar Number</Label>
                            <Input id="name" type='text' bsSize="sm" maxLength={16} value={aadharNo} onChange={(e) => setAadharNo(e.target.value)} required />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="name"> PAN Number</Label>
                            <Input id="name" type='text' bsSize="sm" maxLength={16} value={pan} onChange={(e) => setPan(e.target.value)} required />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="email"> Email </Label>
                            <Input id="email" type='email' bsSize="sm" value={email} onChange={(e) => setEmail(e.target.value)} required />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="education"> Education </Label>
                            <Input id="education" type='text' bsSize="sm" maxLength={50} value={education} onChange={(e) => setEducation(e.target.value)} required />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="ConstitutionType"> Constitution Type </Label>
                            <Input id="ConstitutionType" bsSize="sm" type="select" value={constitutionType} onChange={(e)=> setConstitutionType(e.target.value)} required>
                                <option value=""> --Select Constitution Type--</option>
                                <option value="Elected"> Elected</option>
                                <option value="Nominated"> Nominated</option>
                            </Input>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="joiningDate"> Elected/Nominated Date </Label>
                            <Input id="joiningDate" type='date' bsSize="sm" value={joiningDate} onChange={(e) => setJoiningDate(e.target.value)} required />
                        </Col>
                        <hr />
                        <Col md={2} className="mb-2">
                            <Label for="state"> State </Label>
                            <Input id="state" bsSize="sm" type="select" value={state} onChange={(e)=> setState(e.target.value)} required>
                                <option value=""> --Select State--</option>
                                <option value="10"> BIHAR </option>
                                
                            </Input>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="district"> District </Label>
                            <Input id="district" bsSize="sm" type="select" value={district} onChange={getBlockList} required>
                                <option value=""> --Select District--</option>
                                {districtOpt}
                            </Input>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="block"> Block </Label>
                            <Input id="block" bsSize="sm" type="select" value={block} onChange={getPanchayatList} required>
                                <option value=""> --Select Block--</option>
                                {blockOpt}
                            </Input>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="panchayat"> Panchayat </Label>
                            <Input id="panchayat" bsSize="sm" type="select" value={panchayat} onChange={getVillageList} required>
                                <option value=""> --Select Panchayat--</option>
                                {panchayatOpt}
                            </Input>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="village"> Village </Label>
                            <Input id="village" bsSize="sm" type="select" value={village} onChange={(e)=> setVillage(e.target.value)} required>
                                <option value=""> --Select Village--</option>
                                {villageOpt}
                            </Input>
                        </Col>
                        <hr />
                        <Col md={2} className="mb-2">
                            <Label for="address"> Address </Label>
                            <Input id="address" type='text' bsSize="sm" maxLength={150} value={address} onChange={(e) => setAddress(e.target.value)} required />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="pincode"> Pincode </Label>
                            <Input id="pincode" type='text' bsSize="sm" maxLength={6} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} value={pincode} onChange={(e) => setPincode(e.target.value)} required />
                        </Col>

                        <hr />

                        <Col md={2} className="mb-2">
                            <Label for="bankName"> Bank Name </Label>
                            <Input id="bankName" bsSize="sm" type="select" value={bankName} onChange={(e)=> setBankName(e.target.value)} required>
                                <option value=""> --Select Bank--</option>
                                {bankOpt}
                            </Input>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="accountNo"> Account Number </Label>
                            <Input id="accountNo" type='text' bsSize="sm" maxLength={20} value={accountNo} onChange={(e) => setAccountNo(e.target.value)} required />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="accountType"> Account Type </Label>
                            <Input id="accountType" bsSize="sm" type="select" value={accountType} onChange={(e)=> setAccountType(e.target.value)} required>
                                <option value=""> --Select Account Type--</option>
                                <option value="saving"> Saving </option>
                                <option value="current"> Current </option>
                            </Input>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="ifsc"> IFSC Code </Label>
                            <Input id="ifsc" type='text' bsSize="sm" maxLength={15} value={ifsc} onChange={(e) => setIfsc(e.target.value)} required />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="branchAddress"> Branch Address </Label>
                            <Input id="branchAddress" type='text' bsSize="sm" maxLength={50} value={branchAddress} onChange={(e) => setBranchAddress(e.target.value)} required />
                        </Col>

                        <hr />
                        <Col md={2} className="mb-2">
                            <Button color='primary' size="sm" block> Submit </Button>
                        </Col>
                    </Row>

                </Form>
            </CardBody>
            <CardFooter className='text-end'>
                <Button color='primary' size="sm" onClick={back_handler}> Back </Button>
            </CardFooter>
        </Card>
    )
}

export default EditBod;