import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import ModulePages from '../../component/utility/ModulePages';
import notification from '../../component/hocs/notification';
import mainurl from '../../MainURL';
import axios from 'axios';
import Page from '../../component/utility/Page';

import {
    Col, Row, Card, CardBody, Button, Input, Label, 
} from 'reactstrap';

const AccessControl = () => { 
    const navigate = useNavigate();
    const [role, setRole] = useState('');
    const [accessData, setAccessData] = useState('');
    const [accData, setAccData] = useState('');

    useEffect(() => {
        // check Access
        let accData = JSON.parse(sessionStorage.getItem('accessControl'));
        setAccData(accData[3].pages[14]);
        if(accData[3].pages[14].page_view !== "1"){
            navigate("/home");
        }
    }, []);

    const submit_handler = () => {
        setAccessData('');
        let sendData = [{ "org_id": "100", "role_id": role }]
        axios({
            method: 'post',
            url: mainurl + 'Master/get_access_control_list',
            data: sendData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                setAccessData(response.data);
            })
            .catch(error => {
                let notify = notification({ message: "Data not Found! Try Again", type: 'error' });
                notify();
            })
    }

    
    return(
        <>
           <Header />
           <Page title="Access Control" breadcrumbs="Master / Access Control">
                <Row className='p-3'>
                    <Col xs={12}>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md={2} className="mb-2">
                                        <Label for="role"> Role</Label>
                                        <Input id="role" bsSize="sm" type="select" value={role} onChange={(e)=>setRole(e.target.value)}>
                                            <option value=""> --Select Role--</option>
                                            <option value="101"> Admin </option>
                                            <option value="102"> District </option>
                                            <option value="103"> Block </option>
                                            <option value="104"> APS </option>
                                        </Input>
                                    </Col>
                                    <Col xs={2} className="mb-2">
                                        <Button className="mt-4" color="primary" size="sm" onClick={submit_handler}> Submit </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={12}>
                        <Card>
                            <CardBody>
                                {accessData &&
                                    accessData.map(row => {
                                        return(
                                            <ModulePages data={row}> </ModulePages>
                                        )
                                    })
                                }   
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
           </Page>
           <Footer /> 
        </>
    )
}

export default AccessControl;