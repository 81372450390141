import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import notification from '../../hocs/notification';
import mainurl from '../../../MainURL';
import axios from 'axios';

import { Col, Row, Card, CardBody, Input, CardHeader, Label, Button, Spinner } from 'reactstrap';
import PurchaseItem from './PurchaseItem';


const InputPurchaseEntry = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [districtOpt, setDistrictOpt] = useState();
    const [blockOpt, setBlockOpt] = useState();
    const [villageOpt, setVillageOpt] = useState();
    const [panchyatOpt, setPanchyatOpt] = useState();
    const [pashusakhiOpt, setPashusakhiOpt] = useState();
    const [farmerOpt, setFarmerOpt] = useState();
    const [district, setDistrict] = useState("0");
    const [block, setBlock] = useState("0");
    const [panchyat, setPanchyat] = useState("0");
    const [pashusakhi, setPashusakhi] = useState("0");
    const [pashusakhiName, setPashusakhiName] = useState("");

    const [medicineOpt, setMedicineOpt] = useState("");
    const [medicine, setMedicine] = useState("");
    const [medicineName, setMedicineName] = useState("");
    const [qty, setQty] = useState("");
    const [price, setPrice] = useState("");
    const [source, setSource] = useState("");
    const [sourceOpt, setSourceOpt] = useState("");
    const [purDate, setPurDate] = useState("");

    const [purchaseList, setPurchaseList] = useState("");


    const [dist_role, setDist_role] = useState(false);
    const [block_role, setBlock_role] = useState(false);
    const [count, setCount] = useState(1);

    useEffect(() => {
        // check Access
        let accData = JSON.parse(sessionStorage.getItem('accessControl'));

        if (accData[2].pages[2].page_view !== "1") {
            navigate("/home");
        }
        //
        getMedicineList();
        checkRole();
        getDistrictList();
    }, []);

    const checkRole = () => {
        let role = sessionStorage.getItem('role');
        if (role === "101") {
            getDistrictList();
            setCount(2);
        } else if (role === "102") {
            let dist = sessionStorage.getItem('dist');
            setDist_role(true);
            getBlockList({ target: { value: dist } });
            setCount(2);
        } else if (role === "103") {
            let dist = sessionStorage.getItem('dist');
            setDist_role(true);
            setBlock_role(true);
            getBlockList({ target: { value: dist } });

        }
    }

    const getDistrictList = () => {
        setBlock("0");

        let postData = [{ "org_id": 100, "user_id": "0" }];
        axios({
            method: 'post',
            url: mainurl + 'Geo/geo_district_list2',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return <option key={index} value={row.district_short_cd}> {row.district_name} </option>
                })
                setDistrictOpt(list);
            })
            .catch(error => {
                let notify = notification({ message: "District list not Found! Try Again", type: 'error' });
                notify();
            });
    }

    const getBlockList = (e) => {
        setDistrict(e.target.value);
        setBlock("0");
        let postData = [{ "org_id": 100, "user_id": "0", "dist_id": e.target.value }]
        axios({
            method: 'post',
            url: mainurl + 'Geo/geo_block_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return <option key={index} value={row.block_code}> {row.block_long_name} </option>
                })
                setBlockOpt(list);
                if (count === 1) {
                    setCount(2);
                    let block = sessionStorage.getItem('block');
                    setBlock(block);
                }
            })
            .catch(error => {
                let notify = notification({ message: "Block list not Found! Try Again", type: 'error' });
                notify();
            });
    }
    const getPanchayatList = (e) => {
        setBlock(e.target.value);
        setPanchyat("0");
        let postData = [{ "org_id": 100, "user_id": "0", "block_id": e.target.value }]
        axios({
            method: 'post',
            url: mainurl + 'Geo/geo_pan_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return <option key={index} value={row.panchyat_code}> {row.panchyat_name} </option>
                })

                setPanchyatOpt(list);
            })
            .catch(error => {
                let notify = notification({ message: "Panchayat list not Found! Try Again", type: 'error' });
                notify();
            });
    }

    const getPashusakhiList = (e) => {
        setPashusakhi("");
        setPashusakhiName("");
        setPanchyat(e.target.value);

        let postData = [{ "org_id": 101, "user_id": 128, "geo_type_id": "3", "geo_id": e.target.value }]
        axios({
            method: 'post',
            url: mainurl + 'Geo/geo_aps_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    let name = row.aps_name + '[' + row.aps_id + ']';
                    return <option key={index} value={row.aps_id}> {name} </option>
                })

                setPashusakhiOpt(list);
            })
            .catch(error => {
                let notify = notification({ message: "pashusakhi list not Found! Try Again", type: 'error' });
                notify();
            });
    }

    const getMedicineList = (e) => {
        let postData = [{ "org_id": 100, "type": 0 }];
        axios({
            method: 'post',
            url: mainurl + 'Master/box_input_view',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {

                let list = response.data.filter((r) => {
                    return (r.type == 1 || r.type == 5 || r.type == 6)
                }
                ).map((row, index) => {
                    return <option key={index} value={row.medicine_id}> {row.description_h} </option>
                })
                setMedicineOpt(list);
            })
            .catch(error => {
                let notify = notification({ message: "UOM list not Found! Try Again", type: 'error' });
                notify();
            });
    }

    const add_handler = () => {
        let list = {
            "medicine_id": medicine,
            "medicineName": medicineName,
            "qty": qty,
            "unit_price": price,
            "source": source,
            "date": purDate
        };

        let prevList = [...purchaseList];
        prevList.push(list);
        setPurchaseList(prevList);

        console.log(prevList);

        setMedicine('0');
        setQty('');
        setPrice('');
        setSource('0');
        setPurDate('');

    }

    const purchase_item_delete = (id) => {
        let prevList = [...purchaseList];
        prevList.splice(id, 1);
        setPurchaseList(prevList);
    }

    const purchase_submit_handler = () => {
        let flag = true;

        if (panchyat === "0") {
            flag = false;
            let notify = notification({ message: "Please select Panchayat. ", type: 'warn' });
            notify();
        }
        if (block === "0") {
            flag = false;
            let notify = notification({ message: "Please select Block. ", type: 'warn' });
            notify();
        }
        if (district === "0") {
            flag = false;
            let notify = notification({ message: "Please select District. ", type: 'warn' });
            notify();
        }
        if (pashusakhi === "0") {
            flag = false;
            let notify = notification({ message: "Please select Pashusakhi. ", type: 'warn' });
            notify();
        }
        if (purchaseList.length < 1) {
            flag = false;
            let notify = notification({ message: "Please Add Medicine List. ", type: 'warn' });
            notify();
        }

        if (flag) {
            setLoading(true);
            let postData = [{
                "org_id": 100,
                "user_id": 128,
                "aps_id": pashusakhi,
                "medicine_list": purchaseList
            }]
            console.log(postData);


            axios({
                method: 'post',
                url: mainurl + 'Activity/input_purchase_web',
                data: postData,
                headers: { 'Content-Type': 'application/JSON', }
            })
                .then(function (response) {
                    setLoading(false);
                    if (response.data[0].retcode == 101) {
                        setDistrict('0');
                        setBlock('0');
                        setBlockOpt('');
                        setPanchyat('0');
                        setPanchyatOpt('');
                        setPashusakhi('0');
                        setPashusakhiOpt('')
                        setPurchaseList('');
                        let notify = notification({ message: "Added sucessfully.", type: 'success' });
                        notify();
                    }
                })
                .catch(error => {
                    setLoading(false);
                    let notify = notification({ message: "Failed! Try Again", type: 'error' });
                    notify();
                });
        }

    }

    return (
        <>
            <Row className="p-3">
                <Col xs={12}>
                    <Card>
                        <CardHeader>Input Purchase Entry</CardHeader>
                        <CardBody>
                            <Row>
                                <Col xs={2} className="mb-1">
                                    <Label className="me-sm-2" for="district"> District </Label>
                                    <Input id="district" bsSize="sm" type="select" value={district} onChange={(e) => getBlockList(e)} disabled={dist_role}>
                                        <option value="0"> --Select District--</option>
                                        {districtOpt}
                                    </Input>
                                </Col>
                                <Col xs={2} className="mb-1">
                                    <Label className="me-sm-2" for="block"> Block </Label>
                                    <Input id="block" bsSize="sm" type="select" value={block} onChange={getPanchayatList} disabled={block_role}>
                                        <option value="0"> --Select Block--</option>
                                        {blockOpt}
                                    </Input>
                                </Col>
                                <Col xs={2} className="mb-1">
                                    <Label className="me-sm-2" for="panchayat"> Panchayat </Label>
                                    <Input id="panchayat" bsSize="sm" type="select" value={panchyat} onChange={getPashusakhiList}>
                                        <option value="0"> --Select Panchayat--</option>
                                        {panchyatOpt}
                                    </Input>
                                </Col>
                                <Col xs={3} className="mb-1">
                                    <Label className="me-sm-2" for="pashusakhi"> Pashusakhi </Label>
                                    <Input id="pashusakhi" bsSize="sm" type="select" value={pashusakhi} onChange={(e) => { setPashusakhi(e.target.value); setPashusakhiName(e.target.selectedOptions[0].text); }}>
                                        <option value="0"> --Select Pashusakhi--</option>
                                        {pashusakhiOpt}
                                    </Input>
                                </Col>

                                <Col xs={12} className="mt-3 ">
                                    <hr />
                                </Col>

                                <Col xs={3}>
                                    <Label className="me-sm-2" for="medicine"> Medicine </Label>
                                    <Input id="medicine" bsSize="sm" type="select" value={medicine} onChange={(e) => { setMedicine(e.target.value); setMedicineName(e.target.selectedOptions[0].text) }}>
                                        <option value="0"> --Select Medicine--</option>
                                        {medicineOpt}
                                    </Input>
                                </Col>
                                <Col xs={1}>
                                    <Label className="me-sm-2" for="qty"> Quantity </Label>
                                    <Input id="qty" bsSize="sm" type="text" value={qty} onChange={(e) => setQty(e.target.value)} />
                                </Col>
                                <Col xs={1}>
                                    <Label className="me-sm-2" for="price"> Price/Unit </Label>
                                    <Input id="price" bsSize="sm" type="text" value={price} onChange={(e) => setPrice(e.target.value)} />
                                </Col>
                                <Col xs={2}>
                                    <Label className="me-sm-2" for="Source"> Source </Label>
                                    <Input id="Source" bsSize="sm" type="select" value={source} onChange={(e) => setSource(e.target.value)} >
                                        <option value="0"> --Select Source--</option>
                                        <option value="Demo-Center"> Demo-Center </option>
                                        <option value="Pashusaakhi">  Pashusaakhi </option>
                                        <option value="Shop"> Shop </option>
                                        <option value="Other"> Other </option>

                                    </Input>
                                </Col>
                                <Col xs={2}>
                                    <Label className="me-sm-2" for="purDate"> Purchase Date </Label>
                                    <Input id="purDate" bsSize="sm" type="date" value={purDate} onChange={(e) => setPurDate(e.target.value)} />
                                </Col>
                                <Col xs={2} className="mb-1">
                                    <Button className="mt-4" color="primary" size="sm" onClick={add_handler}> Add </Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Card className='mt-3'>
                        <CardHeader>Purchase List</CardHeader>
                        <CardBody>
                            <Row>
                                <Col xs={1}> <b>SL No</b> </Col>
                                <Col xs={2}> <b>Medicine</b> </Col>
                                <Col xs={1}> <b>Quantity</b> </Col>
                                <Col xs={1}> <b>Price/unit</b> </Col>
                                <Col xs={2}> <b>Source</b> </Col>
                                <Col xs={1}> <b>Purchase Date</b> </Col>
                                <Col xs={1}> <b>Action</b> </Col>
                            </Row>
                            <hr />
                            {purchaseList.length > 0 ? purchaseList.map((row, index) => {
                                return (
                                    <PurchaseItem val={row} index={index} delete_item={() => purchase_item_delete(index)} />
                                )
                            })
                                :
                                <div>No list Found!</div>

                            }

                            <Row>
                                <Col xs="12">
                                    <Button className="mt-4" color="primary" size="" onClick={purchase_submit_handler} disabled={loading}> Submit {loading && <Spinner color="secondary ms-1" size="sm" >Load...</Spinner>} </Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}
export default InputPurchaseEntry;